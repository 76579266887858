import { A } from '@solidjs/router';
import { For, Show } from 'solid-js';
import NoVendorImg from '~/assets/images/empty/noVendor.svg';
import IconHeader from '~/assets/images/vendors/headerImg.png';
// import IconWorkOrder from '~/assets/images/vendors/workOrders.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import { FeedbackIcon } from '~/components/common/FeedbackIcon';
import { FeedbackGroup } from '~/components/common/FeedbackIcon/Group';
import { IconMail, IconPhone, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';

const VendorCard: Component<{ vendor?: MagicDoor.Api.HydratedVendorDto }> = (props) => {
  const { t } = useLocalization();
  return (
    <A
      href={props.vendor ? `/maintenance/vendors/${props.vendor.id}` : '#'}
      class="group rounded-lg border border-partingline bg-white transition hover-allowed:hover:shadow-md">
      <FeedbackGroup
        class="relative p-4"
        style={{
          'background-image': `url(${IconHeader})`,
          'background-size': 'cover',
          'background-position': 'right',
        }}>
        <Show when={props.vendor} fallback={<Skeleton class="h-7 w-44" />}>
          <h2 class="truncate text-lg font-semibold">{props.vendor?.name}</h2>
        </Show>
        <Show
          when={props.vendor}
          fallback={
            <div class="flex h-10 items-center gap-1">
              <Skeleton class="size-6 rounded-full" />
              <Skeleton class="size-6 rounded-full" />
            </div>
          }>
          <FeedbackIcon alwaysShow type="vendors" entityId={props.vendor?.id} class="my-2" initalSelectedIcons={props.vendor?.icons} />
        </Show>
      </FeedbackGroup>
      <div class="p-4 pt-0 text-xs text-text-level02">
        <div class="flex flex-col gap-y-2 py-3">
          <div class="flex items-center gap-2">
            <div class="rounded-full border p-1.5">
              <IconPhone class="size-4 text-text-level03" />
            </div>
            <Show when={props.vendor} fallback={<Skeleton class="w-36" />}>
              <div>{props.vendor?.contact.phone || emptyPlaceholder}</div>
            </Show>
          </div>
          <div class="flex items-center gap-2">
            <div class="rounded-full border p-1.5">
              <IconMail class="size-4 text-text-level03" />
            </div>
            <Show when={props.vendor} fallback={<Skeleton class="w-44" />}>
              <div class="truncate">{props.vendor?.contact.email || emptyPlaceholder}</div>
            </Show>
          </div>
        </div>
        {/* TODD: BACKEND NOT READY */}
        {/* <div class="flex items-center justify-between rounded-lg bg-input px-3 py-2">
          <div class="flex items-center gap-2 text-link">
            <IconWorkOrder class="size-4" />
            <span class="capitalize">{t('Work order')}</span>
          </div>
          <Show when={props.vendor} fallback={<Skeleton class="w-10" />}>
            <div class="text-xl font-semibold"> -- </div>
          </Show>
        </div>
        <div class="flex flex-col gap-y-3 py-3 text-text-level03">
          <div class="flex items-center justify-between gap-2">
            <div>{t('Last work order')}</div>
            <Show when={props.vendor} fallback={<Skeleton class="w-24" />}>
              <div class="font-semibold text-link"> -- </div>
            </Show>
          </div>
          <div class="flex items-center justify-between gap-2">
            <div>{t('Balance due')}</div>
            <Show when={props.vendor} fallback={<Skeleton class="w-20" />}>
              <div class="font-semibold text-error"> -- </div>
            </Show>
          </div>
        </div> */}
        <div class="rounded-lg border p-2 text-center text-sm transition-colors hover-allowed:group-hover:border-primary hover-allowed:group-hover:bg-light-pink">
          {t('View details')}
        </div>
      </div>
    </A>
  );
};

const VendorListView: Component<{ loading: boolean; vendors?: MagicDoor.Api.HydratedVendorDto[] }> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="grid gap-4 text-black md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <Show when={!props.loading} fallback={<For each={new Array(8)}>{() => <VendorCard />}</For>}>
        <Show
          when={props.vendors && props.vendors.length > 0}
          fallback={
            <div class="col-span-full rounded-lg bg-white">
              <Empty class="my-10" imgSrc={NoVendorImg} description={t(`No vendors found`)} />
            </div>
          }>
          <For each={props.vendors}>{(vendor) => <VendorCard vendor={vendor} />}</For>
        </Show>
      </Show>
    </div>
  );
};

export default VendorListView;
