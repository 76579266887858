import { For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { cn } from '~/utils/classnames';
import { safeCall } from '~/utils/tool';
import type { JSX } from 'solid-js';

type ValueType = string | boolean;

export const Radio = (props: {
  items: Array<{ label: JSX.Element; value: ValueType }>;
  value?: ValueType;
  onInput?: (value: ValueType) => void;
  class?: string;
  direction?: 'row' | 'column';
  id?: string;
}) => {
  return (
    <div class={cn('flex cursor-pointer items-center gap-4', { 'flex-col items-start': props.direction === 'column' }, props.class)}>
      <For each={props.items}>
        {(item) => (
          <Checkbox
            id={`${props.id}-${item.value}`}
            showLabel
            htmlType="radio"
            class="rounded-full hover-allowed:hover:before:opacity-0"
            label={item.label}
            checked={props.value === item.value}
            onInput={(checked) => {
              if (!checked) return;
              safeCall(props.onInput, item.value);
            }}
          />
        )}
      </For>
    </div>
  );
};
