import { createSignal, createMemo } from 'solid-js';
import { IconDownload, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { decodeFileName, downloadFile } from '~/utils/file';
import { FilePreviewModal } from './FilePreviewModal';

type Attachment = {
  id: string;
  fileName: string;
  fileSize: number;
  uploaded: string;
  signedUrl: string;
};

interface SimpleFileListViewProps {
  attachments: Attachment[];
  title?: string;
}

export const SimpleFileListView = (props: SimpleFileListViewProps) => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('SimpleFileListView');
  const [previewFile, setPreviewFile] = createSignal<any | undefined>(undefined);

  const getFileIconClass = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'i-mdi-file-pdf-box text-red-500';
      case 'doc':
      case 'docx':
        return 'i-mdi-file-word-box text-blue-500';
      case 'xls':
      case 'xlsx':
        return 'i-mdi-file-excel-box text-green-500';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        return 'i-mdi-file-image-box text-purple-500';
      default:
        return 'i-mdi-file-box text-gray-500';
    }
  };

  const handlePreview = (file: Attachment) => {
    setPreviewFile(file);
  };

  const closePreview = () => {
    setPreviewFile(undefined);
  };

  const attachmentList = createMemo(() => props.attachments || []);

  return (
    <div class="relative">
      <ListView
        page={page()}
        pageSize={pageSize()}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        title={props.title || t('File Attachments')}
        onRowClick={(item) => handlePreview(item)}
        data={attachmentList()}
        loading={false}
        skeletonSize={10}
        columns={[
          {
            title: t('File'),
            render: (row: Attachment) => (
              <div class="flex items-center">
                <i class={`${getFileIconClass(row.fileName)} mr-2 text-2xl`} />
                <div>
                  <div class="mb-1 font-medium">{decodeFileName(row.fileName)}</div>
                  <div class="text-gray-400">{`${(row.fileSize / 1024).toFixed(2)} KB`}</div>
                </div>
              </div>
            ),
          },
          {
            title: t('Uploaded'),
            render: (row: Attachment) => <div class="font-medium">{dateFormat(undefined, row.uploaded)}</div>,
          },
          {
            title: t('Actions'),
            render: (row: Attachment) => (
              <div class="flex gap-2">
                <button
                  class="text-blue-500 hover-allowed:hover:text-blue-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile(row.signedUrl);
                  }}
                  title={t('Download')}>
                  <IconDownload class="size-5" />
                </button>
              </div>
            ),
          },
        ]}
      />
      <FilePreviewModal file={previewFile()} onClose={closePreview} />
    </div>
  );
};

export default SimpleFileListView;
