import { useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, Show } from 'solid-js';
import IconAdd from '~/assets/images/bill/add.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper, SimpleRemover } from '~/components/common/BetterForm/FormListRemover';
import { LinkButton } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BillFileListView } from '~/components/file-attachments/BillFileListView';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

const AddBillForm: Component<{ form?: BetterForm.ContextValue; class?: string }> = (props) => {
  const { t } = useLocalization();
  const [query] = useSearchParams();
  const defaultVendorId = createMemo(() => (query.vendorId ? [query.vendorId] : []));

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <Form
        initialValues={{
          billDate: dayjs().format('YYYY-MM-DD'),
          dueDate: dayjs().format('YYYY-MM-DD'),
        }}
        defaultForm={props.form}
        class={cn('text-left', props.class)}>
        <Section title={t('Vendor information')} border>
          <div class="col-span-full lg:col-span-2">
            <FormItem
              class="w-full"
              label={t(`Vendor`)}
              formFieldName="vendorId"
              component={LabeledVendorSearch}
              rules={[{ required: true, message: t(`Please select vendor`) }]}
              placeholder={t('Please select vendor')}
              defaultSelectedIds={defaultVendorId()}
            />
          </div>
        </Section>
        <Section title={t('Bill dates')} border>
          <div class="grid grid-cols-2 gap-5">
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="billDate"
              label={t('Bill date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Bill date') }) }]}
              component={DueDateInputField}
            />
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="dueDate"
              label={t('Due date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
              component={DueDateInputField}
            />
          </div>
        </Section>
        <Section title={t('Bill detail')} border>
          <div class="grid grid-cols-2 gap-5">
            <FormItem
              label={t('Property, unit or lease')}
              class="col-span-full lg:col-span-2"
              formFieldName="propertyId"
              rules={[{ required: true, message: t('Please select property, unit or lease') }]}
              component={LabeledLeveledSearchSelect}
              enabledTypes={[LevelSearch.Property, LevelSearch.Unit, LevelSearch.Lease]}
              onChange={(_, variants) => {
                props.form?.setFieldsValue(
                  {
                    propertyId: variants.leveledIds.at(0)?.propertyId,
                    unitId: variants.leveledIds.at(0)?.unitId,
                    leaseId: variants.leveledIds.at(0)?.leaseId,
                  },
                  false
                );
              }}
            />
            <FormItem
              class="col-span-full lg:col-span-2"
              formFieldName="memo"
              label={t('Memo')}
              component={LabeledTextInput}
              placeholder={t('Please enter the memo for this bill')}
            />
            <FormItem
              class="col-span-full lg:col-span-2"
              formFieldName="reference"
              label={t('Reference')}
              component={LabeledTextInput}
              placeholder={t('Please enter the reference for this bill')}
            />
          </div>
        </Section>
        <Section title={t('Bill line items')} border>
          <FormList
            formFieldName={'lineItems'}
            renderItemsGroup={({ index, remove }) => {
              return (
                <FormListRemoverWrapper index={index} class="flex-row items-center gap-3 pb-4 last:mb-0 last:border-b-0">
                  <div class="grid grow grid-cols-3 gap-5">
                    <FormItem
                      class="col-span-full lg:col-span-1"
                      placeholder={t('Please select chart of account')}
                      label={t(`Chart of account`)}
                      formFieldName={['chartOfAccountId']}
                      component={LabeledChartOfAccountSelect}
                      types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                      rules={[{ message: t(`Please select chart of account`), required: true }]}
                    />
                    <FormItem
                      class="col-span-full lg:col-span-1"
                      placeholder={t('Please input the amount')}
                      label={t(`Amount`)}
                      formFieldName={['amount']}
                      prepend="$"
                      component={LabeledNumberInput}
                      rules={[{ message: t(`Please input the amount`), required: true }]}
                    />
                    <FormItem
                      class="col-span-full lg:col-span-1"
                      placeholder={t('Please input description')}
                      label={t(`Description`)}
                      formFieldName={['description']}
                      component={LabeledTextInput}
                    />
                  </div>
                  <Show when={index() !== 0} fallback={<div class="w-9" />}>
                    <div class="pt-7">
                      <SimpleRemover index={index} remove={remove} class="ml-0 mt-0" />
                    </div>
                  </Show>
                </FormListRemoverWrapper>
              );
            }}
            autoCombineFieldName={false}>
            {({ add }) => {
              return (
                <LinkButton
                  href=""
                  variant="outlined"
                  rounded="full"
                  class="gap-1 border-0 !bg-transparent p-0 text-xs text-link hover-allowed:hover:text-light-link [&_span]:gap-1 [&_span]:whitespace-nowrap"
                  onClick={add}>
                  <img src={IconAdd} />
                  <span>{t('Add line item')}</span>
                </LinkButton>
              );
            }}
          </FormList>
        </Section>
        <BillFileListView onlyUpload={true} autoUpload={false} />
      </Form>
    </div>
  );
};

export default AddBillForm;
