import { onMount } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import { IconCirclePlus, Pagination, SearchListInput } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import VendorListView from './components/VendorListView';

export const ListVendorsPage = () => {
  const { t } = useLocalization();
  const { filtered, setFilter } = useVendors();

  onMount(() => setFilter({ page: 1, pageSize: 100 }));

  return (
    <div class="text-sm" classList={{ 'cursor-wait': filtered.loading }}>
      <div class="mb-5 flex w-full items-center justify-between rounded-lg border border-partingline bg-white px-6 py-3">
        <div class="flex items-center gap-4">
          <div class="text-lg font-semibold">{t('Vendors')}</div>
          <SearchListInput
            placeholder={t('Search vendors')}
            onChange={(value) => setFilter({ page: 1, pageSize: filtered()?.pageSize, search: value })}
          />
        </div>
        <LinkButton href="/maintenance/vendors/add" noScroll>
          <IconCirclePlus class="size-5" />
          <div class="capitalize">{t('Add vendor')}</div>
        </LinkButton>
      </div>
      <VendorListView loading={filtered.loading} vendors={filtered()?.items ?? []} />

      <Pagination
        class="mt-4 flex justify-center"
        total={filtered()?.totalPages ?? 0}
        value={filtered()?.currentPage ?? 1}
        onChange={(value) => setFilter({ page: value, pageSize: filtered()?.pageSize })}
      />
    </div>
  );
};
