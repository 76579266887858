import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { CheckIfTaxDocumentsCanBeGenerated } from '~/pdfsigner/usecases/taxes/checkIfTaxDocumentsCanBeGenerated';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';

export class GenerateOwnerTaxDocsUseCase extends MagicUseCase {
  public async runLogic(vendorId: string) {
    await new CheckIfTaxDocumentsCanBeGenerated().execute();
    const selectedOwnersAndAmounts = this.getState().taxInfo.ownersAndAmounts.filter((vendor) => vendor.isSelected);
    if (vendorId) {
      await taxDocumentsRepository.regenerateOwnerTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        ownerData: selectedOwnersAndAmounts[0],
      });
      this.navigate(`/users/owners/${vendorId}/tax-documents`);
    } else {
      await taxDocumentsRepository.generateOwnerTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        ownerData: selectedOwnersAndAmounts,
      });
      this.navigate('/users/owners/tax-documents');
    }
  }
}
