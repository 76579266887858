import { createEffect } from 'solid-js';
import IconBankMagic from '~/assets/images/common/bankMagic.png';
import IconTreeBg from '~/assets/images/common/treeBg.png';
import { useForm } from '~/components/common/BetterForm';
import { Panel } from '~/components/common/Panels';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { isEmptyData } from '~/utils/tool';
import BankAccountForm from './BankAccountForm';
import type { Setter } from 'solid-js';
interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  class?: string;
}

function AddNewBankAccount(props: Props) {
  const { t } = useLocalization();
  const { addBankAccount } = useBankAccount();
  const form = useForm();

  const handleAddBankAccount = async () => {
    await addBankAccount(form.formStore as MagicDoor.Api.CreateBankAccountDto);
    props.onClose();
    toast.success(t('{name} has been added successfully', { name: t('Bank account') }));
  };

  createEffect(() => {
    props.setShowCloseConfirmation && props.setShowCloseConfirmation(!isEmptyData(form.formStore));
  });

  return (
    <Panel title={t('Add bank account')} class={props.class}>
      <div
        class="flex h-36 w-full justify-center bg-[#F5F6FE]"
        style={{
          'background-image': `url(${IconTreeBg})`,
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }}>
        <img src={IconBankMagic} />
      </div>

      <BankAccountForm class="h-[calc(100%-205px)]" form={form} onSubmit={handleAddBankAccount} />
    </Panel>
  );
}

export default AddNewBankAccount;
