import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledUploadImage } from '~/components/common/Inputs/LabeledUploadImage';
import { Modal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization, useUnits } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { formConfirmationContent, MAX_INPUT_AMOUNT } from '~/utils/constant';
import { getImageUrl } from '~/utils/file';
import type { Photo } from '~/components/common/Inputs/LabeledUploadImage';

const formId = 'add-or-edit-unit';
const formFields = ['name', 'unitSizeSqft', 'beds', 'baths'] as const;

export const AddUnit = () => {
  const { isMobileView } = useDeviceDetect();
  const { t } = useLocalization();
  const params = useParams<{ propertyId: string; unitId?: string }>();
  const goBack = useGoBack();
  const form = useForm();
  const { unit, getUnit, addUnit, updateUnit } = useUnits();
  const [image, setImage] = createSignal<Photo>();
  const [uploading, setUploading] = createSignal<boolean>(false);

  const isEditing = createMemo(() => params.unitId != null && /^\d+$/.test(params.unitId));

  const isFormDirty = createMemo(() => {
    if (isEditing()) {
      return formFields.some((field) => form.formStore[field] !== unit()?.[field]);
    }
    return formFields.some((field) => form.formStore[field]);
  });

  const setDefaultUnit = createMemo(() => {
    unit()?.image && setImage({ id: unit()?.image?.fileId, url: getImageUrl('unit', params?.unitId as string, unit()?.image?.fileId) });
    return {
      name: '',
      unitSizeSqft: 0,
      beds: 0,
      baths: 0,
      ...unit(),
      imageId: unit()?.image?.fileId,
    };
  });
  const uploadChange = (fieldId: string | undefined, url: string | undefined) => {
    if (fieldId) {
      setImage({ id: fieldId, url });
    } else {
      setImage();
    }
  };

  createEffect(() => isEditing() && getUnit(params.unitId as string));
  createEffect(() => form.setFieldsValue(isEditing() ? setDefaultUnit() : { propertyId: params.propertyId }));

  const handleSubmit = async () => {
    if (isEditing()) {
      await updateUnit(params.unitId as string, form.formStore as MagicDoor.Api.UpdateUnitDto);
      toast.success(t('{name} has been edited successfully', { name: t('Unit') }));
    } else {
      await addUnit(form.formStore as MagicDoor.Api.CreateUnitDto);
      toast.success(t('{name} has been added successfully', { name: t('Unit') }));
    }
    goBack();
  };

  const breadcrumbItems = createMemo(() => [{ label: isEditing() ? t('Edit unit') : t('Add unit') }]);

  const FormContent = () => {
    return (
      <Form class="thinscroll overflow-auto px-3 py-10 md:px-8" id={formId} onFormSubmit={handleSubmit} defaultForm={form}>
        <div class="mb-6 grid grid-cols-1 gap-x-7 gap-y-8 md:grid-cols-2">
          <FormItem
            label={t('Unit name')}
            rules={[
              { length: 150, message: t('Name must be less than 150 characters') },
              {
                required: true,
                message: t('{name} is required', { name: 'Name' }),
              },
            ]}
            placeholder={t('Please enter')}
            component={LabeledTextInput}
            formFieldName="name"
          />
          <FormItem
            label={t('Property size(sqft)')}
            placeholder={t('Please enter')}
            component={LabeledNumberInput}
            formFieldName="unitSizeSqft"
            rules={[
              {
                range: [0, MAX_INPUT_AMOUNT],
                type: 'int',
                message: t('Unit size must be between 0 and 10000'),
              },
            ]}
            getValueFromEvent={(e) => +e}
          />
          <FormItem
            component={LabeledNumberInput}
            label={t('Bedroom')}
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bedroom must be between 0 and 100'),
              },
            ]}
            placeholder={t('Please enter')}
            formFieldName="beds"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
          <FormItem
            component={LabeledNumberInput}
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bathroom must be between 0 and 100'),
              },
            ]}
            label={t('Bathroom')}
            placeholder={t('Please enter')}
            formFieldName="baths"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
        </div>
        <div>
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="imageId"
            component={LabeledUploadImage}
            image={image()}
            onInput={uploadChange}
            type="units"
            onUploadingChange={setUploading}
          />
        </div>
      </Form>
    );
  };

  return (
    <Show
      when={isMobileView()}
      fallback={
        <Modal
          confirmation={isFormDirty() ? formConfirmationContent(t) : false}
          visible
          title={t(isEditing() ? 'Edit unit' : 'Add unit')}
          doneText={t(isEditing() ? 'Update unit' : 'Create unit')}
          submitId={formId}
          disabled={unit.loading || uploading()}
          loading={addUnit.pending || updateUnit.pending}
          onCancel={() => goBack()}>
          <FormContent />
        </Modal>
      }>
      <div>
        <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

        <div class="m-3 mb-[66px] rounded-lg bg-white">
          <FormContent />
          <div class="fixed inset-x-0 bottom-0 flex justify-end gap-2  bg-white p-3">
            <Button variant="outlined" onClick={() => goBack()}>
              {t('Cancel')}
            </Button>
            <Button class="flex w-full justify-center" type="submit" onClick={handleSubmit}>
              {t(isEditing() ? 'Update unit' : 'Create unit')}
            </Button>
          </div>
        </div>
      </div>
    </Show>
  );
};
