import { Show, createMemo, createSignal, createEffect } from 'solid-js';
import IconForm from '~/assets/images/common/form.svg';
import IconPending from '~/assets/images/common/pending.svg';
import IconPlaid from '~/assets/third-parties/plaidSm.svg';
import IconStripe from '~/assets/third-parties/stripeSm.svg';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccountsList, usePlaid, useStripe, PlaidLinkStatus, StripeLinkStatus } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { currency } from '~/utils/number';

type StripeDetails = {
  [key: string]: {
    pending: number;
    available: number;
  };
};

export const BankAccountsListView = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('BankAccountsListView');
  const { isLoading, fetchBankAccounts, bankAccounts, filterItems, filterValue, onResetFilter, onFilterChange, filteredBankAccounts } =
    useBankAccountsList();
  const { linkStripeWithAccount, stripeUrl, getStripeDetails, isStripeLoading } = useStripe();
  const { linkPlaidWithAccount, openPlaidLink, isPlaidLoading } = usePlaid();
  const [localStripeDetails, setLocalStripeDetails] = createSignal<StripeDetails | undefined>({});

  const linkPlaid = async (bankAccountId: string | undefined) => {
    await linkPlaidWithAccount(bankAccountId).then(() => fetchBankAccounts());
  };

  const openStripeLink = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    await linkStripeWithAccount(bankAccountId);
    const url = stripeUrl();
    if (url) {
      window.open(url, '_blank');
    }
  };

  createEffect(() => {
    bankAccounts().forEach((bankAccount) => {
      if (bankAccount?.stripe?.chargesEnabled) {
        getStripeDetails(bankAccount.id).then((details) => {
          if (details) {
            setLocalStripeDetails((prevDetails) => ({
              ...prevDetails,
              [bankAccount.id]: {
                pending: details.pending,
                available: details.available,
              },
            }));
          }
        });
      }
    });
  });

  return (
    <ListView
      class="border"
      title={t('Bank account overview')}
      loading={isLoading()}
      data={filteredBankAccounts()}
      page={page()}
      pageSize={pageSize()}
      filterItems={filterItems()}
      searchPlaceholder={t('Search bank accounts')}
      filterValue={filterValue()}
      onFilterReset={onResetFilter}
      onChange={(args, action) => {
        setPage(args.page);
        setPageSize(args.pageSize);
        onFilterChange(args, action);
      }}
      filterPopoverOverlayClass="w-[22rem]"
      rowLink={(item) => `/accounting/bank-accounts/${item.id}`}
      columns={[
        {
          title: t('Bank account'),
          headerClass: 'w-[20%]',
          render: (bankAccount) => (
            <div class="flex items-center justify-start gap-2 whitespace-nowrap">
              <Avatar name={bankAccount.name || ''} />
              <div class="flex flex-col text-sm text-text-level01">{bankAccount.name}</div>
            </div>
          ),
        },
        {
          title: t('Plaid'),
          headerClass: 'w-60',
          render: (bankAccount) => {
            return (
              <div class="flex min-w-max gap-2 whitespace-nowrap">
                <Show when={bankAccount.plaidLinkStatus === PlaidLinkStatus.UNCONNECTED}>
                  <Button
                    onClick={() => linkPlaid(bankAccount.id)}
                    disabled={isPlaidLoading()}
                    size="xs"
                    class="rounded-full border-none bg-[#4B73FF] hover-allowed:hover:bg-[#4B73FF]/90">
                    <img src={IconPlaid} />
                    <span>{t('Link Plaid')}</span>
                  </Button>
                </Show>
                <Show when={bankAccount.plaidLinkStatus === PlaidLinkStatus.PENDING_QUESTIONNAIRE}>
                  <Button onClick={() => openPlaidLink(bankAccount.id)} disabled={isPlaidLoading()} class="rounded-full" size="xs">
                    <img src={IconForm} />
                    <span>{t('Start questionnaire')}</span>
                  </Button>
                </Show>
                <Show when={bankAccount.plaidLinkStatus === PlaidLinkStatus.PENDING_ORIGINATOR}>
                  <div class="flex gap-1 font-semibold text-[#FF8618]">
                    <img src={IconPending} />
                    <span>{t('Pending')}</span>
                  </div>
                </Show>
                <Show when={bankAccount.plaidLinkStatus === PlaidLinkStatus.CONNECTED}>
                  <div class="font-semibold text-[#28D9A5]">{t('Connected')}</div>
                </Show>
              </div>
            );
          },
        },
        {
          title: t('Plaid details'),
          headerClass: 'w-48',
          render: (bankAccount) => {
            return (
              <div class="flex flex-col gap-1 whitespace-nowrap text-xs">
                <div class="text-text-level03">
                  {t('Pending')}:
                  <Show when={bankAccount?.plaid.pending} fallback=" --">
                    <span class="font-semibold"> {currency(bankAccount?.plaid.pending || '')}</span>
                  </Show>
                </div>
                <div class="text-text-level03">
                  {t('Available')}:
                  <Show when={bankAccount?.plaid.available} fallback=" --">
                    <span class="font-semibold"> {currency(bankAccount?.plaid.available || '')}</span>
                  </Show>
                </div>
              </div>
            );
          },
        },
        {
          title: t('Stripe'),
          headerClass: 'w-60',
          render: (bankAccount) => {
            return (
              <div class="flex min-w-max gap-2 whitespace-nowrap">
                <Show when={bankAccount.stripeLinkStatus === StripeLinkStatus.UNCONNECTED}>
                  <Button
                    onClick={() => openStripeLink(bankAccount.id)}
                    disabled={isStripeLoading()}
                    size="xs"
                    class="rounded-full border-none bg-[#635BFF] hover-allowed:hover:bg-[#635BFF]/90">
                    <img src={IconStripe} />
                    <span>{t('Link Stripe')}</span>
                  </Button>
                </Show>
                <Show when={bankAccount.stripeLinkStatus === StripeLinkStatus.PENDING}>
                  <div class="flex gap-1 font-semibold text-[#FF8618]">
                    <img src={IconPending} />
                    <span>{t('Pending')}</span>
                  </div>
                </Show>
                <Show when={bankAccount.stripeLinkStatus === StripeLinkStatus.CONNECTED}>
                  <div class="font-semibold text-[#28D9A5]">{t('Connected')}</div>
                </Show>
              </div>
            );
          },
        },
        {
          title: t('Stripe details'),
          headerClass: 'w-48',
          render: (bankAccount) => {
            const details = createMemo(() => localStripeDetails()?.[bankAccount.id]);
            return (
              <div class="flex flex-col gap-1 whitespace-nowrap text-xs">
                <div class="text-text-level03">
                  {t('Pending')}:
                  <Show when={details()?.pending} fallback=" --">
                    <span class="font-semibold"> {currency(details()?.pending)}</span>
                  </Show>
                </div>
                <div class="text-text-level03">
                  {t('Available')}:
                  <Show when={details()?.available} fallback=" --">
                    <span class="font-semibold"> {currency(details()?.available)}</span>
                  </Show>
                </div>
              </div>
            );
          },
        },
        {
          title: t('Description'),
          headerClass: 'w-96',
          render: (bankAccount) => (
            <div class="w-96">
              <div class="truncate text-sm normal-case text-text-level01">{bankAccount.description}</div>
            </div>
          ),
        },
      ]}
    />
  );
};
