import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import IconApprove from '~/assets/images/rental-application/approve.svg?component-solid';
import IconConvertToLease from '~/assets/images/rental-application/convert-to-lease.svg?component-solid';
import IconConvertToTenant from '~/assets/images/rental-application/convert-to-tenant.svg?component-solid';
import IconIgnore from '~/assets/images/rental-application/quick-ignore.svg?component-solid';
import IconReject from '~/assets/images/rental-application/quick-reject.svg?component-solid';
import IconPayment from '~/assets/images/rental-application/receive-payment.svg?component-solid';
import IconRefundPayment from '~/assets/images/rental-application/refund-payment.svg?component-solid';
import { toast, confirm } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { ApplicationDecision } from '~/swagger/Api';
import { urlWithQuery } from '~/utils/url';
import { ActionButton } from './components/ActionButton';
import { ConvertToLeaseModal } from './components/ConvertToLeaseModal';
import { ConvertToTenantModal } from './components/ConvertToTenantModal';
import { ReceivePaymentModal } from './components/ReceivePaymentModal';
import { RefundPaymentModal } from './components/RefundPaymentModal';

export const QuickAction = () => {
  const navigate = useNavigate();

  const { t } = useLocalization();
  const { updateDecision, applicationStore, tenantConvert, paymentRefund, fetchApplication, setApplicationStore } = useRentalApplication();

  const [refundAmount, setRefundAmount] = createSignal<string>('');
  const [refundReason, setRefundReason] = createSignal<string>('');
  const [isRefundDisabled, setRefundDisabled] = createSignal<boolean>(false);
  const [unitId, setUnitId] = createSignal<string>('');
  const [showReceivePaymentModal, setShowReceivePaymentModal] = createSignal<boolean>(false);

  const onUpdateDecision = async (decision: MagicDoor.Api.ApplicationDecision, showToast = true) => {
    if (!applicationStore.application?.id) return;

    try {
      const response = await updateDecision(applicationStore.application.id, {
        decision,
      });

      setApplicationStore('application', {
        ...applicationStore.application,
        applicationDecision: response.applicationDecision,
      });

      showToast && toast.success(t('Operation successful'));
    } catch (error) {
      console.error('Failed to update decision:', error);
      toast.error(t('Failed to update decision'));
    }
  };

  const showRefundPaymentModal = () => {
    return confirm({
      title: t('Refund payment'),
      class: 'px-0',
      footerClass: 'px-6',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: () => (
        <RefundPaymentModal
          refundAmount={refundAmount()}
          refundReason={refundReason()}
          maxAmount={applicationStore.application?.paymentAmount || 0}
          onAmountChange={setRefundAmount}
          onReasonChange={setRefundReason}
        />
      ),
      confirmDisabled: () => isRefundDisabled(),
      async onResolve(confirmed) {
        if (!confirmed) {
          setRefundAmount('');
          setRefundReason('');
          return;
        }
        const applicationId = applicationStore.application?.id;
        if (!applicationId) return;
        try {
          await paymentRefund(applicationId, Number(refundAmount()), refundReason());
          fetchApplication(applicationId);
        } catch (error) {
          toast.error(t(`Operation failed, please try again later ${error}`));
        }
      },
    });
  };

  const convertToLease = () => {
    const params: any = {
      rentalApplicationId: applicationStore.application?.id,
      startDate: applicationStore.application?.desiredMoveInDate,
      convertTenant: 1,
    };

    const preferredUnitOptions: MagicDoor.Api.UnitDto[] = applicationStore.application?.interests?.map((item) => item.unit) || [];

    const navigateToNewLease = async () => {
      await onUpdateDecision(ApplicationDecision.Approved, false);
      const link = urlWithQuery(`/leasing/leases/new`, params);
      navigate(link);
    };

    return confirm({
      title: t('Convert to lease'),
      content: () => (
        <ConvertToLeaseModal
          preferredUnitOptions={preferredUnitOptions}
          onUnitSelect={(item) => {
            if (item) {
              params.portfolioId = item.portfolioId;
              params.propertyId = item.propertyId;
              params.unitId = item.id;
              setUnitId(item.id);
            } else {
              setUnitId('');
            }
          }}
        />
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        if (!unitId()) return toast.error(t('Please select unit'));
        await navigateToNewLease();
      },
      confirmDisabled: () => !unitId(),
    });
  };

  const convertToTenant = () => {
    const name = `${applicationStore.application?.firstName} ${applicationStore.application?.lastName}`;

    return confirm({
      title: t('Convert to tenant'),
      class: 'px-0',
      footerClass: 'px-5',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: <ConvertToTenantModal name={name} />,
      async onResolve(confirmed) {
        if (!confirmed) return;
        const applicationId = applicationStore.application?.id;
        if (!applicationId) return;
        const tenant = await tenantConvert(applicationId);
        navigate(`/users/tenants/${tenant.id}`);
      },
    });
  };

  createEffect(() => {
    setRefundDisabled(refundAmount().length === 0 || refundReason().length === 0 || refundReason().length > 150);
  });

  return (
    <>
      <div class="h-fit grow divide-y rounded-lg border border-partingline capitalize sm:mt-6 md:w-full lg:w-auto">
        <div class="flex h-11 items-center p-3 text-sm font-medium capitalize text-text-level01">{t('Quick action')}</div>

        <div class="flex flex-wrap items-center gap-4 p-5">
          <Show when={['noDecision', 'ignored'].includes(applicationStore.application?.applicationDecision as string)}>
            <ActionButton
              class="bg-[#D9F0EB]"
              textClass="text-light-green"
              dividerClass="bg-light-green/20"
              label={t('Approve')}
              icon={IconApprove}
              onClick={() => {
                onUpdateDecision(ApplicationDecision.Approved);
              }}
            />
            <ActionButton
              class="bg-[#DFE8FF]"
              textClass="text-[#3651BE]"
              dividerClass="bg-[#3651BE20]"
              label={t('Ignore')}
              icon={IconIgnore}
              onClick={() => {
                onUpdateDecision(ApplicationDecision.Ignored);
              }}
            />
            <ActionButton
              class="bg-[#F5DBE1]"
              textClass="text-danger"
              dividerClass="bg-danger/20"
              label={t('Reject')}
              icon={IconReject}
              onClick={() => {
                onUpdateDecision(ApplicationDecision.Rejected);
              }}
            />
          </Show>

          <Show when={applicationStore.application?.applicationDecision === 'approved'}>
            <Show when={!applicationStore.application?.tenant}>
              <ActionButton
                class="bg-[#5C63F4]"
                label={t('Convert to tenant')}
                icon={IconConvertToTenant}
                onClick={() => convertToTenant()}
              />
            </Show>
            <Show when={!applicationStore.application?.lease}>
              <ActionButton class="bg-primary" label={t('Convert to lease')} icon={IconConvertToLease} onClick={() => convertToLease()} />
            </Show>
          </Show>
          <Show
            when={
              applicationStore.application?.paymentStatus === 'paid' && applicationStore.application?.applicationDecision === 'rejected'
            }>
            <ActionButton class="bg-danger" label={t('Refund payment')} icon={IconRefundPayment} onClick={() => showRefundPaymentModal()} />
          </Show>

          <Show
            when={
              applicationStore.application?.paymentStatus === 'unpaid' && applicationStore.application?.applicationDecision !== 'rejected'
            }>
            <ActionButton
              class="bg-light-green"
              label={t('Receive payment')}
              icon={IconPayment}
              onClick={() => setShowReceivePaymentModal(true)}
            />
          </Show>
        </div>
      </div>
      <Show when={showReceivePaymentModal()}>
        <ReceivePaymentModal
          rentalApplicationId={applicationStore.application?.id}
          visible={[showReceivePaymentModal, setShowReceivePaymentModal]}
        />
      </Show>
    </>
  );
};
