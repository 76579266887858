import { Presenter } from '~/framework/Presenter';
import { TaxDocumentStatus, TaxDocumentType } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import { commaNumber, currency } from '~/utils/number';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type {
  PresentableOwnerTaxAmount,
  PresentableOwnerTaxAmountGroups,
  PresentableOwnerTaxDocument,
  PresentableOwnerTaxDocuments,
  PresentableOwnerTaxSummary,
  PresentablePdfBlob,
  PresentableVendorTaxAmount,
  PresentableVendorTaxAmountGroups,
  PresentableVendorTaxDocument,
  PresentableVendorTaxDocuments,
  PresentableVendorTaxSummary,
  PresentableYearlyOwnerTaxDocuments,
  PresentableYearlyVendorTaxDocuments,
} from '~/pdfsigner/ui/types/presentableTaxDocs';
import type { OwnerTaxAmount } from '~/pdfsigner/usecases/types/ownerTaxAmount';
import type { OwnerTaxDocument, VendorTaxDocument } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type { VendorTaxAmount } from '~/pdfsigner/usecases/types/vendorTaxAmount';

export class YearlyVendorTaxDocPresenter extends Presenter<PresentableYearlyVendorTaxDocuments[]> {
  createModel(state: PdfState): PresentableYearlyVendorTaxDocuments[] {
    const taxDocuments: VendorTaxDocument[] = state.taxInfo.paginatedVendorTaxDocuments.items;
    const yearlyMap: Map<string, Map<string, PresentableVendorTaxDocument[]>> = new Map();
    taxDocuments.forEach((doc, index) => {
      if (doc) {
        const year = doc.calendarYear.toString();
        const presentableDoc: PresentableVendorTaxDocument = {
          index,
          entityId: doc.entityId,
          name: doc.documentName,
          creationDate: new Date(doc.createdAt).toLocaleDateString(),
          documentType: getDocumentType(doc.taxDocumentType),
          amount: currency(doc.amount),
          status: getStatusText(doc.status),
          statusColor: getStatusColor(doc.status),
          statusBgColor: getStatusBgColor(doc.status),
          url: doc.url,
          isDocumentReady: doc.status === TaxDocumentStatus.Completed,
        };
        if (!yearlyMap.has(year)) {
          yearlyMap.set(year, new Map());
        }
        const vendorsMap = yearlyMap.get(year)!;
        if (!vendorsMap.has(doc.entityName)) {
          vendorsMap.set(doc.entityName, []);
        }
        vendorsMap.get(doc.entityName)!.push(presentableDoc);
      }
    });
    let indexOffset = 0;
    const yearlyArray: PresentableYearlyVendorTaxDocuments[] = Array.from(yearlyMap.entries()).map(([year, vendorsMap]) => {
      const vendorsArray: PresentableVendorTaxDocuments[] = Array.from(vendorsMap.entries()).map(([vendorName, taxDocuments]) => {
        const result = {
          indexOffset,
          vendorName,
          taxDocuments,
        };
        indexOffset += taxDocuments.length;
        return result;
      });
      return {
        year,
        vendors: vendorsArray,
      };
    });
    return yearlyArray;
  }
}

export class VendorTaxAmountPresenter extends Presenter<PresentableVendorTaxSummary> {
  createModel(state: PdfState): PresentableVendorTaxSummary {
    return {
      years: state.taxInfo.availableYears,
      selectedYear: state.taxInfo.selectedYear,
      vendorTaxAmounts: {
        newOrChanged: state.taxInfo.vendorsAndAmounts
          .filter((vendorTaxAmount: VendorTaxAmount) => vendorTaxAmount.hasAmountChanged || !vendorTaxAmount.doesTaxDocExist)
          .map((vendorTaxAmount: VendorTaxAmount) => createPresentableVendorTaxAmount(vendorTaxAmount)),
        existing: state.taxInfo.vendorsAndAmounts
          .filter((vendorTaxAmount: VendorTaxAmount) => !vendorTaxAmount.hasAmountChanged && vendorTaxAmount.doesTaxDocExist)
          .map((vendorTaxAmount: VendorTaxAmount) => createPresentableVendorTaxAmount(vendorTaxAmount)),
      } as PresentableVendorTaxAmountGroups,
      hasAmountsToShow: state.taxInfo.vendorsAndAmounts.length > 0,
    };
  }
}

export class SelectedVendorTaxAmountPresenter extends Presenter<PresentableVendorTaxAmountGroups> {
  createModel(state: PdfState): PresentableVendorTaxAmountGroups {
    const selectedVendors = state.taxInfo.vendorsAndAmounts.filter((vendorTaxAmount: VendorTaxAmount) => vendorTaxAmount.isSelected);
    return {
      newOrChanged: selectedVendors
        .filter((vendorTaxAmount: VendorTaxAmount) => vendorTaxAmount.hasAmountChanged || !vendorTaxAmount.doesTaxDocExist)
        .map((vendorTaxAmount: VendorTaxAmount) => createPresentableVendorTaxAmount(vendorTaxAmount)),
      existing: selectedVendors
        .filter((vendorTaxAmount: VendorTaxAmount) => !vendorTaxAmount.hasAmountChanged && vendorTaxAmount.doesTaxDocExist)
        .map((vendorTaxAmount: VendorTaxAmount) => createPresentableVendorTaxAmount(vendorTaxAmount)),
    } as PresentableVendorTaxAmountGroups;
  }
}

const createPresentableVendorTaxAmount = (vendorTaxAmount: VendorTaxAmount): PresentableVendorTaxAmount => {
  return {
    vendorId: vendorTaxAmount.vendorId,
    vendorName: vendorTaxAmount.vendorName,
    taxAmount: commaNumber(vendorTaxAmount.taxAmount),
    id: vendorTaxAmount.accountId,
    isSelected: vendorTaxAmount.isSelected,
    hasTaxId: vendorTaxAmount.hasTaxId,
  };
};

export class MergedTaxDocPdfPresenter extends Presenter<PresentablePdfBlob> {
  createModel(state: PdfState): PresentablePdfBlob {
    return { pdf: state.taxInfo.mergedPdf };
  }
}

export class YearlyOwnerTaxDocPresenter extends Presenter<PresentableYearlyOwnerTaxDocuments[]> {
  createModel(state: PdfState): PresentableYearlyOwnerTaxDocuments[] {
    const taxDocuments: OwnerTaxDocument[] = state.taxInfo.paginatedOwnerTaxDocuments.items;
    const yearlyMap: Map<string, Map<string, PresentableOwnerTaxDocument[]>> = new Map();
    taxDocuments.forEach((doc, index) => {
      if (doc) {
        const year = doc.calendarYear.toString();
        const presentableDoc: PresentableOwnerTaxDocument = {
          index,
          entityId: doc.entityId,
          name: doc.documentName,
          creationDate: new Date(doc.createdAt).toLocaleDateString(),
          documentType: getDocumentType(doc.taxDocumentType),
          rentAmount: currency(doc.rentAmount),
          otherAmount: currency(doc.otherAmount),
          status: getStatusText(doc.status),
          statusColor: getStatusColor(doc.status),
          statusBgColor: getStatusBgColor(doc.status),
          url: doc.url,
          isDocumentReady: doc.status === TaxDocumentStatus.Completed,
        };
        if (!yearlyMap.has(year)) {
          yearlyMap.set(year, new Map());
        }
        const ownersMap = yearlyMap.get(year)!;
        if (!ownersMap.has(doc.entityName)) {
          ownersMap.set(doc.entityName, []);
        }
        ownersMap.get(doc.entityName)!.push(presentableDoc);
      }
    });
    let indexOffset = 0;
    const yearlyArray: PresentableYearlyOwnerTaxDocuments[] = Array.from(yearlyMap.entries()).map(([year, ownersMap]) => {
      const ownerArray: PresentableOwnerTaxDocuments[] = Array.from(ownersMap.entries()).map(([ownerName, taxDocuments]) => {
        const result = {
          indexOffset,
          ownerName,
          taxDocuments,
        };
        indexOffset += taxDocuments.length;
        return result;
      });
      return {
        year,
        owners: ownerArray,
      };
    });
    return yearlyArray;
  }
}

export class SelectedOwnerTaxAmountPresenter extends Presenter<PresentableOwnerTaxAmountGroups> {
  createModel(state: PdfState): PresentableOwnerTaxAmountGroups {
    const selectedOwners = state.taxInfo.ownersAndAmounts.filter((ownerTaxAmount: OwnerTaxAmount) => ownerTaxAmount.isSelected);
    return {
      newOrChanged: selectedOwners
        .filter((ownerTaxAmount: OwnerTaxAmount) => ownerTaxAmount.hasAmountChanged || !ownerTaxAmount.doesTaxDocExist)
        .map((ownerTaxAmount: OwnerTaxAmount) => createPresentableOwnerTaxAmount(ownerTaxAmount))
        .sort((a, b) => a.ownerName.localeCompare(b.ownerName)),
      existing: selectedOwners
        .filter((ownerTaxAmount: OwnerTaxAmount) => !ownerTaxAmount.hasAmountChanged && ownerTaxAmount.doesTaxDocExist)
        .map((ownerTaxAmount: OwnerTaxAmount) => createPresentableOwnerTaxAmount(ownerTaxAmount))
        .sort((a, b) => a.ownerName.localeCompare(b.ownerName)),
    } as PresentableOwnerTaxAmountGroups;
  }
}

export class OwnerTaxAmountPresenter extends Presenter<PresentableOwnerTaxSummary> {
  createModel(state: PdfState): PresentableOwnerTaxSummary {
    return {
      years: state.taxInfo.availableYears,
      selectedYear: state.taxInfo.selectedYear,
      ownerTaxAmounts: {
        newOrChanged: state.taxInfo.ownersAndAmounts
          .filter((ownerTaxAmount: OwnerTaxAmount) => ownerTaxAmount.hasAmountChanged || !ownerTaxAmount.doesTaxDocExist)
          .map((ownerTaxAmount: OwnerTaxAmount) => createPresentableOwnerTaxAmount(ownerTaxAmount))
          .sort((a, b) => a.ownerName.localeCompare(b.ownerName)),
        existing: state.taxInfo.ownersAndAmounts
          .filter((ownerTaxAmount: OwnerTaxAmount) => !ownerTaxAmount.hasAmountChanged && ownerTaxAmount.doesTaxDocExist)
          .map((ownerTaxAmount: OwnerTaxAmount) => createPresentableOwnerTaxAmount(ownerTaxAmount))
          .sort((a, b) => a.ownerName.localeCompare(b.ownerName)),
      } as PresentableOwnerTaxAmountGroups,
      hasAmountsToShow: state.taxInfo.ownersAndAmounts.length > 0,
    };
  }
}

const createPresentableOwnerTaxAmount = (ownerTaxAmount: OwnerTaxAmount): PresentableOwnerTaxAmount => {
  return {
    ownerId: ownerTaxAmount.ownerId,
    ownerName: ownerTaxAmount.ownerName,
    rentAmount: commaNumber(ownerTaxAmount.rentAmount),
    otherAmount: commaNumber(ownerTaxAmount.otherAmount),
    id: ownerTaxAmount.accountId,
    isSelected: ownerTaxAmount.isSelected,
    hasTaxId: ownerTaxAmount.hasTaxId,
  };
};

const getStatusText = (status: TaxDocumentStatus): string => {
  switch (status) {
    case TaxDocumentStatus.NotStarted:
      return 'Not Started';
    case TaxDocumentStatus.InProgress:
      return 'In Progress';
    case TaxDocumentStatus.Completed:
      return 'Completed';
    case TaxDocumentStatus.Failed:
      return 'Failed';
    case TaxDocumentStatus.NotFound:
      return 'Not Found';
    default:
      return 'Unknown';
  }
};

const getStatusColor = (status: TaxDocumentStatus): string => {
  switch (status) {
    case TaxDocumentStatus.NotStarted:
      return 'text-gray-500';
    case TaxDocumentStatus.InProgress:
      return 'text-sky-500';
    case TaxDocumentStatus.Completed:
      return 'text-teal-500';
    case TaxDocumentStatus.Failed:
      return 'text-red-500';
    case TaxDocumentStatus.NotFound:
      return 'text-gray-500';
    default:
      return 'text-gray-500';
  }
};

const getStatusBgColor = (status: TaxDocumentStatus): string => {
  switch (status) {
    case TaxDocumentStatus.NotStarted:
      return 'bg-gray-100';
    case TaxDocumentStatus.InProgress:
      return 'bg-sky-100';
    case TaxDocumentStatus.Completed:
      return 'bg-teal-100';
    case TaxDocumentStatus.Failed:
      return 'bg-red-100';
    case TaxDocumentStatus.NotFound:
      return 'bg-gray-100';
    default:
      return 'bg-gray-100';
  }
};

const getDocumentType = (type: TaxDocumentType): string => {
  switch (type) {
    case TaxDocumentType.NEC1099:
      return '1099-NEC';
    case TaxDocumentType.MISC1099:
      return '1099-MISC';
    default:
      return 'Unknown';
  }
};
