import { Show } from 'solid-js';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import LabeledInput from '~/components/common/Inputs/LabeledInput';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';

export interface VendorTaxAmountLineProps {
  id: string;
  name: string;
  warningMessage?: string;
  amount: string;
  isAmountEditable: boolean;
  onInput?: (entityId: string, value?: string) => void;
}

export const VendorTaxAmountLine = (props: VendorTaxAmountLineProps) => {
  const { t } = useLocalization();
  return (
    <div class="mb-2 flex items-center rounded-lg bg-input p-4 shadow-sm transition-colors">
      <div class="flex items-center">
        <span class="text-gray-800">{props.name}</span>
        <Show when={props.warningMessage}>
          <Tooltip message={props.warningMessage} align="top">
            <IconWaring class="ml-1" />
          </Tooltip>
        </Show>
      </div>
      <Show when={props.isAmountEditable} fallback={<div class="ml-auto w-44 text-end">{props.amount}</div>}>
        <LabeledInput
          class="!mb-0 ml-auto w-44"
          inputContainerClass="rounded-lg bg-white"
          inputClass="bg-white text-right"
          prefix="$"
          onChange={(value) => props.onInput?.(props.id, value)}
          value={props.amount}
          placeholder={t('Enter amount')}
        />
      </Show>
    </div>
  );
};
