import { Show } from 'solid-js';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import LabeledInput from '~/components/common/Inputs/LabeledInput';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';

export interface OwnerTaxAmountLineProps {
  id: string;
  name: string;
  warningMessage?: string;
  amount: string;
  otherAmount: string;
  isAmountEditable: boolean;
  onAmountInput?: (entityId: string, value?: string) => void;
  onOtherAmountInput?: (entityId: string, value?: string) => void;
}

export const OwnerTaxAmountLine = (props: OwnerTaxAmountLineProps) => {
  const { t } = useLocalization();
  return (
    <div class="mb-2 flex items-center justify-between rounded-lg bg-input p-4 shadow-sm transition-colors ">
      <span class="text-gray-800">{props.name}</span>
      <Show when={props.warningMessage}>
        <Tooltip message={props.warningMessage} align="top">
          <IconWaring class="ml-1" />
        </Tooltip>
      </Show>
      <div class="ml-auto flex flex-row gap-2">
        <Show
          when={props.isAmountEditable}
          fallback={
            <>
              <div class="w-44 text-end">{props.amount}</div>
              <div class="w-44 text-end">{props.otherAmount}</div>
            </>
          }>
          <LabeledInput
            class="!mb-0 w-44"
            inputContainerClass="rounded-lg bg-white"
            inputClass="bg-white text-right"
            prefix="$"
            onChange={(value) => props.onAmountInput?.(props.id, value)}
            value={props.amount}
            placeholder={t('Enter amount')}
          />
          <LabeledInput
            class="!mb-0 w-44"
            inputContainerClass="rounded-lg bg-white"
            inputClass="bg-white text-right"
            prefix="$"
            onChange={(value) => props.onOtherAmountInput?.(props.id, value)}
            value={props.otherAmount}
            placeholder={t('Enter amount')}
          />
        </Show>
      </div>
    </div>
  );
};
