import dayjs from 'dayjs';
import { createMemo } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { protfolioAndPropertyFilter } from '~/utils/protfolioAndPropertyFilter';
import type { FilterItems } from '~/components/ui';
import type { LeaseListFilter } from '~/repositories/leaseRepository';
import type { ExtendableProtfolioAndPropertyFilterValue } from '~/utils/protfolioAndPropertyFilter';

type Status = 'due' | 'active' | 'eviction' | 'ending';
type Balance = 'upcoming';
type Period = '30' | '60' | '90' | '365';

export type FilterValue = ExtendableProtfolioAndPropertyFilterValue<{
  status?: Status;
  balance?: Balance;
  startPeriod?: Period;
  startDate?: string;
  endPeriod?: Period;
  endDate?: string;
}>;

export interface LeaseFilterProps {
  status?: boolean;
  date?: boolean;
}

export const useLeaseFilter = ({ status = true, date = true }: LeaseFilterProps) => {
  const { filterItems: protfolioAndPropertyFilterItems, filterValue, handleFilterReset } = protfolioAndPropertyFilter<FilterValue>();
  const { t } = useLocalization();

  const filterItems = createMemo(
    () =>
      [
        status && {
          type: 'select',
          key: 'status',
          label: t('Status'),
          options: [
            { label: t('All'), value: undefined },
            { label: t('Balance due'), value: 'due' },
            { label: t('Active'), value: 'active' },
            { label: t('Eviction'), value: 'eviction' },
            { label: t('Ending soon'), value: 'ending' },
          ],
        },
        ...protfolioAndPropertyFilterItems,
        date && { type: 'group', label: t('Start date') },
        date && {
          type: 'select',
          key: 'startPeriod',
          label: t('Time period'),
          options: [
            { label: t('All time'), value: undefined },
            { label: t('Last 30 days'), value: '30' },
            { label: t('Last 60 days'), value: '60' },
            { label: t('Last 90 days'), value: '90' },
            { label: t('Custom'), value: 'custom' },
          ],
        },
        date && { type: 'customDate', key: 'startDate', label: t('Date') },
        date && { type: 'group', label: t('End date') },
        date && {
          type: 'select',
          key: 'endPeriod',
          label: t('Time period'),
          options: [
            { label: t('All time'), value: undefined },
            { label: t('Next 30 days'), value: '30' },
            { label: t('Next 60 days'), value: '60' },
            { label: t('Next 90 days'), value: '90' },
            { label: t('Custom'), value: 'custom' },
          ],
        },
        date && { type: 'customDate', key: 'endDate', label: t('Date') },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const parseFilter = (value: FilterValue): LeaseListFilter => {
    const params: LeaseListFilter = {};
    const day = dayjs();

    switch (value.status) {
      case 'due':
        params.balanceMoreThan = 0;
        break;
      case 'active':
        params.eviction = false;
        break;
      case 'eviction':
        params.eviction = true;
        break;
      case 'ending':
        params['LeaseEnd.Start'] = day.format('YYYY-MM-DD');
        params['LeaseEnd.End'] = day.add(30, 'days').format('YYYY-MM-DD');
        break;
    }

    params.portfolioId = value.portfolio;
    params.propertyId = value.property;

    if (value.startPeriod) {
      params['LeaseStart.Start'] = day.subtract(Number(value.startPeriod), 'days').format('YYYY-MM-DD');
      params['LeaseStart.End'] = day.format('YYYY-MM-DD');
    }

    if (value.startDate) {
      params['LeaseStart.Start'] = value.startDate;
      params['LeaseStart.End'] = value.startDate;
    }

    if (value.endPeriod) {
      params['LeaseEnd.Start'] = day.format('YYYY-MM-DD');
      params['LeaseEnd.End'] = day.add(Number(value.endPeriod), 'days').format('YYYY-MM-DD');
    }

    if (value.endDate) {
      params['LeaseEnd.Start'] = value.endDate;
      params['LeaseEnd.End'] = value.endDate;
    }
    if (params['LeaseEnd.Start'] && params['LeaseStart.End'] && params['LeaseEnd.Start'] < params['LeaseStart.End']) {
      toast(t('The end date cannot be less than the start date'), 'error');
      return {};
    }
    return params;
  };

  return { filterValue, filterItems, parseFilter, handleFilterReset };
};
