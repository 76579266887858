import {
  RentalApplicationPaymentSettingsProvider,
  BankAccountsListProvider,
  RentalApplicationPaymentAccountProvider,
} from '~/contexts/local';
import { ApplicationFee } from './ApplicationFee';
import { PaymentAccount } from './PaymentAccount';

export const RentalApplicationFeeWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationPaymentSettingsProvider>
        <ApplicationFee />
      </RentalApplicationPaymentSettingsProvider>
      <RentalApplicationPaymentAccountProvider>
        <BankAccountsListProvider>
          <PaymentAccount />
        </BankAccountsListProvider>
      </RentalApplicationPaymentAccountProvider>
    </div>
  );
};
