import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import type { TaxYearDocumentRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class GetOwnerTaxAmountsUseCase extends MagicUseCase {
  public async runLogic(request: TaxYearDocumentRequest) {
    const ownersAndAmounts = await taxDocumentsRepository.getOwnerTaxAmountsForYear(request);
    this.getState().taxInfo.ownersAndAmounts = ownersAndAmounts;
  }
}
