import { InvalidTaxAmountError } from '~/errors/invalidTaxAmountError';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { OwnerIncomeUpdateRequest } from '~/pdfsigner/usecases/types/ownerTaxAmount';

export class UpdateOwnerIncomeAmountUseCase extends MagicUseCase {
  public async runLogic(request: OwnerIncomeUpdateRequest) {
    let rentAmountNum = undefined;
    if (request.rentAmount) {
      rentAmountNum = parseFloat(request.rentAmount.replace(/,/g, ''));
    }
    let otherAmountNum = undefined;
    if (request.otherAmount) {
      otherAmountNum = parseFloat(request.otherAmount.replace(/,/g, ''));
    }
    if (
      (request.rentAmount != undefined && (rentAmountNum == undefined || isNaN(rentAmountNum) || rentAmountNum < 0)) ||
      (request.otherAmount != undefined && (otherAmountNum == undefined || isNaN(otherAmountNum) || otherAmountNum < 0))
    ) {
      throw new InvalidTaxAmountError();
    }
    this.setOwnerTaxAmount(request.ownerId, rentAmountNum, otherAmountNum);
  }

  private setOwnerTaxAmount(vendorId: string, rentAmount?: number, otherAmount?: number) {
    this.getState().taxInfo.ownersAndAmounts.forEach((owner) => {
      if (owner.ownerId === vendorId) {
        if (rentAmount !== undefined) {
          owner.rentAmount = rentAmount;
          owner.hasAmountChanged = true;
        }
        if (otherAmount !== undefined) {
          owner.otherAmount = otherAmount;
          owner.hasAmountChanged = true;
        }
      }
    });
  }
}
