import { PdfTaxDocGenerator } from '~/pdfsigner/repositories/pdfTaxDocGenerator';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { TaxDocumentStatus } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import { GetVendorTaxDocumentsUseCase } from './getVendorTaxDocumentsUseCase';
import type { PdfTaxDocPrintRequest } from '~/pdfsigner/repositories/pdfTaxDocGenerator';
import type { TaxDocument } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type { TaxDocumentPrintRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class PrintVendorTaxDocsUseCase extends MagicUseCase {
  public async runLogic(request: TaxDocumentPrintRequest) {
    const existingDocuments = this.getState().taxInfo.paginatedVendorTaxDocuments.items;
    const pageSize = this.getState().taxInfo.paginatedVendorTaxDocuments.pageSize;
    const missingPages = new Set<number>();
    for (let i = 0; i < existingDocuments.length; i++) {
      if (existingDocuments[i] === undefined) {
        missingPages.add(Math.floor(i / pageSize) + 1);
      }
    }
    if (missingPages.size > 0) {
      const promises = Array.from(missingPages).map((page) =>
        new GetVendorTaxDocumentsUseCase().execute({
          page,
          itemsPerPage: pageSize,
        })
      );
      await Promise.all(promises);
    }
    const yearlyDocs = this.getState().taxInfo.paginatedVendorTaxDocuments.items.filter((taxDocument) => {
      return shouldPrintDocument(taxDocument, request);
    });
    const annotations = yearlyDocs.map((taxDocument) => taxDocument.annotations);
    this.getState().taxInfo.mergedPdf = await new PdfTaxDocGenerator().createTaxDocument({
      xOffset: -20,
      yOffset: -1,
      itemsPerPage: 3,
      vericalDistanceBetweenItems: 263,
      annotations,
    } as PdfTaxDocPrintRequest);
  }
}

const shouldPrintDocument = (taxDocument: TaxDocument, request: TaxDocumentPrintRequest) => {
  if (!taxDocument) {
    return false;
  }
  if (request.entityId) {
    return (
      taxDocument.calendarYear === request.year &&
      taxDocument.status === TaxDocumentStatus.Completed &&
      taxDocument.entityId === request.entityId
    );
  }
  return taxDocument.calendarYear === request.year && taxDocument.status === TaxDocumentStatus.Completed;
};
