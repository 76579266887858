import { createSignal, For, onCleanup, Show } from 'solid-js';
import IconSave from '~/assets/images/common/download.svg?component-solid';
import IconPrint from '~/assets/images/common/print.svg?component-solid';
import RefreshIcon from '~/assets/images/common/refresh.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { ScrollObserver } from '~/components/common/ScrollObserver/ScrollObserver';
import { DropdownActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import useUseCase from '~/framework/hooks/useUseCase';
import { PrintVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/vendor/printVendorTaxDocsUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { cn } from '~/utils/classnames';
import { saveAsFile } from '~/utils/file';
import { printUrl, resetPrintSystem } from '~/utils/print';
import type { Component, JSX } from 'solid-js';
import type { DropdownAction } from '~/components/ui';
import type { PresentableVendorTaxDocument } from '~/pdfsigner/ui/types/presentableTaxDocs';

export interface VendorCellProps {
  class?: string;
  children: JSX.Element;
}

interface VendorTaxDocumentsProps {
  class?: string;
  year: string;
  indexOffset?: number;
  vendorName?: string;
  taxDocuments: PresentableVendorTaxDocument[];
  onRowDidScrollIntoView?: (index: number) => void;
  onRedo?: (year: string, document: PresentableVendorTaxDocument) => void;
}

const Cell = (props: VendorCellProps) => {
  return <div class={cn('table-cell break-keep px-5 py-3 text-left align-middle', props.class)}>{props.children}</div>;
};

const VendorTaxDocumentsCell: Component<VendorTaxDocumentsProps> = (props) => {
  const { t } = useLocalization();
  const { execute: printTaxDocs } = useUseCase(PrintVendorTaxDocsUseCase);
  const [printIFrame, setPrintIFrame] = createSignal<HTMLIFrameElement>();

  const onPrintIrs = async (entityId: string) => {
    await printTaxDocs({ year: Number(props.year), entityType: TaxEntityTypes.Vendor, entityId });
  };

  const onPrintPdf = async (url: string) => {
    resetPrintSystem(printIFrame());
    setPrintIFrame(await printUrl(url));
  };

  onCleanup(() => {
    resetPrintSystem(printIFrame());
  });

  const getActions = (document: PresentableVendorTaxDocument): DropdownAction[] => {
    if (!document.isDocumentReady) {
      return [];
    }
    return [
      {
        label: t('Print (IRS Doc)'),
        icon: IconPrint,
        onClick: () => onPrintIrs(document.entityId),
      },
      {
        label: t('Print (PDF Doc)'),
        icon: IconPrint,
        onClick: () => onPrintPdf(document.url),
      },
      {
        label: t('Download'),
        icon: IconSave,
        onClick: () => saveAsFile(document.url),
      },
    ];
  };

  return (
    <div class={props.class}>
      <Show when={props.vendorName}>
        <div class="text-xl font-bold text-gray-800">{props.vendorName}</div>
        <div class="mb-4 mt-2 w-full border-b border-gray-300" />
      </Show>
      <div class="mb-2 flex font-medium text-gray-600">
        <span class="flex-1 text-left">{t('Document')}</span>
        <div class="flex justify-end">
          <span class="w-40 text-right">{t('Amount')}</span>
          <span class="w-40 text-center">{t('Type')}</span>
          <span class="w-40 text-center">{t('Status')}</span>
          <span class="w-44 text-center" />
        </div>
      </div>
      <div class="space-y-2">
        <For each={props.taxDocuments}>
          {(document, index) => (
            <ScrollObserver index={(props.indexOffset || 0) + index()} onScrollIntoView={props.onRowDidScrollIntoView}>
              <div class="flex grow items-center rounded-lg bg-gray-100 py-2">
                <div class="flex-1 px-4 text-left">
                  <div class="font-semibold text-gray-800">{document.name}</div>
                  <div class="text-sm text-gray-500">{document.creationDate}</div>
                </div>
                <div class="flex items-center justify-end">
                  <div class="text-green-600 w-40 text-right">{document.amount}</div>
                  <div class="w-40 text-center text-gray-700">{document.documentType}</div>
                  <div class="w-40 p-4">
                    <div class={cn('rounded-full p-1 text-center text-sm', document.statusBgColor, document.statusColor)}>
                      {t(document.status)}
                    </div>
                  </div>
                  <div class="w-44">
                    <Show when={getActions(document).length > 0}>
                      <Cell class="flex flex-row">
                        <Button
                          class="m-2 justify-center rounded-full px-2 py-1 text-center"
                          color="primary"
                          variant="outlined"
                          onClick={() => props.onRedo?.(props.year, document)}>
                          <RefreshIcon class="" /> {t('Redo')}
                        </Button>
                        <DropdownActions actions={getActions(document)} />
                      </Cell>
                    </Show>
                  </div>
                </div>
              </div>
            </ScrollObserver>
          )}
        </For>
      </div>
    </div>
  );
};

export default VendorTaxDocumentsCell;
