import type { OwnerTaxAmount } from './ownerTaxAmount';
import type { VendorTaxAmount } from './vendorTaxAmount';

export enum TaxEntityTypes {
  Vendor = 'vendor',
  Owner = 'owner',
}

export interface GetTaxDocumentsRequest {
  page: number;
  itemsPerPage?: number;
  entityId?: string;
}

export interface NextTaxDocumentBatchRequest {
  currentIndex: number;
  entityId?: string;
}

export interface VendorTaxDocsGenerationRequest {
  vendorData: VendorTaxAmount[];
  year: number;
}

export interface SingleVendorTaxDocsGenerationRequest {
  vendorData: VendorTaxAmount;
  year: number;
}

export interface OwnerTaxDocsGenerationRequest {
  ownerData: OwnerTaxAmount[];
  year: number;
}

export interface SingleOwnerTaxDocsGenerationRequest {
  ownerData: OwnerTaxAmount;
  year: number;
}

export interface TaxYearDocumentRequest {
  year: number;
  entityId?: string;
}

export interface TaxDocRegenerationRequest {
  year: number;
  entityId?: string;
  shouldRefetchAllData?: boolean;
}

export interface TaxDocumentPrintRequest {
  year: number;
  entityId?: string;
}
