import { A } from '@solidjs/router';
import { createSignal, For, Show } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons/LinkButton';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { SendEmail } from '~/components/common/SendEmail';
import { IconSend } from '~/components/common/SendEmail/Icons';
import { IconChevronDown, IconChevronUp, IconMail, IconMessage, IconPhone } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { getTenantFullName } from './utils';
import type { Component, JSX } from 'solid-js';
import type { TenantDto } from '~/swagger/Api';

export interface TenantSummaryProps {
  loading?: boolean;
  tenants: TenantDto[] | TenantDto;
  title?: string;
  renderAdditionalFields?: (tenant: TenantDto) => JSX.Element;
  bottomContent?: JSX.Element;
  hideEmail?: boolean;
  hidePhone?: boolean;
  hideSendEmail?: boolean;
  hideSendChat?: boolean;
  headerBackgroundClass?: string;
}

export const TenantSummary: Component<TenantSummaryProps> = (props) => {
  const { t } = useLocalization();
  const [show, setShow] = createSignal<boolean>(true);

  const tenantsArray = () => (Array.isArray(props.tenants) ? props.tenants : [props.tenants]);

  const getTitle = () => {
    if (props.title) return props.title;
    return tenantsArray().length === 1 ? t('Tenant') : t('Tenants');
  };

  const renderTenant = (tenant: TenantDto) => (
    <div class="flex flex-col rounded-lg border">
      <div class={`flex items-center gap-2 border-b p-3 ${props.headerBackgroundClass || 'bg-warning-light'}`}>
        <Avatar name={getTenantFullName(tenant)} />
        <A class="flex gap-1 hover-allowed:hover:underline" href={`/users/tenants/${tenant.id}`}>
          <span>{getTenantFullName(tenant)}</span>
        </A>
      </div>
      <div class="flex flex-col gap-y-3 p-3">
        <Show when={!props.hidePhone && tenant.phone}>
          <IconField name={t('Phone')} loading={props.loading} value={tenant.phone} src={IconPhone} />
        </Show>
        <Show when={!props.hideEmail && tenant.email}>
          <div class="flex items-center justify-between">
            <IconField name={t('Email')} loading={props.loading} value={tenant.email} src={IconMail} />
            <Show when={!props.hideSendEmail && tenant.email}>
              <SendEmail
                type="tenant"
                id={tenant.id}
                showModal
                icon={<IconSend class="size-5 text-essential-colour hover-allowed:hover:text-essential-colour/80" />}
                class="max-w-fit border-none bg-white p-0 text-essential-colour hover-allowed:hover:bg-white"
              />
            </Show>
          </div>
        </Show>

        <Show when={!props.hideSendChat && tenant.chatId}>
          <div class="mt-2 ">
            <LinkButton
              href={`/communications/chats/${tenant.chatId}`}
              variant="outlined"
              class="flex w-full items-center justify-center text-base font-medium"
              color="primary">
              <IconMessage class="size-5" />
              {t('Message')} {getTenantFullName(tenant)}
            </LinkButton>
          </div>
        </Show>
      </div>

      {props.renderAdditionalFields?.(tenant)}
    </div>
  );

  return (
    <Panel
      header={
        <div onClick={() => setShow(!show())} class="flex items-center justify-between border-b px-6 py-4">
          <span class="text-lg font-semibold">{getTitle()}</span>
          <Show when={show()} fallback={<IconChevronDown />}>
            <IconChevronUp />
          </Show>
        </div>
      }>
      <Show
        when={(Array.isArray(props.tenants) && props.tenants.length > 0) || (!Array.isArray(props.tenants) && props.tenants)}
        fallback={<div class="p-4 normal-case">{t('There are no associated tenants')}</div>}>
        <Show when={show()}>
          <div>
            <div class="flex flex-col gap-4 p-4">
              <For each={tenantsArray()}>{renderTenant}</For>
            </div>
            {props.bottomContent}
          </div>
        </Show>
      </Show>
    </Panel>
  );
};

export default TenantSummary;
