import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { vendorRepo } from '~/repositories/vendorRepository';
import { allOption, protfolioAndPropertyFilter } from '~/utils/protfolioAndPropertyFilter';
import { createLazyResource } from '~/utils/resource';
import type { FilterItems } from '~/components/ui';
import type { BillListFilter } from '~/repositories/billRepository';
import type { ExtendableProtfolioAndPropertyFilterValue, ProtfolioAndPropertyFilterProps } from '~/utils/protfolioAndPropertyFilter';

export type FilterValue = ExtendableProtfolioAndPropertyFilterValue<{
  startDate?: string;
  endDate?: string;
  vendor?: string;
}>;

export const useBillsFilter = (opts?: ProtfolioAndPropertyFilterProps<FilterValue> & { vendor?: boolean }) => {
  const { t } = useLocalization();
  const { filterItems: protfolioAndPropertyFilterItems, filterValue, handleFilterReset } = protfolioAndPropertyFilter<FilterValue>(opts);

  const { vendor = true } = opts ?? {};

  const [vendors] = createLazyResource(() => vendorRepo.getVendors({ active: true }));

  const filterItems = createMemo(
    () =>
      [
        ...protfolioAndPropertyFilterItems,
        vendor && {
          type: 'select',
          key: 'vendor',
          label: t('Vendor'),
          options: async () => {
            if (vendors.loading) return new Promise<[]>(() => void 0);
            return [allOption(), ...(vendors()?.items.map((item) => ({ value: item.id, label: item.name })) ?? [])];
          },
        },
        { type: 'group', label: t('Start date') },
        { type: 'date', key: 'startDate', label: t('Date') },

        { type: 'group', label: t('End date') },
        { type: 'date', key: 'endDate', label: t('Date') },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const parseFilter = (value: FilterValue): BillListFilter => {
    const params: BillListFilter = {};

    params.portfolioIdOr = value.portfolio ? [value.portfolio] : undefined;
    params.propertyIdOr = value.property ? [value.property] : undefined;
    params.vendorId = value.vendor;

    if (value.startDate) {
      params['BillDate.Start'] = value.startDate;
    }

    if (value.endDate) {
      params['BillDate.End'] = value.endDate;
    }

    return params;
  };

  return { filterValue, filterItems, parseFilter, handleFilterReset };
};
