import { useParams, useNavigate } from '@solidjs/router';
import { createMemo, onMount, Show, For } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Panel } from '~/components/common/Panels';
import StatusCol from '~/components/rental-application/components/StatusCol';
import { RentalApplicationEmpty } from '~/components/rental-application/list-rental-applications/RentalApplicationEmpty';
import { getTenantFullName, getTenantShortName } from '~/components/tenants/utils';
import { Table, IconCircleChevronDown } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type PropertyApplicationProps = {
  class?: string;
};

export const PropertyApplication: Component<PropertyApplicationProps> = (props) => {
  const { t } = useLocalization();
  const { propertyId } = useParams();
  const navigate = useNavigate();

  const { getRentalApplications, rentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications({
      propertyIds: [propertyId],
    });
  });

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex items-center space-x-2 text-sm">
          <Show when={item.firstName}>
            <Avatar name={getTenantShortName({ firstName: item.firstName, lastName: item.lastName })} />
          </Show>
          <div class="group flex flex-col">
            <span class="hover-allowed:group-hover:underline ">
              {getTenantFullName({ firstName: item.firstName, lastName: item.lastName })}
            </span>
            <span class="text-xs text-text-level03">{dateFormat('', item.createdAt)}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Status'),
      render: (item) => <StatusCol status={item.applicationDecision} />,
    },
    {
      title: t('Applied unit'),
      render: (item) => {
        const unitNamesStr = createMemo(() => {
          const unitNames =
            item.interests?.map((interest: { unit: { name: string } }) => interest.unit?.name).filter((name: string) => name) || [];
          return unitNames.length > 0 ? unitNames.join(', ') : emptyPlaceholder;
        });

        return <span class="text-sm">{unitNamesStr()}</span>;
      },
    },
    {
      title: t('AI score'),
      render: (item) => <AIScoreCell score={item.aiApplicationScore} />,
    },
    {
      title: t('Annual income'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{currency(item.incomeInformation?.annualIncome || 0, { minimumFractionDigits: 2 })}</span>,
    },
  ]);

  return (
    <div class="mt-3 px-3 text-left md:mt-0 md:px-8">
      <Panel title={t('Applications')} class={props.class}>
        <Show when={rentalApplications().length || isLoading()}>
          <div class="thinscroll hidden overflow-x-auto md:block">
            <Table
              rowLink={(item) => `../application-details/${item.id}`}
              columns={columns()}
              loading={isLoading()}
              data={rentalApplications()}
            />
          </div>

          <div class="thinscroll divide-y overflow-auto p-3 md:hidden">
            <For each={rentalApplications()}>
              {(item) => (
                <div class="space-y-3 py-3" onClick={() => navigate(`../application-details/${item.id}`)}>
                  <div class="flex items-center space-x-2 text-sm">
                    <Show when={item.firstName}>
                      <Avatar name={getTenantShortName({ firstName: item.firstName, lastName: item.lastName })} />
                    </Show>
                    <div class="flex flex-col">
                      <span class="hover-allowed:group-hover:underline ">
                        {getTenantFullName({ firstName: item.firstName, lastName: item.lastName })}
                      </span>
                      <span class="text-xs text-text-level03">{dateFormat('', item.createdAt)}</span>
                    </div>
                  </div>
                  <div class="bg-[#F3F2F8] p-2">
                    <For each={item.interests}>{(interest) => <UnitTitle property={interest.property} unit={interest.unit} />}</For>
                  </div>

                  <div class="flex items-center space-x-2">
                    <span class="font-medium text-text-level03">{t('Annual income')}:</span>
                    <span class="text-sm">{currency(item.incomeInformation?.annualIncome || 0, { minimumFractionDigits: 2 })}</span>
                  </div>

                  <div class="flex items-center justify-between">
                    <StatusCol status={item.applicationDecision} />

                    <div class="flex items-center space-x-2 text-link">
                      <span>{t('View application')}</span>
                      <IconCircleChevronDown class="size-3.5 -rotate-90" />
                    </div>
                  </div>
                </div>
              )}
            </For>
          </div>
        </Show>
        <Show when={!rentalApplications().length && !isLoading()}>{RentalApplicationEmpty()}</Show>
      </Panel>
    </div>
  );
};
