import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { TaxType } from '~/swagger/Api';
import type { AnnotationType, Annotation } from '~/pdfsigner/usecases/types/annotation';
import type { OwnerTaxAmount } from '~/pdfsigner/usecases/types/ownerTaxAmount';
import type {
  TaxDocumentStatus,
  TaxDocumentType,
  PaginatedOwnerTaxDocuments,
  PaginatedVendorTaxDocuments,
  OwnerTaxDocument,
  VendorTaxDocument,
} from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type {
  GetTaxDocumentsRequest,
  OwnerTaxDocsGenerationRequest,
  SingleOwnerTaxDocsGenerationRequest,
  SingleVendorTaxDocsGenerationRequest,
  TaxYearDocumentRequest,
  VendorTaxDocsGenerationRequest,
} from '~/pdfsigner/usecases/types/taxDocumentRequests';
import type { VendorTaxAmount } from '~/pdfsigner/usecases/types/vendorTaxAmount';

const VENDORS_TAX_URL = '/api/vendors/tax-documents';
const OWNERS_TAX_URL = '/api/owners/tax-documents';
const COMPANY_TAX_URL = '/api/companies/tax-documents/check';

class TaxDocumentRepository extends BaseRestRepository {
  public async getVendorTaxDocuments(request?: GetTaxDocumentsRequest): Promise<PaginatedVendorTaxDocuments> {
    const page = request?.page ?? 1;
    const itemsPerpage = request?.itemsPerPage ?? 100;
    const vendorId = request?.entityId;
    const baseUrl = vendorId ? `${VENDORS_TAX_URL}/${vendorId}` : VENDORS_TAX_URL;
    const url = `${baseUrl}?Page=${page}&PageSize=${itemsPerpage}`;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    return {
      currentPage: json.currentPage,
      pageSize: json.pageSize,
      totalItems: json.totalCount,
      totalPages: json.totalPages,
      items: json.items.map((doc: any) => {
        return {
          id: doc.id,
          calendarYear: doc.calendarYear,
          documentName: doc.documentName,
          createdAt: doc.createdAt,
          taxDocumentType: doc.taxDocumentType as TaxDocumentType,
          amount: doc.amount,
          status: doc.status as TaxDocumentStatus,
          url: '/api' + doc.documentUrl,
          entityName: doc.entityName,
          entityId: doc.entityId,
          annotations: this.getAnnotationsFromJson(doc.annotations),
        } as VendorTaxDocument;
      }),
    };
  }

  public async getVendorTaxAmountsForYear(request: TaxYearDocumentRequest): Promise<VendorTaxAmount[]> {
    const baseUrl = request.entityId
      ? `${VENDORS_TAX_URL}/${request.entityId}/amounts/${request.year}`
      : `${VENDORS_TAX_URL}/amounts/${request.year}`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    if (!json) {
      return [];
    }
    if (json instanceof Array) {
      return json.map((item: any) => this.getVendorTaxAmountFromJson(item));
    } else {
      const result = [];
      result.push(this.getVendorTaxAmountFromJson(json));
      return result;
    }
  }

  public async generateVendorTaxDocs(data: VendorTaxDocsGenerationRequest): Promise<void> {
    const url = `${VENDORS_TAX_URL}/${TaxType.Nec}/${data.year}`;
    const taxAmounts = data.vendorData
      .filter((vendor) => !vendor.doesTaxDocExist || vendor.hasAmountChanged)
      .map((vendor) => ({
        accountId: vendor.accountId,
        amount: vendor.taxAmount,
      }));

    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({ taxAmounts }),
    });
  }

  public async regenerateVendorTaxDocs(data: SingleVendorTaxDocsGenerationRequest): Promise<void> {
    const url = `${VENDORS_TAX_URL}/${data.vendorData.vendorId}/${TaxType.Nec}/${data.year}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({
        accountId: data.vendorData.accountId,
        amount: data.vendorData.taxAmount,
      }),
    });
  }

  public async getOwnerTaxDocuments(request?: GetTaxDocumentsRequest): Promise<PaginatedOwnerTaxDocuments> {
    const page = request?.page ?? 1;
    const itemsPerpage = request?.itemsPerPage ?? 100;
    const ownerId = request?.entityId;
    const baseUrl = ownerId ? `${OWNERS_TAX_URL}/${ownerId}` : OWNERS_TAX_URL;
    const url = `${baseUrl}?Page=${page}&PageSize=${itemsPerpage}`;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    return {
      currentPage: json.currentPage,
      pageSize: json.pageSize,
      totalItems: json.totalCount,
      totalPages: json.totalPages,
      items: json.items.map((doc: any) => {
        return {
          id: doc.id,
          calendarYear: doc.calendarYear,
          documentName: doc.documentName,
          createdAt: doc.createdAt,
          taxDocumentType: doc.taxDocumentType as TaxDocumentType,
          rentAmount: doc.amount,
          otherAmount: doc.otherAmount,
          status: doc.status as TaxDocumentStatus,
          url: '/api' + doc.documentUrl,
          entityName: doc.entityName,
          entityId: doc.entityId,
          annotations: this.getAnnotationsFromJson(doc.annotations),
        } as OwnerTaxDocument;
      }),
    };
  }

  public async getOwnerTaxAmountsForYear(request: TaxYearDocumentRequest): Promise<OwnerTaxAmount[]> {
    const baseUrl = request.entityId
      ? `${OWNERS_TAX_URL}/${request.entityId}/amounts/${request.year}`
      : `${OWNERS_TAX_URL}/amounts/${request.year}`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    if (!json) {
      return [];
    }
    if (json instanceof Array) {
      return json.map((item: any) => this.getOwnerTaxAmountFromJson(item));
    } else {
      const result = [];
      result.push(this.getOwnerTaxAmountFromJson(json));
      return result;
    }
  }

  public async generateOwnerTaxDocs(data: OwnerTaxDocsGenerationRequest): Promise<void> {
    const url = `${OWNERS_TAX_URL}/${TaxType.Misc}/${data.year}`;
    const taxAmounts = data.ownerData
      .filter((owner) => !owner.doesTaxDocExist || owner.hasAmountChanged)
      .map((vendor) => ({
        accountId: vendor.accountId,
        rentAmount: vendor.rentAmount,
        otherAmount: vendor.otherAmount,
      }));

    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({ taxAmounts }),
    });
  }

  public async canGenerateTaxDocs(): Promise<boolean> {
    const response = await this.fetchWithAuth(COMPANY_TAX_URL);
    const json = await this.getJsonResponse(response);
    return json.eligible;
  }

  public async regenerateOwnerTaxDocs(data: SingleOwnerTaxDocsGenerationRequest): Promise<void> {
    const url = `${OWNERS_TAX_URL}/${data.ownerData.ownerId}/${TaxType.Misc}/${data.year}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({
        accountId: data.ownerData.accountId,
        rentAmount: data.ownerData.rentAmount,
        otherAmount: data.ownerData.otherAmount,
      }),
    });
  }

  public async getAvailableVendorTaxYears(): Promise<number[]> {
    const baseUrl = `${VENDORS_TAX_URL}/calendar-years`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    return json.calendarYears;
  }

  public async getAvailableOwnerTaxYears(): Promise<number[]> {
    const baseUrl = `${OWNERS_TAX_URL}/calendar-years`;
    const response = await this.fetchWithAuth(baseUrl);
    const json = await this.getJsonResponse(response);
    return json.calendarYears;
  }

  private getAnnotationsFromJson(json: any): Annotation[] {
    return json.map(
      (item: any) =>
        ({
          id: item.id,
          type: item.type as AnnotationType,
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          text: item.text,
          fontSize: item.fontSize,
          dataPathKey: item.dataPath,
        } as Annotation)
    );
  }

  private getVendorTaxAmountFromJson(json: any): VendorTaxAmount {
    return {
      vendorId: json.vendor.id,
      vendorName: json.vendor.name,
      taxAmount: json.amount,
      accountId: json.accountId,
      isSelected: true,
      hasAmountChanged: !!json.amountHasChanged,
      doesTaxDocExist: !!json.documentAlreadyExists,
      hasTaxId: !!json.hasTaxId,
    };
  }

  private getOwnerTaxAmountFromJson(json: any): OwnerTaxAmount {
    return {
      ownerId: json.owner.id,
      ownerName: this.getName(json.owner.firstName, json.owner.lastName),
      rentAmount: json.rentAmount || 0,
      otherAmount: json.otherAmount || 0,
      accountId: json.accountId,
      isSelected: true,
      hasAmountChanged: !!json.amountHasChanged,
      doesTaxDocExist: !!json.documentAlreadyExists,
      hasTaxId: !!json.hasTaxId,
    };
  }

  private getName(firstName?: string, lastName?: string): string {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }
}

export const taxDocumentsRepository = new TaxDocumentRepository();
