import { createSignal, Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LanguageSelect } from '~/components/languages';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const OwnerInformationSection: Component = () => {
  const { t } = useLocalization();

  const { onClickSection } = useCategoryComponentWrapper();

  const [isCompany, setIsCompany] = createSignal(false);
  const handleInteraction = () => {
    onClickSection('ownerInformation');
  };

  const handleIsCompanyChange = (selected: string) => {
    const value = selected.toString() === 'true';
    setIsCompany(value);
  };

  return (
    <div id="ownerInformation" onClick={handleInteraction}>
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Owner information')}</div>
      <div class="grid grid-cols-2 gap-5 py-8">
        <FormItem
          class="col-span-full md:col-span-1"
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          label={t('Is company')}
          placeholder={t('Select')}
          onChangeMethodName="onChange"
          formFieldName="isCompany"
          component={DropdownMenu}
          onChange={handleIsCompanyChange}
        />
        <div class="col-span-full md:col-span-1" />
        <Show
          when={!isCompany()}
          fallback={
            <FormItem
              class="col-span-full md:col-span-1"
              label={t('Company name')}
              placeholder={t('Please enter the {name}', { name: t('Company name') })}
              component={LabeledTextInput}
              formFieldName="firstName"
              rules={[
                { required: true, message: t('{name} is required', { name: t('Company name') }) },
                { length: 150, message: t('{name} must be {length} characters or less', { name: t('Company name'), length: '150' }) },
              ]}
            />
          }>
          <FormItem
            class="col-span-full md:col-span-1"
            label={t('First name')}
            placeholder={t('Please enter the {name}', { name: t('First name') })}
            component={LabeledTextInput}
            formFieldName="firstName"
            rules={[
              { required: true, message: t('{name} is required', { name: t('First name') }) },
              { length: 150, message: t('{name} must be {length} characters or less', { name: t('First name'), length: '150' }) },
            ]}
          />
          <FormItem
            class="col-span-full md:col-span-1"
            label={t('Last name')}
            placeholder={t('Please enter the {name}', { name: t('Last name') })}
            component={LabeledTextInput}
            formFieldName="lastName"
            rules={[{ length: 150, message: t('{name} must be {length} characters or less', { name: t('Last name'), length: '150' }) }]}
          />
        </Show>
        <FormItem
          class="col-span-full md:col-span-1"
          label={t('Email')}
          placeholder={t('Please enter the {name}', { name: t('Email') })}
          component={LabeledTextInput}
          formFieldName="email"
          rules={[{ type: 'email', message: t('{name} is invalid', { name: t('Email') }) }]}
        />
        <FormItem
          class="col-span-full md:col-span-1"
          label={t('Phone')}
          placeholder={t('Please enter the {name}', { name: t('Phone') })}
          component={LabeledTextInput}
          formFieldName="phone"
          rules={[{ type: 'phone', message: t('{name} is invalid', { name: t('Phone') }) }]}
        />
        <FormItem
          class="col-span-full md:col-span-1"
          label={t('Language')}
          placeholder={t('Select language')}
          onChangeMethodName="onChange"
          formFieldName="language"
          component={LanguageSelect}
        />
        {/* TODO: ADD BACK WHEN OWNER PORTAL IS READY */}
        {/* <FormItem
          class="col-span-full md:col-span-1"
          label={t('Password')}
          type="password"
          placeholder={t('Please enter the {name}', { name: t('Password') })}
          component={LabeledTextInput}
          formFieldName="clearTextPassword"
        /> */}
      </div>
    </div>
  );
};

export default OwnerInformationSection;
