import { createMemo, Show } from 'solid-js';
import { QuickAction } from '~/components/rental-application/rental-application-details/renter-report-tab/applicant-summary/quick-actions/QuickAction';
import { useRentalApplication } from '~/contexts/global';
import { AIRatingSummary } from './components/AiRatingBar';
import { ApplicantInformation } from './components/ApplicantInformation';
import { PaymentStatus } from './components/PaymentStatus';
import { TransunionReports } from './components/TransunionReports';

export const ApplicantSummary = () => {
  const { applicationStore, settings } = useRentalApplication();

  const hasActions = createMemo(() => {
    const application = applicationStore.application;
    if (!application || application.paymentStatus === 'refunded') return false;

    const noDecisionOrIgnored = ['noDecision', 'ignored'].includes(application.applicationDecision as string);
    const approved = application.applicationDecision === 'approved';
    const paidAndRejected = application.paymentStatus === 'paid' && application.applicationDecision === 'rejected';
    const unpaidAndNotRejected = application.paymentStatus === 'unpaid' && application.applicationDecision !== 'rejected';

    const convertToTenant = approved && !application.tenant;
    const convertToLease = approved && !application.lease;
    const refundPayment = paidAndRejected;
    const receivePayment = unpaidAndNotRejected;

    return noDecisionOrIgnored || convertToTenant || convertToLease || refundPayment || receivePayment;
  });

  return (
    <div class="text-xs text-text-level03">
      <div class="flex flex-wrap items-center justify-between gap-10">
        <ApplicantInformation />

        <Show when={applicationStore.application?.aiApplicationScore}>
          <AIRatingSummary />
        </Show>
      </div>

      <div class="mb-4 flex w-full flex-wrap gap-4">
        <Show when={hasActions()}>
          <QuickAction />
        </Show>

        <PaymentStatus />
      </div>

      <Show when={settings()?.requireScreening}>
        <TransunionReports />
      </Show>
    </div>
  );
};
