import { A } from '@solidjs/router';
import { createMemo, Show, For } from 'solid-js';
import { Dropdown } from '~/components/common/Dropdown';
import Tooltip from '~/components/common/Tooltip';
import { useMenu } from '~/components/layouts/Menu/MenuContext';
import { cn } from '~/utils/classnames';
import { MainMenuButton } from './MainMenuButton';
import type { MainMenuItemProps } from '~/components/layouts/Menu/types/menuItem';

const TOOLTIP_BG_COLOR = '#8712CF';

export const MainMenuItem = (props: MainMenuItemProps) => {
  const menu = useMenu();

  const isDesktop = createMemo(() => !menu?.isMobile());

  const hasSubMenus = createMemo(() => props.item.subMenuItems && props.item.subMenuItems.length > 0);
  const disableTooltip = createMemo(() => {
    return (!menu?.isManuallyCollapsed() && !menu?.isCollapsed()) || menu?.isActive(props.item);
  });

  const shouldUsePopover = createMemo(() => {
    return menu?.isManuallyCollapsed() && isDesktop() && hasSubMenus();
  });

  const clickHandler = () => {
    const isCurrentlyActive = menu?.isActive(props.item);

    if (isCurrentlyActive) {
      menu?.setActiveMenuItem(undefined);
    } else {
      menu?.setActiveMenuItem(props.item);
    }
    if (!hasSubMenus() && !isDesktop()) {
      props.setIsSidebarOpen(false);
    }
  };

  const menuItem = (
    <li
      id={menu?.getMenuItemId(props.item)}
      class={cn(
        'cursor-pointer rounded-lg border-2 border-transparent',
        { 'bg-essential-colour': menu?.isSelected(props.item) },
        { 'bg-black/20 mr-0 rounded-r-none': menu?.isActive(props.item) && !menu?.isSelected(props.item) },
        { 'bg-essential-colour ': menu?.isActive(props.item) && !menu?.isCollapsed() },
        {
          'hover-allowed:hover:border-essential-colour': !menu?.isSelected(props.item) && !menu?.isActive(props.item),
        },
        { 'mr-2': menu?.isManuallyCollapsed() },
        {
          'hover-allowed:hover:mr-2': menu?.isCollapsed() && !menu?.isActive(props.item),
        },
        {
          'mr-2': menu?.isSelected(props.item) && menu?.isCollapsed(),
        },
        { 'bg-black/20 mr-0 rounded-r-none': menu?.isActive(props.item) && menu?.isSelected(props.item) && menu?.isCollapsed() },
        { 'rounded-lg': menu?.isManuallyCollapsed() && menu?.isActive(props.item) }
      )}
      onClick={clickHandler}>
      <span class={cn('flex w-fit items-center p-3 text-left')}>
        <div class="group relative z-10 inline-block">
          <MainMenuButton item={props.item} setIsSidebarOpen={props.setIsSidebarOpen} isMenuCollapsed={props.isMenuCollapsed} />
        </div>
      </span>
    </li>
  );

  return (
    <Show
      when={shouldUsePopover()}
      fallback={
        <Tooltip class="w-full" bgColor={TOOLTIP_BG_COLOR} message={props.item.text} align="right" disabled={disableTooltip()}>
          {menuItem}
        </Tooltip>
      }>
      <Dropdown
        triggerElement={
          <Tooltip class="w-full" bgColor={TOOLTIP_BG_COLOR} message={props.item.text} align="right" disabled={disableTooltip()}>
            {menuItem}
          </Tooltip>
        }
        contentClass="absolute -top-16 left-16 w-64 gap-2 rounded-lg bg-[#510FA6] pr-2 text-white">
        {() => (
          <>
            <div class="mb-2 border-b border-white/20 px-4 pb-3 pt-1 font-semibold text-white/70">{props.item.text}</div>
            <For each={props.item.subMenuItems}>
              {(subItem) => (
                <A
                  id={menu?.getMenuItemId(subItem, true)}
                  href={subItem.realPath || subItem.path}
                  class="block rounded-md px-4 py-2 hover-allowed:hover:bg-black/20"
                  classList={{
                    'bg-essential-colour': menu?.isSelected(subItem),
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    menu?.setActiveMenuItem(subItem);
                    if (!isDesktop()) {
                      props.setIsSidebarOpen(false);
                    }
                  }}>
                  {subItem.text}
                </A>
              )}
            </For>
          </>
        )}
      </Dropdown>
    </Show>
  );
};
