import { Navigate, Route, Routes, useLocation, useMatch, useParams } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { getOwnerFullName } from '~/components/owner/utils';
import { TabLinks } from '~/components/ui';
import { useLocalization, useOwners } from '~/contexts/global';
import { AttachmentsProvider, LeaseRentTransactionsProvider, MaintenanceRequestsProvider } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { OwnerTaxDocumentsPage } from '~/pages/owner/tax-documents/OwnerTaxDocumentsPage';
import { OwnerSummary } from './components/OwnerSummary';
import { OwnerCommunicationsTab } from './tabs/OwnerCommunicationsTab';
import { OwnersEmailsTab } from './tabs/OwnerEmailsTab';
import { ListOwnerPayments as OwnerPaymentsTab } from './tabs/OwnerPaymentsTab/ListOwnerPayments';
import { OwnersNotesTab } from './tabs/OwnersNotesTab';
import type { BreadcrumbItem } from '~/components/common/Breadcrumb';

export const OwnerDetailsPage = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const location = useLocation();
  const params = useParams<{ ownerId: `${number}` }>();

  const { current, setCurrentId } = useOwners();

  const isPaymentsMethodsPage = useMatch(() => `/users/owner/:id/payments/methods`);

  const breadcrumbItems = createMemo(
    () =>
      [
        { label: t('Owners'), link: '/users/owners' },
        { label: getOwnerFullName(current()) || t('Loading') },
        isPaymentsMethodsPage() ? { label: t('Payment method') } : undefined,
      ].filter(Boolean) as BreadcrumbItem[]
  );

  createEffect(() => setCurrentId(params.ownerId));

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <div class="flex flex-col gap-4 px-8 py-5 lg:flex-row">
        <OwnerSummary class="w-full shrink-0 lg:w-96" loading={current.loading} owner={current()} />
        <div class="relative flex-1 overflow-hidden">
          <TabLinks
            class="mb-4 rounded-lg border border-partingline bg-white"
            items={[
              { label: t('Communications'), href: `communications${location.search}`, end: false },
              { label: t('Emails'), href: `emails${location.search}`, end: false },
              { label: t('Payment accounts'), href: `payments-accounts${location.search}`, end: false },
              { label: t('Notes'), href: 'notes' },
              { label: t('Tax documents'), href: 'tax-documents' },

              // TODO: WHEN BACKEND IS READY
              // { label: t('Overview'), href: location.search },
              // { label: t('Property'), href: `property${location.search}` },
              // { label: t('Files'), href: `files${location.search}` },
              // { label: t('Activity & Portal access'), href: `portal-access${location.search}` },
            ]}
            replace
          />
          <LeaseRentTransactionsProvider>
            <MaintenanceRequestsProvider>
              <AttachmentsProvider>
                <Routes>
                  <Route path="/" element={<Navigate href="communications" />} />
                  <Route path="/emails/*" component={OwnersEmailsTab} />
                  <Route path="/communications" component={OwnerCommunicationsTab} />
                  <Route path="/notes/*" component={OwnersNotesTab} />
                  <Route path="/payments-accounts/*" component={OwnerPaymentsTab} />
                  <Route path="/tax-documents/*" element={<OwnerTaxDocumentsPage />} />
                </Routes>
              </AttachmentsProvider>
            </MaintenanceRequestsProvider>
          </LeaseRentTransactionsProvider>
        </div>
      </div>
    </div>
  );
};
