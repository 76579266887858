import dayjs from 'dayjs';
import { For, Show, createEffect, createMemo, createSignal, onCleanup } from 'solid-js';
import IconArrow from '~/assets/images/chat/arrowWithBase.svg';
import IconChatDefault from '~/assets/images/chat/chatDefault.png';
import IconLoadingChat from '~/assets/images/chat/loadingChat.gif';
import IconClose from '~/assets/images/common/whiteClose.svg';
import { toast } from '~/components/ui';
import { useLocalization, useGlobalChat, useCompanies } from '~/contexts/global';
import { useChat, useChatsList, updateLatestMessage } from '~/contexts/local';
import AiGenerateInput from '~/pages/chats/chat-details/components/AiGenerateInput';
import { AiGenerateMessageBanner } from '~/pages/chats/chat-details/components/AiGenerateMessageBanner';
import ChatHeader from '~/pages/chats/chat-details/components/ChatHeader';
import { GetFilePreview, createFileUrl } from '~/pages/chats/chat-details/components/GetFilePreview';
import NewMessageInput from '~/pages/chats/chat-details/components/NewMessageInput';
import SendMessageOptions from '~/pages/chats/chat-details/components/SendMessageOptions';
import { clearChatInput, getChatInput, setChatInput } from '~/pages/chats/chat-details/components/chatInputStore';
import { ChatParticipantType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { ChatContent } from './components/ChatContent';
import { PrintContainer } from './components/print-components/PrintContainer';
import { PrintHeader } from './components/print-components/PrintHeader';

export interface ChatWindowProps {
  chatId?: string | null;
  participantId?: string | null;
  participantType?: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`;
  class?: string;
  source?: 'chat' | 'archive';
}

export const ChatWindow = (props: ChatWindowProps) => {
  const { t } = useLocalization();

  const {
    sendChat,
    removeMessage,
    fetchChatMessagesById,
    setChatId,
    chatMessages,
    chatId,
    addNewMessage,
    isInitialized,
    initializeChat,
    resetChat,
    createChat,
    hasMoreMessages,
    isLoading,
    markMessagesAsRead,
    chat,
    setChatMessages,
  } = useChat();

  const { registerMessageReceivedCallback } = useGlobalChat();

  const { chats, clearUnreadMessages } = useChatsList();

  const { companies } = useCompanies();

  const [newMessage, setNewMessage] = createSignal<string>('');
  const [sendEmail, setSendEmail] = createSignal<boolean>(false);
  const [sendText, setSendText] = createSignal<boolean>(true);
  const [files, setFiles] = createSignal<File[]>([]);
  const [isSendingMessage, setIsSendingMessage] = createSignal<boolean>(false);
  const [newMessagesCount, setNewMessagesCount] = createSignal<number>(0);
  const [showAiGenerateInput, setShowAiGenerateInput] = createSignal<boolean>(false);
  const [showScrollToBottom, setShowScrollToBottom] = createSignal<boolean>(false);
  const [aiMessage, setAiMessage] = createSignal<string>('');

  const [isInitialLoading, setIsInitialLoading] = createSignal<boolean>(true);
  const [showNewMessageIndicator, setShowNewMessageIndicator] = createSignal<boolean>(false);
  const [lastSeenMessageId, setLastSeenMessageId] = createSignal<string | undefined>(undefined);
  const [isFirstUnreadMessage, setIsFirstUnreadMessage] = createSignal<boolean>(true);
  const [firstUnreadMessageIndex, setFirstUnreadMessageIndex] = createSignal<number | null>(null);
  const [chatWindowContainer, setChatWindowContainer] = createSignal<HTMLElement | null>(null);

  const [requestFocus, setRequestFocus] = createSignal<boolean>(false);
  const [memberContent, setMemberContent] = createSignal<string>('');

  const currentChat = createMemo(() => chats().find((chat) => chat.id === chatId()));
  const unreadMessagesCount = createMemo(() => currentChat()?.unreadMessages || 0);
  const oldestMessage = createMemo(() => chatMessages()[chatMessages().length - 1]);

  createEffect(() => {
    const participants = chat()?.participants || [];

    const getNames = (type: ChatParticipantType) => participants.filter((p) => p.participantType === type && p.name).map((p) => p.name);

    const roleNames: Record<string, string> = {
      Manager: getNames(ChatParticipantType.PropertyManager).join(','),
      Vendor: getNames(ChatParticipantType.Vendor).join(','),
      Tenant: getNames(ChatParticipantType.Tenant).join(','),
      Company: getNames(ChatParticipantType.Company).join(','),
      Owner: getNames(ChatParticipantType.Owner).join(','),
      Other: participants
        .filter(
          (p) =>
            ![
              ChatParticipantType.PropertyManager,
              ChatParticipantType.Vendor,
              ChatParticipantType.Tenant,
              ChatParticipantType.Company,
              ChatParticipantType.Owner,
            ].includes(p.participantType) && p.name
        )
        .map((p) => p.name)
        .join(','),
    };

    const memberContent = Object.keys(roleNames)
      .map((role) => {
        if (roleNames[role]) {
          return `${t(role)}(${roleNames[role]})`;
        }
        return null;
      })
      .filter(Boolean)
      .join(', ');

    setMemberContent(memberContent);
  });

  const handleSendMessage = async (messageContent = newMessage()) => {
    if (!messageContent.trim().length && !files().length) {
      toast.error(t('Cannot send empty messages'));
      return;
    }
    setIsSendingMessage(true);

    let targetChatId = props.chatId;

    if (!targetChatId && props.participantType && props.participantId) {
      try {
        const newChatResult = await createChat(props.participantType, props.participantId);
        if (newChatResult && newChatResult.chatId) {
          targetChatId = newChatResult.chatId;
          await initializeChat(targetChatId);
        } else {
          console.error('Failed to create a new chat: No chatId returned');
          setIsSendingMessage(false);
          return;
        }
      } catch (error) {
        console.error('Error creating chat:', error);
        setIsSendingMessage(false);
        return;
      }
    }

    if (targetChatId) {
      try {
        const optimisticMessage: Partial<MagicDoor.Api.ChatMessageDto> = {
          id: `temp-${Date.now()}`,
          message: messageContent,
          sentAt: dayjs().toISOString(),
          chatId: targetChatId,
          messageType: 'propertyManager' as MagicDoor.Api.MessageType | undefined,
          sendEmail: sendEmail(),
          sendText: sendText(),
          source: 'app' as MagicDoor.Api.MessageSource,
        };
        addNewMessage(optimisticMessage as MagicDoor.Api.ChatMessageDto);
        scrollToBottom();

        const result = await sendChat(targetChatId, {
          message: messageContent,
          sendEmail: sendEmail(),
          sendText: sendText(),
          files: files(),
        });

        if (!result?.status) {
          removeMessage(optimisticMessage.id as string);
        }

        setFiles([]);
        setNewMessage('');
        setAiMessage('');
        clearChatInput(targetChatId);
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsSendingMessage(false);
      }
    } else {
      setIsSendingMessage(false);
      console.error('No chatId available to send message');
    }
    setRequestFocus(true);
  };

  const scrollToBottom = async () => {
    const chatContainer = chatWindowContainer();
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
      await setMessagesAsRead();
      setNewMessagesCount(0);
      setShowScrollToBottom(false);
    }
  };

  const isNearBottom = () => {
    const chatContainer = chatWindowContainer();
    if (!chatContainer) return false;

    const threshold = 300;
    const position = chatContainer.scrollTop + chatContainer.offsetHeight;
    const height = chatContainer.scrollHeight;
    return position >= height - threshold;
  };

  const messageStore = (chatId: string, message: string) => {
    setChatInput(chatId, message);
  };

  const removeFile = (indexToRemove: number) => {
    setFiles(files().filter((_, index) => index !== indexToRemove));
  };

  const displayedMessageIds = new Set<string>();

  const handleMessageReceived = (message: MagicDoor.Api.ChatMessageDto) => {
    if (message.chatId === chatId()) {
      if (!displayedMessageIds.has(message.id)) {
        displayedMessageIds.add(message.id);

        const nearBottom = isNearBottom();

        if (message.messageType !== 'propertyManager') {
          if (!chatMessages().some((msg) => msg.id === message.id)) {
            addNewMessage(message);
            setNewMessagesCount((prevCount) => prevCount + 1);
          }

          if (isFirstUnreadMessage()) {
            setLastSeenMessageId(message.id);
            setIsFirstUnreadMessage(false);
          }

          if (nearBottom) {
            scrollToBottom();
          } else {
            setShowScrollToBottom(true);
          }
        } else {
          const index = chatMessages().findIndex((msg) => msg.id.startsWith('temp-') && msg.message === message.message);
          if (index !== -1) {
            const updatedMessages = [...chatMessages()];
            updatedMessages[index] = message;
            setChatMessages(updatedMessages);
          }
        }

        updateLatestMessage(message);
      }
    }
  };

  const initializeChatAndHandleUnread = async (id: string) => {
    if (!isInitialized() || chatId() !== id) {
      await initializeChat(id);
      const messages = chatMessages();
      if (messages.length > 0) {
        const unreadCount = unreadMessagesCount();
        if (unreadCount > 0) {
          setFirstUnreadMessageIndex(messages.length - unreadCount);
          setShowNewMessageIndicator(true);
          setTimeout(() => {
            setShowNewMessageIndicator(false);
            setFirstUnreadMessageIndex(null);
          }, 1000);
        }
      }
    }
  };

  const handleFormatError = (err: any) => {
    const errors = err?.json?.errors?.MessageIds.join(',');
    toast.error(errors);
  };

  const setMessagesAsRead = async () => {
    try {
      if (chatId()) {
        const unreadMessages = chatMessages().filter((message) => !message.read && !message.id.includes('temp-'));
        const unreadMessageIds = unreadMessages.map((message) => message.id);

        if (unreadMessageIds.length > 0) {
          const currentChatId = chatId();
          if (currentChatId) {
            await markMessagesAsRead(currentChatId);
            clearUnreadMessages(currentChatId);
          }
          setIsFirstUnreadMessage(true);
          setNewMessagesCount(0);
          setShowNewMessageIndicator(false);
        }
      }
    } catch (err) {
      handleFormatError(err);
      console.error(err);
    }
  };

  const chatDebounce = (fn: (...args: any[]) => void, delay: number) => {
    let timeoutID: ReturnType<typeof setTimeout>;
    return function (...args: any[]) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  };

  createEffect(() => {
    const initChat = async () => {
      setIsInitialLoading(true);
      if (props.chatId) {
        await initializeChatAndHandleUnread(props.chatId);
      } else if (props.participantId && props.participantType) {
        const response = await createChat(props.participantType, props.participantId);
        if (response && response.chatId) {
          await initializeChatAndHandleUnread(response.chatId);
        }
      } else {
        resetChat();
      }
      setIsInitialLoading(false);
      scrollToBottom();
    };

    initChat();
  });

  createEffect(() => {
    registerMessageReceivedCallback(handleMessageReceived);
  });

  createEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showAiGenerateInput()) {
        setShowAiGenerateInput(false);
      } else if (e.key === 'Enter' && !e.shiftKey && !isSendingMessage()) {
        const messageToSend = showAiGenerateInput() ? aiMessage() : newMessage();
        if (messageToSend && messageToSend.trim() !== '') {
          e.preventDefault();
          handleSendMessage(messageToSend);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    onCleanup(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });
  });

  createEffect(() => {
    if (props.chatId) {
      const storedInput = getChatInput(props.chatId);
      if (newMessage() !== storedInput) {
        setNewMessage(storedInput);
      }
    }
  });

  createEffect(() => {
    setChatId(props.chatId || undefined);
  });

  createEffect(() => {
    const container = chatWindowContainer();
    if (!container) return;

    const handleScroll = async () => {
      const chatContainer = chatWindowContainer();
      if (!chatContainer) return;

      const nearBottom = isNearBottom();
      setShowScrollToBottom(!nearBottom);

      if (nearBottom && props.chatId) {
        clearUnreadMessages(props.chatId);
      }

      const scrollTop = chatContainer.scrollTop;

      if (scrollTop < 800 && !isLoading() && hasMoreMessages()) {
        const oldScrollHeight = chatContainer.scrollHeight;
        const oldScrollTop = chatContainer.scrollTop;

        if (oldestMessage() && props.chatId) {
          await fetchChatMessagesById(props.chatId, oldestMessage().sentAt);

          requestAnimationFrame(() => {
            if (chatContainer) {
              const newScrollHeight = chatContainer.scrollHeight;
              const scrollOffset = newScrollHeight - oldScrollHeight;
              chatContainer.scrollTop = oldScrollTop + scrollOffset;
            }
          });
        }
      }
    };

    const debouncedScroll = chatDebounce(handleScroll, 300);
    container.addEventListener('scroll', debouncedScroll);

    onCleanup(() => container.removeEventListener('scroll', debouncedScroll));
  });

  const onSetFile = (files: File[]) => {
    setFiles(files);
    setRequestFocus(true);
  };

  const printHeader = <PrintHeader companies={companies()} chatMessages={chatMessages()} chat={chat()} memberContent={memberContent()} />;

  const printContainer = (
    <PrintContainer
      isInitialLoading={isInitialLoading()}
      chatMessages={chatMessages()}
      chat={chat()}
      chatId={chatId()}
      showNewMessageIndicator={showNewMessageIndicator()}
      firstUnreadMessageIndex={firstUnreadMessageIndex()}
      lastSeenMessageId={lastSeenMessageId()}
    />
  );

  return (
    <div class={cn('relative flex h-section flex-1 flex-col bg-white', props.class)}>
      <Show
        when={isInitialized() || props.chatId || props.participantId}
        fallback={
          <div class="flex h-full flex-col items-center justify-center">
            <img src={IconChatDefault} />
            <div class="text-text-level03">{t('Select a chat to begin')}</div>
          </div>
        }>
        <div class="flex h-[74px] items-center justify-between border-b border-partingline bg-white p-4">
          <Show when={chat()}>
            <ChatHeader
              chats={chat() || undefined}
              chatId={chatId() || props.chatId}
              printHeader={printHeader}
              printContainer={printContainer}
              source={props.source}
            />
          </Show>
        </div>

        <Show when={showScrollToBottom()}>
          <button
            onClick={scrollToBottom}
            class="z-10 flex w-full flex-col items-center justify-center gap-3 rounded bg-black/30 p-1 text-xs text-white">
            <Show when={newMessagesCount() > 0}>
              <div>{newMessagesCount() === 1 ? t('1 new message') : `${newMessagesCount()} ${t('new messages')}`}</div>
            </Show>
            <div class="flex gap-1">
              <img src={IconArrow} />
              <span>{t('Scroll to bottom')}</span>
            </div>
          </button>
        </Show>

        <Show when={isLoading()}>
          <div class="flex w-full justify-center text-text-level03">
            <img src={IconLoadingChat} />
          </div>
        </Show>

        <Show
          when={!isLoading() && !chatMessages()?.length}
          fallback={
            <div class="thinscroll flex flex-1 flex-col space-y-2 overflow-y-auto p-6" ref={setChatWindowContainer}>
              <Show
                when={!isInitialLoading() && chatId()}
                fallback={
                  <div class="flex w-full justify-center text-text-level03">
                    <img src={IconLoadingChat} />
                  </div>
                }>
                <Show when={hasMoreMessages()}>
                  <div class="flex w-full justify-center text-text-level03">
                    <img src={IconLoadingChat} />
                  </div>
                </Show>
                <For each={chatMessages()?.slice().reverse() || []}>
                  {(message, index) => (
                    <>
                      <Show
                        when={
                          (showNewMessageIndicator() && index() === firstUnreadMessageIndex()) ||
                          (message.id === lastSeenMessageId() && message.messageType !== 'propertyManager')
                        }>
                        <div class="relative mb-8 flex w-full items-center justify-center border-b">
                          <div class="absolute rounded-full bg-input px-2 py-1 text-xs text-text-level03">{t('New message')}</div>
                        </div>
                      </Show>
                      <ChatContent message={message} allMessage={chatMessages()} chatDetails={chat()} />
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
          <div class="flex h-full flex-col items-center justify-center">
            <img src={IconChatDefault} />
            <div class="text-text-level03">{t('Send a message to begin')}</div>
          </div>
        </Show>

        <Show when={files().length !== 0}>
          <div class="border-t border-gray-200 bg-white px-8 pt-4">
            <div class="flex gap-2">
              <For each={files()}>
                {(file, index) => (
                  <div class="relative mb-2 max-w-xs ">
                    {GetFilePreview(file, createFileUrl)}

                    <button
                      class="absolute -right-1 -top-1 rounded-full border-2 border-auxiliary-text bg-black/80 p-1"
                      onClick={() => removeFile(index())}>
                      <img src={IconClose} class="size-2.5" />
                    </button>
                  </div>
                )}
              </For>
            </div>
          </div>
        </Show>

        <Show
          when={!chat()?.closed || props.source === 'archive'}
          fallback={
            <div class="absolute inset-0 flex items-center justify-center bg-black/50">
              <div class="rounded-lg bg-white p-4">
                <div class="text-center">{t('This chat has been closed')}</div>
              </div>
            </div>
          }>
          <Show when={showAiGenerateInput() && props.source !== 'archive'}>
            <AiGenerateInput
              files={files()}
              setFiles={setFiles}
              isSendingMessage={isSendingMessage()}
              newMessage={newMessage()}
              setNewMessage={setNewMessage}
              requestFocus={requestFocus}
              setRequestFocus={setRequestFocus}
              handleSendMessage={handleSendMessage}
              handleCancel={() => setShowAiGenerateInput(false)}
              aiMessage={aiMessage()}
              setAiMessage={setAiMessage}
              chatId={chatId() || ''}
              messageStore={messageStore}
              participantType={props.participantType}
              participantId={props.participantId}
              createChat={createChat}
              setChatId={setChatId}
            />
          </Show>
          <Show when={!showAiGenerateInput() && props.source !== 'archive'}>
            <AiGenerateMessageBanner onClick={() => setShowAiGenerateInput(true)} />
            <NewMessageInput
              files={files()}
              setFiles={setFiles}
              requestFocus={requestFocus}
              setRequestFocus={setRequestFocus}
              isSendingMessage={isSendingMessage()}
              newMessage={newMessage()}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              messageStore={messageStore}
              chatId={chatId() || ''}
            />
          </Show>
        </Show>
        <Show when={props.source !== 'archive'}>
          <SendMessageOptions
            sendEmail={() => sendEmail()}
            sendText={() => sendText()}
            setSendEmail={setSendEmail}
            setSendText={setSendText}
            files={files()}
            setFiles={onSetFile}
          />
        </Show>
      </Show>
    </div>
  );
};
