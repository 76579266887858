import { createMemo, onMount, Show } from 'solid-js';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import { Avatar } from '~/components/common/Avatar';
import DisplayAddress from '~/components/common/DisplayAddress';
import { getTenantFullName } from '~/components/tenants/utils';
import { Table, Image } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type IncomeStatisticsProps = {
  class?: string;
  filterNew?: boolean;
};

const RentalApplications: Component<IncomeStatisticsProps> = (props) => {
  const { t } = useLocalization();
  const { rentalApplications, getRentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications({ new: props.filterNew });
  });

  const data = createMemo<MagicDoor.Api.RentalApplicationListDto[]>(() =>
    rentalApplications()?.filter((item) => item.hasBeenSubmitted)
  );

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Unit name'),
      render: (item) => {
        const unit = () => item.interests?.[0]?.unit;
        return (
          <div class="flex w-full items-center gap-2">
            <Show when={unit()} fallback={<Image class="hidden size-11 rounded-lg md:flex" src={IconTempUnit} />}>
              <UnitImage class="hidden size-11 rounded-lg md:flex" unit={unit() as { id: string; imageId?: string }} />
            </Show>

            <div class="w-36 flex-1 overflow-hidden">
              <Show when={unit()} fallback={<div class="font-medium normal-case text-text-level01">{t('Open to any units')}</div>}>
                <div class="truncate font-medium text-text-level01">{unit()?.name}</div>
                <DisplayAddress class="text-text-level03" address={item.interests?.[0]?.property.address} />
              </Show>
            </div>
          </div>
        );
      },
    },
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex w-full items-center gap-1.5">
          <Show when={item.firstName}>
            <div class="hidden md:flex">
              <Avatar size="default" name={getTenantFullName(item)} />
            </div>
            <span class="w-36 truncate font-medium text-text-level01">{getTenantFullName(item)}</span>
          </Show>
        </div>
      ),
    },
  ]);

  return <Table data={data()} columns={columns()} rowLink={(item) => `/leasing/rental-applications/${item.id}`} loading={isLoading()} />;
};

export default RentalApplications;
