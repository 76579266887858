import { getOwner } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Form, FormItem } from '~/components/common/BetterForm';
import { FormList } from '~/components/common/BetterForm/Form';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { LanguageSelect } from '~/components/languages';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { genderTypes } from '~/utils/constant';
import { addressFormat, tenantName } from '~/utils/formRules';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

const DriverLicenseInput = () => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-2 gap-2">
      <FormItem
        class="col-span-full lg:col-span-1"
        label={t('Driver license number')}
        placeholder={t('Driver license number')}
        formFieldName={['driversLicense', 'number']}
        component={LabeledTextInput}
      />
      <FormItem
        rules={[{ length: 2, message: t('{name} must be {length} characters', { name: t('Driver license state'), length: 2 }) }]}
        class="col-span-full lg:col-span-1"
        label={t('Driver license state')}
        placeholder={t('Driver license state')}
        formFieldName={['driversLicense', 'state']}
        component={LabeledTextInput}
      />
    </div>
  );
};

const EmergencyContacts = () => {
  const { t } = useLocalization();

  return (
    <FormList
      formFieldName={'emergencyContacts'}
      renderItemsGroup={({ index, remove }) => {
        return (
          <FormListRemoverWrapper index={index} remove={remove}>
            <div class="flex gap-5">
              <FormItem
                placeholder="Name"
                label={t(`Name`)}
                class="grow"
                formFieldName={['name']}
                component={LabeledTextInput}
                rules={[{ message: t(`Please input name`), required: true }]}
              />
              <FormItem
                class="grow"
                placeholder="Email"
                label={t(`Email address`)}
                formFieldName={['email']}
                component={LabeledTextInput}
              />
              <FormItem class="grow" placeholder="Phone" label={t(`Phone number`)} formFieldName={['phone']} component={LabeledTextInput} />
              <FormItem
                class="grow"
                placeholder="Relationship"
                label={t(`Relationship`)}
                formFieldName={['relationship']}
                component={LabeledTextInput}
              />
              <div class="ml-2 mt-8 flex cursor-pointer items-start" onClick={remove}>
                <IconRemove class="size-5 text-primary" />
              </div>
            </div>
          </FormListRemoverWrapper>
        );
      }}
      autoCombineFieldName={false}>
      {({ add }) => {
        return (
          <Button variant="white" onClick={add}>
            <PlusIcon /> {t('Add more')}
          </Button>
        );
      }}
    </FormList>
  );
};

export const AddTenantForm: Component<{ form?: BetterForm.ContextValue; class?: string; ref?: any }> = (props) => {
  const { t } = useLocalization();
  const owner = getOwner();

  const defaultLanguage = 'en';

  let expandableRef: any;
  props.ref &&
    props.ref({
      expandableToggleOpen(open?: boolean) {
        expandableRef.toggleOpen(open);
      },
    });
  return (
    <Form
      initialValues={{
        currentAddress: {},
        emergencyContacts: [{}],
        language: defaultLanguage,
      }}
      defaultForm={props.form}
      class={cn('text-left', props.class)}>
      <Section title={t('Tenant information')} border>
        <div class="grid grid-cols-2 gap-4">
          <FormItem
            class="col-span-full lg:col-span-1"
            rules={[{ message: t(`Please input first name`), required: true }, ...tenantName(t('First name'))]}
            formFieldName="firstName"
            label={t('First name')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            rules={[{ message: t(`Please input last name`), required: true }, ...tenantName(t('Last name'))]}
            formFieldName="lastName"
            label={t('Last name')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
        </div>
        <div class="grid grid-cols-3 gap-4 gap-y-6 pt-4">
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="email"
            label={t('Email address')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
            rules={[{ type: 'email', message: t('Email is invalid') }]}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="phone"
            label={t('Phone number')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
            rules={[{ type: 'phone', message: t('Phone is invalid') }]}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Language')}
            placeholder={t('Select language')}
            onChangeMethodName="onChange"
            formFieldName="language"
            component={LanguageSelect}
          />
        </div>
      </Section>

      <ExpandableSection
        ref={expandableRef}
        headerBorder
        class="px-0 text-base"
        title={
          <span class="capitalize">
            {t('Other information')} <span class="text-xs text-text-level03">({t('Optional sections')})</span>
          </span>
        }>
        <div class="mb-8 grid grid-cols-3 gap-4 gap-y-6">
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="dateOfBirth"
            label={t('Date of birth')}
            contentPosition={['top', 'right']}
            component={DueDateInputField}
            controlled
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="gender"
            label={t('Gender')}
            options={genderTypes}
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Select gender')}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="ssn"
            label={t('Social security number (ssn)')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
            rules={[{ type: 'ssn', message: t('Social security number is invalid') }]}
          />
          <div class="col-span-3 flex flex-col gap-y-8">
            <FormItem
              class="col-span-3"
              formFieldName="currentAddress"
              rules={[addressFormat(owner, t, false)]}
              label={t('Current address')}
              component={LabeledAddressInput}
            />
          </div>
          <div class="col-span-3 grid grid-cols-2 gap-4">
            <DriverLicenseInput />
          </div>
        </div>

        <Section class="mt-5" title={t(`Emergency contact`)} border>
          <EmergencyContacts />
        </Section>
      </ExpandableSection>
    </Form>
  );
};
