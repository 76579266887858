import { Show } from 'solid-js';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import type { HydratedLeaseDto } from '~/swagger/Api';

export const LevelUnitTitle = (props: { unit: { id: string; name: string; imageId?: string; leases?: HydratedLeaseDto[] } }) => {
  const { t } = useLocalization();
  return (
    <div class="flex cursor-pointer items-center justify-between rounded-md transition-colors aria-checked:bg-light-pink aria-disabled:cursor-not-allowed aria-disabled:opacity-50 hover-allowed:hover:bg-light-pink">
      <div class="flex items-center gap-2">
        <UnitImage class="size-8 rounded-md" unit={props.unit} data-slot="image" />

        <span>{props.unit.name}</span>
        <Show when={props.unit.leases?.length}>
          <span class="rounded-full bg-[#F2F3FA] px-2 py-0.5 text-xs text-text-level03">
            {props.unit.leases?.length} {t('leases')}
          </span>
        </Show>
      </div>
    </div>
  );
};
