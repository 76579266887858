import { createEffect, createSignal, onCleanup } from 'solid-js';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { MergedTaxDocPdfPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { DeleteMergedPdfUseCase } from '~/pdfsigner/usecases/taxes/deleteMergedPdfUseCase';
import { printFileBlob, resetPrintSystem } from '~/utils/print';

export function usePdfPrinter() {
  const { model: printablePdf } = usePresenter(MergedTaxDocPdfPresenter);
  const [printIFrame, setPrintIFrame] = createSignal<HTMLIFrameElement>();
  const { execute: deletePrintablePdf } = useUseCase(DeleteMergedPdfUseCase);
  const [isPrinting, setIsPrinting] = createSignal(false);

  const printPdf = async (generatePdf: () => Promise<void>) => {
    setIsPrinting(true);
    try {
      await generatePdf();
    } finally {
      setIsPrinting(false);
    }
  };

  createEffect(() => {
    const pdfBlob = printablePdf()?.pdf;
    if (pdfBlob) {
      const iframe = printFileBlob(pdfBlob);
      setPrintIFrame(iframe);
      onCleanup(() => {
        resetPrintSystem(iframe);
      });
    } else {
      setPrintIFrame(undefined);
    }
  });

  onCleanup(() => {
    deletePrintablePdf();
    resetPrintSystem(printIFrame());
  });

  return {
    printPdf,
    isPrinting,
  };
}
