import { For, createSignal } from 'solid-js';
import term from '~/assets/images/common/term.png';
import type { Component, JSX } from 'solid-js';

export interface TableOfContentsItem {
  id: string;
  title: string;
}

export interface Section {
  id: string;
  title: string;
  content: string | string[] | JSX.Element | JSX.Element[];
  subsections?: {
    title: string;
    content: string | string[] | JSX.Element | JSX.Element[];
  }[];
}

export const EmailLink: Component<{ email: string }> = (props) => (
  <a href={`mailto:${props.email}`} class="text-primary underline hover-allowed:hover:text-primary/80">
    {props.email}
  </a>
);

export const externalLink = (href: string, text: string) => (
  <a href={href} target="_blank" rel="noopener noreferrer" class="text-primary underline hover-allowed:hover:text-primary/80">
    {text}
  </a>
);

export const TermWithTableOfContents = (props: {
  sections: Section[];
  tableOfContents: TableOfContentsItem[];
  title: string;
  header?: JSX.Element;
  scrollOffset: number;
}) => {
  const [activeSection, setActiveSection] = createSignal('');
  let scrollContainerRef: HTMLDivElement | undefined;

  const scrollToSection = (id: string, event: Event) => {
    event.preventDefault();
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element && scrollContainerRef) {
      const offset = props.scrollOffset;
      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition - offset;

      scrollContainerRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div class="flex items-center gap-2 font-medium text-essential-colour">
        <img class="size-4" src={term} alt="term-icon" />
        <span>{props.title}</span>
      </div>
      <div class="rounded bg-partingline p-2 pr-0">
        <div ref={scrollContainerRef} class="thinscroll h-60 overflow-auto normal-case">
          <div class="rounded-lg bg-white p-6 shadow md:p-8">
            {props?.header}

            <nav class="mb-4 rounded-lg bg-gray-50 p-4">
              <h2 class="mb-2 ml-3.5 leading-relaxed text-gray-600">Table of Contents</h2>
              <ol class="list-inside list-decimal space-y-2">
                <For each={props.tableOfContents}>
                  {(item) => (
                    <li class="ml-4">
                      <button
                        onClick={(e) => scrollToSection(item.id, e)}
                        class="text-left text-primary focus:outline-none hover-allowed:hover:text-primary/80 hover-allowed:hover:underline">
                        {item.title}
                      </button>
                    </li>
                  )}
                </For>
              </ol>
            </nav>

            <div class="space-y-8">
              <For each={props.sections}>
                {(section) => (
                  <section class="mb-12 scroll-mt-20" id={section.id} classList={{ 'bg-primary/10': activeSection() === section.id }}>
                    <h2 class="text-2xl font-bold">{section.title}</h2>
                    <div class="space-y-2">
                      <For each={Array.isArray(section.content) ? section.content : [section.content]}>{(content) => <p>{content}</p>}</For>
                    </div>
                    {section.subsections && (
                      <For each={section.subsections}>
                        {(subsection) => (
                          <div class="ml-4 space-y-2">
                            <h3 class="text-xl font-semibold">{subsection.title}</h3>
                            <For each={Array.isArray(subsection.content) ? subsection.content : [subsection.content]}>
                              {(content) => <p>{content}</p>}
                            </For>
                          </div>
                        )}
                      </For>
                    )}
                  </section>
                )}
              </For>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
