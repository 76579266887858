import { createMemo, For, Show } from 'solid-js';
import IconDownload from '~/assets/images/common/download.svg?component-solid';
import IconAdditional from '~/assets/images/rental-application/additional.svg?component-solid';
import IconBankStatement from '~/assets/images/rental-application/bankStatement.svg?component-solid';
import IconEmploymentVerification from '~/assets/images/rental-application/employmentVerification.svg?component-solid';
import IconIdentification from '~/assets/images/rental-application/identification.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { FileType } from '~/swagger/Api';
import { downloadFile, decodeFileName } from '~/utils/file';
import { validArr } from '~/utils/tool';
import { DocumentsModal } from './DocumentsModal';
import type { Component, JSX } from 'solid-js';

const FileTypeSort: MagicDoor.Api.FileType[] = [
  FileType.Identification,
  FileType.EmploymentVerification,
  FileType.BankStatement,
  FileType.Additional,
];

export const Documents = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  const sortedFileList = createMemo(() =>
    (applicationStore.application?.files ? [...applicationStore.application.files] : []).sort(
      (a, b) => FileTypeSort.indexOf(a.type) - FileTypeSort.indexOf(b.type)
    )
  );
  const IconMap: Record<MagicDoor.Api.FileType, { label: string; icon: Component<JSX.SvgSVGAttributes<SVGSVGElement>> }> = {
    bankStatement: {
      label: t('Bank statement'),
      icon: IconBankStatement,
    },
    identification: {
      label: t('Identification'),
      icon: IconIdentification,
    },
    employmentVerification: {
      label: t('Employment verification'),
      icon: IconEmploymentVerification,
    },
    additional: {
      label: t('Additional documents'),
      icon: IconAdditional,
    },
  };

  return (
    <>
      <Show
        when={validArr(applicationStore.application?.files)}
        fallback={<Empty description={t('Tenant has not report this information')} />}>
        <div class="mb-6 grid grid-cols-3 gap-7">
          <For each={sortedFileList()}>
            {(section) => {
              const { label, icon: Icon } = IconMap[section.type] || {};
              return (
                <div class="flex h-16 items-center divide-x rounded border border-partingline">
                  <div class="flex grow items-center overflow-hidden px-4">
                    <Icon class="size-5 shrink-0" />
                    <div class="ml-4 flex min-w-0 flex-col overflow-hidden whitespace-nowrap">
                      <div class="overflow-hidden text-ellipsis text-base text-text-level01">{label}</div>
                      <div class="overflow-hidden text-ellipsis text-xs text-text-level03">{decodeFileName(section.fileName)}</div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      downloadFile(section.fileUrl, section.fileName);
                    }}
                    class="flex h-8 shrink-0 cursor-pointer items-center justify-center px-4">
                    <IconDownload />
                  </div>
                </div>
              );
            }}
          </For>
        </div>
      </Show>
      <DocumentsModal />
    </>
  );
};
