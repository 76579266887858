import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios, useUnitListings } from '~/contexts/global';
import type { Variants } from '~/components/search-select/Context';
import type { addUnitListingPayload } from '~/repositories/unitListingsRepository';
import type { ImportUnitListingDto } from '~/swagger/Api';
export const UseAddUnitListing = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { current } = usePortfolios();
  const [selected, setSelected] = createSignal<Variants | undefined>();
  const { addUnitListing } = useUnitListings();

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/listings/${currentId}` : '/listings', { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const _selected = selected();
    if (!_selected?.leveledIds.length) {
      return;
    }

    const payload: addUnitListingPayload = _selected.mutiTypeIds;

    const res = await addUnitListing(payload);
    handleClose();
    handleImportTip(res);
    if (_selected.leveledIds.length === 1) {
      navigate(`/listings/${_selected.leveledIds.at(0)?.portfolioId}`);
    }
  };

  const handleImportTip = (res: ImportUnitListingDto) => {
    const count = res?.unitListingIds?.length || 0;
    if (count) {
      toast.success(t('Successfully added {count} items of data', { count }));
    } else {
      toast.normal(t('The listing that you have selected has already been imported'));
    }
  };

  return {
    handleClose,
    handleSubmit,
    selected,
    setSelected,
  };
};
