import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { VendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { GenerateVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/vendor/generateVendorTaxDocsUseCase';
import { GetAvailableVendorTaxYearsUseCase } from '~/pdfsigner/usecases/taxes/vendor/getAvailableVendorTaxYearsUseCase';
import { ChooseVendors } from './ChooseVendors';
import { VendorTaxAmountsPage } from './VendorTaxAmountsPage';
import { VendorTaxDocGenerationSummary } from './VendorTaxDocGenerationSummary';

export const GenerateVendorTaxDocs = () => {
  const { t } = useLocalization();
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = createSignal(0);
  const breadcrumbItems = createMemo(() => [
    { label: t('Tax documents'), link: '/maintenance/vendors/tax-documents' },
    { label: t('Generate tax documents') },
  ]);
  const steps = vendorId
    ? [t('Choose year'), t('Compensation amounts'), t('Summary')]
    : [t('Choose vendors'), t('Compensation amounts'), t('Summary')];
  const stepComponents = createMemo(() => [ChooseVendors, VendorTaxAmountsPage, VendorTaxDocGenerationSummary]);
  const { execute: generateVendorTaxDocs } = useUseCase(GenerateVendorTaxDocsUseCase);
  const { execute: getAvailableTaxYears, isLoading } = useUseCase(GetAvailableVendorTaxYearsUseCase);
  const { model: vendorTaxAmounts } = usePresenter(VendorTaxAmountPresenter);

  onMount(() => getAvailableTaxYears(vendorId));

  const onStepChange = (step: number, isBack?: boolean) => {
    setCurrentStep(step);
  };

  const shouldDisableNext = (): boolean => {
    if (currentStep() < 2) {
      return !vendorTaxAmounts()?.hasAmountsToShow;
    }
    const selectedNewOrChanged = vendorTaxAmounts()?.vendorTaxAmounts.newOrChanged.filter((v) => v.isSelected);
    return selectedNewOrChanged?.length === 0;
  };

  const getTitle = () => {
    let vendorName = undefined;
    if (vendorId) {
      const newVendorName = vendorTaxAmounts()?.vendorTaxAmounts.newOrChanged.find((v) => v.vendorId === vendorId)?.vendorName;
      const existingVendorName = vendorTaxAmounts()?.vendorTaxAmounts.existing.find((v) => v.vendorId === vendorId)?.vendorName;
      vendorName = newVendorName || existingVendorName;
    }
    if (vendorName && vendorId) {
      return t('Generate tax documents for {vendor}', { vendor: vendorName });
    }
    return t('Generate tax documents');
  };

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="size-full">
        <Show
          when={!isLoading()}
          fallback={
            <div class="flex size-full flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" size={100} showIcon={true} />
            </div>
          }>
          <Panel title={getTitle()} class="mt-4 flex size-full flex-col p-0">
            <MultiSteps
              showPrevious
              loading={false}
              submitText={t('Submit')}
              onSubmit={() => generateVendorTaxDocs(vendorId)}
              steps={stepComponents()}
              disableNext={shouldDisableNext()}
              currentStep={currentStep()}
              contentClass="pt-9 [&>div]:mx-auto [&>div]:w-full [&>div]:max-w-[1200px]"
              stepper={<Stepper class="flex justify-center pb-[72px]" steps={steps} step={currentStep()} />}
              onClose={() => navigate(-1)}
              onStepChange={(step, isBack) => onStepChange(step, isBack)}
            />
          </Panel>
        </Show>
      </div>
    </div>
  );
};
