import { splitProps, Show, createSignal, createMemo } from 'solid-js';
import IconMinus from '~/assets/images/common/minus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { LabeledGroup } from '~/components/common/Inputs';
import { RingLoader } from '~/components/common/Loaders';
import { Upload, UploadTrigger, FileItem, useUpload } from '~/components/common/Upload';
import { fileRepository } from '~/repositories/fileRepository';
import { createMutation } from '~/utils/resource';
import type { Component, JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { FileDescriptor, HydrateFile, UploaderFile } from '~/components/common/Upload/Interface';

export const FilePreview = (props: { file?: FileDescriptor | UploadedFile; onClean: () => void }) => {
  const { onFileRemove } = useUpload();

  const onClean = () => {
    if (props.file && 'uploaderFile' in props.file) {
      onFileRemove(props.file.uploaderFile);
    }
    props.onClean?.();
  };

  return (
    <div class="flex min-w-0 flex-1 items-center justify-between gap-2">
      <FileItem file={props.file as HydrateFile} />
      <Button onClick={onClean} variant="text">
        <IconMinus />
      </Button>
    </div>
  );
};

export type UploadedFile = {
  signedUrl?: string;
  thumbSignedUrl?: string | null;
  name?: string;
  fileId?: string;
  uploaderFile: UploaderFile;
};

export const LabeledSingleFilePreUploader: Component<
  {
    initialFile?: FileDescriptor;
    accept: string;
    maxSize?: number; // in MB
    needProcess?: boolean;
    trigger: JSX.Element;
    onAfterUpload: (file: UploadedFile) => void;
    onClean: () => void;
    onBeforeUpload?: (file: File) => void;
  } & LabeledGroupProps
> = (props) => {
  const [labeledGroudProps, fileUploaderProps] = splitProps(props, ['labelClass', 'error', 'label', 'groupClass', 'labelJSX', 'class']);

  const [file, setFile] = createSignal<UploadedFile | undefined>();

  const selectedFile = createMemo(() => {
    if (fileUploaderProps.initialFile) return fileUploaderProps.initialFile;

    if (file()?.fileId) return file();

    return undefined;
  });

  const doUpload = createMutation(async (file: UploaderFile, needProcess: boolean) => {
    fileUploaderProps.onBeforeUpload?.(file);
    const uploadedFile = await fileRepository.uploadFile(file, needProcess).catch(() => undefined);

    const parsedUploadedFile = {
      fileId: uploadedFile?.file.fileId,
      name: file.name,
      signedUrl: uploadedFile?.file.signedUrl,
      thumbSignedUrl: uploadedFile?.file.thumbSignedUrl,
      uploaderFile: file,
    };

    setFile(parsedUploadedFile);
    fileUploaderProps.onAfterUpload(parsedUploadedFile);
  });

  const onFilesListChange = async (files: UploaderFile[]) => {
    const file = files[0];
    if (!file) return;
    doUpload(file, props.needProcess || false);
  };

  return (
    <Upload name={props.id} maxSize={props.maxSize} multiple={false} accept={props.accept} onFilesListChange={onFilesListChange}>
      <LabeledGroup {...labeledGroudProps} labelContainerClass="h-full">
        <div class="relative flex min-h-20 flex-1 items-center justify-center gap-1 overflow-hidden rounded-lg border border-partingline p-4 text-xs text-primary">
          <Show when={doUpload.loading}>
            <div class="absolute inset-0 z-10 flex items-center justify-center bg-black/20">
              <RingLoader class="mx-auto my-2 h-8" />
            </div>
          </Show>
          <Show
            when={!selectedFile()}
            fallback={
              <FilePreview
                file={selectedFile()}
                onClean={() => {
                  fileUploaderProps.onClean?.();
                  setFile(undefined);
                }}
              />
            }>
            <UploadTrigger class="size-full">
              <div class="flex h-full cursor-pointer flex-col items-center justify-center gap-1">{props.trigger}</div>
            </UploadTrigger>
          </Show>
        </div>
      </LabeledGroup>
    </Upload>
  );
};
