import { createMemo, onMount, Show } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import rentalApplicationsBg from '~/assets/images/dashboard/rentalApplicationsBg.svg';
import { LinkButton } from '~/components/common/Buttons';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { useBankAccountsList, useRentalApplicationPaymentSettings } from '~/contexts/local';
import Card from '~/pages/dashboard/components/Card';
import { emptyPlaceholder } from '~/utils/constant';
import { ApplyRentalApplicationCard } from './ApplyRentalApplicationCard';
import RentalApplications from './RentalApplications';
import type { Component } from 'solid-js';

type RentalApplicationCardProps = {
  class?: string;
};

export const RentalApplicationCard: Component<RentalApplicationCardProps> = (props) => {
  const { t } = useLocalization();
  const { rentalApplications, isLoading, getRentalApplications } = useRentalApplication();
  const { bankAccounts } = useBankAccountsList();
  const { rentalApplicationPaymentSettings, getRentalApplicationPaymentSettings } = useRentalApplicationPaymentSettings();

  const requirePaymentForApplication = createMemo(() => {
    return rentalApplicationPaymentSettings()?.requirePaymentForApplication ?? false;
  });

  const hasStripeConnection = () => bankAccounts()?.some((account) => account?.stripe?.connected);

  const applicationCount = () => (isLoading() ? emptyPlaceholder : rentalApplications().length.toString());

  onMount(async () => {
    await getRentalApplicationPaymentSettings();
    await getRentalApplications({ new: true });
  });

  return (
    <Card
      class={props.class}
      title={t('New rental applications')}
      description={
        requirePaymentForApplication() && !hasStripeConnection() ? t('Not activated') : t('{count} new', { count: applicationCount() })
      }
      bgClass="bg-[#F9FFFE]"
      bgImgSrc={rentalApplicationsBg}
      actions={
        <Show when={!requirePaymentForApplication()} fallback={null}>
          <LinkButton href="/leasing/rental-applications" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
            <div class="md:hidden">{t('All')}</div>
            <div class="hidden whitespace-nowrap md:flex">{t('View all')}</div>
            <IconViewArrow class="size-4 text-essential-colour" />
          </LinkButton>
        </Show>
      }>
      <Show when={!requirePaymentForApplication() || hasStripeConnection()} fallback={<ApplyRentalApplicationCard />}>
        <RentalApplications filterNew={true} />
      </Show>
    </Card>
  );
};
