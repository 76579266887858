import { Route, Routes } from '@solidjs/router';
import { OwnerTabRoutes } from './OwnerTabRoutes';
import { EditOwner } from './forms/EditOwner';
import { OwnerDetailsPage } from './owner-details';
import { GenerateOwnerTaxDocs } from './tax-documents/GenerateOwnerTaxDocs';

const matchFilters = {
  ownerId: /\d+/,
};
export const OwnerRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:ownerId/*" matchFilters={matchFilters} component={OwnerDetailsPage} />
        <Route path="/:ownerId/edit" component={EditOwner} />
        <Route path="/tax-documents/generate/:ownerId?" component={GenerateOwnerTaxDocs} />
        <Route path="*" component={OwnerTabRoutes} />
      </Routes>
    </>
  );
};
