import { useNavigate } from '@solidjs/router';
import { Button } from '~/components/common/Buttons';
import { IconSquarePen } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

interface LinkProps {
  value: string;
  onChange?: () => void;
}
export const LinkBankAccount: Component<LinkProps> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  return (
    <div class="col-span-2 flex items-center gap-1">
      <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => navigate(props.value)}>
        <IconSquarePen class="size-4" />
        <div class="text-xs">{t('Jump to bank account')}</div>
      </Button>
      <span class="text-xs text-text-level03">{t('Add and manage bank accounts')}</span>
    </div>
  );
};
