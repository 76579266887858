import { For, Show } from 'solid-js';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import { SelectedOwnerTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { OwnerTaxAmountLine } from './OwnerTaxAmountLine';

export const OwnerTaxDocGenerationSummary = () => {
  const { t } = useLocalization();
  const { model: ownerTaxAmounts } = usePresenter(SelectedOwnerTaxAmountPresenter);

  return (
    <Section
      title={t('Summary')}
      class="h-full px-[96px] pb-4"
      titleClass="mt-2 px-0"
      contentClass="flex h-full grow justify-center text-center normal-case">
      <Show
        when={ownerTaxAmounts()?.newOrChanged && ownerTaxAmounts()!.newOrChanged.length > 0}
        fallback={
          <div class={'flex items-center justify-center normal-case'}>
            {t('No owners without tax documents, or with changed compensation amounts were selected')}
          </div>
        }>
        <div class="grid size-full grid-cols-2 gap-4">
          <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
            <div class="mb-2 flex flex-row justify-between p-2">
              <div>{t('Owner')}</div>
              <div class="flex flex-row gap-2">
                <div class="w-44 pr-2 text-end">{t('Rent income')}</div>
                <div class="w-44 pr-2 text-end">{t('Other income')}</div>
              </div>
            </div>
            <For each={ownerTaxAmounts()?.newOrChanged}>
              {(owner) => (
                <OwnerTaxAmountLine
                  id={owner.ownerId}
                  name={owner.ownerName}
                  warningMessage={owner.hasTaxId ? undefined : t('Tax ID is missing!')}
                  amount={owner.rentAmount}
                  otherAmount={owner.otherAmount}
                  isAmountEditable={false}
                />
              )}
            </For>
          </div>
        </div>
      </Show>
    </Section>
  );
};
