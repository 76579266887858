import { RentalApplicationScreeningSettingsProvider } from '~/contexts/local';
import { Screen } from './Screen';

export const RentalApplicationInformationWarp = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationScreeningSettingsProvider>
        <Screen />
      </RentalApplicationScreeningSettingsProvider>
    </div>
  );
};
