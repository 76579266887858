import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import { GetOwnerTaxAmountsUseCase } from './getOwnerTaxAmountsUseCase';
import { GetOwnerTaxDocumentsUseCase } from './getOwnerTaxDocumentsUseCase';
import type { TaxDocRegenerationRequest, GetTaxDocumentsRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class RegenerateOwnerTaxDocsUseCase extends MagicUseCase {
  public async runLogic(request: TaxDocRegenerationRequest) {
    if (!this.getState().taxInfo.ownersAndAmounts || this.getState().taxInfo.ownersAndAmounts.length === 0) {
      await new GetOwnerTaxAmountsUseCase().execute(request);
    }
    const selectedOwnerTaxAmount = this.getState().taxInfo.ownersAndAmounts.find((owner) => owner.ownerId === request.entityId);
    if (!selectedOwnerTaxAmount) {
      return;
    }
    await taxDocumentsRepository.regenerateOwnerTaxDocs({ year: request.year, ownerData: selectedOwnerTaxAmount });
    await new GetOwnerTaxDocumentsUseCase().execute({
      entityType: TaxEntityTypes.Vendor,
      page: this.getState().taxInfo.paginatedVendorTaxDocuments.currentPage,
      entityId: request.shouldRefetchAllData === false ? request.entityId : undefined,
    } as GetTaxDocumentsRequest);
  }
}
