import { A } from '@solidjs/router';
import { Show, For } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { getOwnerName } from '~/components/owner/utils';
import { cn } from '~/utils/classnames';
import type { CustomLink } from '~/types/display';

export const OwnerTitle = (props: { owner: MagicDoor.Api.OwnerDto; class?: string; customLinks?: CustomLink[] }) => {
  return (
    <div class={cn('flex items-center justify-start gap-2', props.class)}>
      <div class="flex items-center gap-3">
        <Avatar class="size-9 shrink-0" name={getOwnerName(props.owner)} />
        <div class=" flex flex-col space-y-1 text-sm">
          <div class="line-clamp-2 space-y-0.5 text-left text-sm font-medium">{getOwnerName(props.owner)}</div>
          <Show when={props.customLinks && props.customLinks.length > 0}>
            <div class="flex flex-wrap items-start text-xs">
              <For each={props.customLinks}>
                {(link, index) => (
                  <>
                    <A href={link.href} class="text-primary hover-allowed:hover:underline">
                      {link.displayName}
                    </A>
                    <Show when={index() < props.customLinks!.length - 1}>
                      <span class="mx-2  border-r border-partingline">&nbsp;</span>
                    </Show>
                  </>
                )}
              </For>
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
};
