import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import { ChangeOwnerTaxYearUseCase } from './changeOwnerTaxYearUseCase';

export class GetAvailableOwnerTaxYearsUseCase extends MagicUseCase {
  public async runLogic(ownerId?: string) {
    this.getState().taxInfo.availableYears = await taxDocumentsRepository.getAvailableOwnerTaxYears();
    await new ChangeOwnerTaxYearUseCase().execute({
      year: this.getState().taxInfo.availableYears[0],
      ownerId,
    });
  }
}
