import { useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import ArrowRightIcon from '~/assets/images/common/arrowRight.svg?component-solid';
import UploadDocumentLease from '~/assets/images/leases/uploadDocument.svg?component-solid';
import ViewDocumentLease from '~/assets/images/leases/viewDocument.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type ReviewProps = {
  leaseId?: string | null;
};

export const Review: Component<ReviewProps> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  return (
    <div class="w-full">
      <div class="flex flex-col items-center p-14 text-xl font-semibold text-text-level01">
        <span>{t('Lease_create_success_message')}</span>
        <div class="my-6 flex gap-6">
          <div
            class="group flex h-60 cursor-pointer items-center gap-5 rounded-xl px-8 py-7 transition-opacity hover-allowed:hover:opacity-90"
            style={{
              background: 'linear-gradient(126deg, #4D1ABA 1.32%, #8C43C5 100%), linear-gradient(126deg, #6227DF 1.32%, #E338FF 100%)',
            }}
            onClick={() => navigate(`/leasing/leases/${props.leaseId}`)}>
            <div class="flex h-full flex-col justify-between">
              <div class="flex w-40 flex-col gap-y-1.5">
                <div class="text-xl font-semibold text-white">{t('View it now')}</div>
                <div class="text-xs text-white/80">{t('The lease is sent to the tenant later')}</div>
              </div>
              <div class="flex size-8 items-center justify-center rounded-lg border text-white hover-allowed:group-hover:bg-white hover-allowed:group-hover:text-essential-colour">
                <ArrowRightIcon />
              </div>
            </div>
            <ViewDocumentLease />
          </div>

          <div
            class="group flex h-60 cursor-pointer items-center gap-5 rounded-xl px-8 py-7 transition-opacity hover-allowed:hover:opacity-90"
            style={{
              background:
                'linear-gradient(126deg, #6227DF 1.32%, #E338FF 100%), linear-gradient(126deg, #4D1ABA 1.32%, #8C43C5 100%), #0C9743',
            }}
            onClick={() => navigate(`/leasing/leases/${props.leaseId}/sign`)}>
            <div class="flex h-full flex-col justify-between">
              <div class="flex w-40 flex-col gap-y-1.5">
                <div class="text-xl font-semibold text-white">{t('Send out lease to E-sign/print')}</div>
                <div class="text-xs text-white/80">{t('Tenant signs to complete the lease')}</div>
              </div>
              <div class="flex size-8 items-center justify-center rounded-lg border text-white hover-allowed:group-hover:bg-white hover-allowed:group-hover:text-essential-colour">
                <ArrowRightIcon />
              </div>
            </div>
            <UploadDocumentLease />
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-end gap-2 border-t border-partingline px-6 py-5">
        <Show when={props.leaseId}>
          <LinkButton href={`/leasing/leases/${props.leaseId}`} variant="solid">
            {t('Take me to the lease')}
          </LinkButton>
        </Show>
      </div>
    </div>
  );
};
