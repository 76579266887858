import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import type { GetTaxDocumentsRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class GetOwnerTaxDocumentsUseCase extends MagicUseCase {
  public async runLogic(request?: GetTaxDocumentsRequest) {
    const existingDocuments = this.getState().taxInfo.paginatedOwnerTaxDocuments;
    const documents = await taxDocumentsRepository.getOwnerTaxDocuments(request);
    if (existingDocuments.totalItems != documents.totalItems) {
      this.getState().taxInfo.paginatedOwnerTaxDocuments.currentPage = documents.currentPage;
      this.getState().taxInfo.paginatedOwnerTaxDocuments.pageSize = documents.pageSize;
      this.getState().taxInfo.paginatedOwnerTaxDocuments.totalItems = documents.totalItems;
      this.getState().taxInfo.paginatedOwnerTaxDocuments.totalPages = documents.totalPages;
      this.getState().taxInfo.paginatedOwnerTaxDocuments.items = Array(documents.totalItems).fill(undefined);
    }
    const startIndex = (documents.currentPage - 1) * documents.pageSize;
    documents.items.forEach((item, index) => {
      this.getState().taxInfo.paginatedOwnerTaxDocuments.items[startIndex + index] = item;
    });
  }
}
