import { MissingCompanyTaxIdError } from '~/errors/missingCompanyTaxIdError';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { CheckIfTaxDocumentsCanBeGenerated } from '~/pdfsigner/usecases/taxes/checkIfTaxDocumentsCanBeGenerated';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';

export class GenerateVendorTaxDocsUseCase extends MagicUseCase {
  public async runLogic(vendorId: string) {
    await new CheckIfTaxDocumentsCanBeGenerated().execute();
    const selectedVendorsAndAmounts = this.getState().taxInfo.vendorsAndAmounts.filter((vendor) => vendor.isSelected);
    if (!taxDocumentsRepository.canGenerateTaxDocs()) {
      throw new MissingCompanyTaxIdError();
    }
    if (vendorId) {
      await taxDocumentsRepository.regenerateVendorTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        vendorData: selectedVendorsAndAmounts[0],
      });
      this.navigate(`/maintenance/vendors/${vendorId}/tax-documents`);
    } else {
      await taxDocumentsRepository.generateVendorTaxDocs({
        year: this.getState().taxInfo.selectedYear,
        vendorData: selectedVendorsAndAmounts,
      });
      this.navigate('/maintenance/vendors/tax-documents');
    }
  }
}
