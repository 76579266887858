import { Show } from 'solid-js';
import { Modal } from '~/components/modals/Modal';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import { AddUnitListingMobile } from './AddUnitListingMobile';
import { UseAddUnitListing } from './UseAddUnitListing';
import type { Component } from 'solid-js';

export const AddUnitListingModal: Component = () => {
  const { t } = useLocalization();
  const { addUnitListing } = useUnitListings();
  const { handleClose, handleSubmit, selected, setSelected } = UseAddUnitListing();

  return (
    <>
      <Show when={window.innerWidth > 767}>
        <Modal
          visible
          class="animate-zoom-in"
          title={t('Select listing units')}
          doneText={t('Confirm')}
          onDone={handleSubmit}
          onCancel={handleClose}
          disabled={!selected()?.leveledIds.length}
          loading={addUnitListing.loading}
          confirmation={false}>
          <div class="px-7 py-6">
            <LabeledLeveledSearchSelect
              id="listings-add-select"
              label={t('Select items')}
              enabledTypes={[LevelSearch.Portfolio, LevelSearch.Property, LevelSearch.Unit]}
              multiple
              singleTypeOnly={false}
              onChange={(_, variants) => {
                setSelected(variants);
              }}
            />
          </div>
        </Modal>
      </Show>
      <Show when={window.innerWidth <= 767}>
        <div class="absolute left-0 top-16 w-full bg-inputbox-bg">
          <AddUnitListingMobile />
        </div>
      </Show>
    </>
  );
};
