/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddAssociationViolationLeaseBillDto {
  description: string;
  chartOfAccountId: string;
  /** @format double */
  amount: number;
  companyCharge: boolean;
}

export interface AddCompanyPayOutDto {
  bankAccountId: string;
  /** @format double */
  amount: number;
  memo?: string | null;
  paymentType: PayBillType;
  paymentMethod?: TransactionPaymentMethod | null;
  externalTransactionId?: string | null;
}

export interface AddCompanyPayOutsDto {
  payOuts: AddCompanyPayOutDto[];
  /** @format date-time */
  paymentDate?: string | null;
  setPayOutDate?: boolean | null;
}

export interface AddDraftWorkOrderBillDto {
  /** @format date */
  billDate?: string | null;
  /** @format date */
  dueDate: string;
  lineItems: WorkOrderBillLineItemDto[];
}

export interface AddLeaseDepositRequestDto {
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @format date */
  dueDate: string;
  description?: string | null;
  /** @format date */
  billDate?: string | null;
}

export interface AddManagementFeesDto {
  /** @format date-time */
  calculateFeesUntil: string;
  /** @minItems 1 */
  managementFees: ManagementFeeDto[];
}

export interface AddOrUpdateWorkOrderActionDto {
  subject: string;
  description?: string | null;
  files?: EmbedFileDto[] | null;
  status: Status;
  /** @format date-time */
  actionDate: string;
}

export interface AddPropertyManagerOverwriteForRunBookRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyManagerId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
}

export interface AddServiceBillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 0
   * @maxLength 150
   */
  propertyBillMemo?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  companyBillMemo?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyChartOfAccountId: string;
}

export interface AddVendorOverwriteForRunBookRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  timeFrame?: TimeFrameDto | null;
}

export interface AddressDto {
  /**
   * @minLength 1
   * @maxLength 250
   */
  streetAddress1?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  streetAddress2?: string | null;
  /**
   * @minLength 2
   * @maxLength 150
   */
  city?: string | null;
  /**
   * @minLength 2
   * @maxLength 25
   */
  state?: string | null;
  /**
   * @minLength 2
   * @maxLength 25
   */
  zipCode?: string | null;
  /**
   * @minLength 2
   * @maxLength 25
   */
  country?: string | null;
  /** Indicates if the address is valid for a rental application */
  isValid: boolean;
}

export enum AmenityAvalability {
  Unavailable = 'unavailable',
  Available = 'available',
  Limited = 'limited',
  Pending = 'pending',
}

export interface AmenityDto {
  /**
   * @minLength 2
   * @maxLength 50
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  icon?: string | null;
  avalability: AmenityAvalability;
  description?: string | null;
}

export interface AnnouncementListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyId: string;
  /** @format date-time */
  sentAt: string;
  chatMessage?: string | null;
  emailMessage?: string | null;
  /** @maxLength 250 */
  subject?: string | null;
  /** @format int32 */
  targetsCount: number;
}

export interface AnnouncementsDto {
  announcements: AnnouncementListDto[];
  properties: PropertyDto[];
}

export enum ApplicationDecision {
  NoDecision = 'noDecision',
  Approved = 'approved',
  Rejected = 'rejected',
  Ignored = 'ignored',
}

export enum ApplicationPaymentStatus {
  Unpaid = 'unpaid',
  Processing = 'processing',
  Paid = 'paid',
  Failed = 'failed',
  Refunded = 'refunded',
  Unrequired = 'unrequired',
}

export interface AssociationViolationDto {
  id: string;
  portfolioId: string;
  propertyId: string;
  unitId?: string | null;
  leaseId?: string | null;
  status: AssociationViolationStatus;
  hoaVendorId: string;
  reference: string;
  /** @format date */
  violationReceivedDate: string;
  /** @format date */
  violationDate: string;
  /** @format date */
  fixByDate?: string | null;
  /** @format double */
  associationFine?: number | null;
  title: string;
  description: string;
  /** @format date-time */
  notificationSentAt?: string | null;
  files: FileDto[];
  propertyFineBill?: BillDto | null;
  leaseFineBill?: BillDto | null;
  portfolio: PortfolioDto;
  property: PropertyDto;
  unit?: UnitDto | null;
  lease?: LeaseDto | null;
  workOrders: WorkOrderListDto[];
  leaseBills: BillDto[];
  owners: OwnerDto[];
  workOrderVendors: VendorDto[];
}

export interface AssociationViolationListDto {
  id: string;
  portfolioId: string;
  propertyId: string;
  unitId?: string | null;
  leaseId?: string | null;
  status: AssociationViolationStatus;
  hoaVendorId: string;
  reference: string;
  /** @format date */
  violationReceivedDate: string;
  /** @format date */
  violationDate: string;
  /** @format date */
  fixByDate?: string | null;
  /** @format double */
  associationFine?: number | null;
  title: string;
  description: string;
  /** @format date-time */
  notificationSentAt?: string | null;
}

export enum AssociationViolationStatus {
  Received = 'received',
  InProgress = 'inProgress',
  Resolved = 'resolved',
}

export interface AuthRequestDto {
  /**
   * @format email
   * @minLength 1
   * @maxLength 250
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 250
   */
  password: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyId?: string | null;
}

export interface AuthResultDto {
  success: boolean;
  token?: string | null;
  companies?: CompanyLoginDetailsDto[] | null;
}

export interface BalanceSheetReportDataDto {
  groupHeaders: ReportGroupHeader[];
  assets: ChartOfAccountReportLineDto[];
  totalAssets: Record<string, number>;
  liabilities: ChartOfAccountReportLineDto[];
  totalLiabilities: Record<string, number>;
  capital: ChartOfAccountReportLineDto[];
  totalCapital: Record<string, number>;
}

export interface BankAccountActivityDto {
  /** @format date-time */
  date: string;
  payeeOrPayerName: string;
  transactionIdentity: TransactionIdentity;
  transactionPaymentMethod: TransactionPaymentMethod;
  transactionStatus: TransactionStatus;
  externalTransactionType?: ExternalTransactionType | null;
  externalTransactionId?: string | null;
  failureReason?: TransactionFailure | null;
  /** @format double */
  cashIn?: number | null;
  /** @format double */
  cashOut?: number | null;
  /** @format double */
  balance: number;
  description?: string | null;
  companyBill: boolean;
  cleared: boolean;
}

export interface BankAccountActivityReportDto {
  account: BankAccountDto;
  dateRange: DateRangeDto;
  /** @format double */
  startingBalance: number;
  /** @format double */
  netChange: number;
  activities: BankAccountActivityDto[];
  activitiesTotal: BankAccountActivityTotalDto;
}

export interface BankAccountActivityTotalDto {
  /** @format double */
  cashIn: number;
  /** @format double */
  cashOut: number;
}

export enum BankAccountConnectType {
  Plaid = 'plaid',
  Stripe = 'stripe',
}

export interface BankAccountConnectionBalanceDto {
  /** @format double */
  available: number;
  /** @format double */
  pending: number;
}

export enum BankAccountConnectionStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}

export interface BankAccountConnectionsDto {
  plaid: BankAccountPlaidConnectionDto;
  stripe: BankAccountStripeConnectionDto;
}

export interface BankAccountDto {
  id: string;
  name: string;
  description?: string | null;
  active: boolean;
  accountNumber?: string | null;
  routingNumber?: string | null;
  type?: BankAccountType | null;
}

export interface BankAccountPlaidConnectionDto {
  eligible: boolean;
  status?: BankAccountConnectionStatus | null;
  balance?: BankAccountConnectionBalanceDto | null;
  notEligibleReason?: string | null;
  deniedReason?: string | null;
  limits?: PlaidTransferLimitsDto | null;
}

export interface BankAccountStripeConnectionDto {
  eligible: boolean;
  status?: BankAccountConnectionStatus | null;
  balance?: BankAccountConnectionBalanceDto | null;
  notEligibleReason?: string | null;
  deniedReason?: string | null;
}

export enum BankAccountType {
  Checking = 'checking',
  Savings = 'savings',
}

export interface BankRouting {
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: BankAccountType;
}

export interface BatchCreateOwnerTaxDocumentDto {
  taxAmounts?: CreateOwnerTaxDocumentDto[] | null;
}

export interface BatchCreateTaxDocumentDto {
  taxAmounts?: CreateTaxDocumentDto[] | null;
}

export interface BillAllocationDto {
  transactionId: string;
  /** @format double */
  amount: number;
  /** @format date-time */
  paymentDate: string;
}

export interface BillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  companyCharge: boolean;
  identity: BillIdentity;
  direction: CreditDebitDirection;
  /** @minLength 1 */
  reference?: string | null;
  /** @minLength 1 */
  memo?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate: string;
  /** @format double */
  totalAmount: number;
  /** @format double */
  paid: number;
  /** @format double */
  due: number;
  lineItems: BillLineItemDto[];
  recurringChargeId?: string | null;
  creator: CreatorDto;
}

export interface BillFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
  /** @format int64 */
  fileSize: number;
  /** @format date-time */
  uploaded: string;
  description?: string | null;
}

export enum BillIdentity {
  Lease = 'lease',
  Vendor = 'vendor',
  MagicDoor = 'magicDoor',
  RentalApplication = 'rentalApplication',
  ProcessingFee = 'processingFee',
  ManagementFee = 'managementFee',
  LeaseDeposit = 'leaseDeposit',
  OwnerTransfer = 'ownerTransfer',
  ServiceBill = 'serviceBill',
  CompanyPropertyCredit = 'companyPropertyCredit',
}

export interface BillLineItemDto {
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  paid: number;
  allocations?: BillAllocationDto[] | null;
}

export interface BillPayProperty {
  property: PropertyDto;
  /** @format double */
  availableBalance: number;
  defaultBankAccountId?: string | null;
  bills: BillDto[];
}

export interface CalculateManagementFeesDto {
  propertyIds?: string[] | null;
  managementFeeDate: ManagementFeeDate;
}

export interface CalculatedDistributionsForPropertiesDto {
  properties: CalculatedDistributionsForPropertyDto[];
}

export interface CalculatedDistributionsForPropertyDto {
  property: PropertyDto;
  defaultBankAccountId?: string | null;
  /** @format double */
  currentPropertyBalance: number;
  /** @format double */
  propertyReserve: number;
  /** @format double */
  propertyBalance: number;
  /** @format double */
  availableForDistribution: number;
  defaultMemo: string;
  distributionOwners: DistributionOwnerDto[];
}

export interface CanSubmitTaxDocumentsDto {
  eligible: boolean;
}

export enum CardType {
  DebitCard = 'debitCard',
  CreditCard = 'creditCard',
}

export enum CashFlowGroupBy {
  Total = 'total',
  Property = 'property',
  Portfolio = 'portfolio',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export interface CashFlowReportDto {
  groupHeaders: ReportGroupHeaderDto[];
  income: ChartOfAccountReportLineDto[];
  incomeTotal: Record<string, number>;
  expenses: ChartOfAccountReportLineDto[];
  expensesTotal: Record<string, number>;
  netIncome: Record<string, number>;
  otherItems: ChartOfAccountReportLineDto[];
  netOtherItems: Record<string, number>;
  cashFlow: Record<string, number>;
  beginningCash: Record<string, number>;
  endingCash: Record<string, number>;
}

export interface ChargeAssociationFineToPropertyDto {
  /** @format date */
  dueDate?: string | null;
}

export enum ChargeFrequency {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  Monthly2XSplit = 'monthly2XSplit',
}

export interface ChartOfAccountDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  parentId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  offsetChartOfAccountId?: string | null;
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  number?: number | null;
  type: ChartOfAccountType;
  isDefault: boolean;
}

export interface ChartOfAccountReportDto {
  chartOfAccount: ChartOfAccountDto;
  lineItems: LineItemReportDto[];
  /** @format double */
  startingBalance: number;
  /** @format double */
  totalDebit: number;
  /** @format double */
  totalCredit: number;
  /** @format double */
  endingBalance: number;
  /** @format double */
  netChange: number;
}

export interface ChartOfAccountReportLineDto {
  chartOfAccount: ChartOfAccountDto;
  children: ChartOfAccountReportLineDto[];
  groups: Record<string, number>;
  groupTotal?: Record<string, number>;
}

export enum ChartOfAccountType {
  Asset = 'asset',
  Equity = 'equity',
  Liability = 'liability',
  Revenue = 'revenue',
  Expense = 'expense',
  Capital = 'capital',
}

export interface ChatAILeaseSettingsDto {
  enabled: boolean;
  additionalInstructions: string;
}

export interface ChatAILeaseSettingsDtoSettingsWithOverrideDto {
  settings?: ChatAILeaseSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface ChatAILeaseSettingsDtoSettingsWithOverridesDto {
  settings?: ChatAILeaseSettingsDto | null;
  overrides: ChatAILeaseSettingsDtoSettingsWithOverrideDto[];
}

export interface ChatAIMaintenanceRequestsSettingsDto {
  enabled: boolean;
  additionalInstructions: string;
}

export interface ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverrideDto {
  settings?: ChatAIMaintenanceRequestsSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverridesDto {
  settings?: ChatAIMaintenanceRequestsSettingsDto | null;
  overrides: ChatAIMaintenanceRequestsSettingsDtoSettingsWithOverrideDto[];
}

export interface ChatCreateResponseDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /** @minLength 1 */
  failureReason?: string | null;
}

export interface ChatMessageDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId: string;
  messageType: MessageType;
  /**
   * @minLength 19
   * @maxLength 19
   */
  participantId?: string | null;
  /** @minLength 1 */
  message?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  messageLanguage?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /** @minLength 1 */
  translatedMessage?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  translatedMessageLanguage?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  translatedFailed: boolean;
  aiEnhanced: boolean;
  /** @format date-time */
  sentAt: string;
  sendText: boolean;
  textStatus?: TextStatus | null;
  /** @format date-time */
  textSentAt?: string | null;
  /** @format date-time */
  textDeliveredAt?: string | null;
  sendEmail: boolean;
  source: MessageSource;
  files: ChatMessageFileDto[];
  read: boolean;
  /** @format date-time */
  readAt?: string | null;
  emailStatus?: EmailStatus | null;
  /** @format date-time */
  emailSentAt?: string | null;
  /** @format date-time */
  emailDeliveredAt?: string | null;
}

export interface ChatMessageFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  fileExtension: string;
  /** @minLength 1 */
  fileUrl: string;
  thumbUrl?: string | null;
  /** @minLength 1 */
  contentType: string;
  /** @format int64 */
  fileSize: number;
}

export enum ChatParticipantType {
  Tenant = 'tenant',
  Unknown = 'unknown',
  Vendor = 'vendor',
  PropertyManager = 'propertyManager',
  Company = 'company',
  Owner = 'owner',
}

export interface ChatSettingsDto {
  personalChats: boolean;
  enableTranslation: boolean;
  quitHours?: TimeRange | null;
}

export enum ChatTypes {
  Lease = 'lease',
  Tenant = 'tenant',
  Vendor = 'vendor',
  MaintenanceRequest = 'maintenanceRequest',
  WorkOrder = 'workOrder',
  WorkOrderGroup = 'workOrderGroup',
  Unknown = 'unknown',
  Owner = 'owner',
}

export interface CheckBillPaymentDto {
  billId?: string | null;
  data: Record<string, string | null>;
  /** @format double */
  amount: number;
}

export interface CheckDto {
  id: string;
  companyId: string;
  /** @format int32 */
  checkNumber: number;
  vendorId?: string | null;
  ownerId?: string | null;
  bankAccountId: string;
  /** @format double */
  amount: number;
  memo?: string | null;
  /** @format date-time */
  createdOn: string;
  /** @format date-time */
  date: string;
  transactionId?: string | null;
  billPayments: CheckBillPaymentDto[];
  dataHeaders: string[];
  voided: boolean;
  voidReason?: string | null;
  amountString?: string;
}

export enum CheckIdentity {
  VendorBill = 'vendorBill',
  OwnerDistribution = 'ownerDistribution',
  CompanyPayOut = 'companyPayOut',
}

export interface CheckScreeningStatusResultDto {
  isScreeningComplete: boolean;
  status: ScreeningStatus;
  /** @format int32 */
  transunionScreeningRequestId?: number | null;
  /** @format int32 */
  transunionScreeningRequestRenterId?: number | null;
}

export enum CommentSection {
  ApplicantInformation = 'applicantInformation',
  Questions = 'questions',
  IntrestedUnits = 'intrestedUnits',
  RentalHistory = 'rentalHistory',
  WorkHistory = 'workHistory',
  Screening = 'screening',
  Other = 'other',
}

export enum CompanyBusinessInfoStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface CompanyBusinessInfoStatusDto {
  status?: CompanyBusinessInfoStatus | null;
  rejectedReason?: string | null;
}

export enum CompanyChargeService {
  AchReturnFee = 'achReturnFee',
  AchFailedProcessingFee = 'achFailedProcessingFee',
  AchTenantProcessingFee = 'achTenantProcessingFee',
  AchOutgoingProcessingFee = 'achOutgoingProcessingFee',
  TransunionScreening = 'transunionScreening',
  Subscription = 'subscription',
}

export interface CompanyDomainDto {
  /** @pattern ^[a-z0-9][a-z0-9-]+(\.[a-z0-9][a-z0-9-]+)*\.[a-z]{2,}$ */
  domain?: string | null;
}

export interface CompanyDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @format uri
   * @minLength 1
   */
  subUrl: string;
  /**
   * @format tel
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  /**
   * @format tel
   * @minLength 1
   */
  phoneNumber: string;
  /** @minLength 1 */
  timeZoneInfo: string;
  /** @minLength 1 */
  customDomain?: string | null;
  address: AddressDto;
  publicUrl: string;
}

export interface CompanyInvoiceAllocationDto {
  transaction: TransactionDto;
  /** @format double */
  amount: number;
  /** @format date-time */
  paymentDate: string;
}

export interface CompanyInvoiceChargeDto {
  service: CompanyChargeService;
  /** @format double */
  amount: number;
  /** @format date-time */
  chargeTime: string;
  /** @format date-time */
  dueTime: string;
  description: string;
  billId: string;
  metadata?: Record<string, string>;
}

export interface CompanyInvoiceDto {
  id: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format double */
  totalAmount: number;
  /** @format double */
  allocated: number;
  /** @format double */
  due: number;
  summaries: CompanyInvoiceSummaryDto[];
  charges: CompanyInvoiceChargeDto[];
  allocations: CompanyInvoiceAllocationDto[];
}

export interface CompanyInvoiceSummaryDto {
  service: CompanyChargeService;
  /** @format int32 */
  itemCount: number;
  /** @format double */
  itemTotal: number;
}

export interface CompanyLoginDetailsDto {
  id: string;
  name: string;
}

export interface CompanyPayOutBankAccountDto {
  bankAccount: BankAccountDto;
  /** @format double */
  amount: number;
  lineItems: LineItemDto[];
}

export interface CompanyPayOutDto {
  /** @format date-time */
  lastRun?: string | null;
  bankAccountPayOuts: CompanyPayOutBankAccountDto[];
  chartOfAccounts: ChartOfAccountDto[];
}

export interface CompanySettingsDto {
  workHours: TimeRange;
  /** @minLength 1 */
  subscriptionChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  invoiceBankAccountId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  invoicePaymentAccountId?: string | null;
}

export interface CompanySubUrlDto {
  /**
   * @minLength 3
   * @maxLength 150
   * @pattern ^[a-z0-9][a-z0-9-_]+$
   */
  url: string;
}

export type ConnectBankAccountDto = object;

export interface ConnectBankAccountResultDto {
  link?: string | null;
}

export interface ContactDto {
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @format email
   * @minLength 1
   * @maxLength 250
   */
  email?: string | null;
  address?: AddressDto | null;
}

export interface ContactPhoneEmailDto {
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @format email
   * @minLength 1
   * @maxLength 250
   */
  email?: string | null;
}

export interface ContributionSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  contributionChartOfAccountId: string;
}

export interface ContributionSettingsDtoSettingsWithOverrideDto {
  settings?: ContributionSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface ContributionSettingsDtoSettingsWithOverridesDto {
  settings?: ContributionSettingsDto | null;
  overrides: ContributionSettingsDtoSettingsWithOverrideDto[];
}

export interface CreateAssociationViolationRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  status?: AssociationViolationStatus | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  reference?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  hoaVendorId: string;
  /** @format date */
  violationReceivedDate: string;
  /** @format date */
  violationDate: string;
  /** @format date */
  fixByDate?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  associationFine?: number | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  title?: string | null;
  description: string;
}

export interface CreateBankAccountDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  description?: string | null;
  /**
   * A valid 4 to 17 digit account number.
   * @minLength 1
   * @pattern ^[0-9]{4,17}$
   */
  accountNumber: string;
  /**
   * A valid 9-digit bank routing number.
   * @minLength 1
   * @pattern ^[0-9]{9}$
   */
  routingNumber: string;
  type: BankAccountType;
}

export interface CreateChartOfAccountDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  parentId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  offsetChartOfAccountId?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  number?: number | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  type: ChartOfAccountType;
}

export interface CreateCompanyPaymentAccountDto {
  /**
   * Required for ACH accounts
   * @minLength 3
   * @maxLength 100
   */
  bankName?: string | null;
  /**
   * A valid 9-digit bank routing number.
   * @pattern ^[0-9]{9}$
   */
  routingNumber?: string | null;
  /**
   * A valid 4 to 17 digit account number.
   * @pattern ^[0-9]{4,17}$
   */
  accountNumber?: string | null;
  /** Required for ACH accounts */
  accountType?: BankAccountType | null;
  /** Required for Stripe accounts */
  stripeSessionId?: string | null;
}

export interface CreateDepositSlipsRequestDto {
  transactionIds: string[];
}

export interface CreateLeaseChargeDto {
  /** @format date */
  dueDate: string;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  companyCharge: boolean;
}

export interface CreateLeaseCreditDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /** @minLength 1 */
  chartOfAccountId: string;
  description?: string | null;
  /** @format date */
  issueDate: string;
}

export interface CreateLeaseDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  monthToMonth: boolean;
  tenantIds: string[];
  rent: LeaseChargeDto;
  otherCharges?: LeaseChargeDto[] | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  proratedAmount?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  deposit?: number | null;
  draft: boolean;
  fromType?: LeaseFromType | null;
  /**
   * @format int64
   * @minLength 19
   * @maxLength 19
   */
  fromEntityId?: number | null;
}

export interface CreateLeaseTemplateCategoryDto {
  /**
   * @minLength 0
   * @maxLength 150
   */
  name: string;
}

export interface CreateMaintenanceRequestCategoryDto {
  /**
   * @minLength 0
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 2000
   */
  description?: string | null;
}

export interface CreateMaintenanceRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  requesterId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  files?: UpdateMaintenanceRequestFileDto[] | null;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  internalDescription?: string | null;
  status: MaintenanceRequestStatus;
  urgency: Urgency;
  permissionToEnter: boolean;
  disableAi: boolean;
  /** @format date */
  due?: string | null;
}

export interface CreateOrUpdateNoteDto {
  /** @minLength 1 */
  content: string;
}

export interface CreateOrUpdatePortfolioDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  icons?: string[] | null;
}

export interface CreateOrUpdateRentalApplicationDto {
  /**
   * @minLength 1
   * @maxLength 20
   */
  password?: string | null;
  /**
   * @minLength 2
   * @maxLength 150
   */
  firstName?: string | null;
  /**
   * @minLength 2
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId?: string | null;
  /**
   * Date of birth of the renter. Must be between 18 and 125 years old.
   * @format date
   */
  dateOfBirth?: string | null;
  applyingWith?: string[] | null;
  /** @format date */
  desiredMoveInDate?: string | null;
  maritalStatus?: MaritalStatus | null;
  driversLicense?: DriversLicenseDto | null;
  incomeInformation?: IncomeInformationDto | null;
  pets?: PetDto[] | null;
  residentialHistory?: ResidentialHistoryDto[] | null;
  emergencyContact?: EmergencyContactDto | null;
  employment?: RentalApplicationEmploymentDto[] | null;
  comments?: RentalApplicationCommentDto[] | null;
  questions?: Record<string, string>;
  interestedUnitIds?: string[] | null;
  files?: UpdateRentalApplicationFileDto[] | null;
  lastStep?: RentalApplicationStep | null;
}

export interface CreateOrUpdateVendorDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  contact?: ContactPhoneEmailDto | null;
  address?: AddressDto | null;
  /** @minLength 1 */
  memo?: string | null;
  /** @minLength 1 */
  website?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  categoryId?: string | null;
  irs1099Form?: FederalTaxInformationDto | null;
  insurancePolicy?: InsurancePolicyDto | null;
  icons?: string[] | null;
  type: VendorType;
  defaultMemo?: string | null;
  paymentSettings?: PaymentSettingsDto | null;
}

export interface CreateOwnerDistributionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  distributionAmount: number;
  /**
   * @minLength 0
   * @maxLength 250
   */
  memo?: string | null;
}

export interface CreateOwnerDistributionsDto {
  groups: CreateOwnerDistributionsGroupDto[];
}

export interface CreateOwnerDistributionsGroupDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  ownerId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  paymentType: PayBillType;
  /** Required if payment type is `Manual` */
  paymentMethod?: TransactionPaymentMethod | null;
  /**
   * Required if payment type is `Manual`
   * @minLength 0
   * @maxLength 100
   */
  externalTransactionId?: string | null;
  /**
   * Required if payment type is `ACH`
   * @minLength 19
   * @maxLength 19
   */
  paymentAccountId?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  memo?: string | null;
  distributions: CreateOwnerDistributionDto[];
}

export interface CreateOwnerDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  isCompany: boolean;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  address?: AddressDto | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  password?: string | null;
}

export interface CreateOwnerPaymentAccountDto {
  /**
   * Required for ACH accounts
   * @minLength 3
   * @maxLength 100
   */
  bankName?: string | null;
  /**
   * A valid 9-digit bank routing number.
   * @pattern ^[0-9]{9}$
   */
  routingNumber?: string | null;
  /**
   * A valid 4 to 17 digit account number.
   * @pattern ^[0-9]{4,17}$
   */
  accountNumber?: string | null;
  /** Required for ACH accounts */
  accountType?: BankAccountType | null;
  /** Required for Stripe accounts */
  stripeSessionId?: string | null;
}

export interface CreateOwnerTaxDocumentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  accountId: string;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  rentAmount: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  otherAmount: number;
}

export interface CreatePhoneChatDto {
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phoneNumber: string;
  /** @minLength 1 */
  participantName?: string | null;
  /** @minLength 1 */
  subject?: string | null;
}

export interface CreatePropertyDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  address: RequiredAddressDto;
  type: PropertyType;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  imageId?: string | null;
  magicTags?: Record<string, string>;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  totalFloors?: number | null;
}

export interface CreatePropertyManagerDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /** @minLength 1 */
  email: string;
  isBusinessAgent: boolean;
  /**
   * @minLength 1
   * @maxLength 150
   */
  clearTextPassword?: string | null;
}

export interface CreateRecurringLeaseChargeDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  chargeFrequency: ChargeFrequency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  /** @format date */
  startFrom: string;
  /** @format date */
  end?: string | null;
  createPastCharges: boolean;
  companyCharge: boolean;
}

export interface CreateRunBookDto {
  /** @maxLength 150 */
  title?: string | null;
  /** @maxLength 250 */
  description?: string | null;
  /** @minLength 50 */
  script: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultVendorId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultPropertyManagerId?: string | null;
}

export interface CreateSyndicationDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  website: string;
  type: SyndicationConfigType;
  config: Record<string, any>;
}

export interface CreateTaxDocumentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  accountId: string;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
}

export interface CreateTenantPaymentAccountDto {
  /**
   * Required for ACH accounts
   * @minLength 3
   * @maxLength 100
   */
  bankName?: string | null;
  /**
   * A valid 9-digit bank routing number.
   * @pattern ^[0-9]{9}$
   */
  routingNumber?: string | null;
  /**
   * A valid 4 to 17 digit account number.
   * @pattern ^[0-9]{4,17}$
   */
  accountNumber?: string | null;
  /** Required for ACH accounts */
  accountType?: BankAccountType | null;
  /** Required for Stripe accounts */
  stripeSessionId?: string | null;
}

export interface CreateUnitDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  beds?: number | null;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  baths?: number | null;
  /**
   * Value must be either null or greater than or equal to 0.
   * @format int32
   * @min 0
   */
  unitSizeSqft?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  floor?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  imageId?: string | null;
  createUnitListing?: boolean | null;
  magicTags?: Record<string, string>;
}

export interface CreateVendorBillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId: string;
  reference?: string | null;
  memo?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate: string;
  /** @minItems 1 */
  lineItems: BillLineItemDto[];
}

export interface CreateVendorPaymentAccountDto {
  /**
   * Required for ACH accounts
   * @minLength 3
   * @maxLength 100
   */
  bankName?: string | null;
  /**
   * A valid 9-digit bank routing number.
   * @pattern ^[0-9]{9}$
   */
  routingNumber?: string | null;
  /**
   * A valid 4 to 17 digit account number.
   * @pattern ^[0-9]{4,17}$
   */
  accountNumber?: string | null;
  /** Required for ACH accounts */
  accountType?: BankAccountType | null;
  /** Required for Stripe accounts */
  stripeSessionId?: string | null;
}

export interface CreateWorkOrderDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  urgency: Urgency;
  status?: Status | null;
  /** @format date */
  dueDate?: string | null;
  permissionToEnter: boolean;
  /**
   * @minLength 0
   * @maxLength 150
   */
  title?: string | null;
  description: string;
  internalDescription?: string | null;
  assignedPropertyManagerId?: string | null;
}

export interface CreateWorkOrderFromAssociationViolationDto {
  vendorId?: string | null;
  permissionToEnter: boolean;
}

export interface CreateWorkOrderFromMaintenanceRequestRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /** @format date */
  dueDate?: string | null;
  urgency?: Urgency | null;
  status?: Status | null;
}

export enum CreationSource {
  None = 'none',
  Direct = 'direct',
  Import = 'import',
  AutoPay = 'autoPay',
  Recurring = 'recurring',
}

export interface CreatorDto {
  type: CreatorType;
  source: CreationSource;
  vendorId?: string | null;
  tenantId?: string | null;
  propertyManagerId?: string | null;
  ownerId?: string | null;
}

export interface CreatorInfoDto {
  vendor?: VendorDto | null;
  tenant?: TenantDto | null;
  propertyManager?: PropertyManagerDto | null;
  owner?: OwnerDto | null;
}

export enum CreatorType {
  System = 'system',
  Tenant = 'tenant',
  PropertyManager = 'propertyManager',
  Owner = 'owner',
  Vendor = 'vendor',
}

export enum CreditDebitDirection {
  Debit = 'debit',
  Credit = 'credit',
}

export interface DataPathDto {
  key: string;
  name: string;
  type: DataPathValueType;
  children?: DataPathDto[] | null;
  text?: string | null;
}

export enum DataPathValueType {
  None = 'none',
  String = 'string',
  DateOnly = 'dateOnly',
  Currency = 'currency',
}

export interface DataPathsDto {
  dataPaths: DataPathDto[];
}

export interface DateDto {
  /** @format date */
  date: string;
}

export interface DateOnlyRangeDto {
  /** @format date */
  start?: string | null;
  /** @format date */
  end?: string | null;
}

export interface DateRangeDto {
  /** @format date */
  start?: string | null;
  /** @format date */
  end?: string | null;
}

export enum DayOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export interface DeleteAssociationViolationRequestDto {
  deleteBills: boolean;
}

export interface DeleteBillDto {
  deleteMethod?: DeleteBillPaymentMethod | null;
}

export interface DeleteBillPaymentDto {
  deleteMethod?: DeleteBillPaymentMethod | null;
}

export enum DeleteBillPaymentMethod {
  AdjustTransaction = 'adjustTransaction',
  LeaveTransactionAsIs = 'leaveTransactionAsIs',
}

export interface DeleteChartOfAccountDto {
  replacementId: string;
}

export interface DeleteCheckRequestDto {
  deleteTransactions: boolean;
}

export interface DelinquencyReportDto {
  groups: DelinquencyReportGroupDto[];
  metadata: DelinquencyReportMetadataDto;
}

export interface DelinquencyReportGroupDto {
  portfolio: PortfolioDto;
  property: PropertyDto;
  items: DelinquencyReportItemDto[];
}

export interface DelinquencyReportItemDto {
  lease: LeaseDto;
  unit: UnitDto;
  tenants: LeaseWithTenantDto[];
}

export interface DelinquencyReportMetadataDto {
  /** @format double */
  totalDeposit: number;
}

export interface DepositPaymentSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId?: string | null;
  allowOnlinePayments: boolean;
  allowAchPayments: boolean;
  allowCardPayments: boolean;
  allowPartialPayments: boolean;
  minimumPaymentType: MinimumPaymentType;
  /**
   * @format double
   * @min 0.01
   * @max 1
   */
  minimumPaymentPercent?: number | null;
  /**
   * @format double
   * @min 1
   * @max 1000
   */
  minimumPaymentAmount?: number | null;
}

export interface DepositPaymentSettingsDtoSettingsWithOverrideDto {
  settings?: DepositPaymentSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface DepositPaymentSettingsDtoSettingsWithOverridesDto {
  settings?: DepositPaymentSettingsDto | null;
  overrides: DepositPaymentSettingsDtoSettingsWithOverrideDto[];
}

export interface DepositSlipDto {
  id: string;
  bankAccountId: string;
  reference: string;
  /** @format date-time */
  date: string;
  transaction: TransactionDto[];
  bankAccounts: BankAccountDto[];
  tenants: TenantDto[];
  vendors: VendorDto[];
  owners: OwnerDto[];
  leases: LeaseDto[];
  units: UnitDto[];
  properties: PropertyDto[];
}

export interface DepositSlipListDto {
  id: string;
  bankAccountId: string;
  reference: string;
  /** @format date-time */
  date: string;
  transactionIds: string[];
}

export interface DepositSlipPaginatedDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: DepositSlipListDto[];
  bankAccounts: BankAccountDto[];
}

export interface DepositSlipsDto {
  depositSlips: DepositSlipListDto[];
  transaction: TransactionDto[];
  bankAccounts: BankAccountDto[];
  tenants: TenantDto[];
  vendors: VendorDto[];
  owners: OwnerDto[];
  leases: LeaseDto[];
  units: UnitDto[];
  properties: PropertyDto[];
}

export enum DestinationType {
  Email = 'email',
  Text = 'text',
}

export enum DiligenceStatus {
  NotSubmitted = 'notSubmitted',
  Submitted = 'submitted',
  UnderReview = 'underReview',
  Approved = 'approved',
  Denied = 'denied',
  Undefined = 'undefined',
  MoreInformationRequired = 'moreInformationRequired',
}

export interface DistributionOwnerDto {
  owner: OwnerDto;
  /** @format double */
  ownershipPercentage: number;
  /** @format double */
  distributionAmount: number;
  paymentAccounts: DistributionOwnerPaymentAccountDto[];
}

export interface DistributionOwnerPaymentAccountDto {
  id: string;
  name: string;
  accountMask?: string | null;
}

export interface DistributionValidatePropertiesDto {
  propertyIds?: string[] | null;
}

export interface DistributionValidationDto {
  needsAttentionProperties: InvalidPropertyDto[];
  validProperties: ValidPropertyDto[];
  owners: OwnerDto[];
}

export interface DriverLicenseDto {
  /** @minLength 1 */
  number: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  state: string;
}

export interface DriversLicenseDto {
  /**
   * @minLength 0
   * @maxLength 100
   */
  number: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  state: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  country: string;
}

export interface EmailDto {
  id: string;
  targets: EmailTargetDto[];
  /** @format date-time */
  sent: string;
  subject: string;
  body: string;
  template?: EmailTemplates | null;
  metadata?: Record<string, string>;
  files: FileDto[];
  propertyManager?: PropertyManagerDto | null;
}

export interface EmailListDto {
  id: string;
  subject: string;
  /** @format date-time */
  sent: string;
  body: string;
  template?: EmailTemplates | null;
  metadata?: Record<string, string>;
  targets: EmailTarget[];
  propertyManagerId?: string | null;
}

export enum EmailStatus {
  NotSent = 'notSent',
  Disabled = 'disabled',
  NoRecipients = 'noRecipients',
  Sent = 'sent',
  Delivered = 'delivered',
  Bounce = 'bounce',
  Complaint = 'complaint',
  DeliveryDelay = 'deliveryDelay',
  Reject = 'reject',
  Failed = 'failed',
}

export interface EmailTarget {
  email: string;
  /** @format int64 */
  ownerId?: number | null;
  /** @format int64 */
  tenantId?: number | null;
  /** @format int64 */
  leaseId?: number | null;
  /** @format int64 */
  vendorId?: number | null;
}

export interface EmailTargetDto {
  email: string;
  owner?: OwnerDto | null;
  tenants?: TenantDto[] | null;
  lease?: LeaseDto | null;
  vendor?: VendorDto | null;
}

export enum EmailTemplates {
  Empty = 'empty',
  CompanyGeneric = 'companyGeneric',
  MagicDoor = 'magicDoor',
}

export interface EmailsDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: EmailListDto[];
  owners: OwnerDto[];
  tenants: TenantDto[];
  leases: LeaseDto[];
  vendors: VendorDto[];
  propertyManagers: PropertyManagerDto[];
}

export interface EmbedFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  default?: boolean | null;
  /** @maxLength 200 */
  description?: string | null;
  signedUrl?: string | null;
  signedThumbUrl?: string | null;
}

export interface EmergencyContactDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /** @format email */
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  relationship?: string | null;
}

export interface EmploymentDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  position?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  salary?: number | null;
  /** @format date */
  startDate?: string | null;
  /** @format date */
  endDate?: string | null;
}

export enum EntityType {
  Lease = 'lease',
  Unit = 'unit',
  Property = 'property',
  Portfolio = 'portfolio',
  Company = 'company',
  Tenant = 'tenant',
  Vendor = 'vendor',
  MaintenanceRequest = 'maintenanceRequest',
  WorkOrder = 'workOrder',
  Bill = 'bill',
  Transaction = 'transaction',
  Chat = 'chat',
  ChatMessage = 'chatMessage',
  PropertyManager = 'propertyManager',
  Unknown = 'unknown',
  RentalApplication = 'rentalApplication',
  LeaseTemplate = 'leaseTemplate',
  LeaseDocument = 'leaseDocument',
  AssociationViolation = 'associationViolation',
  Owner = 'owner',
}

export interface ExamQuestionDto {
  questionKeyName?: string | null;
  questionDisplayName?: string | null;
  type?: string | null;
  choices?: QuestionChoiceDto[] | null;
}

export interface ExpiringLeaseCounts {
  /** @format int32 */
  lessThanOneMonth: number;
  /** @format int32 */
  oneToTwoMonths: number;
  /** @format int32 */
  twoToThreeMonths: number;
  /** @format int32 */
  moreThanThreeMonths: number;
  /** @format int32 */
  total: number;
}

export enum ExpiringStatus {
  None = 'none',
  Soon = 'soon',
  Imminent = 'imminent',
  Immediate = 'immediate',
}

export enum ExternalTransactionType {
  Plaid = 'plaid',
  Stripe = 'stripe',
  Offline = 'offline',
}

export interface FailedOwnerDistributionAchResultDto {
  owner: OwnerDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  properties: OwnerDistributionPropertyResultDto[];
  failureReason: StartOutgoingAchFailure;
}

export interface FailedVendorBillResultDto {
  vendor: VendorDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  payments: VendorBillPaymentResultDto[];
  failureReason: StartOutgoingAchFailure;
}

export interface FederalTaxInformationDto {
  taxIdType: IdentificationType;
  /**
   * @minLength 1
   * @maxLength 200
   */
  taxId: string;
  overwriteAddress?: AddressDto | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  overwriteName?: string | null;
}

export interface FileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
  /** @format int64 */
  fileSize: number;
  /** @format date-time */
  uploaded: string;
}

export enum FileType {
  Identification = 'identification',
  BankStatement = 'bankStatement',
  EmploymentVerification = 'employmentVerification',
  Additional = 'additional',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  PreferNotToSay = 'preferNotToSay',
}

export interface GeneralLedgerReportDataDto {
  properties: PropertyDto[];
  vendors: VendorDto[];
  tenants: TenantDto[];
  chartOfAccounts: ChartOfAccountReportDto[];
}

export interface GetAnnouncementTargetsRequestDto {
  targetType: TargetType;
  hasBalanceDue?: boolean | null;
  includeEndedLease?: boolean | null;
  portfolioIds?: string[] | null;
  propertyIds?: string[] | null;
  unitIds?: string[] | null;
}

export interface GetPlaidQuestionnaireUrlDto {
  /** @minLength 1 */
  url: string;
}

export interface GivePropertyCreditDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  /** @format date */
  creditDate?: string | null;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyChartOfAccountsId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyChartOfAccountsId?: string | null;
  /**
   * @minLength 0
   * @maxLength 500
   */
  memo?: string | null;
}

export interface HydratedAnnouncementDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyId: string;
  /** @format date-time */
  sentAt: string;
  chatMessage?: string | null;
  emailMessage?: string | null;
  /** @maxLength 250 */
  subject?: string | null;
  targets: HydratedAnnouncementTargetsDto;
  chatAttachments?: FileDto[] | null;
  emailAttachments?: FileDto[] | null;
}

export interface HydratedAnnouncementPortfolioTargetsDto {
  portfolio: PortfolioDto;
  properties: HydratedAnnouncementPropertyTargetsDto[];
}

export interface HydratedAnnouncementPropertyTargetsDto {
  property: PropertyDto;
  units: HydratedAnnouncementUnitTargetsDto[];
}

export interface HydratedAnnouncementTargetsDto {
  portfolioTargets: HydratedAnnouncementPortfolioTargetsDto[];
  owners: OwnerDto[];
  vendors: VendorDto[];
  tenants: TenantDto[];
  targetTypes: TargetType[];
}

export interface HydratedAnnouncementUnitTargetsDto {
  unit: UnitDto;
  tenants: TenantDto[];
  leases: LeaseDto[];
}

export interface HydratedBankAccountDto {
  id: string;
  name: string;
  description?: string | null;
  active: boolean;
  accountNumber?: string | null;
  routingNumber?: string | null;
  type?: BankAccountType | null;
  plaid: HydratedBankAccountPlaidDto;
  stripe: HydratedBankAccountStripeDto;
}

export interface HydratedBankAccountPlaidDto {
  canReceiveTransfers: boolean;
  hasOriginator: boolean;
  originatorStatus?: DiligenceStatus | null;
  /** @format double */
  available?: number | null;
  /** @format double */
  pending?: number | null;
  shouldPerformQuestionnaire: boolean;
  limits: HydratedBankAccountPlaidLimitsDto;
}

export interface HydratedBankAccountPlaidLimitsDto {
  /** @format double */
  maxSingleTransferCreditAmount?: number | null;
  /** @format double */
  maxSingleTransferDebitAmount?: number | null;
  /** @format double */
  maxDailyCreditAmount?: number | null;
  /** @format double */
  maxDailyDebitAmount?: number | null;
  /** @format double */
  maxMonthlyCreditAmount?: number | null;
  /** @format double */
  maxMonthlyDebitAmount?: number | null;
}

export interface HydratedBankAccountStripeDto {
  connected: boolean;
  chargesEnabled: boolean;
  detailsSubmitted: boolean;
}

export interface HydratedBillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  companyCharge: boolean;
  identity: BillIdentity;
  direction: CreditDebitDirection;
  /** @minLength 1 */
  reference?: string | null;
  /** @minLength 1 */
  memo?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate: string;
  /** @format double */
  totalAmount: number;
  /** @format double */
  paid: number;
  /** @format double */
  due: number;
  lineItems: HydratedBillLineItemDto[];
  recurringChargeId?: string | null;
  creator: CreatorDto;
  files: BillFileDto[];
  transactions: Record<string, TransactionDto>;
  portfolio?: PortfolioDto | null;
  property?: PropertyDto | null;
  unit?: UnitDto | null;
  vendor?: VendorDto | null;
  lease?: LeaseDto | null;
  creatorInfo: CreatorInfoDto;
}

export interface HydratedBillDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedBillDto[];
}

export interface HydratedBillLineItemDto {
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  paid: number;
  allocations?: BillAllocationDto[] | null;
  chartOfAccount: ChartOfAccountDto;
}

export interface HydratedChatParticipantDto {
  /** @minLength 1 */
  id: string;
  participantType: ChatParticipantType;
  accountId?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  tenant?: TenantDto | null;
  active: boolean;
}

export interface HydratedCheckDto {
  id: string;
  companyId: string;
  /** @format int32 */
  checkNumber: number;
  vendorId?: string | null;
  ownerId?: string | null;
  bankAccountId: string;
  /** @format double */
  amount: number;
  memo?: string | null;
  /** @format date-time */
  createdOn: string;
  /** @format date-time */
  date: string;
  transactionId?: string | null;
  billPayments: CheckBillPaymentDto[];
  dataHeaders: string[];
  voided: boolean;
  voidReason?: string | null;
  amountString?: string;
  bankAccount: BankAccountDto;
  company: CompanyDto;
  vendor?: VendorDto | null;
  owner?: OwnerDto | null;
  bills: BillDto[];
  transactions: TransactionDto[];
}

export interface HydratedFileAttachmentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @minLength 1 */
  fileName: string;
  description?: string | null;
  /** @minLength 1 */
  fileId: string;
  /** @minLength 1 */
  fileUrl: string;
  /** @minLength 1 */
  fileThumbUrl: string;
  /** @format int64 */
  fileSizeBytes: number;
  /** @format date-time */
  created: string;
  uploaderName?: string | null;
  uploaderType?: string | null;
}

export interface HydratedGetLeaseTransactionsDto {
  /** @format double */
  currentBalance: number;
  /** @format double */
  postedBalance: number;
  transactions: HydratedLeaseTransactionDto[];
}

export interface HydratedLeaseDocumentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseTemplateIds: string[];
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  name: string;
  status: LeaseDocumentStatus;
  signers: LeaseDocumentSignerDto[];
  annotations: LeaseDocumentAnnotationDto[];
  file: FileDto;
}

export interface HydratedLeaseDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  ended: boolean;
  monthToMonth: boolean;
  eviction: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  tenants: HydratedLeaseTenantDto[];
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  balance: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  currentRent: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositPaid: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositRequested: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositDue: number;
  /** @format date */
  lastPaymentDate?: string | null;
  /** @format date */
  earliestUnpaidBillDueDate?: string | null;
  icons: string[];
  draft: boolean;
  status: LeaseStatus;
  expiringStatus: ExpiringStatus;
  /** @format int32 */
  pastDueDays?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  postedBalance: number;
  unit: UnitDto;
  property: PropertyDto;
}

export interface HydratedLeaseDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedLeaseDto[];
}

export interface HydratedLeaseTenantDto {
  tenant: TenantDto;
  /** @format date */
  moveIn: string;
  /** @format date */
  moveOut?: string | null;
  isMovedOut: boolean;
}

export interface HydratedLeaseTransactionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  type: RentTransactionType;
  status: LeaseTransactionStatus;
  /** @format date */
  transactionDate: string;
  /** @minLength 1 */
  description?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  /** @format double */
  balance: number;
  /** @format double */
  postedBalance: number;
  transaction?: HydratedTransactionDto | null;
  bill?: HydratedBillDto | null;
}

export interface HydratedMagicChatDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  type: ChatTypes;
  /** @minLength 1 */
  typeKey: string;
  /** @minLength 1 */
  groupNumber?: string | null;
  participants: HydratedChatParticipantDto[];
  /** @minLength 1 */
  subject: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  participantLanguage:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  closed: boolean;
  /** @format date-time */
  created: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  latestMessageId?: string | null;
  /** @format date-time */
  lastMessageSentAt?: string | null;
  /** @format int32 */
  messageCount: number;
  showTranslation: boolean;
  latestMessage?: ChatMessageDto | null;
  lease?: LeaseDto | null;
  maintenanceRequest?: MaintenanceRequest | null;
  workOrder?: WorkOrder | null;
  /** @format int32 */
  unreadMessages: number;
}

export interface HydratedNewOwnerDto {
  id: string;
  active: boolean;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  isCompany: boolean;
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  chatId?: string | null;
  language: string;
  icons: string[];
  address?: AddressDto | null;
  portalStatus: PortalStatus;
  ownerProperties: OwnerPropertyDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  paymentAccounts: PaymentAccountDto[];
  latestContribution?: OwnerTransferListDto | null;
  bankAccounts: BankAccountDto[];
  bills: BillDto[];
  transactions: TransactionDto[];
  password: string;
}

export interface HydratedOwnerDto {
  id: string;
  active: boolean;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  isCompany: boolean;
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  chatId?: string | null;
  language: string;
  icons: string[];
  address?: AddressDto | null;
  portalStatus: PortalStatus;
  ownerProperties: OwnerPropertyDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  paymentAccounts: PaymentAccountDto[];
  latestContribution?: OwnerTransferListDto | null;
  bankAccounts: BankAccountDto[];
  bills: BillDto[];
  transactions: TransactionDto[];
}

export interface HydratedOwnerListDto {
  id: string;
  active: boolean;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  isCompany: boolean;
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  chatId?: string | null;
  language: string;
  icons: string[];
  address?: AddressDto | null;
  portalStatus: PortalStatus;
  ownerProperties: OwnerPropertyDto[];
}

export interface HydratedOwnerListPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedOwnerListDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
}

export interface HydratedOwnerPropertyDto {
  owner: OwnerDto;
  property: PropertyDto;
  portfolio: PortfolioDto;
  /** @format double */
  ownershipPercentage: number;
}

export interface HydratedPlaidTransferDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @minLength 1 */
  plaidTransferId: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastUpdated: string;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  facilitatorFee: number;
  /** @format date */
  expectedSettlementDate?: string | null;
  transferStatus: PlaidTransferStatus;
  /** @minLength 1 */
  failureReason?: string | null;
  /** @minLength 1 */
  returnCode?: string | null;
  transaction?: HydratedTransactionDto | null;
  fromTenant?: TenantDto | null;
  bankAccount?: BankAccountDto | null;
}

export interface HydratedPlaidTransferDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedPlaidTransferDto[];
}

export interface HydratedPortfolioDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /** @format int32 */
  propertyCount: number;
  icons: string[];
  /** @format int32 */
  activePropertyCount: number;
  properties: PropertyDto[];
  /** @format int32 */
  recentRentalApplications: number;
  latestRentalApplication?: RentalApplicationListDto | null;
}

export interface HydratedPropertyDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  displayName: string;
  address?: AddressDto | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  type: PropertyType;
  /** @format int32 */
  totalFloors?: number | null;
  /** @format int32 */
  unitCount: number;
  active: boolean;
  imageId?: string | null;
  icons: string[];
  magicTags?: Record<string, string>;
  image?: FileDto | null;
  portfolio: PortfolioDto;
  /** @format int32 */
  recentRentalApplications: number;
  latestRentalApplication?: RentalApplicationListDto | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  totalRent: number;
  /** @format double */
  currentBalance: number;
  /** @format double */
  postedBalance: number;
  /** @format double */
  totalDeposit: number;
  /** @format int32 */
  occupiedUnits: number;
  /** @format int32 */
  activeUnits: number;
  /** @format int32 */
  emptyUnits: number;
  owners: HydratedPropertyOwnerDto[];
}

export interface HydratedPropertyDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedPropertyDto[];
}

export interface HydratedPropertyOwnerDto {
  owner: OwnerDto;
  /** @format double */
  ownershipPercentage: number;
}

export interface HydratedRecurringLeaseChargeDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  chargeFrequency: ChargeFrequency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  companyCharge: boolean;
  /** @format date */
  lastCharge?: string | null;
  /** @format date */
  nextCharge: string;
  /** @format date */
  startFrom: string;
  /** @format date */
  end?: string | null;
  status: LeaseRecurringChargeStatus;
  chartOfAccount: ChartOfAccountDto;
}

export interface HydratedRunBookDto {
  publicRunBook: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 250
   */
  description: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated?: string | null;
  /** @deprecated */
  vendorId?: string | null;
  defaultVendorId?: string | null;
  defaultPropertyManagerId?: string | null;
  enabled: boolean;
  /** @format int32 */
  usageCount: number;
  /** @format date-time */
  lastUsed?: string | null;
  /** @minLength 1 */
  script: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  lastUseMaintenanceRequestId?: string | null;
  vendorOverwrites?: RunBookVendorOverwriteDto[] | null;
  propertyManagerOverwrites?: RunBookPropertyManagerOverwriteDto[] | null;
  actionExitCounts: {
    /** @format int32 */
    askQuestion?: number;
    /** @format int32 */
    closeRequest?: number;
    /** @format int32 */
    createWorkOrder?: number;
    /** @format int32 */
    handOverToHuman?: number;
    /** @format int32 */
    reselectRunBook?: number;
  };
  propertyManagers: PropertyManagerDto[];
  vendors: VendorDto[];
  properties: PropertyDto[];
  portfolios: PortfolioDto[];
  lastUseMaintenanceRequest?: MaintenanceRequest | null;
}

export interface HydratedTenantAutoPayDto {
  id: string;
  type: TenantAutoPayType;
  /** @format double */
  fixedAmount?: number | null;
  /** @format double */
  maximumAmount?: number | null;
  frequency: ChargeFrequency;
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  /** @format date */
  lastPayment?: string | null;
  /** @format date */
  nextPayment?: string | null;
  lease: LeaseDto;
  paymentAccount: PaymentAccountDto;
  tenant: TenantDto;
  portfolio: PortfolioDto;
  property: PropertyDto;
  unit: UnitDto;
}

export interface HydratedTenantDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  portalStatus: PortalStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  driversLicense?: DriverLicenseDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: Gender | null;
  currentAddress?: AddressDto | null;
  emergencyContacts?: EmergencyContactDto[] | null;
  icons: string[];
  primaryLeaseId?: string | null;
  currentLeases?: LeaseDto[] | null;
  properties?: PropertyDto[] | null;
  units?: UnitDto[] | null;
}

export interface HydratedTenantListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  portalStatus: PortalStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  driversLicense?: DriverLicenseDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: Gender | null;
  currentAddress?: AddressDto | null;
  emergencyContacts?: EmergencyContactDto[] | null;
  icons: string[];
  primaryLeaseId?: string | null;
  currentLeases?: LeaseDto[] | null;
}

export interface HydratedTenantListPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedTenantListDto[];
  properties: PropertyDto[];
  units: UnitDto[];
}

export interface HydratedTransactionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @format date */
  transactionDate: string;
  transactionIdentity: TransactionIdentity;
  transactionPaymentMethod: TransactionPaymentMethod;
  status: TransactionStatus;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  memo?: string | null;
  direction: TransactionDirection;
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  transactionFinalizedDate?: string | null;
  externalTransactionType?: ExternalTransactionType | null;
  /** @minLength 1 */
  externalTransactionId?: string | null;
  failureReason?: TransactionFailure | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  refundTransactionId?: string | null;
  reference?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  depositSlipId?: string | null;
  creator: CreatorDto;
  bankAccount: BankAccountDto;
  tenant?: TenantDto | null;
  vendor?: VendorDto | null;
  lease?: LeaseDto | null;
  property?: PropertyDto | null;
  unit?: UnitDto | null;
  creatorInfo: CreatorInfoDto;
}

export interface HydratedTransactionDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedTransactionDto[];
}

export interface HydratedUnitDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  beds?: number | null;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  baths?: number | null;
  /**
   * Value must be either null or greater than or equal to 0.
   * @format int32
   * @min 0
   */
  unitSizeSqft?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  floor?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  active: boolean;
  imageId?: string | null;
  icons: string[];
  magicTags?: Record<string, string>;
  image?: FileDto | null;
  property: PropertyDto;
  activeLeases: LeaseDto[];
  /** @format int32 */
  recentRentalApplications: number;
  latestRentalApplication?: RentalApplicationListDto | null;
  /** @format double */
  postedBalance: number;
}

export interface HydratedUnitDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedUnitDto[];
}

export interface HydratedUnitListingDto {
  id: string;
  portfolioId: string;
  propertyId: string;
  unitId: string;
  status: UnitListingStatus;
  /** @format double */
  rentalPrice?: number | null;
  /** @format double */
  securityDeposit?: number | null;
  /** @format date */
  availableFrom?: string | null;
  leaseTerm?: LeaseTerm | null;
  title?: string | null;
  /** @format date */
  listed?: string | null;
  /** @format date-time */
  updated: string;
  pinned: boolean;
  primaryImage?: EmbedFileDto | null;
  primaryVideo?: EmbedFileDto | null;
  propertyType?: PropertyType | null;
  singleLineAddress?: string | null;
  /** @format int32 */
  recentRentalApplications?: number | null;
  description?: string | null;
  descriptionTranslations?: TranslationDto[] | null;
  images?: EmbedFileDto[] | null;
  videos?: EmbedFileDto[] | null;
  tags?: string[] | null;
  petPolicy?: PetPolicyDto | null;
  amenities?: AmenityDto[] | null;
  locationAnalysis?: string | null;
  terms?: Record<string, string>;
  unit: UnitDto;
  portfolio: PortfolioDto;
  property: PropertyDto;
  /** Reasons why the unit listing cannot be listed, null if it can be listed */
  canNotBeListedReasons?: Record<string, string>;
}

export interface HydratedVendorDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  contact: ContactDto;
  /** @minLength 1 */
  memo?: string | null;
  active: boolean;
  /** @minLength 1 */
  website?: string | null;
  irs1099Form?: FederalTaxInformationDto | null;
  insurancePolicy?: InsurancePolicyDto | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  icons: string[];
  type: VendorType;
  defaultMemo?: string | null;
  paymentSettings: PaymentSettingsDto;
  paymentAccounts: PaymentAccountDto[];
}

export interface HydratedVendorDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: HydratedVendorDto[];
}

export interface HydratedWorkOrderDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  groupChatId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  reference: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  maintenanceRequestId?: string | null;
  status: Status;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated?: string | null;
  /** @format date */
  dueDate?: string | null;
  urgency: Urgency;
  /** @minLength 1 */
  description: string;
  internalDescription?: string | null;
  permissionToEnter: boolean;
  portfolio: PortfolioDto;
  property: PropertyDto;
  unit?: UnitDto | null;
  lease?: LeaseDto | null;
  vendor?: VendorDto | null;
  maintenanceRequest?: MaintenanceRequestListDto | null;
  assignedPropertyManager?: PropertyManagerDto | null;
  bills?: WorkOrderBillDto[] | null;
  files: WorkOrderFileDto[];
  chartOfAccounts: ChartOfAccountDto[];
  owners: PropertyOwnerDto[];
  /** @format double */
  ownerApprovalLimit?: number | null;
  linkWithPassword: string;
}

export enum IdentificationType {
  Ssn = 'ssn',
  Ein = 'ein',
}

export interface ImportUnitListingDto {
  unitListingIds: string[];
}

export interface ImportUnitListingRequestDto {
  portfolioIds?: string[] | null;
  propertyIds?: string[] | null;
  unitIds?: string[] | null;
}

export interface IncomeExpenses {
  /** @format double */
  income: number;
  /** @format double */
  expenses: number;
}

export interface IncomeInformationDto {
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  annualIncome?: number | null;
}

export interface IncomeStatementDto {
  groupHeaders: ReportGroupHeader[];
  income: ChartOfAccountReportLineDto[];
  totalIncome: Record<string, number>;
  expenses: ChartOfAccountReportLineDto[];
  totalExpense: Record<string, number>;
  netOperatingIncome: Record<string, number>;
}

export interface InsurancePolicyDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  policyNumber: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  provider?: string | null;
  /** @format date */
  expirationDate?: string | null;
}

export interface InvalidPropertyDto {
  property: PropertyDto;
  isMissingOwnerInformation: boolean;
  owners: PropertyOwnerIdDto[];
  /** @format double */
  calculatedOwnershipPercentage: number;
}

export interface LastCompanyPayOutRunDto {
  /** @format date-time */
  lastRun?: string | null;
}

export enum LateFeeCalculation {
  Fixed = 'fixed',
  Percent = 'percent',
  PercentOrMinimum = 'percentOrMinimum',
}

export interface LateFeesSettingsDto {
  enabled: boolean;
  /**
   * @format int32
   * @min 0
   * @max 31
   */
  gracePeriod: number;
  calculation: LateFeeCalculation;
  /**
   * @format double
   * @min 0
   * @max 1000
   */
  amount: number;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  feePercent: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  lateFeeChartOfAccountId: string;
  /** @minLength 1 */
  lateFeeDescription: string;
  lateFeeIncludeChartOfAccountIds?: string[] | null;
  lateFeePreventChartOfAccountIds?: string[] | null;
  lateFeeIsCompanyBill: boolean;
}

export interface LateFeesSettingsDtoSettingsWithOverrideDto {
  settings?: LateFeesSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface LateFeesSettingsDtoSettingsWithOverridesDto {
  settings?: LateFeesSettingsDto | null;
  overrides: LateFeesSettingsDtoSettingsWithOverrideDto[];
}

export interface LeaseChargeDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  isCompanyBill: boolean;
  chargeFrequency: ChargeFrequency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  description?: string | null;
  /** @format date */
  startFrom?: string | null;
}

export interface LeaseChartOfAccountSettingsDto {
  rentChartOfAccountIds?: string[] | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultSecurityDepositChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultRentChartOfAccountId: string;
}

export interface LeaseChartOfAccountSettingsDtoSettingsWithOverrideDto {
  settings?: LeaseChartOfAccountSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface LeaseChartOfAccountSettingsDtoSettingsWithOverridesDto {
  settings?: LeaseChartOfAccountSettingsDto | null;
  overrides: LeaseChartOfAccountSettingsDtoSettingsWithOverrideDto[];
}

export interface LeaseDocument {
  /** @format int64 */
  companyId: number;
  /** @format int64 */
  portfolioId: number;
  /** @format int64 */
  propertyId: number;
  /** @format int64 */
  unitId: number;
  /** @format int64 */
  leaseId: number;
  leaseTemplateIds: number[];
  /** @format int64 */
  fileId: number;
  fileName: string;
  name: string;
  status: LeaseDocumentStatus;
  signers: LeaseDocumentSigner[];
  annotations: LeaseDocumentAnnotation[];
  /** @format date-time */
  completionDate?: string | null;
  /** @format int64 */
  id: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
}

export interface LeaseDocumentAnnotation {
  id: string;
  signerId?: string | null;
  /** @format int32 */
  page: number;
  /** @format double */
  x: number;
  /** @format double */
  y: number;
  /** @format double */
  width: number;
  /** @format double */
  height: number;
  /** @format double */
  fontSize: number;
  value: LeaseDocumentAnnotationValue;
  type: LeaseDocumentAnnotationType;
  dataPath?: string | null;
}

export interface LeaseDocumentAnnotationDto {
  /** @minLength 1 */
  id: string;
  signerId?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 10000
   */
  page: number;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  x: number;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  y: number;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  width: number;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  height: number;
  /**
   * @format double
   * @min 1
   * @max 1000
   */
  fontSize: number;
  /**
   * @minLength 0
   * @maxLength 1000
   */
  text?: string | null;
  value?: LeaseDocumentAnnotationValueDto | null;
  type: LeaseDocumentAnnotationType;
  dataPath?: string | null;
}

export enum LeaseDocumentAnnotationType {
  Text = 'text',
  Initials = 'initials',
  Signature = 'signature',
  DateOnly = 'dateOnly',
  DateTime = 'dateTime',
  Bool = 'bool',
  Flag = 'flag',
  Currency = 'currency',
  Autofill = 'autofill',
  FullName = 'fullName',
}

export interface LeaseDocumentAnnotationValue {
  boolValue?: boolean | null;
  text?: string | null;
  flag?: string | null;
  /** @format date-time */
  dateOnly?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  /** @format double */
  currencyValue?: number | null;
  fullName?: string | null;
  type: LeaseDocumentAnnotationType;
  hasValue: boolean;
}

export interface LeaseDocumentAnnotationValueDto {
  boolValue?: boolean | null;
  /** @format date */
  dateOnly?: string | null;
  /** @format date-time */
  dateTime?: string | null;
  /** @format double */
  decimalValue?: number | null;
  /**
   * @minLength 0
   * @maxLength 1000
   */
  text?: string | null;
  /**
   * @minLength 0
   * @maxLength 20
   */
  flag?: string | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  fullName?: string | null;
}

export interface LeaseDocumentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseTemplateIds: string[];
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  name: string;
  status: LeaseDocumentStatus;
  signers: LeaseDocumentSignerDto[];
  annotations: LeaseDocumentAnnotationDto[];
}

export interface LeaseDocumentSignature {
  destination: string;
  type: DestinationType;
  password: string;
  /** @format date-time */
  sentDate?: string | null;
  /** @format date-time */
  viewedDate?: string | null;
  /** @format date-time */
  signedDate?: string | null;
  signedIp?: string | null;
  signedLocation?: string | null;
  status: StatusType;
}

export interface LeaseDocumentSignatureCompleted {
  name: string;
  signatureName: string;
  signatureInitials: string;
  destination: string;
  type: DestinationType;
  password: string;
  /** @format date-time */
  sentDate: string;
  /** @format date-time */
  viewedDate: string;
  /** @format date-time */
  signedDate: string;
  signedIp: string;
  signedLocation: string;
}

export interface LeaseDocumentSignatureDto {
  /** @minLength 1 */
  destination: string;
  type: DestinationType;
  /** @format date-time */
  sentDate?: string | null;
  /** @format date-time */
  viewedDate?: string | null;
  /** @format date-time */
  signedDate?: string | null;
  signedIp?: string | null;
}

export interface LeaseDocumentSigner {
  /** @format int64 */
  leaseDocumentId: number;
  signerId: string;
  /** @format int64 */
  entityId: number | null;
  name: string;
  signatureName: string;
  signatureInitials: string;
  type: LeaseDocumentSignerType;
  completedSignature?: LeaseDocumentSignatureCompleted | null;
  emailSignature?: LeaseDocumentSignature | null;
  textSignature?: LeaseDocumentSignature | null;
  signatureStatus: StatusType;
  /** @format int64 */
  id: number;
}

export interface LeaseDocumentSignerDto {
  /** @minLength 1 */
  id: string;
  /**
   * @minLength 2
   * @maxLength 100
   */
  name: string;
  type: LeaseDocumentSignerType;
  signatureStatus: StatusType;
  /** @minLength 1 */
  signatureName: string;
  /** @minLength 1 */
  signatureInitials: string;
  emailSignature?: LeaseDocumentSignatureDto | null;
  textSignature?: LeaseDocumentSignatureDto | null;
}

export enum LeaseDocumentSignerType {
  PropertyManager = 'propertyManager',
  Tenant = 'tenant',
  External = 'external',
}

export enum LeaseDocumentStatus {
  Draft = 'draft',
  PendingSignatures = 'pendingSignatures',
  Completed = 'completed',
}

export interface LeaseDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  ended: boolean;
  monthToMonth: boolean;
  eviction: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  tenants: LeaseTenantDto[];
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  balance: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  currentRent: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositPaid: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositRequested: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDepositDue: number;
  /** @format date */
  lastPaymentDate?: string | null;
  /** @format date */
  earliestUnpaidBillDueDate?: string | null;
  icons: string[];
  draft: boolean;
  status: LeaseStatus;
  expiringStatus: ExpiringStatus;
  /** @format int32 */
  pastDueDays?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  postedBalance: number;
}

export enum LeaseFromType {
  RentalApplication = 'rentalApplication',
}

export enum LeaseRecurringChargeStatus {
  Active = 'active',
  Ended = 'ended',
  DueSoon = 'dueSoon',
}

export enum LeaseStatus {
  Lease = 'lease',
  LeaseMonthToMonth = 'leaseMonthToMonth',
  MonthToMonth = 'monthToMonth',
  Eviction = 'eviction',
  Expired = 'expired',
  Draft = 'draft',
  Ended = 'ended',
  Future = 'future',
}

export interface LeaseTemplateCategoryDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  isDefault: boolean;
}

export interface LeaseTemplateDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseTemplateCategoryId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /**
   * @minLength 1
   * @maxLength 10
   */
  fileExtension: string;
  /** @minLength 1 */
  signedUrl: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description?: string | null;
  signers: LeaseTemplateSignerDto[];
  annotations: LeaseDocumentAnnotationDto[];
  /** @minLength 1 */
  contentType: string;
  /** @format int64 */
  fileSize: number;
}

export interface LeaseTemplateSignerDto {
  /** @minLength 1 */
  id: string;
  /**
   * @minLength 2
   * @maxLength 100
   */
  name: string;
  type: LeaseDocumentSignerType;
}

export interface LeaseTenantDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId: string;
  /** @format date */
  moveIn: string;
  /** @format date */
  moveOut?: string | null;
}

export enum LeaseTerm {
  MonthToMonth = 'monthToMonth',
  HalfYear = 'halfYear',
  OneYear = 'oneYear',
  TwoYears = 'twoYears',
  ThreeYears = 'threeYears',
  Other = 'other',
}

export enum LeaseTransactionStatus {
  Future = 'future',
  Due = 'due',
  PastDue = 'pastDue',
  Paid = 'paid',
  Failed = 'failed',
  Returned = 'returned',
  Processing = 'processing',
  Settled = 'settled',
}

export interface LeaseUnpaidLedgerReportDto {
  lease: LeaseDto;
  property: PropertyDto;
  unit: UnitDto;
  tenants: TenantDto[];
  unpaidBills: BillDto[];
  chartOfAccounts: ChartOfAccountDto[];
}

export interface LeaseWithTenantDto {
  lease: LeaseTenantDto;
  tenant: TenantDto;
}

export enum LegalEntityType {
  SoleProprietorship = 'soleProprietorship',
  Llc = 'llc',
  PrivateCompany = 'privateCompany',
  PublicCompanyUnlisted = 'publicCompanyUnlisted',
  PublicCompanyListed = 'publicCompanyListed',
  CharitableOrganizationNotForProfit = 'charitableOrganizationNotForProfit',
  Partnership = 'partnership',
}

export enum LevelSearch {
  Portfolio = 'portfolio',
  Property = 'property',
  Unit = 'unit',
  Lease = 'lease',
}

export interface LineItemDto {
  bill: BillDto;
  transaction: TransactionDto;
  /** @format double */
  amount: number;
  /** @format double */
  balance: number;
}

export interface LineItemReportDto {
  propertyId?: string | null;
  tenantId?: string | null;
  vendorId?: string | null;
  /** @format date-time */
  date: string;
  transactionId: string;
  paymentMethod: TransactionPaymentMethod;
  /** @format double */
  debit?: number | null;
  /** @format double */
  credit?: number | null;
  /** @format double */
  balance: number;
  memo?: string | null;
  payeeOrPayerName: string;
  billId: string;
}

export interface ListRunBookDto {
  publicRunBook: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 250
   */
  description: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated?: string | null;
  /** @deprecated */
  vendorId?: string | null;
  defaultVendorId?: string | null;
  defaultPropertyManagerId?: string | null;
  enabled: boolean;
  /** @format int32 */
  usageCount: number;
  /** @format date-time */
  lastUsed?: string | null;
}

export interface MagicChatTextRequestDto {
  /** @minLength 1 */
  myText: string;
}

export interface MagicChatTextResponseDto {
  /** @minLength 1 */
  suggestedText: string;
  failed: boolean;
}

export interface MaintenanceRequest {
  /** @format int64 */
  companyId: number;
  /** @format int64 */
  portfolioId: number;
  /** @format int64 */
  propertyId: number;
  /** @format int64 */
  unitId: number;
  /** @format int64 */
  leaseId: number;
  /** @format int64 */
  tenantId: number;
  /** @format int64 */
  assignedPropertyManagerId?: number | null;
  title: string;
  description: string;
  internalDescription?: string | null;
  status: MaintenanceRequestStatus;
  urgency: Urgency;
  /** @format int64 */
  categoryId?: number | null;
  files: MaintenanceRequestFile[];
  /** @format int32 */
  aiUrgencyScore: number;
  permissionToEnter: boolean;
  /** @format date-time */
  due?: string | null;
  /** @format int64 */
  chatId?: number | null;
  /** @format int64 */
  runBookId?: number | null;
  pastRunBookIds?: number[] | null;
  aiProcessed: boolean;
  disableAi: boolean;
  /** @format int64 */
  id: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
}

export interface MaintenanceRequestCategoryDto {
  id: string;
  name: string;
  description?: string | null;
}

export interface MaintenanceRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  status: MaintenanceRequestStatus;
  urgency: Urgency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  categoryId?: string | null;
  disableAi: boolean;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  aiUrgencyScore: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastUpdated: string;
  aiProcessed: boolean;
  newRequest: boolean;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  internalDescription?: string | null;
  files: MaintenanceRequestFileDto[];
  permissionToEnter: boolean;
  /** @format date */
  due?: string | null;
  runBookId?: string | null;
  tenant: TenantDto;
  propertyManager?: PropertyManagerDto | null;
  property: PropertyDto;
  unit: UnitDto;
  lease: LeaseDto;
  category?: MaintenanceRequestCategoryDto | null;
  workOrder?: WorkOrderListDto | null;
  owners: PropertyOwnerDto[];
  /** @format double */
  ownerApprovalLimit?: number | null;
  runBook?: ListRunBookDto | null;
}

export interface MaintenanceRequestFile {
  /** @format int64 */
  fileId: number;
  description?: string | null;
}

export interface MaintenanceRequestFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
  /** @format int64 */
  fileSize: number;
  /** @format date-time */
  uploaded: string;
  description?: string | null;
}

export interface MaintenanceRequestListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  status: MaintenanceRequestStatus;
  urgency: Urgency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  categoryId?: string | null;
  disableAi: boolean;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  aiUrgencyScore: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastUpdated: string;
  aiProcessed: boolean;
  newRequest: boolean;
}

export interface MaintenanceRequestPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: MaintenanceRequestListDto[];
  tenants: TenantDto[];
  propertyManagers: PropertyManagerDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  leases: LeaseDto[];
  categories: MaintenanceRequestCategoryDto[];
  runBooks: ListRunBookDto[];
}

export interface MaintenanceRequestRunBookSettingsDto {
  enableRunBook: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultPropertyManagerId?: string | null;
}

export interface MaintenanceRequestRunBookSettingsDtoSettingsWithOverrideDto {
  settings?: MaintenanceRequestRunBookSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface MaintenanceRequestRunBookSettingsDtoSettingsWithOverridesDto {
  settings?: MaintenanceRequestRunBookSettingsDto | null;
  overrides: MaintenanceRequestRunBookSettingsDtoSettingsWithOverrideDto[];
}

export interface MaintenanceRequestSettingsDto {
  allowTenantToClose: boolean;
  onlyAllowRequesterToClose: boolean;
  allowTenantToEdit: boolean;
  onlyAllowRequesterToEdit: boolean;
  /** @format int32 */
  recentRequestsDays: number;
}

export interface MaintenanceRequestSettingsDtoSettingsWithOverrideDto {
  settings?: MaintenanceRequestSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface MaintenanceRequestSettingsDtoSettingsWithOverridesDto {
  settings?: MaintenanceRequestSettingsDto | null;
  overrides: MaintenanceRequestSettingsDtoSettingsWithOverrideDto[];
}

export enum MaintenanceRequestStatus {
  Closed = 'closed',
  Pending = 'pending',
  InProgress = 'inProgress',
  AiProcessing = 'aiProcessing',
  WaitingForWorkOrder = 'waitingForWorkOrder',
}

export interface MaintenanceRequestsStatsDto {
  /** @format int32 */
  allOpen: number;
  /** @format int32 */
  newRequests: number;
  /** @format int32 */
  waitingForWorkOrder: number;
  /** @format int32 */
  aiProcessing: number;
  /** @format int32 */
  inProgress: number;
  /** @format int32 */
  pending: number;
  /** @format int32 */
  closed: number;
  /** @format date-time */
  cacheDate: string;
}

export enum ManagementFeeDate {
  EndOfLastMonth = 'endOfLastMonth',
  Now = 'now',
}

export interface ManagementFeeDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  feeAmount: number;
  memo?: string | null;
}

export interface ManagementFeeSettingsDto {
  ignoreChartOfAccounts: string[];
  includeChartOfAccounts: string[];
  includeDebits: boolean;
  createSupplementaryBills: boolean;
  feeType: ManagementFeeType;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  feePercentage: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  minimumFee: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  maximumFee?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  managementFeePropertyChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  managementFeeCompanyChartOfAccountId: string;
}

export interface ManagementFeeSettingsDtoSettingsWithOverrideDto {
  settings?: ManagementFeeSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface ManagementFeeSettingsDtoSettingsWithOverridesDto {
  settings?: ManagementFeeSettingsDto | null;
  overrides: ManagementFeeSettingsDtoSettingsWithOverrideDto[];
}

export enum ManagementFeeType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export interface ManagementFeesPropertyDto {
  property: PropertyDto;
  /** @format int32 */
  year: number;
  /** @format int32 */
  month: number;
  /** @format double */
  managementFee: number;
  /** @format double */
  revenue: number;
  /** @format double */
  credits: number;
  /** @format double */
  debits: number;
  bills: HydratedBillDto[];
  settings: ManagementFeeSettingsDto;
  feeDateRange: DateOnlyRangeDto;
  memo: string;
}

export interface ManagementFeesResultDto {
  /** @format date-time */
  calculateFeesUntil: string;
  properties: ManagementFeesPropertyDto[];
}

export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widowed = 'widowed',
}

export enum MessageSource {
  App = 'app',
  Email = 'email',
  Text = 'text',
  System = 'system',
}

export enum MessageType {
  Participant = 'participant',
  PropertyManager = 'propertyManager',
  Bot = 'bot',
  Announcement = 'announcement',
}

export interface MineWorkOrdersDto {
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  leases: LeaseDto[];
  vendors: VendorDto[];
  items: WorkOrderListDto[];
  /** @format int32 */
  totalCount: number;
  urgencies?: {
    /** @format int32 */
    urgent?: number;
    /** @format int32 */
    high?: number;
    /** @format int32 */
    medium?: number;
    /** @format int32 */
    low?: number;
    /** @format int32 */
    none?: number;
  };
}

export enum MinimumPaymentType {
  Fixed = 'fixed',
  Percent = 'percent',
  PercentOrMinimum = 'percentOrMinimum',
}

export interface MoveInDto {
  /** @format date */
  moveIn?: string | null;
}

export interface MovePropertyDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
}

export interface MoveUnitDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
}

export interface NameDto {
  /** @minLength 1 */
  name: string;
}

export interface NewPropertyManagerDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  active: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastSeen?: string | null;
  isBusinessAgent: boolean;
  name?: string;
  imageId?: string | null;
  image?: PropertyManagerImageDto | null;
  /** @minLength 1 */
  clearTextPassword: string;
}

export interface NextCheckNumberDto {
  /** @format int32 */
  nextCheckNumber: number;
}

export interface NoteDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @minLength 1 */
  content: string;
  /** @format date-time */
  createdAt: string;
  propertyManager: PropertyManagerDto;
}

export interface NoteListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @minLength 1 */
  content: string;
  /** @format date-time */
  createdAt: string;
  propertyManager: PropertyManagerDto;
}

export interface NoteListDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: NoteListDto[];
}

export interface OfflinePaymentDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /** @format date */
  date: string;
  /** @minLength 1 */
  description?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId?: string | null;
  paymentMethod?: OfflineTransactionPaymentMethodDto | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  reference?: string | null;
}

export enum OfflineTransactionPaymentMethodDto {
  Check = 'check',
  CashiersCheck = 'cashiersCheck',
  MoneyOrder = 'moneyOrder',
  Cash = 'cash',
  Other = 'other',
}

export interface OwnerDirectoryReportBillDueDto {
  billId: string;
  /** @format date-time */
  dueDate: string;
  description: string;
  /** @format double */
  amount: number;
  /** @format double */
  totalAmount: number;
}

export interface OwnerDirectoryReportBillDueTotalDto {
  /** @format double */
  amount: number;
}

export interface OwnerDirectoryReportDto {
  items: OwnerDirectoryReportItemDto[];
}

export interface OwnerDirectoryReportItemDto {
  owner: OwnerDto;
  properties: OwnerDirectoryReportItemPropertyDto[];
}

export interface OwnerDirectoryReportItemPropertyDto {
  portfolio: PortfolioDto;
  property: PropertyDto;
  ownerProperty: OwnerPropertyDto;
}

export interface OwnerDirectoryReportTransactionTotalDto {
  /** @format double */
  cashIn: number;
  /** @format double */
  cashOut: number;
}

export interface OwnerDistributionAchResultsDto {
  successful: OwnerDistributionResultDto[];
  scheduled: OwnerDistributionResultDto[];
  failed: FailedOwnerDistributionAchResultDto[];
}

export interface OwnerDistributionPrintCheckResultDto {
  owner: OwnerDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  properties: OwnerDistributionPropertyResultDto[];
  check: HydratedCheckDto;
}

export interface OwnerDistributionPropertyResultDto {
  property: PropertyDto;
  bill: BillDto;
}

export interface OwnerDistributionResultDto {
  owner: OwnerDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  properties: OwnerDistributionPropertyResultDto[];
}

export interface OwnerDistributionsResultDto {
  manual: OwnerDistributionResultDto[];
  printCheck: OwnerDistributionPrintCheckResultDto[];
  ach: OwnerDistributionAchResultsDto;
}

export interface OwnerDto {
  id: string;
  active: boolean;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  isCompany: boolean;
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  chatId?: string | null;
  language: string;
  icons: string[];
  address?: AddressDto | null;
  portalStatus: PortalStatus;
}

export interface OwnerPropertyDto {
  /** @format int64 */
  id: number;
  /** @format int64 */
  portfolioId: number;
  /** @format int64 */
  propertyId: number;
  /** @format int64 */
  ownerId: number;
  /** @format double */
  ownershipPercentage: number;
}

export interface OwnerStatementReportDto {
  owner: OwnerDto;
  dateRange: DateRangeDto;
  properties: OwnerStatementReportPropertyDto[];
  tenants: Record<string, TenantDto>;
  vendors: Record<string, VendorDto>;
}

export interface OwnerStatementReportPropertyDto {
  /** @format double */
  beginningCash: number;
  /** @format double */
  cashIn: number;
  /** @format double */
  cashOut: number;
  /** @format double */
  ownerContributions: number;
  /** @format double */
  ownerDistributions: number;
  /** @format double */
  companyCredits: number;
  /** @format double */
  endingCash: number;
  /** @format double */
  unpaid: number;
  /** @format double */
  propertyReserve: number;
  /** @format double */
  netOwnerFunds: number;
  property: PropertyDto;
  portfolio: PortfolioDto;
  ownerProperty: OwnerPropertyDto;
  transactions: OwnerStatementReportTransactionDto[];
  transactionsTotal: OwnerDirectoryReportTransactionTotalDto;
  billsDue: OwnerDirectoryReportBillDueDto[];
  billsDueTotal: OwnerDirectoryReportBillDueTotalDto;
}

export interface OwnerStatementReportTransactionDto {
  billId: string;
  chartOfAccountId: string;
  tenantId: string | null;
  vendorId: string | null;
  transactionId: string;
  /** @format date */
  paymentDate: string;
  payeeOrPayerName: string;
  description: string | null;
  /** @format double */
  cashIn: number | null;
  /** @format double */
  cashOut: number | null;
  /** @format double */
  balance: number;
}

export interface OwnerTaxAmountDto {
  accountId: string;
  /** @format int32 */
  calendarYear: number;
  /** @format double */
  rentAmount: number;
  /** @format double */
  otherAmount: number;
  documentAlreadyExists: boolean;
  amountHasChanged: boolean;
  hasTaxId: boolean;
  owner: OwnerDto | null;
}

export interface OwnerTaxDocumentDto {
  id: string;
  /** @format int32 */
  calendarYear: number;
  entityName?: string | null;
  entityId?: string | null;
  /** @format double */
  amount: number;
  taxDocumentType: TaxType;
  documentName?: string | null;
  documentUrl?: string | null;
  status: ProcessStatus;
  taskErrorMessage?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  annotations?: TaxDocumentAnnotation[] | null;
  /** @format double */
  otherAmount: number;
}

export interface OwnerTaxDocumentListDto {
  id: string;
  /** @format int32 */
  calendarYear: number;
  entityName?: string | null;
  entityId?: string | null;
  /** @format double */
  amount: number;
  taxDocumentType: TaxType;
  documentName?: string | null;
  documentUrl?: string | null;
  status: ProcessStatus;
  taskErrorMessage?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  annotations?: TaxDocumentAnnotation[] | null;
  /** @format double */
  otherAmount: number;
}

export interface OwnerTaxDocumentPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: OwnerTaxDocumentListDto[];
}

export interface OwnerTransferDto {
  id: string;
  companyId: string;
  ownerId: string;
  portfolioId: string;
  propertyId: string;
  type: OwnerTransferType;
  /** @format double */
  amount: number;
  /** @format date-time */
  created: string;
  bankAccountId?: string | null;
  billId?: string | null;
  transactionId?: string | null;
  owner: OwnerDto;
  property: PropertyDto;
  bankAccount?: BankAccountDto | null;
  bill?: BillDto | null;
  transaction?: TransactionDto | null;
}

export interface OwnerTransferListDto {
  id: string;
  companyId: string;
  ownerId: string;
  portfolioId: string;
  propertyId: string;
  type: OwnerTransferType;
  /** @format double */
  amount: number;
  /** @format date-time */
  created: string;
  bankAccountId?: string | null;
  billId?: string | null;
  transactionId?: string | null;
}

export interface OwnerTransferPaginatedDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: OwnerTransferListDto[];
  owners: OwnerDto[];
  properties: PropertyDto[];
  bankAccounts: BankAccountDto[];
  bills: BillDto[];
  transactions: TransactionDto[];
}

export enum OwnerTransferType {
  Contribution = 'contribution',
  Distribution = 'distribution',
}

export interface PaginatedAssociationViolationListDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: AssociationViolationListDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  leases: LeaseDto[];
  vendors: VendorDto[];
}

export interface PaginatedBillsDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: BillDto[];
  chartOfAccounts: ChartOfAccountDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  vendors: VendorDto[];
  owners: OwnerDto[];
  tenants: TenantDto[];
  propertyManagers: PropertyManagerDto[];
}

export interface PaginatedChecksDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: CheckDto[];
  bankAccounts: BankAccountDto[];
  vendors: VendorDto[];
  owners: OwnerDto[];
}

export interface PaginatedHydratedLeaseDocumentDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: LeaseDocumentDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  leases: LeaseDto[];
  files: FileDto[];
}

export interface PaginatedUnitListingDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: UnitListingDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
}

export interface PastCompanyPayOutDto {
  transaction: TransactionDto;
  check?: HydratedCheckDto | null;
}

export interface PastCompanyPayOutDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: PastCompanyPayOutDto[];
}

export enum PayBillType {
  Manual = 'manual',
  PrintCheck = 'printCheck',
  Ach = 'ach',
}

export interface PayBillsResultsDto {
  manual: VendorBillResultDto[];
  printCheck: VendorBillPrintCheckResultDto[];
  ach: VendorBillAchResultsDto;
  properties: PropertyDto[];
}

export interface PayCompanyInvoiceDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  paymentAccountId?: string | null;
}

export interface PayOutResultDto {
  /** @format double */
  amount: number;
  check: HydratedCheckDto | null;
  bankAccount: BankAccountDto;
  transaction: TransactionDto | null;
}

export interface PayOutResultsDto {
  payOuts: PayOutResultDto[];
}

export interface PayVendorBillsDto {
  /** @format date */
  paymentDate?: string | null;
  groups: PayVendorBillsGroupDto[];
}

export interface PayVendorBillsGroupDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  paymentType: PayBillType;
  /** Required if payment type is `Manual` */
  paymentMethod?: TransactionPaymentMethod | null;
  /**
   * Required if payment type is `Manual`
   * @minLength 0
   * @maxLength 100
   */
  externalTransactionId?: string | null;
  /**
   * Required if payment type is `ACH`
   * @minLength 19
   * @maxLength 19
   */
  paymentAccountId?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  memo?: string | null;
  payments: VendorBillPaymentDto[];
}

export interface PaymentAccountDto {
  id: string;
  name: string;
  accountMask?: string | null;
  accountProvider: PaymentAccountProvider;
  mapped: boolean;
  bankInformation?: BankRouting | null;
  stripe?: StripePaymentAccountDto | null;
  plaid?: PlaidPaymentAccountDto | null;
}

export enum PaymentAccountProvider {
  Plaid = 'plaid',
  Stripe = 'stripe',
  None = 'none',
}

export interface PaymentAccountsDto {
  paymentAccounts: PaymentAccountDto[];
}

export interface PaymentSettingsDto {
  onePaymentPerBill: boolean;
}

export interface PetDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  breed: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  weight?: string | null;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  age?: number | null;
  gender?: Gender | null;
}

export interface PetPolicyDto {
  allowed?: boolean | null;
  policies?: {
    cat?: boolean;
    dog?: boolean;
    fish?: boolean;
    bird?: boolean;
    reptile?: boolean;
    other?: boolean;
  } | null;
  size?: PetSize | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  fee?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  deposit?: number | null;
  /**
   * @minLength 0
   * @maxLength 200
   */
  description?: string | null;
}

export enum PetSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface PlaidPaymentAccountDto {
  plaidAccountNumber: string;
  plaidAccountName: string;
  plaidAccountOfficialName?: string | null;
  plaidAccountType: string;
}

export interface PlaidTransferLimitsDto {
  isoCurrencyCode: string;
  /** @format double */
  maxDailyCreditAmount?: number | null;
  /** @format double */
  maxDailyDebitAmount?: number | null;
  /** @format double */
  maxMonthlyCreditAmount?: number | null;
  /** @format double */
  maxMonthlyDebitAmount?: number | null;
  /** @format double */
  maxSingleTransferCreditAmount?: number | null;
  /** @format double */
  maxSingleTransferDebitAmount?: number | null;
}

export enum PlaidTransferStatus {
  Pending = 'pending',
  Posted = 'posted',
  Settled = 'settled',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Returned = 'returned',
}

export type PlaidTransferTrackingFilterDto = object;

export interface PortalInviteResponseDto {
  /** @minLength 1 */
  newPassword: string;
}

export interface PortalSettingsDto {
  iconFileId?: string | null;
  logoFileId?: string | null;
  coverFileId?: string | null;
  metadata: WebsiteMetadata;
  contact: ContactPhoneEmailDto;
  socialLinks: {
    twitter?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    youTube?: string;
    tikTok?: string;
  };
  translationLanguages: string[];
  iconUrl?: string | null;
  iconThumbUrl?: string | null;
  logoUrl?: string | null;
  logoThumbUrl?: string | null;
  coverUrl?: string | null;
}

export enum PortalStatus {
  NotInvited = 'notInvited',
  Invited = 'invited',
  Active = 'active',
}

export interface PortfolioDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /** @format int32 */
  propertyCount: number;
  icons: string[];
}

export interface PortfolioOverviewDto {
  portfolio: PortfolioDto;
  summary: PortfolioSummary;
  /** @format date-time */
  cacheDate: string;
}

export interface PortfolioSummary {
  propertiesDistribution: {
    /** @format int32 */
    singleFamily?: number;
    /** @format int32 */
    multiFamily?: number;
    /** @format int32 */
    commercial?: number;
    /** @format int32 */
    condo?: number;
    /** @format int32 */
    townHome?: number;
    /** @format int32 */
    other?: number;
  };
  /** @format int32 */
  activeProperties: number;
  /** @format int32 */
  totalProperties: number;
  /** @format int32 */
  occupiedUnits: number;
  /** @format int32 */
  activeUnits: number;
  /** @format int32 */
  unlistedUnits: number;
  /** @format int32 */
  totalUnits: number;
  /** @format double */
  totalRent: number;
  /** @format double */
  totalDeposit: number;
  /** @format double */
  currentBalance: number;
  /** @format int32 */
  vacantUnits: number;
}

export enum PrincipalType {
  ControlPerson = 'controlPerson',
  BeneficialOwner = 'beneficialOwner',
}

export enum ProcessStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
  NotFound = 'notFound',
}

export interface PropertyBalanceDto {
  /** @format double */
  income: number;
  /** @format double */
  expenses: number;
  /** @format double */
  balance: number;
}

export interface PropertyDirectoryReportDto {
  items: PropertyDirectoryReportItemDto[];
}

export interface PropertyDirectoryReportItemDto {
  property: PropertyDto;
  managementFeeType: ManagementFeeType;
  /** @format double */
  managementFeeRate: number;
  /** @format double */
  minimumManagementFee: number;
  /** @format double */
  maximumManagementFee: number | null;
  /** @format double */
  reserve: number;
  owners: OwnerDto[];
}

export interface PropertyDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  displayName: string;
  address?: AddressDto | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  type: PropertyType;
  /** @format int32 */
  totalFloors?: number | null;
  /** @format int32 */
  unitCount: number;
  active: boolean;
  imageId?: string | null;
  icons: string[];
  magicTags?: Record<string, string>;
}

export interface PropertyManagerDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  active: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastSeen?: string | null;
  isBusinessAgent: boolean;
  name?: string;
  imageId?: string | null;
  image?: PropertyManagerImageDto | null;
}

export interface PropertyManagerImageDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
}

export interface PropertyManagerNotificationSettingsDto {
  sendNotifyOnPaymentFailure: boolean;
  sendNotifyOnPaymentReturned: boolean;
}

export interface PropertyManagerNotificationSettingsDtoSettingsWithOverrideDto {
  settings?: PropertyManagerNotificationSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface PropertyManagerNotificationSettingsDtoSettingsWithOverridesDto {
  settings?: PropertyManagerNotificationSettingsDto | null;
  overrides: PropertyManagerNotificationSettingsDtoSettingsWithOverrideDto[];
}

export interface PropertyOverviewDto {
  property: PropertyDto;
  summary: PropertySummary;
  revenue: PropertyRevenueDto;
  owners: PropertyOwnerDto[];
  maintenanceRequests: MaintenanceRequestListDto[];
  upcomingRenewalLeases: LeaseDto[];
  outstandingBalanceLeases: LeaseDto[];
  tenants: TenantDto[];
  units: UnitDto[];
  expiringLeaseCounts: ExpiringLeaseCounts;
  /** @format date-time */
  cacheDate: string;
}

export interface PropertyOwnerDto {
  id: string;
  active: boolean;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  isCompany: boolean;
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  chatId?: string | null;
  language: string;
  icons: string[];
  address?: AddressDto | null;
  portalStatus: PortalStatus;
  /** @format double */
  ownershipPercentage: number;
}

export interface PropertyOwnerIdDto {
  ownerId: string;
  /** @format double */
  ownershipPercentage: number;
}

export interface PropertyOwnershipDto {
  /** @format double */
  ownershipPercentage: number;
}

export interface PropertyRevenueDto {
  group: Record<string, IncomeExpenses>;
  /** @format double */
  income: number;
  /** @format double */
  expenses: number;
  /** @format double */
  balance: number;
}

export interface PropertySettingsDto {
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  reserve: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  ownerApprovalLimit?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  vendorApprovalLimit?: number | null;
}

export interface PropertySettingsDtoSettingsWithOverrideDto {
  settings?: PropertySettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface PropertySettingsDtoSettingsWithOverridesDto {
  settings?: PropertySettingsDto | null;
  overrides: PropertySettingsDtoSettingsWithOverrideDto[];
}

export interface PropertySummary {
  /** @format int32 */
  totalUnits: number;
  /** @format int32 */
  occupiedUnits: number;
  /** @format int32 */
  activeUnits: number;
  /** @format int32 */
  unlistedUnits: number;
  /** @format double */
  totalRent: number;
  /** @format double */
  totalDeposit: number;
  /** @format double */
  currentBalance: number;
  /** @format int32 */
  vacantUnits: number;
}

export enum PropertyType {
  SingleFamily = 'singleFamily',
  MultiFamily = 'multiFamily',
  Commercial = 'commercial',
  Condo = 'condo',
  TownHome = 'townHome',
  Other = 'other',
}

export interface ProratedAmountResultDto {
  /** @format double */
  proratedAmount: number;
}

export interface PublicCountry {
  code: string;
  name: string;
  regions: PublicRegion[];
}

export interface PublicLanguage {
  name: string;
  code: string;
}

export interface PublicRegion {
  code: string;
  name: string;
}

export interface QuestionChoiceDto {
  choiceKeyName: string;
  choiceDisplayName: string;
}

export interface RecurringLeaseChargeDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  chargeFrequency: ChargeFrequency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @minLength 1 */
  description?: string | null;
  companyCharge: boolean;
  /** @format date */
  lastCharge?: string | null;
  /** @format date */
  nextCharge: string;
  /** @format date */
  startFrom: string;
  /** @format date */
  end?: string | null;
}

export interface RefundRentalApplicationPaymentDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /** @minLength 1 */
  reason?: string | null;
}

export interface RegisterOwnerContributionDto {
  propertyId: string;
  bankAccountId: string;
  /** @format date */
  contributionDate: string;
  paymentMethod: TransactionPaymentMethod;
  /** @format double */
  amount: number;
  memo?: string | null;
  externalTransactionId?: string | null;
}

export interface ReleaseDepositRequestDto {
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
  /**
   * The bank account to release the deposit from. If not provided, the default bank account in `DepositPaymentSettings` will be used.
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId?: string | null;
}

export interface ReleaseLeaseBalanceRequestDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId?: string | null;
  /** @maxLength 999 */
  memo?: string | null;
  /** @maxLength 999 */
  externalTransactionId?: string | null;
  /** @format date */
  releaseDate?: string | null;
}

export interface RentPaymentFailureFeeSettingsDto {
  chargeFeeForFailedTransfers: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  noSufficientFundsFeeChargeChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  achReturnFeeChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  achFailedProcessingFeeChartOfAccountId: string;
  /**
   * @format double
   * @min 0
   * @max 1000
   */
  noSufficientFundsFee: number;
  /**
   * @format double
   * @min 0
   * @max 1000
   */
  achFailedFee: number;
  /**
   * @format double
   * @min 0
   * @max 1000
   */
  achReturnFee: number;
  /** @minLength 1 */
  disputedFeeChartOfAccountId: string;
  /** @format double */
  disputedFee: number;
}

export interface RentPaymentFailureFeeSettingsDtoSettingsWithOverrideDto {
  settings?: RentPaymentFailureFeeSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto {
  settings?: RentPaymentFailureFeeSettingsDto | null;
  overrides: RentPaymentFailureFeeSettingsDtoSettingsWithOverrideDto[];
}

export interface RentPaymentNotificationSettingsDto {
  sendRentReceivedNotifications: boolean;
  sendRentReceivedNotificationAsText: boolean;
  sendRentReceivedNotificationAsEmail: boolean;
}

export interface RentPaymentNotificationSettingsDtoSettingsWithOverrideDto {
  settings?: RentPaymentNotificationSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface RentPaymentNotificationSettingsDtoSettingsWithOverridesDto {
  settings?: RentPaymentNotificationSettingsDto | null;
  overrides: RentPaymentNotificationSettingsDtoSettingsWithOverrideDto[];
}

export interface RentPaymentProcessingFeesSettingsDto {
  propertyManagerPaysProcessingFees: boolean;
  /** @minLength 1 */
  propertyManagerPaysProcessingFeesChartOfAccountId: string;
  /** @format double */
  propertyManagerProcessingFee: number;
  /** @minLength 1 */
  propertyManagerProcessingFeeChartOfAccountId: string;
}

export interface RentPaymentProcessingFeesSettingsDtoSettingsWithOverrideDto {
  settings?: RentPaymentProcessingFeesSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface RentPaymentProcessingFeesSettingsDtoSettingsWithOverridesDto {
  settings?: RentPaymentProcessingFeesSettingsDto | null;
  overrides: RentPaymentProcessingFeesSettingsDtoSettingsWithOverrideDto[];
}

export interface RentPaymentSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId?: string | null;
  allowOnlinePayments: boolean;
  allowAchPayments: boolean;
  allowCardPayments: boolean;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  postBillsDaysBeforeDue: number;
  allowPartialPayments: boolean;
  minimumPaymentType: MinimumPaymentType;
  /**
   * @format double
   * @min 0.01
   * @max 1
   */
  minimumPaymentPercent?: number | null;
  /**
   * @format double
   * @min 1
   * @max 1000
   */
  minimumPaymentAmount?: number | null;
}

export interface RentPaymentSettingsDtoSettingsWithOverrideDto {
  settings?: RentPaymentSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface RentPaymentSettingsDtoSettingsWithOverridesDto {
  settings?: RentPaymentSettingsDto | null;
  overrides: RentPaymentSettingsDtoSettingsWithOverrideDto[];
}

export interface RentRollReportDto {
  groups: RentRollReportGroupDto[];
  metadata: RentRollReportMetadataDto;
}

export interface RentRollReportGroupDto {
  portfolio: PortfolioDto;
  property: PropertyDto;
  items: RentRollReportItemDto[];
}

export interface RentRollReportItemDto {
  unit: UnitDto;
  lease?: LeaseDto | null;
  tenants?: TenantDto[] | null;
  status: RentStatus;
}

export interface RentRollReportMetadataDto {
  /** @format int32 */
  totalUnits: number;
  /** @format double */
  occupancyRate: number;
  /** @format int32 */
  totalSqft: number;
  /** @format double */
  totalRent: number;
  /** @format double */
  totalDeposit: number;
}

export enum RentStatus {
  Rented = 'rented',
  UnRented = 'unRented',
}

export enum RentTransactionType {
  Payment = 'payment',
  Charge = 'charge',
  LeaseCredit = 'leaseCredit',
  Return = 'return',
}

export interface RentalApplicationChartOfAccountSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  rentalApplicationChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  creditReportChartOfAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  disputeFeeChartOfAccountId: string;
}

export interface RentalApplicationCommentDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  comment: string;
  section: CommentSection;
}

export interface RentalApplicationCountsDto {
  /** @format int32 */
  newRentalApplications: number;
  /** @format int32 */
  allApplications: number;
  /** @format int32 */
  approvedApplications: number;
  /** @format int32 */
  deniedApplications: number;
  /** @format date-time */
  cachedAt: string;
}

export interface RentalApplicationDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  password: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  tenant?: TenantDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  applyingWith?: string[] | null;
  /** @format date */
  desiredMoveInDate?: string | null;
  maritalStatus?: MaritalStatus | null;
  incomeInformation?: IncomeInformationDto | null;
  interests?: RentalApplicationInterestDto[] | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  draft: boolean;
  applicationDecision: ApplicationDecision;
  /** @format date-time */
  submittedAt?: string | null;
  screeningPaid: boolean;
  paymentStatus: ApplicationPaymentStatus;
  screeningStatus: ScreeningStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyPaymentBillId?: string | null;
  reportStatus?: ReportStatus | null;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  aiApplicationScore?: number | null;
  /** @minLength 1 */
  aiApplicationDescription?: string | null;
  hasBeenSubmitted: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  new: boolean;
  driversLicense?: DriversLicenseDto | null;
  pets?: PetDto[] | null;
  residentialHistory?: ResidentialHistoryDto[] | null;
  emergencyContact?: EmergencyContactDto | null;
  employment?: EmploymentDto[] | null;
  files?: RentalApplicationFileDto[] | null;
  questions?: Record<string, string>;
  comments?: RentalApplicationCommentDto[] | null;
  reports?: RentalApplicationReport[] | null;
  paymentBillId?: string | null;
  /** @format double */
  paymentAmount?: number | null;
  /** @format double */
  refundAmount?: number | null;
  rentalApplicationTransactionDto?: RentalApplicationTransactionDto | null;
  lease?: LeaseDto | null;
}

export interface RentalApplicationEmploymentDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  position?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  salary?: number | null;
  /** @format date */
  startDate?: string | null;
  /** @format date */
  endDate?: string | null;
}

export interface RentalApplicationFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  type: FileType;
  /** @minLength 1 */
  description?: string | null;
  /** @minLength 1 */
  fileName: string;
  /**
   * @minLength 3
   * @maxLength 100
   */
  fileExtension: string;
  /** @minLength 1 */
  fileUrl: string;
  thumbUrl?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  contentType: string;
  /** @format int64 */
  fileSize: number;
}

export interface RentalApplicationInterestDto {
  portfolio: PortfolioDto;
  property: PropertyDto;
  unit: UnitDto;
}

export interface RentalApplicationListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  password: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  tenant?: TenantDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  applyingWith?: string[] | null;
  /** @format date */
  desiredMoveInDate?: string | null;
  maritalStatus?: MaritalStatus | null;
  incomeInformation?: IncomeInformationDto | null;
  interests?: RentalApplicationInterestDto[] | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  draft: boolean;
  applicationDecision: ApplicationDecision;
  /** @format date-time */
  submittedAt?: string | null;
  screeningPaid: boolean;
  paymentStatus: ApplicationPaymentStatus;
  screeningStatus: ScreeningStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  companyPaymentBillId?: string | null;
  reportStatus?: ReportStatus | null;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  aiApplicationScore?: number | null;
  /** @minLength 1 */
  aiApplicationDescription?: string | null;
  hasBeenSubmitted: boolean;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  new: boolean;
}

export interface RentalApplicationListDtoPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: RentalApplicationListDto[];
}

export interface RentalApplicationOfflinePaymentDto {
  paymentMethod: TransactionPaymentMethod;
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 0
   * @maxLength 150
   */
  externalTransactionId: string;
  /** @minLength 1 */
  memo?: string | null;
}

export interface RentalApplicationPaymentAccountSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  rentalApplicationBankAccountId: string;
}

export interface RentalApplicationPaymentIntentDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  intentId: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  clientSecret: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  stripeAccountId: string;
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  totalAmount: number;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  processingFees: number;
}

export interface RentalApplicationPaymentSettingsDto {
  requirePaymentForApplication: boolean;
  /**
   * @format double
   * @min 0
   * @max 1000
   */
  applicationFee: number;
}

export interface RentalApplicationPaymentStatusDto {
  paid: boolean;
}

export interface RentalApplicationQuestion {
  text: string;
  type: RentalApplicationQuestionType;
  options?: string[] | null;
}

export interface RentalApplicationQuestionDto {
  /**
   * @minLength 1
   * @maxLength 500
   */
  text: string;
  type: RentalApplicationQuestionType;
  options?: string[] | null;
}

export enum RentalApplicationQuestionType {
  Input = 'input',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Boolean = 'boolean',
}

export interface RentalApplicationReport {
  reportType: TransunionReportType;
  reportCulture: string;
  reportData: string;
}

export interface RentalApplicationScreeningSettingsDto {
  requireScreeningForApplication: boolean;
}

export interface RentalApplicationSettingsDto {
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  rentalApplicationRecencyInDays: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  draftExpirationDays?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 20
   */
  draftReminderDays?: number | null;
  questions: RentalApplicationQuestionDto[];
}

export enum RentalApplicationStep {
  ApplicantInformation = 'applicantInformation',
  PotentialUnits = 'potentialUnits',
  RentalHistory = 'rentalHistory',
  WorkHistory = 'workHistory',
  Questions = 'questions',
  TermsAndConditions = 'termsAndConditions',
  Payment = 'payment',
  TransUnionScreening = 'transUnionScreening',
  TransUnionQuestions = 'transUnionQuestions',
  ApplicationSubmitted = 'applicationSubmitted',
  ManualVerification = 'manualVerification',
}

export interface RentalApplicationTransactionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  transactionId: string;
  transactionStatus?: TransactionStatus | null;
  transactionType: TransactionType;
  /** @format date */
  transactionDate: string;
  /** @format date */
  paymentDate: string;
  /** @minLength 1 */
  transactionMemo?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  transactionAmount: number;
  transactionPaymentMethod: TransactionPaymentMethod;
  transactionDirection: TransactionDirection;
  externalTransactionType: ExternalTransactionType;
  /** @minLength 1 */
  externalTransactionId: string;
}

export interface RentalApplicationsSettingsDto {
  canAcceptRentalApplications: boolean;
  requirePayment: boolean;
  /** @format double */
  paymentAmount: number;
  requireScreening: boolean;
  questions: RentalApplicationQuestion[];
}

export interface ReportGroupHeader {
  id: string;
  name: string;
}

export interface ReportGroupHeaderDto {
  id: string;
  name: string;
}

export enum ReportStatus {
  NotRequested = 'notRequested',
  Requested = 'requested',
  Available = 'available',
}

export interface RequiredAddressDto {
  /**
   * @minLength 1
   * @maxLength 250
   */
  streetAddress1: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  streetAddress2?: string | null;
  /**
   * @minLength 2
   * @maxLength 150
   */
  city: string;
  /**
   * @minLength 2
   * @maxLength 25
   */
  state: string;
  /**
   * @minLength 2
   * @maxLength 25
   */
  zipCode: string;
  /**
   * @minLength 3
   * @maxLength 25
   */
  country?: string | null;
}

export interface ResidentialHistoryDto {
  address: AddressDto;
  /**
   * @minLength 1
   * @maxLength 150
   */
  landlordName?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  landlordPhone?: string | null;
  /**
   * @minLength 1
   * @maxLength 500
   */
  reasonForLeaving?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  rent: number;
  /** @format date */
  moveInDate?: string | null;
  /** @format date */
  moveOutDate?: string | null;
}

export interface ReturnLeasePaymentDto {
  /** @maxLength 150 */
  reason?: string | null;
}

export interface RunBookPropertyManagerOverwriteDto {
  overwriteId: string;
  propertyManagerId: string;
  portfolioId?: string | null;
  propertyId?: string | null;
}

export interface RunBookVendorOverwriteDto {
  overwriteId: string;
  vendorId: string;
  portfolioId?: string | null;
  propertyId?: string | null;
  timeFrame?: TimeFrameDto | null;
}

export interface RunBooksPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: ListRunBookDto[];
  vendors: VendorDto[];
  propertyManagers: PropertyManagerDto[];
}

export enum ScreeningStatus {
  NotStarted = 'notStarted',
  Verified = 'verified',
  UnVerified = 'unVerified',
  ManualVerificationRequired = 'manualVerificationRequired',
}

export interface SelectSearchLeaseResultDto {
  lease: LeaseDto;
  tenants?: TenantDto[] | null;
}

export interface SelectSearchPortfolioResultDto {
  portfolio: PortfolioDto;
  properties?: SelectSearchPropertyResultDto[] | null;
}

export interface SelectSearchPropertyResultDto {
  property: PropertyDto;
  units?: SelectSearchUnitResultDto[] | null;
}

export interface SelectSearchRequestDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  search: string;
  levelSearch: LevelSearch;
  includeTenants: boolean;
  includeEndedLeases: boolean;
  includeDeactivated: boolean;
  includeFutureLease: boolean;
  excludeUnlistedUnit: boolean;
}

export interface SelectSearchResultDto {
  portfolios: SelectSearchPortfolioResultDto[];
}

export interface SelectSearchUnitResultDto {
  unit: UnitDto;
  leases?: SelectSearchLeaseResultDto[] | null;
}

export interface ServiceBillResultDto {
  propertyBill: BillDto;
  companyBill: BillDto;
}

export interface SetMessagesReadDto {
  /** @minItems 1 */
  messageIds: string[];
}

export enum SettingsLevel {
  Default = 'default',
  Company = 'company',
  Portfolio = 'portfolio',
  Property = 'property',
  Unit = 'unit',
  Lease = 'lease',
}

export enum StartOutgoingAchFailure {
  PaymentAccountNotMapped = 'paymentAccountNotMapped',
  PaymentAccountNotActive = 'paymentAccountNotActive',
  StartPaymentFailed = 'startPaymentFailed',
  Unknown = 'unknown',
}

export enum Status {
  Closed = 'closed',
  Pending = 'pending',
  InProgress = 'inProgress',
}

export enum StatusType {
  Draft = 'draft',
  Sent = 'sent',
  Viewed = 'viewed',
  Signed = 'signed',
}

export interface StripeAccountDetailsDto {
  linked: boolean;
  requireActions: boolean;
  /** @format double */
  available: number;
  /** @format double */
  pending: number;
}

export interface StripeCheckoutDto {
  sessionId: string;
  clientSecret: string;
}

export interface StripeIsConnectedDto {
  isConnected: boolean;
}

export interface StripeLinkDto {
  /** @minLength 1 */
  url: string;
}

export interface StripePaymentAccountDto {
  cardType?: CardType | null;
  brand?: string | null;
  country: string;
  /** @format int32 */
  expMonth?: number | null;
  /** @format int32 */
  expYear?: number | null;
  last4?: string | null;
}

export interface SuggestedLeaseEndDateDto {
  /** @format date */
  suggestedEndDate: string;
}

export enum SyndicationConfigType {
  Zillow = 'zillow',
}

export interface SyndicationDto {
  id: string;
  name: string;
  website: string;
  active: boolean;
  type: SyndicationConfigType;
  config?: Record<string, any>;
  /** @format date-time */
  lastSyndicated?: string | null;
}

export enum TargetType {
  Lease = 'lease',
  Tenant = 'tenant',
  Owner = 'owner',
  Vendor = 'vendor',
}

export enum TaxDocumentAccountType {
  Vendor = 'vendor',
  Owner = 'owner',
}

export interface TaxDocumentAnnotation {
  id: string;
  /** @format int32 */
  page: number;
  /** @format double */
  x: number;
  /** @format double */
  y: number;
  /** @format double */
  width: number;
  /** @format double */
  height: number;
  /** @format double */
  fontSize: number;
  value: TaxDocumentAnnotationValue | null;
  text: string | null;
  type: string;
  dataPath?: string | null;
}

export enum TaxDocumentAnnotationType {
  Text = 'text',
  Currency = 'currency',
}

export interface TaxDocumentAnnotationValue {
  text?: string | null;
  /** @format double */
  currencyValue?: number | null;
  type?: TaxDocumentAnnotationType | null;
  hasValue: boolean;
}

export interface TaxDocumentCalendarYearDto {
  calendarYears: number[];
}

export interface TaxDocumentDto {
  id: string;
  /** @format int32 */
  calendarYear: number;
  entityName?: string | null;
  entityId?: string | null;
  /** @format double */
  amount: number;
  taxDocumentType: TaxType;
  documentName?: string | null;
  documentUrl?: string | null;
  status: ProcessStatus;
  taskErrorMessage?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  annotations?: TaxDocumentAnnotation[] | null;
}

export interface TaxDocumentListDto {
  id: string;
  /** @format int32 */
  calendarYear: number;
  entityName?: string | null;
  entityId?: string | null;
  /** @format double */
  amount: number;
  taxDocumentType: TaxType;
  documentName?: string | null;
  documentUrl?: string | null;
  status: ProcessStatus;
  taskErrorMessage?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  annotations?: TaxDocumentAnnotation[] | null;
}

export interface TaxDocumentYearDto {
  /** @format int32 */
  calendarYear: number;
  /** @format int32 */
  taxDocumentsCount: number;
}

export enum TaxType {
  Misc = 'misc',
  Nec = 'nec',
}

export interface TenantAutoPayDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantPaymentAccountId: string;
  type: TenantAutoPayType;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  fixedAmount?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  maximumAmount?: number | null;
  frequency: ChargeFrequency;
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  /** @format date */
  lastPayment?: string | null;
  /** @format date */
  nextPayment?: string | null;
}

export interface TenantAutoPayFailedNotificationSettingsDto {
  sendNotifyOnTenantAutoPayFailed: boolean;
  sendNotifyOnTenantAutoPayFailedViaText: boolean;
  sendNotifyOnTenantAutoPayFailedViaEmail: boolean;
}

export interface TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverrideDto {
  settings?: TenantAutoPayFailedNotificationSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverridesDto {
  settings?: TenantAutoPayFailedNotificationSettingsDto | null;
  overrides: TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverrideDto[];
}

export enum TenantAutoPayType {
  FixedAmount = 'fixedAmount',
  PostedBalance = 'postedBalance',
  CurrentBalance = 'currentBalance',
}

export interface TenantBalanceDueNotificationSettingsDto {
  notifyOnBalanceDue: boolean;
  sendBalanceDueNotificationsToEvictionLeases: boolean;
  sendBalanceDueNotificationsToEndedLeases: boolean;
  sendBalanceDueNotificationsAsText: boolean;
  sendBalanceDueNotificationsAsEmail: boolean;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  notifyBalanceDueEveryXDays: number;
  sendNotificationsOfBalanceDueRightAway: boolean;
}

export interface TenantBalanceDueNotificationSettingsDtoSettingsWithOverrideDto {
  settings?: TenantBalanceDueNotificationSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface TenantBalanceDueNotificationSettingsDtoSettingsWithOverridesDto {
  settings?: TenantBalanceDueNotificationSettingsDto | null;
  overrides: TenantBalanceDueNotificationSettingsDtoSettingsWithOverrideDto[];
}

export interface TenantCountsDto {
  /** @format int32 */
  totalTenants: number;
  /** @format int32 */
  activeTenants: number;
  /** @format int32 */
  newTenantsToday: number;
  /** @format int32 */
  unInvitedTenants: number;
}

export interface TenantCreatedDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  portalStatus: PortalStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  driversLicense?: DriverLicenseDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: Gender | null;
  currentAddress?: AddressDto | null;
  emergencyContacts?: EmergencyContactDto[] | null;
  icons: string[];
  /**
   * @minLength 1
   * @maxLength 150
   */
  password: string;
}

export interface TenantDirectoryReportDto {
  items: TenantDirectoryReportItemDto[];
}

export interface TenantDirectoryReportItemDto {
  tenant: TenantDto;
  leases: TenantDirectoryReportItemLeaseDto[];
}

export interface TenantDirectoryReportItemLeaseDto {
  lease: LeaseTenantDto;
  unit: UnitDto;
  property: PropertyDto;
}

export interface TenantDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  active: boolean;
  /**
   * @format email
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  /** @format date-time */
  lastSeen?: string | null;
  /** @format date-time */
  lastPortalInvite?: string | null;
  portalStatus: PortalStatus;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  driversLicense?: DriverLicenseDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: Gender | null;
  currentAddress?: AddressDto | null;
  emergencyContacts?: EmergencyContactDto[] | null;
  icons: string[];
}

export interface TenantPortalInviteTextNotificationSettingsDto {
  /** @minLength 1 */
  portalInviteMessage: string;
}

export interface TextSettingsDto {
  enableTexts: boolean;
  includeAiTag: boolean;
}

export interface TextSettingsDtoSettingsWithOverrideDto {
  settings?: TextSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface TextSettingsDtoSettingsWithOverridesDto {
  settings?: TextSettingsDto | null;
  overrides: TextSettingsDtoSettingsWithOverrideDto[];
}

export enum TextStatus {
  NotSent = 'notSent',
  Disabled = 'disabled',
  NoRecipients = 'noRecipients',
  Sent = 'sent',
  Delivered = 'delivered',
  Failed = 'failed',
}

export interface TimeFrameDto {
  /** @format date-span */
  startTime: string;
  /** @format date-span */
  endTime: string;
  days?: DayOfWeek[] | null;
}

export interface TimeRange {
  /** @format date-span */
  start?: string | null;
  /** @format date-span */
  end?: string | null;
}

export enum TransactionDirection {
  In = 'in',
  Out = 'out',
}

export interface TransactionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /** @format date */
  transactionDate: string;
  transactionIdentity: TransactionIdentity;
  transactionPaymentMethod: TransactionPaymentMethod;
  status: TransactionStatus;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  memo?: string | null;
  direction: TransactionDirection;
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  tenantId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  transactionFinalizedDate?: string | null;
  externalTransactionType?: ExternalTransactionType | null;
  /** @minLength 1 */
  externalTransactionId?: string | null;
  failureReason?: TransactionFailure | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  refundTransactionId?: string | null;
  reference?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  depositSlipId?: string | null;
  creator: CreatorDto;
}

export enum TransactionFailure {
  NoSufficientFunds = 'noSufficientFunds',
  Unknown = 'unknown',
  TransferLimitReached = 'transferLimitReached',
  CardLost = 'cardLost',
  StolenCard = 'stolenCard',
  ExpiredCard = 'expiredCard',
  TenantAccountSetupError = 'tenantAccountSetupError',
  CompanyAccountSetupError = 'companyAccountSetupError',
}

export enum TransactionIdentity {
  Lease = 'lease',
  Vendor = 'vendor',
  Company = 'company',
  ProcessingFees = 'processingFees',
  RentalApplication = 'rentalApplication',
  ManagementFee = 'managementFee',
  OwnerTransfer = 'ownerTransfer',
  LeaseDeposit = 'leaseDeposit',
  ServiceBill = 'serviceBill',
  CompanyPayOut = 'companyPayOut',
  CompanyPropertyCredit = 'companyPropertyCredit',
  CompanyInvoice = 'companyInvoice',
}

export enum TransactionPaymentMethod {
  Ach = 'ach',
  Credit = 'credit',
  DebitCard = 'debitCard',
  Wallet = 'wallet',
  CreditCard = 'creditCard',
  Check = 'check',
  CashiersCheck = 'cashiersCheck',
  MoneyOrder = 'moneyOrder',
  Cash = 'cash',
  Other = 'other',
}

export enum TransactionStatus {
  Pending = 'pending',
  Failed = 'failed',
  Success = 'success',
  Cancelled = 'cancelled',
  Returned = 'returned',
  Refunded = 'refunded',
}

export enum TransactionType {
  Payment = 'payment',
  Return = 'return',
  Refund = 'refund',
}

export interface TransactionsDto {
  transactions: TransactionDto[];
  bankAccounts: BankAccountDto[];
  tenants: TenantDto[];
  vendors: VendorDto[];
  leases: LeaseDto[];
  properties: PropertyDto[];
  units: UnitDto[];
}

export interface TransferOwnerFundsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fromPropertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  toPropertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  ownerId: string;
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 0
   * @maxLength 150
   */
  memo?: string | null;
}

export interface TranslationDto {
  /**
   * @minLength 2
   * @maxLength 10
   */
  language: string;
  /** @minLength 1 */
  value: string;
}

export interface TransunionAnswersChoiceDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  questionKeyName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  selectedChoiceKeyName: string;
}

export interface TransunionAnswersDto {
  /** @format int32 */
  examId: number;
  answers: TransunionAnswersChoiceDto[];
}

export interface TransunionAnswersResultDto {
  screeningStatus: ScreeningStatus;
  /** @minLength 1 */
  resultStatus?: string | null;
}

export interface TransunionExamQuestionsDto {
  status: ScreeningStatus;
  /** @format int32 */
  examId?: number | null;
  /** @format int32 */
  transunionScreeningId?: number | null;
  /** @format int32 */
  transunionScreeningRequestRenterId?: number | null;
  questions?: ExamQuestionDto[] | null;
}

export enum TransunionReportType {
  Criminal = 'criminal',
  Credit = 'credit',
  Eviction = 'eviction',
}

export interface UnitDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  beds?: number | null;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  baths?: number | null;
  /**
   * Value must be either null or greater than or equal to 0.
   * @format int32
   * @min 0
   */
  unitSizeSqft?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  floor?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  active: boolean;
  imageId?: string | null;
  icons: string[];
  magicTags?: Record<string, string>;
}

export interface UnitListingDto {
  id: string;
  portfolioId: string;
  propertyId: string;
  unitId: string;
  status: UnitListingStatus;
  /** @format double */
  rentalPrice?: number | null;
  /** @format double */
  securityDeposit?: number | null;
  /** @format date */
  availableFrom?: string | null;
  leaseTerm?: LeaseTerm | null;
  title?: string | null;
  /** @format date */
  listed?: string | null;
  /** @format date-time */
  updated: string;
  pinned: boolean;
  primaryImage?: EmbedFileDto | null;
  primaryVideo?: EmbedFileDto | null;
  propertyType?: PropertyType | null;
  singleLineAddress?: string | null;
  /** @format int32 */
  recentRentalApplications?: number | null;
}

export enum UnitListingStatus {
  Pending = 'pending',
  Available = 'available',
  Reserved = 'reserved',
  Leased = 'leased',
  Occupied = 'occupied',
  UnderMaintenance = 'underMaintenance',
  Sold = 'sold',
  Other = 'other',
}

export interface UnitMasterListingDto {
  /** @minLength 1 */
  title: string;
  keywords: string[];
  /** @minLength 1 */
  description: string;
}

export interface UnitMasterListingRequestDto {
  /**
   * @minLength 1
   * @maxLength 500
   */
  prompt?: string | null;
}

export interface UnreadMessagePaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: ChatMessageDto[];
  chats: HydratedMagicChatDto[];
}

export interface UpdateAssociationViolationRequestDto {
  /** @format date */
  violationReceivedDate: string;
  /** @format date */
  violationDate: string;
  /** @format date */
  fixByDate?: string | null;
  /** @format double */
  associationFine: number;
  title: string;
  description: string;
}

export interface UpdateAssociationViolationStatusRequestDto {
  status: AssociationViolationStatus;
}

export interface UpdateBankAccountDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  description?: string | null;
}

export enum UpdateBillAllocations {
  LeavePaymentAsIs = 'leavePaymentAsIs',
  UpdatePaymentToMatchBill = 'updatePaymentToMatchBill',
  FailIfAllocationsExist = 'failIfAllocationsExist',
}

export interface UpdateBillDto {
  /** @maxLength 900 */
  reference?: string | null;
  /** @maxLength 900 */
  memo?: string | null;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate: string;
  /** @minItems 1 */
  lineItems: UpdateBillLineItemDto[];
}

export interface UpdateBillLineItemDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  description?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
}

export interface UpdateChartOfAccountDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  parentId?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  offsetChartOfAccountId?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  number?: number | null;
}

export interface UpdateChatDto {
  /** @minLength 1 */
  subject: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  participantLanguage?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
}

export interface UpdateCompanyBusinessInfoDto {
  /**
   * @minLength 0
   * @maxLength 150
   */
  legalName: string;
  /**
   * @minLength 1
   * @pattern ^\d{2}-?\d{7}$
   */
  ein: string;
  legalEntityType: LegalEntityType;
  businessAddress: RequiredAddressDto;
  /**
   * @minLength 19
   * @maxLength 19
   */
  businessNameFileId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  businessAddressFileId: string;
  /** @minLength 1 */
  irsFileId: string;
  /** @minItems 1 */
  principals: UpdateCompanyPrincipalDto[];
}

export interface UpdateCompanyDto {
  /**
   * @minLength 2
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 150
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  address: AddressDto;
}

export interface UpdateCompanyPrincipalDto {
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone: string;
  address: RequiredAddressDto;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn: string;
  /** @format date */
  dateOfBirth: string;
  type: PrincipalType;
  /**
   * Required if type is `BeneficialOwner`, otherwise optional. Value must be between 25 and 100.
   * @format int32
   */
  ownership?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  ssnCardFileId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  driversLicenseFileId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  personalAddressFileId: string;
}

export interface UpdateLeaseDocumentDto {
  /** @minLength 1 */
  name: string;
  signers: UpdateLeaseDocumentSignerDto[];
  annotations: LeaseDocumentAnnotationDto[];
}

export interface UpdateLeaseDocumentSignerDto {
  /** @minLength 1 */
  id: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  signatureName: string;
  /**
   * @minLength 1
   * @maxLength 10
   */
  signatureInitials: string;
  type: LeaseDocumentSignerType;
}

export interface UpdateLeaseDto {
  /** @format date */
  start: string;
  /** @format date */
  end?: string | null;
  eviction: boolean;
  monthToMonth: boolean;
  icons: string[];
}

export interface UpdateLeaseEvictionStatusDto {
  eviction: boolean;
}

export interface UpdateLeaseTemplateDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseTemplateCategoryId: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  description?: string | null;
  signers: LeaseTemplateSignerDto[];
  annotations: LeaseDocumentAnnotationDto[];
}

export interface UpdateMaintenanceRequestDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  requesterId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId: string;
  files?: UpdateMaintenanceRequestFileDto[] | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  internalDescription?: string | null;
  status: MaintenanceRequestStatus;
  urgency: Urgency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  categoryId?: string | null;
  permissionToEnter: boolean;
  /** @format date */
  due?: string | null;
}

export interface UpdateMaintenanceRequestFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  description?: string | null;
}

export interface UpdateMaintenanceRequestSelectedCategoryDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  categoryId?: string | null;
}

export interface UpdateMaintenanceRequestStatusDto {
  status: MaintenanceRequestStatus;
}

export interface UpdateMaintenanceRequestUrgencyDto {
  urgency: Urgency;
}

export interface UpdateOwnerDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  isCompany: boolean;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  federalTaxInformation?: FederalTaxInformationDto | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  address?: AddressDto | null;
}

export interface UpdatePortalSettingsDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  iconFileId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  logoFileId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  coverFileId?: string | null;
  metadata?: WebsiteMetadata | null;
  contact?: ContactPhoneEmailDto | null;
  socialLinks?: {
    twitter?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    youTube?: string;
    tikTok?: string;
  } | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  translationLanguages?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
}

export interface UpdatePropertyDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  name?: string | null;
  address: RequiredAddressDto;
  type: PropertyType;
  /**
   * @minLength 19
   * @maxLength 19
   */
  imageId?: string | null;
  icons?: string[] | null;
  magicTags?: Record<string, string>;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  totalFloors?: number | null;
}

export interface UpdatePropertyMagicTagDto {
  magicTags: Record<string, string>;
}

export interface UpdatePropertyManagerActiveDto {
  active: boolean;
}

export interface UpdatePropertyManagerDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email: string;
  isBusinessAgent?: boolean | null;
}

export interface UpdatePropertyManagerMeDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email: string;
}

export interface UpdatePropertyManagerMePasswordDto {
  /**
   * @minLength 5
   * @maxLength 150
   */
  clearTextPassword: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  currentPassword: string;
}

export interface UpdatePropertyManagerPasswordDto {
  /**
   * @minLength 5
   * @maxLength 150
   */
  clearTextPassword: string;
}

export interface UpdateRecurringLeaseChargeDto {
  /**
   * Value must be a decimal greater than or equal to 1 and less than or equal to 1000000000000.
   * @format double
   * @min 1
   * @max 1000000000000
   */
  amount: number;
  chargeFrequency: ChargeFrequency;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId: string;
  /** @minLength 1 */
  description: string;
  companyCharge?: boolean | null;
  /** @format date */
  startFrom: string;
  /** @format date */
  end?: string | null;
  updatePastCharges: boolean;
}

export interface UpdateRentalApplicationDecisionDto {
  decision: ApplicationDecision;
}

export interface UpdateRentalApplicationFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  type: FileType;
  /**
   * @minLength 0
   * @maxLength 150
   */
  description?: string | null;
}

export interface UpdateRunBookDefaultPropertyManagerDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultPropertyManagerId: string;
}

export interface UpdateRunBookDefaultVendorDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  defaultVendorId: string;
}

export interface UpdateRunBookDto {
  /** @maxLength 150 */
  title?: string | null;
  /** @maxLength 250 */
  description?: string | null;
  /** @minLength 50 */
  script: string;
}

export interface UpdateSyndicationDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  website: string;
  type: SyndicationConfigType;
  config: Record<string, any>;
  active: boolean;
}

export interface UpdateTenantDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  lastName?: string | null;
  /**
   * @minLength 0
   * @maxLength 250
   */
  email?: string | null;
  /**
   * @minLength 10
   * @maxLength 13
   * @pattern ^\+{0,1}\d{0,1}\d{10}$
   */
  phone?: string | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  password?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu'
    | null;
  /**
   * @minLength 9
   * @maxLength 11
   * @pattern ^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$
   */
  ssn?: string | null;
  driversLicense?: DriverLicenseDto | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: Gender | null;
  currentAddress?: AddressDto | null;
  emergencyContacts?: EmergencyContactDto[] | null;
  icons?: string[] | null;
}

export interface UpdateTenantPasswordDto {
  /**
   * @minLength 1
   * @maxLength 150
   */
  password: string;
}

export interface UpdateTransactionDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId: string;
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
  /** @maxLength 900 */
  memo?: string | null;
  /** @format date-time */
  transactionDate: string;
  /** @format date-time */
  transactionFinalizedDate?: string | null;
  externalTransactionType?: ExternalTransactionType | null;
  /** @maxLength 900 */
  externalTransactionId?: string | null;
  paymentMethod: TransactionPaymentMethod;
  status: TransactionStatus;
  failureReason?: TransactionFailure | null;
}

export interface UpdateUnitDto {
  /**
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  beds?: number | null;
  /**
   * Value must be a double greater than or equal to 0 and evenly divisible by 0.5.
   * @format double
   * @min 0
   */
  baths?: number | null;
  /**
   * Value must be either null or greater than or equal to 0.
   * @format int32
   * @min 0
   */
  unitSizeSqft?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 200
   */
  floor?: number | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  imageId?: string | null;
  icons?: string[] | null;
  magicTags?: Record<string, string>;
}

export interface UpdateUnitListingDto {
  status?: UnitListingStatus | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  rentalPrice?: number | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  securityDeposit?: number | null;
  /** @format date */
  availableFrom?: string | null;
  leaseTerm?: LeaseTerm | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title?: string | null;
  /** @minLength 1 */
  description?: string | null;
  images?: EmbedFileDto[] | null;
  videos?: EmbedFileDto[] | null;
  tags?: string[] | null;
  petPolicy?: PetPolicyDto | null;
  amenities?: AmenityDto[] | null;
  terms?: Record<string, string>;
  listed?: boolean | null;
  pinned?: boolean | null;
}

export interface UpdateUnitMagicTagDto {
  magicTags: Record<string, string>;
}

export interface UpdateVendorBillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId: string;
  reference?: string | null;
  memo?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate: string;
  /** @minItems 1 */
  lineItems: BillLineItemDto[];
  allocationHandling: UpdateBillAllocations;
}

export interface UpdateWorkOrderDto {
  status: Status;
  urgency: Urgency;
  /** @format date */
  dueDate?: string | null;
  permissionToEnter: boolean;
  /**
   * @minLength 0
   * @maxLength 150
   */
  title: string;
  /** @minLength 1 */
  description: string;
  internalDescription?: string | null;
  assignedPropertyManagerId?: string | null;
}

export interface UpdateWorkOrderFileDto {
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string | null;
}

export interface UpdateWorkOrderLeaseDto {
  propertyId?: string | null;
  unitId?: string | null;
  leaseId?: string | null;
}

export interface UpdateWorkOrderMaintenanceRequestDto {
  newMaintenanceRequestId?: string | null;
}

export interface UpdateWorkOrderStatusDto {
  status: Status;
}

export interface UpdateWorkOrderUrgencyDto {
  urgency: Urgency;
}

export interface UpdateWorkOrderVendorDto {
  newVendorId?: string | null;
}

export interface UploadResultDto {
  fileId: string;
  signedUrl: string;
  thumbSignedUrl?: string | null;
}

export enum Urgency {
  Urgent = 'urgent',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  None = 'none',
}

export interface ValidPropertyDto {
  property: PropertyDto;
  owners: PropertyOwnerIdDto[];
}

export interface ValidateDto {
  isValid: boolean;
}

export interface VendorBillAchResultsDto {
  successful: VendorBillResultDto[];
  scheduled: VendorBillResultDto[];
  failed: FailedVendorBillResultDto[];
}

export interface VendorBillPaymentDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  billId: string;
  /**
   * Value must be a decimal greater than or equal to 0.01 and less than or equal to 1000000000000.
   * @format double
   * @min 0.01
   * @max 1000000000000
   */
  amount: number;
}

export interface VendorBillPaymentResultDto {
  bill: BillDto;
  /** @format double */
  amount: number;
}

export interface VendorBillPrintCheckResultDto {
  vendor: VendorDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  payments: VendorBillPaymentResultDto[];
  check: HydratedCheckDto;
}

export interface VendorBillResultDto {
  vendor: VendorDto;
  bankAccount: BankAccountDto;
  transaction: TransactionDto;
  payments: VendorBillPaymentResultDto[];
}

export interface VendorBillsToPayDto {
  properties: BillPayProperty[];
  vendors: HydratedVendorDto[];
}

export interface VendorCategoryDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
}

export interface VendorCommunicationsDto {
  /** @format int32 */
  messagesCount: number;
  /** @format int32 */
  unreadMessagesCount: number;
}

export interface VendorCreatedDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  contact: ContactDto;
  /** @minLength 1 */
  memo?: string | null;
  active: boolean;
  /** @minLength 1 */
  website?: string | null;
  irs1099Form?: FederalTaxInformationDto | null;
  insurancePolicy?: InsurancePolicyDto | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  icons: string[];
  type: VendorType;
  defaultMemo?: string | null;
  paymentSettings: PaymentSettingsDto;
  /**
   * @minLength 1
   * @maxLength 150
   */
  password: string;
}

export interface VendorDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language:
    | 'aa'
    | 'ab'
    | 'ae'
    | 'af'
    | 'ak'
    | 'am'
    | 'an'
    | 'ar'
    | 'as'
    | 'av'
    | 'ay'
    | 'az'
    | 'ba'
    | 'be'
    | 'bg'
    | 'bh'
    | 'bi'
    | 'bm'
    | 'bn'
    | 'bo'
    | 'br'
    | 'bs'
    | 'ca'
    | 'ce'
    | 'ch'
    | 'co'
    | 'cr'
    | 'cs'
    | 'cu'
    | 'cv'
    | 'cy'
    | 'da'
    | 'de'
    | 'dv'
    | 'dz'
    | 'ee'
    | 'el'
    | 'en'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'ff'
    | 'fi'
    | 'fj'
    | 'fo'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'gn'
    | 'gu'
    | 'gv'
    | 'ha'
    | 'he'
    | 'hi'
    | 'ho'
    | 'hr'
    | 'ht'
    | 'hu'
    | 'hy'
    | 'hz'
    | 'ia'
    | 'id'
    | 'ie'
    | 'ig'
    | 'ii'
    | 'ik'
    | 'io'
    | 'is'
    | 'it'
    | 'iu'
    | 'ja'
    | 'jv'
    | 'ka'
    | 'kg'
    | 'ki'
    | 'kj'
    | 'kk'
    | 'kl'
    | 'km'
    | 'kn'
    | 'ko'
    | 'kr'
    | 'ks'
    | 'ku'
    | 'kv'
    | 'kw'
    | 'ky'
    | 'la'
    | 'lb'
    | 'lg'
    | 'li'
    | 'ln'
    | 'lo'
    | 'lt'
    | 'lu'
    | 'lv'
    | 'mg'
    | 'mh'
    | 'mi'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'mt'
    | 'my'
    | 'na'
    | 'nb'
    | 'nd'
    | 'ne'
    | 'ng'
    | 'nl'
    | 'nn'
    | 'no'
    | 'nr'
    | 'nv'
    | 'ny'
    | 'oc'
    | 'oj'
    | 'om'
    | 'or'
    | 'os'
    | 'pa'
    | 'pi'
    | 'pl'
    | 'ps'
    | 'pt'
    | 'qu'
    | 'rm'
    | 'rn'
    | 'ro'
    | 'ru'
    | 'rw'
    | 'sa'
    | 'sc'
    | 'sd'
    | 'se'
    | 'sg'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sm'
    | 'sn'
    | 'so'
    | 'sq'
    | 'sr'
    | 'ss'
    | 'st'
    | 'su'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'ti'
    | 'tk'
    | 'tl'
    | 'tn'
    | 'to'
    | 'tr'
    | 'ts'
    | 'tt'
    | 'tw'
    | 'ty'
    | 'ug'
    | 'uk'
    | 'ur'
    | 'uz'
    | 've'
    | 'vi'
    | 'vo'
    | 'wa'
    | 'wo'
    | 'xh'
    | 'yi'
    | 'yo'
    | 'za'
    | 'zh'
    | 'zu';
  contact: ContactDto;
  /** @minLength 1 */
  memo?: string | null;
  active: boolean;
  /** @minLength 1 */
  website?: string | null;
  irs1099Form?: FederalTaxInformationDto | null;
  insurancePolicy?: InsurancePolicyDto | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  icons: string[];
  type: VendorType;
  defaultMemo?: string | null;
  paymentSettings: PaymentSettingsDto;
}

export interface VendorOverviewDto {
  vendor: VendorDto;
  unpaidBills: VendorUnpaidBillsDto;
  workOrders: VendorWorkOrdersDto;
  transactions: VendorTransactionsDto;
  communications: VendorCommunicationsDto;
  references: VendorOverviewReferenceDto;
  /** @format date-time */
  cacheDate: string;
}

export interface VendorOverviewReferenceDto {
  properties: Record<string, PropertyDto>;
  units: Record<string, UnitDto>;
}

export interface VendorTaxAmountDto {
  accountId: string;
  /** @format int32 */
  calendarYear: number;
  /** @format double */
  amount: number;
  documentAlreadyExists: boolean;
  amountHasChanged: boolean;
  hasTaxId: boolean;
  vendor: VendorDto | null;
}

export interface VendorTaxDocumentPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: TaxDocumentListDto[];
}

export interface VendorTransactionsDto {
  items: Record<string, VendorTransactionsItem>;
  /** @format double */
  totalAmount: number;
  /** @format int32 */
  totalCount: number;
}

export interface VendorTransactionsItem {
  /** @format int32 */
  transactions: number;
  /** @format double */
  amount: number;
  /** @format double */
  due: number;
  /** @format double */
  paid: number;
}

export enum VendorType {
  Hoa = 'hoa',
  PropertyManager = 'propertyManager',
  Other = 'other',
}

export interface VendorUnpaidBillsDto {
  items: BillDto[];
  /** @format double */
  totalAmount: number;
  /** @format double */
  totalDue: number;
}

export interface VendorWorkOrdersDto {
  items: WorkOrderListDto[];
  /** @format double */
  totalCount: number;
}

export interface VoidCheckRequestDto {
  /**
   * @minLength 0
   * @maxLength 900
   */
  voidReason?: string | null;
}

export interface WebsiteMetadata {
  title: string;
  titleTemplate: string;
  description: string;
  keywords: string[];
  themeColor: string;
}

export interface WorkOrder {
  active: boolean;
  /** @format int64 */
  companyId: number;
  /** @format int64 */
  portfolioId: number;
  /** @format int64 */
  propertyId: number;
  /** @format int64 */
  unitId: number | null;
  /** @format int64 */
  leaseId: number | null;
  /** @format int64 */
  vendorId: number | null;
  /** @format int64 */
  chatId: number | null;
  /** @format int64 */
  groupChatId: number | null;
  /** @format int64 */
  maintenanceRequestId: number | null;
  /** @format int64 */
  associationViolationId: number | null;
  /** @format int64 */
  assignedPropertyManagerId: number | null;
  reference: string;
  status: Status;
  urgency: Urgency;
  title: string;
  description: string;
  internalDescription: string | null;
  files: WorkOrderFile[];
  /** @format date-time */
  dueDate: string | null;
  permissionToEnter: boolean;
  bills: WorkOrderDraftBill[] | null;
  password: string;
  /** @format int64 */
  id: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
}

export interface WorkOrderActionDto {
  id: string;
  subject: string;
  description?: string | null;
  status: Status;
  propertyManager: PropertyManagerDto;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  files?: EmbedFileDto[] | null;
  /** @format date-time */
  actionDate: string;
}

export interface WorkOrderActionListDto {
  id: string;
  subject: string;
  description?: string | null;
  status: Status;
  propertyManager: PropertyManagerDto;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
  files?: EmbedFileDto[] | null;
  /** @format date-time */
  actionDate: string;
}

export interface WorkOrderBillDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  files?: WorkOrderDraftBillFileDto[] | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  billId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  bankAccountId?: string | null;
  approved: boolean;
  /** @format date */
  billDate: string;
  /** @format date */
  dueDate?: string | null;
  lineItems: WorkOrderBillLineItemDto[];
}

export interface WorkOrderBillLineItem {
  memo: string | null;
  /** @format double */
  amount: number;
  /** @format int64 */
  chartOfAccountId: number | null;
}

export interface WorkOrderBillLineItemDto {
  /**
   * @minLength 0
   * @maxLength 150
   */
  memo?: string | null;
  /**
   * Value must be a decimal greater than or equal to 0 and less than or equal to 1000000000000.
   * @format double
   * @min 0
   * @max 1000000000000
   */
  amount: number;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chartOfAccountId?: string | null;
}

export interface WorkOrderDraftBill {
  /** @format int64 */
  billId: number | null;
  approved: boolean;
  /** @format date-time */
  addedOn: string;
  addedByVendor: boolean;
  /** @format date-time */
  billDate: string;
  /** @format date-time */
  dueDate: string | null;
  lineItems: WorkOrderBillLineItem[];
  files: WorkOrderDraftBillFile[] | null;
  /** @format int64 */
  id: number;
}

export interface WorkOrderDraftBillFile {
  /** @format int64 */
  fileId: number;
  description: string | null;
}

export interface WorkOrderDraftBillFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
  /** @format int64 */
  fileSize: number;
  /** @format date-time */
  uploaded: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  description?: string | null;
}

export interface WorkOrderFile {
  /** @format int64 */
  fileId: number;
  description: string | null;
  /** @format date-time */
  addedOn: string;
}

export interface WorkOrderFileDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  fileId: string;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  signedUrl: string;
  signedThumbUrl?: string | null;
  /** @format int64 */
  fileSize: number;
  /** @format date-time */
  uploaded: string;
  description?: string | null;
  /** @format date */
  addedOn: string;
}

export interface WorkOrderListDto {
  /**
   * @minLength 19
   * @maxLength 19
   */
  id: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  portfolioId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  propertyId: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  unitId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  leaseId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  vendorId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  chatId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  groupChatId?: string | null;
  /**
   * @minLength 19
   * @maxLength 19
   */
  assignedPropertyManagerId?: string | null;
  reference: string;
  /**
   * @minLength 19
   * @maxLength 19
   */
  maintenanceRequestId?: string | null;
  status: Status;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated?: string | null;
  /** @format date */
  dueDate?: string | null;
  urgency: Urgency;
}

export interface WorkOrderSettingsDto {
  sendVendorsTextMessageWhenAssigned: boolean;
  sendVendorsEmailMessageWhenAssigned: boolean;
  sendTenantVendorCoordinationMessage: boolean;
}

export interface WorkOrderSettingsDtoSettingsWithOverrideDto {
  settings?: WorkOrderSettingsDto | null;
  level: SettingsLevel;
  levelId: string;
}

export interface WorkOrderSettingsDtoSettingsWithOverridesDto {
  settings?: WorkOrderSettingsDto | null;
  overrides: WorkOrderSettingsDtoSettingsWithOverrideDto[];
}

export interface WorkOrdersPaginationDto {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  items: WorkOrderListDto[];
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  units: UnitDto[];
  leases: LeaseDto[];
  vendors: VendorDto[];
  propertyManagers: PropertyManagerDto[];
}
