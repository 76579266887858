import { A } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import IconBookmarks from '~/assets/images/common/bookmarks.svg?component-solid';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { PortfolioIcon } from '~/components/portfolios/PortfolioIcon';
import { PropertyImage } from '~/components/properties';
import { IconCirclePlus, IconTrash } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import type { Component } from 'solid-js';
interface DefaultPmAndVendorViewProps {
  source: 'vendor' | 'propertyManager';
}
export const DefaultPmAndVendorView: Component<DefaultPmAndVendorViewProps> = (props) => {
  const { t } = useLocalization();
  const { runBook } = useRunBooks();

  const defaultData = createMemo(() => {
    return props.source === 'vendor' ? runBook()?.defaultVendor : runBook()?.defaultPropertyManager;
  });

  const overrides = createMemo(() => {
    const result: any[] = [];
    const data = props.source === 'vendor' ? runBook()?.vendorOverwrites : runBook()?.propertyManagerOverwrites;
    data?.forEach((override) => {
      if (override.portfolio) {
        const resultIndex = result.findIndex((item) => item?.portfolio?.id === override.portfolioId);
        if (resultIndex !== -1) {
          result[resultIndex].overrides.push(override);
        } else {
          result.push({
            portfolio: override.portfolio,
            overrides: [override],
          });
        }
      }

      if (override.property) {
        const resultIndex = result.findIndex((item) => item?.property?.id === override.propertyId);
        if (resultIndex !== -1) {
          result[resultIndex].overrides.push(override);
        } else {
          result.push({
            property: override.property,
            overrides: [override],
          });
        }
      }
    });
    return result;
  });

  return (
    <div class="flex flex-col gap-3">
      <Panel
        title={
          <div class="flex items-center gap-2">
            <IconBookmarks />
            <span class="text-lg font-semibold text-text-level01">
              {props.source === 'vendor' ? t('Default vendor') : t('Default property manager')}
            </span>
          </div>
        }
        actions={
          <LinkButton variant="outlined" size="sm" href="edit-default">
            <IconEdit />
            {t('Edit')}
          </LinkButton>
        }>
        <div class="px-6 py-4">
          <div class="flex h-12 items-center rounded-lg bg-[#9EA7FD0F] px-4">{defaultData()?.name || t('Not assigned')}</div>
        </div>
      </Panel>
      <Show when={defaultData()?.id}>
        <Panel
          title={t('Overrides')}
          actions={
            <LinkButton size="sm" href="add-overrides">
              <IconCirclePlus class="size-5" />
              {t('Add new override')}
            </LinkButton>
          }>
          <div class="flex flex-col gap-4 px-6 py-4">
            <Show when={!!overrides().length} fallback={<Empty />}>
              <For each={overrides()}>
                {(item, index) => (
                  <div>
                    <div
                      class="flex h-[49px] items-center gap-1"
                      classList={{
                        'border-t': !!index(),
                      }}>
                      <Show
                        when={item.portfolio}
                        fallback={
                          <>
                            <div class="text-sm text-text-level03">{t('Property')}: </div>
                            <PropertyImage class="size-5 rounded-[4px]" property={item.property} />
                            <div class="text-sm text-text-level01">{item.property?.displayName}</div>
                            <div class="  hidden items-center text-xs text-text-level03 lg:flex">
                              (<DisplayAddress address={item.property?.address} />)
                            </div>
                            <div class="text-sm font-semibold text-purple">{item.overrides?.length}</div>
                          </>
                        }>
                        <div class="text-sm text-text-level03">{t('Portfolio')}: </div>
                        <div class="flex size-5 items-center justify-center rounded-[4px] bg-light-pink">
                          <PortfolioIcon class="size-3" data-slot="icon" />
                        </div>
                        <div class="text-sm text-title-gray">{item.portfolio?.name}</div>
                        <div class="text-sm font-semibold text-purple">{item.overrides?.length}</div>
                      </Show>
                    </div>

                    <div class="flex flex-col gap-2">
                      <For each={item.overrides}>
                        {(override) => (
                          <div class="flex h-12 items-center justify-between rounded-lg bg-[#9EA7FD0F] px-4">
                            <span>{override[props.source]?.name || t('Not assigned')}</span>
                            <A href={`${override.overwriteId}/delete`}>
                              <IconTrash class="size-4 cursor-pointer text-title-gray hover-allowed:hover:text-primary" />
                            </A>
                          </div>
                        )}
                      </For>
                    </div>
                  </div>
                )}
              </For>
            </Show>
          </div>
        </Panel>
      </Show>
    </div>
  );
};
