import { For, createMemo, createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import * as icons from '~/components/ui/Icons';

export const IconsPage = () => {
  const [search, setSearch] = createSignal('');

  const filtered = createMemo(() => {
    return Object.entries(icons).filter(([key]) => key.toLowerCase().replace('icon', '').includes(search().toLowerCase()));
  });

  const copyCode = (name: string) => {
    navigator.clipboard.writeText(`<${name} class="size-5 text-text-level03" />`).then(() => toast.success(`${name} copied`));
  };

  return (
    <div class="mx-auto max-w-[912px] p-6">
      <h1 class="mb-4 text-xl font-semibold text-title-gray">Icons</h1>
      <input
        class="w-full rounded-lg border bg-transparent px-4 py-2 outline-none transition-colors focus:border-primary focus:ring focus:ring-primary/40"
        value={search()}
        onInput={(e) => setSearch(e.target.value)}
      />
      <div class="mt-10 flex flex-wrap gap-4">
        <For each={filtered()}>
          {([key, Icon]) => (
            <div
              class="flex basis-40 cursor-pointer flex-col items-center gap-2 text-[#3c3c43] hover-allowed:hover:text-black"
              onClick={() => copyCode(key)}>
              <Icon class="size-1/2" />
              <span class="font-mono text-xs">&lt;{key} /&gt;</span>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
