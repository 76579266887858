import { untrack } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import AIProcessingBg from '~/assets/images/maintenance/aIProcessingBg.png';
import { useLocalization } from '~/contexts/global';
import { MaintenanceRequestStatus } from '~/swagger/Api';
import { PendingIcon } from './Icons';
import IconAiProcessing from './aiProcessing.svg?component-solid';
import IconWorkerOrder from './workOrder.svg?component-solid';
import type { JSX } from 'solid-js';
import type { FilterKey } from '~/pages/maintenance-request/list-maintenance-request/list-components/statusFilters';
import type { MaintenanceRequestListDto } from '~/swagger/Api';

export const AIProcessing = () => {
  const { t } = useLocalization();
  return (
    <div
      class="relative flex h-5 max-w-fit items-center justify-center gap-1 rounded-3xl bg-cover bg-center bg-no-repeat px-2"
      style={{
        'background-image': `url(${AIProcessingBg})`,
      }}>
      <IconAiProcessing />
      <span class="text-nowrap text-xxs font-semibold text-white">{t('AI processing')}</span>
    </div>
  );
};

export const AIProcessed = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-5 items-center justify-center rounded-3xl bg-gray-level01 px-2">
      <span class="text-nowrap text-xxs font-medium text-text-level03">{t('AI processed')}</span>
    </div>
  );
};

const WaitingForWorkOrder = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-5 max-w-fit items-center justify-center gap-1 rounded-3xl bg-green px-2">
      <IconWorkerOrder />
      <span class="text-nowrap text-xxs font-medium text-white">{t('Waiting for work order')}</span>
    </div>
  );
};

const InProgress = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-5 max-w-fit items-center justify-center gap-1 rounded-3xl bg-essential-colour px-2">
      <span class="text-nowrap text-xxs font-medium text-white">{t('In progress')}</span>
    </div>
  );
};

const Pending = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-5 max-w-fit items-center justify-center gap-1 rounded-3xl bg-light-purple px-2">
      <PendingIcon class="size-3" />
      <span class="text-nowrap text-xxs font-medium text-white">{t('Pending')}</span>
    </div>
  );
};

export const NewStatus = () => {
  const { t } = useLocalization();
  return (
    <div class="bg-light-purple/10 text-primary rounded-full px-1 text-xxs">{t('New')}</div>
  )
};

const statusToComponent: {
  [key in MaintenanceRequestStatus | 'aiProcessed']?: () => JSX.Element;
} = {
  [MaintenanceRequestStatus.AiProcessing]: AIProcessing,
  [MaintenanceRequestStatus.WaitingForWorkOrder]: WaitingForWorkOrder,
  [MaintenanceRequestStatus.InProgress]: InProgress,
  [MaintenanceRequestStatus.Pending]: Pending,
  aiProcessed: AIProcessed,
};

export const getStatusColumn = (status?: FilterKey) => {
  const { t } = useLocalization();

  // Only newRequests status is displayed in the Status column. No other status is displayed
  if (status !== 'newRequests') return undefined;

  return {
    title: t('Status'),
    render(row: MaintenanceRequestListDto) {
      const component = untrack(() => row.status);
      return <Dynamic component={statusToComponent[component]} />;
    },
  };
};
