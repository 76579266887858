import { A } from '@solidjs/router';
import { Show, For, createMemo } from 'solid-js';
import IconUser from '~/assets/images/announcement/user.svg?component-solid';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import { Button, LinkButton } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { SendEmail } from '~/components/common/SendEmail';
import { IconClock } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
export const WorkOrderDetails: Component<{
  class?: string;
  loading?: boolean;
  workOrders?: Partial<MagicDoor.Api.WorkOrderListDto[]>;
  onCreateWorkOrder: () => void;
  onSendVendorNotification: (workOrderId: string) => void;
}> = (props) => {
  const { t } = useLocalization();

  const { violation } = useViolations();

  const workOrdersWithVendors = createMemo(() =>
    props.workOrders?.map((workOrder) => {
      const vendorId = workOrder?.vendorId ?? '';
      return {
        workOrder,
        vendor: violation()?.workOrderVendors?.find((vendor) => vendor.id === vendorId),
      };
    })
  );

  return (
    <Show
      when={props.workOrders && props.workOrders.length > 0}
      fallback={
        <Panel title={t('Work orders')}>
          <div class="flex justify-center py-4">
            <Button onClick={props.onCreateWorkOrder} class="font-medium capitalize" variant="outlined">
              {t('Create work order')}
            </Button>
          </div>
        </Panel>
      }>
      <For each={workOrdersWithVendors()}>
        {({ workOrder, vendor }, index) => (
          <Panel
            title={t('Work orders')}
            actions={
              <A
                href={`/maintenance/work-orders/${workOrder?.id}`}
                class="mx-auto flex cursor-pointer items-center gap-2 text-sm capitalize text-link">
                {t('View')}
                <IconViewArrow class="size-5" />
              </A>
            }>
            <div>
              <WorkOrderItem workOrder={workOrder} vendor={vendor} loading={props.loading} />
              <Show when={props.workOrders?.length && index() < props.workOrders?.length - 1}>
                <div class="h-px w-full bg-input-border" />
              </Show>
            </div>
            <Show when={props.workOrders?.length && props.workOrders.length > 0}>
              <div class="px-4 py-3">
                {/* <Button
                  class="w-full justify-center text-sm capitalize"
                  onClick={() => props.workOrders?.[0]?.id && props.onSendVendorNotification(props.workOrders[0].id)}>
                  <IconSend class="size-5 text-white" />
                  {t('Send vendor notification')}
                </Button> */}
                <SendEmail
                  class="w-full justify-center"
                  type="vendor"
                  isAssociationViolation
                  text={t('Send vendor email')}
                  id={vendor?.id}
                  showModal
                />
              </div>
            </Show>
          </Panel>
        )}
      </For>
    </Show>
  );
};

const WorkOrderItem: Component<{
  workOrder?: MagicDoor.Api.WorkOrderListDto;
  vendor?: MagicDoor.Api.VendorDto;
  loading?: boolean;
}> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
      <IconField
        name={t('Vendor')}
        loading={props.loading}
        value={
          <A class="hover-allowed:hover:underline" href={`/maintenance/vendors/${props.vendor?.id}`}>
            {props.vendor?.name}
          </A>
        }
        src={IconUser}
      />
      <IconField name={t('Email')} loading={props.loading} value={props.vendor?.contact?.email} src={IconEmail} />
      <IconField
        name={t('Created at')}
        loading={props.loading}
        value={dateFormat(t('MM/DD/YYYY hh:mm A'), props.workOrder?.created)}
        src={IconClock}
      />
      <div class="h-px w-full bg-input-border" />

      <Show when={props.workOrder?.chatId}>
        <LinkButton
          href={`/communications/chats/${props.workOrder?.chatId}`}
          variant="outlined"
          class="mx-auto max-w-fit text-base font-medium"
          color="primary">
          {t('Work order chat')}
        </LinkButton>
      </Show>
    </div>
  );
};
