import { createMemo, createSignal, For, onMount, Show } from 'solid-js';
import NoTenantImg from '~/assets/images/empty/noTenant.svg';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeaseStatusElement, LeaseTitle } from '~/components/leases';
import { TenantTitle } from '~/components/tenants';
import { IconBan, IconSquarePen, ListView } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { DeactivateTenant } from '~/pages/tenants/components/DeactivateTenant';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
export const TenantsListView: Component = () => {
  const { t } = useLocalization();
  const { filtered, setFilter } = useTenants();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('TenantsListView');

  const [deactivatingTenant, setDeactivatingTenant] = createSignal<MagicDoor.Api.HydratedTenantDto>();
  const [viewAllStatus, setViewAllStatus] = createSignal<Array<boolean>>([]);
  // const { filterItems, filterValue, handleFilterReset } = useLeaseFilter({ status: false, date: false });

  onMount(() => setFilter({ page: page(), pageSize: pageSize() }));
  const items = createMemo(() => filtered()?.items || []);
  const properties = createMemo(() => filtered()?.properties || []);
  const units = createMemo(() => filtered()?.units || []);
  const listData = createMemo(() =>
    items().map((item, index) => ({
      ...item,
      property: properties(),
      unit: units(),
      rowIndex: index,
    }))
  );

  const getProperty = (propertyId: string) => {
    return properties().find((item) => item.id === propertyId);
  };

  const getUnit = (unitId: string) => {
    return units().find((item) => item.id === unitId);
  };

  return (
    <>
      <ListView
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search });
        }}
        title={t('Tenants Overview')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={listData()}
        viewAllSuffix="leases"
        getTotalRowCount={(data) => data.currentLeases?.length ?? 0}
        loading={filtered.loading}
        skeletonSize={10}
        searchPlaceholder={t('Search tenants')}
        rowLink={(item) => `/users/tenants/${item.id}`}
        empty={<Empty imgSrc={NoTenantImg} description={t('No tenants yet')} />}
        onViewAllClicked={(rowIndex: number) => {
          setViewAllStatus((prev) => {
            const newStatus = [...prev];
            newStatus[rowIndex] = !newStatus[rowIndex];
            return newStatus;
          });
        }}
        columns={[
          {
            title: t('Tenant'),
            headerClass: 'w-52 xl:w-64',
            render: (item) => <TenantTitle tenant={item} />,
          },
          {
            title: t('Lease'),
            key: 'currentLeases',
            render: (_, leases) => {
              return (
                <Show when={leases.length} fallback={<span class="text-xs capitalize text-text-level03">{t('No lease yet')}</span>}>
                  <For each={leases}>
                    {(lease) => {
                      return (
                        <LeaseTitle class="pt-4" lease={lease} property={getProperty(lease.propertyId)} unit={getUnit(lease.unitId)} showTenants />
                      );
                    }}
                  </For>
                </Show>
              );
            },
          },
          {
            title: t('Lease status'),
            key: 'currentLeases',
            headerClass: 'w-56',
            render: (_, leases) => (
              <div class="flex flex-col gap-10">
                <Show when={leases?.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <LeaseStatusElement lease={lease} />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Balance'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            key: 'currentLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-10">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.balance} />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Posted balance'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            key: 'currentLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-10">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.postedBalance} />}</For>
                </Show>
              </div>
            ),
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit tenant'),
            icon: IconSquarePen,
            href: `/users/tenants/${item.id}/edit`,
          },
          {
            label: t('Deactivate'),
            icon: IconBan,
            onClick: () => setDeactivatingTenant(item),
          },
        ]}
      />
      <DeactivateTenant tenant={deactivatingTenant()} onClose={setDeactivatingTenant} />
    </>
  );
};
