import fontkit from 'pdf-fontkit';
import { PDFDocument, rgb } from 'pdf-lib';
import BoldFont from '~/assets/fonts/NotoSansSC-Bold.ttf';
import RegularFont from '~/assets/fonts/NotoSansSC-Regular.ttf';
import { TransactionPaymentMethod } from '~/swagger/Api';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Color, PDFFont, PDFPage } from 'pdf-lib';
import type { CompanyDto, HydratedLeaseTransactionDto } from '~/swagger/Api';

interface PageSize {
  width: number;
  height: number;
}

export class PdfReceiptGenerator {
  private yOffset: number = 0;
  private font?: PDFFont;
  private boldFont?: PDFFont;
  private pdfDoc?: PDFDocument;
  private pageSize: PageSize = { width: 612, height: 792 };
  private margin = 50;
  private currentPage?: PDFPage = undefined;
  private fontSize = 10;
  private rowHeight = 20;
  private headerBackground = rgb(0.95, 0.95, 0.95);

  public async generate(companyInfo: CompanyDto, transaction: HydratedLeaseTransactionDto) {
    this.pdfDoc = await PDFDocument.create();
    this.pdfDoc.registerFontkit(fontkit);
    await this.embedFonts(this.pdfDoc);
    this.pageSize = this.getPageSize('Letter');
    this.addPage();
    this.drawReceipt(companyInfo, transaction);
    this.addPageNumbers();
    const pdfBytes = await this.pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  private async embedFonts(document: PDFDocument) {
    const font = await fetch(RegularFont).then((res) => res.arrayBuffer());
    const boldFont = await fetch(BoldFont).then((res) => res.arrayBuffer());
    this.font = await document.embedFont(font, { subset: true });
    this.boldFont = await document.embedFont(boldFont, { subset: true });
  }

  private addPage() {
    const page = this.pdfDoc?.addPage([this.pageSize.width, this.pageSize.height]);
    if (!page) {
      return;
    }
    this.currentPage = page;
    this.yOffset = page.getHeight() - 75;
  }

  private getPageSize(pageSize?: 'Letter' | 'A4'): PageSize {
    switch (pageSize) {
      case 'A4':
        return { width: 595.28, height: 841.89 };
      case 'Letter':
        return { width: 612, height: 792 };
      default:
        return { width: 612, height: 792 };
    }
  }

  private drawReceipt(companyInfo: CompanyDto, transaction: HydratedLeaseTransactionDto) {
    let y = this.currentPage!.getHeight() - 50;
    const pageWidth = this.currentPage!.getWidth();
    const titleFontSize = 18;
    const columnPadding = 20;
    const title = transaction.transaction ? 'Receipt' : 'Invoice';
    const titleWidth = this.boldFont!.widthOfTextAtSize(title, titleFontSize);
    this.currentPage!.drawText(title, {
      x: (pageWidth - titleWidth) / 2,
      y,
      size: titleFontSize,
      font: this.boldFont,
    });
    y -= 40;
    const columnWidth = (pageWidth - this.margin * 2 - columnPadding) / 2;
    const leftColumnX = this.margin;
    const rightColumnX = leftColumnX + columnWidth + columnPadding;
    const rowSpacing = 1.5 * this.fontSize;
    const leftColumnValues: string[] = [];
    const address = companyInfo.address;
    leftColumnValues.push(companyInfo.name);
    if (address) {
      leftColumnValues.push(this.getAddressLine1(address));
      leftColumnValues.push(this.getAddressLine2(address));
    }
    const rightColumnValues: string[] = [];
    let propertyAdress = undefined;
    let unitName = undefined;
    if (transaction.transaction?.tenant) {
      propertyAdress = transaction.transaction.property?.address;
      unitName = transaction.transaction.unit?.name;
      rightColumnValues.push(`${transaction.transaction.tenant.firstName} ${transaction.transaction.tenant.lastName || ''}`);
    } else if (transaction.bill) {
      propertyAdress = transaction.bill.property?.address;
      unitName = transaction.bill.unit?.name;
    }
    if (propertyAdress) {
      rightColumnValues.push(`${this.getAddressLine1(propertyAdress)} ${unitName || ''}`);
      rightColumnValues.push(this.getAddressLine2(propertyAdress));
    }
    leftColumnValues.forEach((value) => {
      this.currentPage!.drawText(value, { x: leftColumnX, y, size: this.fontSize, font: this.font });
      y -= rowSpacing;
    });
    y = this.currentPage!.getHeight() - 90;
    rightColumnValues.forEach((value) => {
      this.currentPage!.drawText(value, { x: rightColumnX, y, size: this.fontSize, font: this.font });
      y -= rowSpacing;
    });
    y -= 30;
    if (transaction.transaction) {
      this.printTransactionTable(this.margin, y, transaction);
    } else if (transaction.bill) {
      this.printInvoiceTable(this.margin, y, transaction);
    }
  }

  private printTransactionTable(x: number, y: number, leaseTransaction: HydratedLeaseTransactionDto) {
    const pageWidth = this.currentPage!.getWidth();
    const tableWidth = pageWidth - this.margin * 2;
    const columnWidths = [tableWidth * 0.15, tableWidth * 0.15, tableWidth * 0.2, tableWidth * 0.5];
    const transaction = leaseTransaction.transaction!;
    const transactionId = `Transaction ID: ${transaction.id}`;
    const referenceNumber = `Reference: ${transaction.reference || ''}`;
    const xPos = x;
    this.currentPage!.drawText(transactionId, {
      x,
      y,
      size: this.fontSize,
      font: this.font!,
    });
    y -= this.fontSize * 1.5;
    if (transaction.reference) {
      this.currentPage!.drawText(referenceNumber, {
        x,
        y,
        size: this.fontSize,
        font: this.font!,
      });
      y -= this.fontSize * 1.5;
    }
    y -= 20;
    this.currentPage!.drawRectangle({
      x,
      y,
      width: tableWidth,
      height: this.rowHeight,
      color: this.headerBackground,
    });
    const headers = ['Date', 'Amount', 'Method', 'Description'];
    const values = [
      dateFormat('MM/DD/YYYY', transaction.transactionDate),
      currency(transaction.amount),
      this.getPaymentMethod(transaction.transactionPaymentMethod),
      leaseTransaction.description || transaction.memo || '',
    ];
    y = this.drawHeadersAndValues(xPos, y, headers, values, columnWidths, this.fontSize);
    this.drawDoubleLine(x, y, tableWidth, this.headerBackground);
  }

  private printInvoiceTable(x: number, y: number, leaseTransaction: HydratedLeaseTransactionDto) {
    const pageWidth = this.currentPage!.getWidth();
    const tableWidth = pageWidth - this.margin * 2;
    const bill = leaseTransaction.bill!;
    const transactionId = `Invoice No: ${bill.id}`;
    const referenceNumber = `Reference: ${bill.reference || ''}`;
    this.currentPage!.drawText(transactionId, {
      x,
      y,
      size: this.fontSize,
      font: this.font!,
    });
    y -= this.fontSize * 1.5;
    if (bill.reference) {
      this.currentPage!.drawText(referenceNumber, {
        x,
        y,
        size: this.fontSize,
        font: this.font!,
      });
      y -= this.fontSize * 1.5;
    }
    y -= 20;
    this.currentPage!.drawRectangle({
      x: x,
      y,
      width: tableWidth,
      height: this.rowHeight,
      color: this.headerBackground,
    });
    const columnWidths = [tableWidth * 0.15, tableWidth * 0.55, tableWidth * 0.15, tableWidth * 0.15];
    const headers = ['Date', 'Description', 'Due Date', 'Amount'];
    const values = [
      dateFormat('MM/DD/YYYY', bill.billDate),
      leaseTransaction.description || bill.memo || '',
      dateFormat('MM/DD/YYYY', bill.dueDate),
      currency(bill.totalAmount),
    ];
    y = this.drawHeadersAndValues(x, y, headers, values, columnWidths, this.fontSize);
    this.drawDoubleLine(x, y, tableWidth, this.headerBackground);
  }

  private drawHeadersAndValues(
    x: number,
    y: number,
    headers: string[],
    values: string[],
    columnWidths: number[],
    fontSize: number
  ): number {
    const rowHeight = 20;
    const padding = 5;
    let posX = x;
    headers.forEach((header, index) => {
      const columnWidth = columnWidths[index];
      const textWidth = this.boldFont!.widthOfTextAtSize(header, fontSize);
      this.currentPage!.drawText(header, {
        x: posX + (columnWidth - textWidth) / 2,
        y: y + padding,
        size: fontSize,
        font: this.boldFont,
      });
      posX += columnWidth;
    });
    y -= rowHeight;
    let tableEndOffset: number | undefined = undefined;
    posX = x;
    values.forEach((value, index) => {
      const columnWidth = columnWidths[index];
      const wrappedLines = this.wrapText(value, columnWidth - padding * 2, this.font!, fontSize);
      let lineY = y;
      wrappedLines.forEach((line) => {
        this.currentPage!.drawText(line, {
          x: posX + (columnWidth - this.font!.widthOfTextAtSize(line, fontSize)) / 2,
          y: lineY,
          size: fontSize,
          font: this.font!,
        });
        lineY -= fontSize + 2;
        if (tableEndOffset === undefined || lineY < tableEndOffset) {
          tableEndOffset = lineY;
        }
      });
      posX += columnWidth;
    });
    y = tableEndOffset ? tableEndOffset : y - 10;
    return y;
  }

  private drawDoubleLine(x: number, y: number, width: number, color: Color) {
    this.currentPage!.drawLine({
      start: { x, y },
      end: { x: x + width, y },
      thickness: 1,
      color,
    });
    this.currentPage!.drawLine({
      start: { x, y: y - 2 },
      end: { x: x + width, y: y - 2 },
      thickness: 1,
      color,
    });
  }

  private getAddressLine1(address: any): string {
    const resultArray = [];
    if (address.streetAddress1) {
      resultArray.push(address.streetAddress1);
    }
    if (address.streetAddress2) {
      resultArray.push(address.streetAddress2);
    }
    return resultArray.join(', ');
  }

  private getAddressLine2(address: any): string {
    const resultArray = [];
    if (address.city) {
      resultArray.push(address.city);
    }
    if (address.state) {
      resultArray.push(address.state);
    }
    if (address.zipCode) {
      resultArray.push(address.zipCode);
    }
    return resultArray.join(', ');
  }

  private getPaymentMethod = (method: TransactionPaymentMethod): string => {
    switch (method) {
      case TransactionPaymentMethod.Ach:
        return 'ACH';
      case TransactionPaymentMethod.Cash:
        return 'Cash';
      case TransactionPaymentMethod.CashiersCheck:
        return "Cashier's Check";
      case TransactionPaymentMethod.Check:
        return 'Check';
      case TransactionPaymentMethod.CreditCard:
        return 'Credit Card';
      case TransactionPaymentMethod.DebitCard:
        return 'Debit Card';
      case TransactionPaymentMethod.Credit:
        return 'Credit';
      case TransactionPaymentMethod.MoneyOrder:
        return 'Money Order';
      case TransactionPaymentMethod.Wallet:
        return 'Wallet';
      default:
        return 'Other';
    }
  };

  private wrapText(text: string, maxWidth: number, font: PDFFont, fontSize: number): string[] {
    const lines: string[] = [];
    let currentLine = '';
    try {
      if (text.includes(' ')) {
        const words = text.split(' ');
        words.forEach((word) => {
          const testLine = currentLine + (currentLine === '' ? '' : ' ') + word;
          const testWidth = this.measureStringWidth(testLine, font, fontSize);
          if (testWidth > maxWidth) {
            lines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }
        });
      } else {
        for (let i = 0; i < text.length; i++) {
          const testLine = currentLine + text[i];
          const testWidth = this.measureStringWidth(testLine, font, fontSize);
          if (testWidth > maxWidth) {
            lines.push(currentLine);
            currentLine = text[i];
          } else {
            currentLine = testLine;
          }
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines;
    } catch (e) {
      console.log(e);
      return [text];
    }
  }

  private measureStringWidth(text: string, font: PDFFont, fontSize: number): number {
    let totalWidth = 0;
    for (const char of text) {
      if (/[\u4e00-\u9fff]/.test(char)) {
        totalWidth += font.widthOfTextAtSize(char, fontSize);
      } else {
        totalWidth += font.widthOfTextAtSize(char, fontSize);
      }
    }
    return totalWidth;
  }

  private addPageNumbers() {
    const pages = this.pdfDoc?.getPages();
    if (!pages) {
      return;
    }
    pages.forEach((page, index) => {
      const { width } = page.getSize();
      const pageNumberText = `Page ${index + 1} / ${pages.length}`;
      const textWidth = this.measureStringWidth(pageNumberText, this.font!, 10);
      page.drawText(pageNumberText, {
        x: width - textWidth - this.margin,
        y: this.margin - 10,
        size: 8,
        font: this.font,
      });
    });
  }
}
