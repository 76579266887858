import { mergeProps } from 'solid-js';
import { Button } from './Button';
import type { ButtonProps } from './Button';
import type { Component, JSX } from 'solid-js';

type LinkButtonProps = ButtonProps &
  JSX.AnchorHTMLAttributes<any> & {
    href: string;
  };

export const LinkButton: Component<LinkButtonProps> = Button;

export const GrayLinkButton: Component<LinkButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      class:
        'w-full border-text-level03 text-text-level01 hover-allowed:hover:border-essential-colour hover-allowed:hover:text-essential-colour py-2 px-5 text-sm leading-normal',
      variant: 'outlined',
    } as LinkButtonProps,
    originProps
  );

  return <LinkButton {...props} />;
};

export const WhiteLinkButton: Component<LinkButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      class:
        'z-10 w-fit border-solid text-white border-white hover-allowed:hover:border-slate-100 hover-allowed:hover:bg-slate-100/10 hover-allowed:hover:text-slate-100 text-xs px-4 py-2.5',
      variant: 'outlined',
      rounded: 'md',
    } as LinkButtonProps,
    originProps
  );

  return <LinkButton {...props} />;
};
