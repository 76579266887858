import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { GetOwnerTaxAmountsUseCase } from './getOwnerTaxAmountsUseCase';
import type { TaxYearDocumentRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class ChangeOwnerTaxYearUseCase extends MagicUseCase {
  public async runLogic(request: TaxYearDocumentRequest) {
    this.getState().taxInfo.selectedYear = request.year;
    await new GetOwnerTaxAmountsUseCase().execute(request);
  }
}
