import { For, Show } from 'solid-js';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import { SelectedVendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { VendorTaxAmountLine } from './VendorTaxAmountLine';

export const VendorTaxDocGenerationSummary = () => {
  const { t } = useLocalization();
  const { model: vendorTaxAmounts } = usePresenter(SelectedVendorTaxAmountPresenter);

  return (
    <Section
      title={t('Summary')}
      class="h-full px-[96px] pb-4"
      titleClass="mt-2 px-0"
      contentClass="flex h-full grow justify-center text-center normal-case">
      <Show
        when={vendorTaxAmounts()?.newOrChanged && vendorTaxAmounts()!.newOrChanged.length > 0}
        fallback={
          <div class={'flex items-center justify-center normal-case'}>
            {t('No vendors without tax documents, or with changed compensation amounts were selected')}
          </div>
        }>
        <div class="grid size-full grid-cols-2 gap-4">
          <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
            <div class="mb-2 flex flex-row justify-between p-2">
              <div>{t('Vendor')}</div>
              <div>{t('Non-employee compensation')}</div>
            </div>
            <For each={vendorTaxAmounts()?.newOrChanged}>
              {(vendor) => (
                <VendorTaxAmountLine
                  id={vendor.vendorId}
                  name={vendor.vendorName}
                  warningMessage={vendor.hasTaxId ? undefined : t('Tax ID is missing!')}
                  amount={vendor.taxAmount}
                  isAmountEditable={false}
                />
              )}
            </For>
          </div>
        </div>
      </Show>
    </Section>
  );
};
