import { A } from '@solidjs/router';
import { createSignal, For, Show } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons/LinkButton';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { SendEmail } from '~/components/common/SendEmail';
import { IconSend } from '~/components/common/SendEmail/Icons';
import { IconChevronDown, IconChevronUp, IconMail, IconMessage, IconPhone } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component, JSX } from 'solid-js';

export interface OwnerDropdownSummaryProps {
  loading?: boolean;
  owners?: MagicDoor.Api.OwnerDto[];
  title?: string;
  renderAdditionalFields?: (item: MagicDoor.Api.OwnerDto) => JSX.Element;
  bottomContent?: JSX.Element;
  hideEmail?: boolean;
  hidePhone?: boolean;
  hideSendEmail?: boolean;
  hideSendChat?: boolean;
  isAssociationViolation?: boolean;
}

export const OwnerDropdownSummary: Component<OwnerDropdownSummaryProps> = (props) => {
  const { t } = useLocalization();
  const [show, setShow] = createSignal<boolean>(true);

  const getTitle = () => {
    if (props.title) return props.title;
    const ownerCount = props.owners?.length || 0;
    return ownerCount === 1 ? t('Owner') : t('Owners');
  };

  return (
    <Panel
      header={
        <div onClick={() => setShow(!show())} class="flex items-center justify-between border-b px-6 py-4">
          <span class="text-lg font-semibold">{getTitle()}</span>
          <Show when={show()} fallback={<IconChevronDown />}>
            <IconChevronUp />
          </Show>
        </div>
      }>
      <Show when={props.owners?.length !== 0} fallback={<div class="p-4 normal-case">{t('There are no associated owners')}</div>}>
        <Show when={show()}>
          <div>
            <div class="flex flex-col gap-4 p-4">
              <For each={props.owners}>
                {(item) => (
                  <div class="flex flex-col gap-y-3 rounded-lg border p-3">
                    <div class="flex items-center gap-2 border-b pb-3">
                      <Avatar name={`${item.firstName} ${item.lastName}`} />
                      <A class="flex gap-1 hover-allowed:hover:underline" href={`/users/owners/${item.id}`}>
                        <span>{item.firstName}</span>
                        <span>{item.lastName}</span>
                      </A>
                    </div>

                    <Show when={!props.hidePhone}>
                      <IconField name={t('Phone')} loading={props.loading} value={item.phone} src={IconPhone} />
                    </Show>
                    <Show when={!props.hideEmail}>
                      <div class="flex items-center justify-between">
                        <IconField name={t('Email')} loading={props.loading} value={item.email} src={IconMail} />
                        <Show when={!props.hideSendEmail && item.email}>
                          <SendEmail
                            type="owner"
                            id={item.id}
                            showModal
                            isAssociationViolation={props.isAssociationViolation}
                            icon={<IconSend class="size-5 text-essential-colour hover-allowed:hover:text-essential-colour/80" />}
                            class="max-w-fit border-none bg-white p-0 text-essential-colour hover-allowed:hover:bg-white"
                          />
                        </Show>
                      </div>
                    </Show>

                    <Show when={!props.hideSendChat && item.chatId}>
                      <div class="mt-2 ">
                        <LinkButton
                          href={`/users/owners/${item.id}/communications`}
                          variant="outlined"
                          class="flex w-full items-center justify-center text-base font-medium"
                          color="primary">
                          <IconMessage class="size-5" />
                          {t('Message')} {item.firstName} {item.lastName}
                        </LinkButton>
                      </div>
                    </Show>

                    {props.renderAdditionalFields?.(item)}
                  </div>
                )}
              </For>
            </div>
            {props.bottomContent}
          </div>
        </Show>
      </Show>
    </Panel>
  );
};
