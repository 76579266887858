import { createSignal, For, Show, mergeProps, createEffect } from 'solid-js';
import { Dropdown } from '~/components/common/Dropdown';
import { IconCheck } from '~/components/ui';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

const ToggleButton: Component<{ expanded: boolean; onClick: () => void }> = (props) => {
  return (
    <button onClick={() => props.onClick()} class="mr-1 size-5 rounded-full bg-white transition-all hover-allowed:hover:bg-inputbox-bg">
      {props.expanded ? '-' : '+'}
    </button>
  );
};

const TreeDropdown: Component<{
  node: DataPathNode;
  level?: number;
  onSelect: (node: DataPathNode, path: string) => void;
  selectedPath: string;
  currentPath: string;
}> = (_props) => {
  const props = mergeProps({ level: 0 }, _props);
  const [expanded, setExpanded] = createSignal<boolean>(false);

  const toggleExpand = () => {
    setExpanded(!expanded());
  };

  const getFullPath = () => {
    return props.currentPath ? `${props.currentPath}/${props.node.key}` : props.node.key;
  };

  createEffect(() => {
    setExpanded(props.selectedPath.includes(getFullPath()));
  });

  const isSelected = () => {
    return getFullPath() === props.selectedPath;
  };

  return (
    <div class={`ml-${props.level}`}>
      <div class="flex items-center">
        <Show when={props.node.children && props.node.children.length > 0}>
          <ToggleButton expanded={expanded()} onClick={toggleExpand} />
        </Show>
        <Show
          when={props.node.canSelect}
          fallback={<span class={cn('grow p-2', isSelected() ? 'bg-gray-200' : 'bg-white')}>{props.node.name}</span>}>
          <button
            onClick={() => props.onSelect(props.node, getFullPath())}
            class={cn(
              'inline-flex flex-1 cursor-pointer items-center justify-between rounded-lg bg-white p-2 text-left transition-all hover-allowed:hover:bg-gray-200'
            )}>
            <span>{props.node.name}</span>
            <Show when={isSelected()}>
              <IconCheck class="ml-auto size-4 text-essential-colour" />
            </Show>
          </button>
        </Show>
      </div>
      <Show when={expanded() && props.node.children && props.node.children.length > 0}>
        <div class={`ml-2`}>
          <For each={props.node.children}>
            {(child) => (
              <TreeDropdown
                node={child}
                level={props.level + 1}
                onSelect={props.onSelect}
                selectedPath={props.selectedPath}
                currentPath={getFullPath()}
              />
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};

export const DropdownWithTree: Component<{
  data?: DataPathNode[];
  class?: string;
  onSelect?: (node: DataPathNode) => void;
  selectedNode?: DataPathNode;
  enabled?: boolean;
  triggerElement: JSX.Element;
}> = (props) => {
  const [selectedPath, setSelectedPath] = createSignal<string>('');

  const handleSelect = (node: DataPathNode, path: string) => {
    setSelectedPath(path);
    props.onSelect?.(node);
  };

  const findPath = (node: DataPathNode, targetNode: DataPathNode, currentPath: string): string | null => {
    if (node.key === targetNode.key) return currentPath;
    if (node.children) {
      for (const child of node.children) {
        const path = findPath(child, targetNode, `${currentPath}/${child.key}`);
        if (path) return path;
      }
    }
    return null;
  };

  createEffect(() => {
    if (props.selectedNode) {
      for (const rootNode of props.data || []) {
        const path = findPath(rootNode, props.selectedNode, rootNode.key);
        if (path) {
          setSelectedPath(path);
          break;
        }
      }
    }
  });

  return (
    <Dropdown disabled={!props.enabled} triggerElement={props.triggerElement} align="end">
      {(open, setIsOpen) => (
        <Show when={open}>
          <div class="w-[200px]">
            <For each={props.data}>
              {(node) => (
                <TreeDropdown
                  node={node}
                  onSelect={(node, path) => {
                    handleSelect(node, path);
                    setIsOpen(false);
                  }}
                  selectedPath={selectedPath()}
                  currentPath=""
                />
              )}
            </For>
          </div>
        </Show>
      )}
    </Dropdown>
  );
};
