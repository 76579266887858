import { A, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createSignal, onMount, onCleanup, createEffect, on, untrack } from 'solid-js';
import NoSearchData from '~/assets/images/empty/noSearchData.svg';
import { Empty } from '~/components/common/Empty';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { commaNumber } from '~/utils/number';
import { debounce } from '~/utils/tool';
import reportStyles from './report.css?inline';
import type { JSX } from 'solid-js';
import type { ReportGroupHeader } from '~/swagger/Api';

export enum DataType {
  Number = 'number',
  String = 'string',
  Date = 'date',
}

export type Column<T = LineItem> = {
  label: string;
  type: 'number' | 'string' | 'date';
  render: (item: T) => JSX.Element;
  style?: string | Record<string, string>;
};

export type LineItem =
  | {
      type?: 'item' | 'total';
      label: JSX.Element;
      level: number;
      values?: Record<string, string | number | boolean | null | undefined>;
      metadata?: Record<string, unknown>;
    }
  | { type: 'empty'; label?: never; level?: never; values?: never; metadata?: never };

export const ReportTable = <T extends LineItem>(props: { label: JSX.Element; columns: Column<T>[]; lines: T[]; class?: string }) => {
  const { t } = useLocalization();
  let tableWrapperRef: HTMLDivElement | undefined;
  const [isDragging, setIsDragging] = createSignal(false);
  const [startX, setStartX] = createSignal(0);
  const [scrollLeft, setScrollLeft] = createSignal(0);
  const [isScrollable, setIsScrollable] = createSignal(false);
  const [isHeaderFixed, setIsHeaderFixed] = createSignal(false);
  const headerRef = { current: null as HTMLTableSectionElement | null };
  const tableContainerRef = { current: null as HTMLDivElement | null };
  const [tableOffset, setTableOffset] = createSignal({ left: 0, width: 0 });
  const [tableThWidth, setTableThWidth] = createSignal<number[]>([]);

  const checkScrollable = () => {
    if (tableWrapperRef) {
      setIsScrollable(tableWrapperRef.scrollWidth > tableWrapperRef.clientWidth);
    }
  };

  const getInitialTableThWidths = () => {
    const result: number[] = [];
    tableWrapperRef?.querySelectorAll('thead th')?.forEach((item) => {
      if (item instanceof HTMLElement) {
        result.push(item?.offsetWidth);
      }
    });
    setTableThWidth(result);
  };

  const setTableThWidths = (reset = false) => {
    tableWrapperRef?.querySelectorAll('thead th').forEach((item, index) => {
      if (item instanceof HTMLElement) {
        item.style.minWidth = reset ? '' : untrack(() => tableThWidth()[index]) + 'px';
      }
    });
  };

  const handleResize = debounce(() => {
    if (!tableContainerRef.current) return;
    tableContainerRef.current.style.display = 'none';
    setTableThWidths(true);
    tableContainerRef.current.offsetHeight;
    tableContainerRef.current.style.display = '';
    tableContainerRef.current.offsetHeight;
    getInitialTableThWidths();
    setTableThWidths();
  }, 16);

  onMount(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    if (tableWrapperRef) {
      tableWrapperRef.addEventListener('mousedown', startDragging);
      tableWrapperRef.addEventListener('mousemove', drag);
      tableWrapperRef.addEventListener('mouseup', stopDragging);
      tableWrapperRef.addEventListener('mouseleave', stopDragging);
      tableWrapperRef.addEventListener('touchstart', startDragging);
      tableWrapperRef.addEventListener('touchmove', drag);
      tableWrapperRef.addEventListener('touchend', stopDragging);
      getInitialTableThWidths();
    }

    const handleScroll = () => {
      if (!headerRef.current || !tableContainerRef.current) return;

      const tableRect = tableContainerRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY;
      const tableTop = tableRect.top + scrollTop;

      setIsHeaderFixed(scrollTop > tableTop - 64);
      setTableOffset({
        left: tableRect.left,
        width: tableWrapperRef?.clientWidth || 0,
      });
      setTableThWidths();
    };

    const handleHorizontalScroll = () => {
      if (tableWrapperRef) {
        setScrollLeft(tableWrapperRef.scrollLeft);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    tableWrapperRef?.addEventListener('scroll', handleHorizontalScroll);

    onCleanup(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      tableWrapperRef?.removeEventListener('scroll', handleHorizontalScroll);
    });

    createEffect(on(isHeaderFixed, (isFixed) => isFixed && setTableThWidths(), { defer: true }));
  });

  onCleanup(() => {
    window.removeEventListener('resize', checkScrollable);
    if (tableWrapperRef) {
      tableWrapperRef.removeEventListener('mousedown', startDragging);
      tableWrapperRef.removeEventListener('mousemove', drag);
      tableWrapperRef.removeEventListener('mouseup', stopDragging);
      tableWrapperRef.removeEventListener('mouseleave', stopDragging);
      tableWrapperRef.removeEventListener('touchstart', startDragging);
      tableWrapperRef.removeEventListener('touchmove', drag);
      tableWrapperRef.removeEventListener('touchend', stopDragging);
    }
  });

  const startDragging = (e: MouseEvent | TouchEvent) => {
    if (!isScrollable()) return;
    setIsDragging(true);
    setStartX(e instanceof MouseEvent ? e.pageX : e.touches[0].pageX);
    setScrollLeft(tableWrapperRef?.scrollLeft || 0);
    if (tableWrapperRef) {
      tableWrapperRef.style.userSelect = 'none';
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
    if (tableWrapperRef) {
      tableWrapperRef.style.userSelect = '';
    }
  };

  const drag = (e: MouseEvent | TouchEvent) => {
    if (!isDragging() || !isScrollable()) return;
    e.preventDefault();
    const x = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX;
    const walk = (x - startX()) * 2;
    if (tableWrapperRef) {
      tableWrapperRef.scrollLeft = scrollLeft() - walk;
    }
  };

  return (
    <div ref={(el) => (tableContainerRef.current = el)} class={cn('relative w-full pb-2 text-sm text-title-gray', props.class)}>
      <style>{reportStyles}</style>
      <div ref={tableWrapperRef} class={cn('thinscroll w-full overflow-x-auto', isScrollable() && 'cursor-grab active:cursor-grabbing')}>
        <Show when={props.lines && props.lines.length}>
          <table data-slot="report" class="w-full">
            {/* Fixed header that shows when scrolled */}
            <Show when={isHeaderFixed()}>
              <thead
                class="fixed top-[64px] z-[11] overflow-hidden bg-white shadow-md"
                style={{
                  width: `${tableOffset().width}px`,
                  left: `${tableOffset().left}px`,
                }}>
                <tr>
                  <th data-slot="label" class="sticky left-0 z-10 whitespace-nowrap bg-white px-4 py-2">
                    {props.label}
                  </th>
                  <For each={props.columns}>
                    {(col) => (
                      <th
                        data-slot="column"
                        data-type={col.type}
                        style={{
                          ...(typeof col.style === 'object' ? col.style : {}),
                          transform: `translateX(-${scrollLeft()}px)`,
                        }}
                        class="whitespace-nowrap bg-white px-4 py-2">
                        {col.label}
                      </th>
                    )}
                  </For>
                </tr>
              </thead>
            </Show>

            {/* Original header that maintains table layout */}
            <thead ref={(el) => (headerRef.current = el)} data-slot="head" class={cn('w-full bg-white', isHeaderFixed() && 'invisible')}>
              <tr>
                <th data-slot="label" class="sticky left-0 z-10 whitespace-nowrap bg-white px-4 py-2">
                  {props.label}
                </th>
                <For each={props.columns}>
                  {(col) => (
                    <th data-slot="column" data-type={col.type} style={col.style} class="whitespace-nowrap px-4 py-2">
                      {col.label}
                    </th>
                  )}
                </For>
              </tr>
            </thead>
            <Show when={props.lines && props.lines.length}>
              <tbody data-slot="body">
                <For each={props.lines}>
                  {(line) =>
                    line.type === 'empty' ? (
                      <tr data-slot="line" data-type={line.type}>
                        <td data-slot="label" class="sticky left-0 z-10 bg-white">
                          &nbsp;
                        </td>
                        <For each={props.columns}>{(col) => <td style={col.style} />}</For>
                      </tr>
                    ) : (
                      <tr data-slot="line" data-type={line.type} data-level={line.level} style={{ '--level': line.level }}>
                        <td data-slot="label" class="sticky left-0 z-10 bg-white">
                          {line.label}
                        </td>
                        <For each={props.columns}>
                          {(col) => (
                            <td data-slot="value" data-type={col.type} style={col.style}>
                              {col.render(line)}
                            </td>
                          )}
                        </For>
                      </tr>
                    )
                  }
                </For>
              </tbody>
            </Show>
          </table>
        </Show>

        <Show when={!props.lines || !props.lines.length}>
          <div class="flex w-full items-center justify-center">
            <Empty imgSrc={NoSearchData} description={t('No data yet')} />
          </div>
        </Show>
      </div>
    </div>
  );
};

const isNullOrEmpty = (input: Record<string, number | null> | undefined) => input == null || Object.keys(input).length === 0;

export const parseLines = (lines?: MagicDoor.Api.ChartOfAccountReportLineDto[], level = 1): LineItem[] => {
  const { t } = useLocalization();
  if (lines == null || lines.length == 0) return [];

  return lines.flatMap<LineItem>((line) => {
    const label = line.chartOfAccount?.name as string;
    const base = {
      level,
      values: isNullOrEmpty(line.groups) ? undefined : line.groups,
      metadata: { chartOfAccountId: line.chartOfAccount?.id },
    };
    if (line.groupTotal == null) return { label: t(label), ...base };
    const upperLabel = t(label).toUpperCase();
    return [
      { label: upperLabel, ...base },
      ...parseLines(line.children, level + 1),
      {
        label: t(`Total {type}`, { type: upperLabel }),
        type: 'total',
        level,
        values: isNullOrEmpty(line.groupTotal) ? undefined : line.groupTotal,
      },
    ];
  });
};

function formatValueByType(
  _text: string | undefined | null | number,
  columnType: Column['type']
  // , lineItemType: LineItem['type']
) {
  if (columnType === 'date') return dayjs(_text).format('MM/DD/YYYY');
  if (columnType === 'number') return _text ? commaNumber(_text) : _text;

  return _text;
}

export const parseColumns = (headers: Array<ReportGroupHeader & Partial<Pick<Column, 'type'>>> | undefined): Column[] => {
  if (headers == null) return [];
  const { t } = useLocalization();
  const [searchParams] = useSearchParams();

  return headers.map((h) => {
    const type = h.type ?? 'number';
    return {
      label: t(h.name as string),
      type,
      render: (item: LineItem) => (
        <Show when={item.values}>
          {(values) => {
            const href = () => {
              if (typeof item.metadata?.chartOfAccountId !== 'string') return;
              const query = new URLSearchParams(searchParams);
              query.set('chartOfAccountIds', item.metadata.chartOfAccountId);
              return `/reports/general-ledger?${query}`;
            };

            const _text = formatValueByType((values() as Record<string, any>)[h.id as string], type);

            return (
              <Show when={href()} fallback={_text}>
                {(href) => (
                  <A class="hover-allowed:hover:underline" href={href()}>
                    {_text}
                  </A>
                )}
              </Show>
            );
          }}
        </Show>
      ),
    };
  });
};
