import { useSearchParams } from '@solidjs/router';
import { createMemo, createSignal, Show, For, createEffect } from 'solid-js';
import IconLease from '~/assets/images/announcement/leases.svg?component-solid';
import IconOwner from '~/assets/images/announcement/owners.svg?component-solid';
import IconTenant from '~/assets/images/announcement/tenants.svg?component-solid';
import IconVendor from '~/assets/images/announcement/vendors.svg?component-solid';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { OptionButton } from '~/components/common/OptionButton';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
import type { Variants } from '~/components/search-select/Context';
type SelectTargetProps = {
  model: any;
  onUpdate: (updates: Partial<any>) => void;
  errors: any;
};

export const SelectTarget: Component<SelectTargetProps> = (props) => {
  const { t } = useLocalization();
  const [query] = useSearchParams();
  const [targetSelected, setTargetSelected] = createSignal(false);
  const [initialSelected, setInitialSelected] = createSignal<any[]>([]);
  const [initialExpandedKeys, setInitialExpandedKeys] = createSignal<string[]>([]);

  const targetTypes = createMemo(() => [
    { value: 'lease', label: t('Leases'), Icon: IconLease },
    { value: 'tenant', label: t('Tenants'), Icon: IconTenant },
    { value: 'owner', label: t('Owners'), Icon: IconOwner },
    { value: 'vendor', label: t('Vendors'), Icon: IconVendor },
  ]);

  const handleTargetTypeChange = (value: string) => {
    setTargetSelected(false);
    const newUpdates = {
      targetType: value,
      portfolioIds: [] as string[],
      propertyIds: [],
      unitIds: [],
      selectionType: null,
      hasBalanceDue: '',
    };
    if (query.portfolioId) {
      newUpdates.portfolioIds = [query.portfolioId];
    }
    props.onUpdate(newUpdates);
    setTargetSelected(true);
  };

  const handleSelectionTypeChange = (value: string) => {
    props.onUpdate({
      selectionType: value,
      portfolioIds: [],
      propertyIds: [],
      unitIds: [],
    });
  };

  const handleIdSelection = async (ids: string | string[], type: 'portfolioIds' | 'propertyIds' | 'unitIds') => {
    const idArray = Array.isArray(ids) ? ids : [ids];
    props.onUpdate({ [type]: idArray });
  };

  const handleBalanceDueChange = (value: any) => {
    props.onUpdate({ hasBalanceDue: value === '' ? undefined : value === 'true' });
  };

  const balanceDueOptions = [
    { value: '', label: emptyPlaceholder },
    { value: 'true', label: t('Yes') },
    { value: 'false', label: t('No') },
  ];

  const enabledTypes = createMemo(() => {
    return props.model.targetType === 'owner'
      ? [LevelSearch.Portfolio, LevelSearch.Property]
      : [LevelSearch.Portfolio, LevelSearch.Property, LevelSearch.Unit];
  });

  const onSelect = (_: unknown, variants: Variants) => {
    if (variants.singleType) {
      handleSelectionTypeChange(variants.singleType);
      handleIdSelection(variants.singleTypeIds, `${variants.singleType}Ids` as 'portfolioIds' | 'propertyIds' | 'unitIds');
    } else {
      handleSelectionTypeChange('');
    }
  };

  createEffect(() => {
    if (query.targetType) {
      handleTargetTypeChange(query.targetType);
    }
    if (query.selectionType) {
      handleSelectionTypeChange(query.selectionType);
    }

    switch (query.selectionType) {
      case LevelSearch.Portfolio:
        handleIdSelection([query.portfolioId], 'portfolioIds');
        setInitialSelected([
          {
            id: query.portfolioId,
            name: query.name,
            __type: LevelSearch.Portfolio,
            __lazy: true,
            __keyPath: [query.portfolioId],
          },
        ]);
        break;
      case LevelSearch.Property:
        handleIdSelection([query.propertyId], 'propertyIds');
        setInitialSelected([
          {
            id: query.propertyId,
            displayName: query.name,
            __type: LevelSearch.Property,
            __lazy: true,
            __keyPath: [query.portfolioId, query.propertyId],
          },
        ]);
        setInitialExpandedKeys([query.portfolioId]);
        break;
      case LevelSearch.Unit:
        handleIdSelection([query.unitId], 'unitIds');
        setInitialSelected([
          {
            id: query.unitId,
            name: query.name,
            __type: LevelSearch.Unit,
            __lazy: true,
            __keyPath: [query.portfolioId, query.propertyId, query.unitId],
          },
        ]);
        setInitialExpandedKeys([query.portfolioId, query.propertyId]);
        break;
    }
  });

  return (
    <div class="flex w-full flex-col gap-10 pt-10">
      <h4 class="w-full text-lg font-semibold">{t('Select target')}</h4>

      <div class="flex w-full flex-wrap gap-2">
        <For each={targetTypes()}>
          {(type) => (
            <OptionButton
              class="grow"
              label={type.label}
              isActive={props.model.targetType === type.value}
              Icon={type.Icon}
              onClick={() => handleTargetTypeChange(type.value)}
            />
          )}
        </For>
      </div>
      <Show when={props.errors.targetType}>
        <p class="w-full text-red-500">{props.errors.targetType}</p>
      </Show>

      <Show when={targetSelected()}>
        <Show when={props.model.targetType !== 'vendor'}>
          <div class="flex flex-col gap-4">
            <div class="flex w-full items-center">
              <LabeledSelect
                class="w-full md:w-auto"
                label={t('Has balance due')}
                options={balanceDueOptions}
                value={props.model.hasBalanceDue === undefined ? '' : props.model.hasBalanceDue.toString()}
                onInput={handleBalanceDueChange}
              />
            </div>

            <div class="flex w-full items-center">
              <Checkbox
                showLabel
                label={t('Include ended lease')}
                checked={props.model.includeEndedLease}
                onInput={(checked) => props.onUpdate({ includeEndedLease: checked })}
              />
            </div>
          </div>
          <LabeledLeveledSearchSelect
            initialExpandedKeys={initialExpandedKeys()}
            initialSelected={initialSelected()}
            label={
              props.model.targetType === 'owner' ? t('Select portfolios or properties') : t('Select portfolios or properties or units')
            }
            multiple
            enabledTypes={enabledTypes()}
            onChange={onSelect}
            singleTypeOnly
          />
        </Show>
      </Show>

      <Show when={props.errors.portfolioIds}>
        <p class="w-full text-red-500">{props.errors.portfolioIds}</p>
      </Show>
    </div>
  );
};
