import { Show, For } from 'solid-js';
import IconForm from '~/assets/images/common/form.svg';
import IconPending from '~/assets/images/common/pending.svg';
import IconPlaid from '~/assets/third-parties/plaidSm.svg';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';
interface PlaidPanelProps {
  plaidUI: any;
  bankAccount: any;
  isLoading?: boolean;
}

export const PlaidPanel = (props: PlaidPanelProps) => {
  const { t } = useLocalization();
  const { fetchBankAccountById } = useBankAccount();

  const limits = [
    { label: 'Max single transfer credit amount', field: 'maxSingleTransferCreditAmount' },
    { label: 'Max single transfer debit amount', field: 'maxSingleTransferDebitAmount' },
    { label: 'Max daily credit amount', field: 'maxDailyCreditAmount' },
    { label: 'Max daily debit amount', field: 'maxDailyDebitAmount' },
    { label: 'Max monthly credit amount', field: 'maxMonthlyCreditAmount' },
    { label: 'Max monthly debit amount', field: 'maxMonthlyDebitAmount' },
  ];

  const LimitDisplay = (props: { label: string; value: any }) => {
    const { t } = useLocalization();

    return (
      <div class="flex justify-between text-text-level02">
        {t(props.label)}:
        <Show when={props.value !== undefined} fallback={<div class="font-semibold">{emptyPlaceholder}</div>}>
          <span class="font-semibold">{currency(props.value || '')}</span>
        </Show>
      </div>
    );
  };

  return (
    <Panel title={t('Plaid')}>
      <div class="flex flex-col justify-center gap-2 p-3 ">
        <div
          class={`${props.plaidUI.getPlaidStatusBgColor(props.bankAccount?.plaid?.originatorStatus)} flex justify-center rounded-md py-4 `}>
          <Show when={!props.plaidUI.shouldPerformQuestionnaire() && !props.plaidUI.isPlaidConnected()}>
            <Button
              onClick={() =>
                props.plaidUI.linkPlaidWithAccountAndRefresh(props.bankAccount?.id, () => fetchBankAccountById(props.bankAccount?.id))
              }
              disabled={props.plaidUI.isPlaidLoading()}
              size="xs"
              class="rounded-full border-none bg-[#4B73FF] hover-allowed:hover:bg-[#4B73FF]/90">
              <img src={IconPlaid} />
              <span>{t('Link Plaid')}</span>
            </Button>
          </Show>
          <Show when={props.plaidUI.shouldPerformQuestionnaire() && props.plaidUI.notSubmittedOriginator()}>
            <Tooltip message={t('Redirect to Plaid')} class="cursor-pointer" align="top">
              <Button
                disabled={props.plaidUI.isPlaidLoading()}
                onClick={() => props.plaidUI.openPlaidLink(props.bankAccount?.id)}
                loading={props.plaidUI.isPlaidLinking()}
                class="rounded-full"
                size="xs">
                <img src={IconForm} />
                <span>{t('Start questionnaire')}</span>
              </Button>
            </Tooltip>
          </Show>
          <Show when={props.plaidUI.pendingOriginator()}>
            <Button class="flex gap-1 font-semibold text-[#FF8618]" variant="text" size="xs">
              <img src={IconPending} />
              <span>{t('Pending')}</span>
            </Button>
          </Show>
          <Show when={props.plaidUI.approvedOriginator()}>
            <Button variant="text" size="xs" class="font-semibold text-[#28D9A5]">
              {t('Connected')}
            </Button>
          </Show>
        </div>
        <div class="flex flex-col gap-1 text-sm">
          <div class="flex justify-between text-text-level02">
            {t('Pending')}:
            <Show when={props.bankAccount?.plaid.pending} fallback={<div class="font-semibold">--</div>}>
              <span class="font-semibold"> {currency(props.bankAccount?.plaid.pending || '')}</span>
            </Show>
          </div>
          <div class="flex justify-between text-text-level02">
            {t('Available')}:
            <Show when={props.bankAccount?.plaid.available} fallback={<div class="font-semibold">--</div>}>
              <span class="font-semibold">{currency(props.bankAccount?.plaid.available || '')}</span>
            </Show>
          </div>
          <div class="font-semibold text-text-level01">{t('Limits')}</div>
          <For each={limits}>{(limit) => <LimitDisplay label={limit.label} value={props.bankAccount?.plaid?.limits?.[limit.field]} />}</For>
        </div>
      </div>
    </Panel>
  );
};
