import { RentalApplicationPaymentSettingsProvider, BankAccountsListProvider } from '~/contexts/local';
import { RentalApplicationCard } from './RentalApplicationCard';
export const ApplyRentalApplication = () => {
  return (
    <RentalApplicationPaymentSettingsProvider>
      <BankAccountsListProvider>
        <RentalApplicationCard />
      </BankAccountsListProvider>
    </RentalApplicationPaymentSettingsProvider>
  );
};
