import { createSignal, createEffect, createMemo, For, Show } from 'solid-js';
import { IconCheck, IconChevronDown, IconLoader } from '~/components/ui/Icons';
import { Popover } from '~/components/ui/Popover';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { JSX } from 'solid-js';
import type { Promisable } from '~/utils/types';

type Option<T> = { type: 'group'; label: string } | { type?: 'option'; label: string; value: T };

export type SelectOptions<T> = Option<T>[] | (() => Promisable<Option<T>[]>);

export type SelectProps<T, U extends undefined | boolean = undefined, V = U extends true ? T[] : T> = {
  options: SelectOptions<T>;
  renderOption?: (option: Option<T> & { selected: boolean }) => JSX.Element;
  prefix?: string;
  value?: V;
  onChange?: (value: V, currentSelected: T) => void;
  multiple?: U;
  filterable?: boolean;
  placeholder?: string;
  class?: string;
  popoverClass?: string;
  disabled?: boolean;
  freeInput?: boolean;
  id?: string;
};

const AS_YOUR_INPUT = 'as your input: ';

export const Select = <T, U extends undefined | boolean = undefined, V = U extends true ? T[] : T>(props: SelectProps<T, U, V>) => {
  const [options, setOptions] = createSignal<Option<T>[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [search, setSearch] = createSignal<string>('');
  const [value, setValue] = createSignal<V>();
  const { t } = useLocalization();

  const [isInputFocused, setIsInputFocused] = createSignal(false);
  const [isSelecting, setIsSelecting] = createSignal(false);

  createEffect(() => setValue(props.value as any));

  createEffect(() => {
    if (typeof props.options !== 'function') {
      return setOptions(props.options);
    }
    setLoading(true);
    Promise.resolve(props.options())
      .then(setOptions)
      .finally(() => setLoading(false));
  });

  const selectdOptions = createMemo(() => {
    const values = value();
    return options().filter((option) => {
      if (option.type === 'group') return false;
      if (!Array.isArray(values)) {
        return option.value === value();
      }
      return values?.includes(option.value);
    });
  });

  const selectedLabels = () => {
    const selected = selectdOptions();
    const text = search();

    if (props.freeInput && text && selected.length === 0) {
      return text;
    }

    return selected.map((option) => option.label).join(', ');
  };

  const filterOptions = createMemo(() => {
    const text = search();
    let filtered = options();
    let hasSameOption = false;

    if (text != null) {
      filtered = options().filter((option) => {
        const lowerOption = option.label.toLowerCase();
        const lowerText = text.toLowerCase();
        if (lowerOption === lowerText) {
          hasSameOption = true;
        }
        return option.type === 'group' || lowerOption.includes(lowerText);
      });
    }

    if (props.freeInput && text && !hasSameOption) {
      filtered.push({ type: 'option', label: `${t(AS_YOUR_INPUT)}${text}`, value: text } as Option<T>);
    }

    return filtered;
  });

  const isTempOption = (value: string) => {
    return filterOptions().filter((item) => item.type === 'option' && item.value === value).length === 1;
  };

  const handleSelect = (e: MouseEvent, input: T) => {
    const isSelectTempOption = isTempOption(input as string);
    if (props.freeInput && typeof input === 'string' && isSelectTempOption) {
      const userInput = input.replace(t(AS_YOUR_INPUT + ' '), '');
      setValue(() => userInput as unknown as V);
      props.onChange?.(userInput as unknown as V, userInput as T);
    } else {
      let newValue: V;
      if (props.multiple) {
        if (input == null) {
          newValue = setValue(() => undefined as V);
        } else {
          const values = (value() as T[] | undefined) || [];
          if (values.includes(input)) {
            newValue = setValue(() => values.filter((v) => v !== input) as V);
          } else {
            newValue = setValue(() => [...(values || []), input] as V);
          }
          e.preventDefault();
        }
      } else {
        newValue = setValue(() => input as unknown as V);
      }
      props.onChange?.(newValue as V, input);
      setSearch('');
    }
  };

  const handleOpenOnly = (e: MouseEvent) => {
    if (!props.filterable) return;
    const clicked = e.target as HTMLElement;
    if (clicked.tagName !== 'INPUT') return;
    const trigger = e.currentTarget as HTMLElement;
    if (trigger.ariaExpanded === 'true') {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  const getValue = () => {
    if (!isInputFocused()) {
      return search() || selectedLabels();
    } else {
      return search();
    }
  };

  const findOptionByValue = (searchValue: T) =>
    options().find(
      (option): option is { type?: 'option'; label: string; value: T } => option.type !== 'group' && option.value === searchValue
    );

  return (
    <Popover onOutsideClick={(e: MouseEvent) => (e.currentTarget as Node).contains(e.relatedTarget as Node) && e.preventDefault()}>
      <Popover.Trigger
        id={`${props.id}-button`}
        class={cn(
          'group relative flex w-full items-center gap-1 overflow-hidden rounded-md border border-input-border bg-light-gray px-2 py-1 text-sm text-title-gray outline-none placeholder:text-auxiliary-text focus-within:ring-1 focus-within:ring-primary aria-expanded:ring-1 aria-expanded:ring-primary',
          props.class
        )}
        disabled={props.disabled}
        onClick={handleOpenOnly}>
        <Show when={props.prefix}>
          <span class="select-none whitespace-nowrap text-text-level03">{props.prefix}:</span>
        </Show>
        <input
          id={`${props.id}-input`}
          class="w-full truncate bg-transparent p-1 outline-none read-only:cursor-pointer read-only:select-none disabled:cursor-not-allowed"
          value={getValue()}
          readOnly={!props.filterable}
          placeholder={props.placeholder || emptyPlaceholder}
          onFocus={() => {
            if (!props.filterable) return;
            setIsInputFocused(true);
          }}
          onBlur={(e) => {
            if (!props.filterable) return;
            const inputValue = e.target.value;

            if (!isSelecting()) {
              setIsInputFocused(false);

              if (props.freeInput && (inputValue === '' || isTempOption(inputValue)) && value() && !isTempOption(value() as string)) {
                const selectedOption = findOptionByValue(value() as T);
                setSearch(selectedOption?.label ?? '');
              } else {
                setSearch('');
              }
            }
          }}
          onInput={(e) => {
            const inputValue = e.target.value;
            setSearch(inputValue);
          }}
          disabled={props?.disabled}
        />
        <Show when={!loading()} fallback={<IconLoader class="pointer-events-none size-5 animate-spin text-title-gray" />}>
          <IconChevronDown class="pointer-events-none size-5 cursor-pointer text-title-gray transition-transform group-aria-expanded:rotate-180" />
        </Show>
      </Popover.Trigger>
      <Popover.Content
        as="ul"
        align="start"
        class={cn(
          'thinscroll my-1 flex max-h-dropdown min-w-[--reference-width] flex-col gap-1 overflow-auto rounded-md bg-white p-2 text-sm shadow-md ring-1 ring-partingline',
          props.popoverClass
        )}>
        <Show
          when={!loading()}
          fallback={
            <li>
              <IconLoader class="mx-auto my-4 size-8 animate-spin text-essential-colour" />
            </li>
          }>
          <For each={filterOptions()}>
            {(option, index) => (
              <Show when={option.type !== 'group'} fallback={<li class="px-2 py-1 text-text-level03">{option.label}</li>}>
                <Popover.Trigger
                  id={`${props.id}-option-${index()}`}
                  as="li"
                  onMouseDown={() => {
                    setIsSelecting(true);
                  }}
                  onMouseUp={() => {
                    setIsSelecting(false);
                    setIsInputFocused(false);
                  }}
                  onClick={(e) => handleSelect(e, (option as { value: T }).value)}>
                  <Show
                    when={typeof props.renderOption !== 'function'}
                    fallback={props.renderOption?.({ ...option, selected: selectdOptions().includes(option) })}>
                    <div class="flex cursor-pointer items-center gap-1 rounded-md px-3 py-2.5 text-title-gray transition-colors hover-allowed:hover:bg-light-pink">
                      {option.label}
                      <Show when={selectdOptions().includes(option)}>
                        <IconCheck class="ml-auto size-4 text-essential-colour" />
                      </Show>
                    </div>
                  </Show>
                </Popover.Trigger>
              </Show>
            )}
          </For>
        </Show>
      </Popover.Content>
    </Popover>
  );
};
