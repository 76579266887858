import { A } from '@solidjs/router';
import { For } from 'solid-js';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { statusFilters } from './statusFilters';
import type { Component } from 'solid-js';

export const RentalApplicationStatusFilterTabs: Component<{ current?: keyof MagicDoor.Api.RentalApplicationCountsDto }> = (props) => {
  const { t } = useLocalization();
  const { counts } = useRentalApplication();

  return (
    <div class="flex flex-wrap gap-5 rounded-lg border border-partingline bg-white px-6 py-4">
      <For each={statusFilters}>
        {(item) => (
          <A
            href={`?status=${item.key}`}
            class="relative h-28 w-52 flex-1 cursor-pointer rounded-lg bg-gray-level01 from-[#cf82ff] to-[#a126ec] px-5 py-3 text-left text-text-level02 transition-colors aria-selected:bg-gradient-to-br aria-selected:text-white hover-allowed:hover:bg-gray-level02"
            aria-selected={item.key === props.current}
            role="tab">
            <span class="block text-sm font-medium">{t(item.label)}</span>
            <span class="mt-1 block text-2xl font-bold">{counts()?.[item.key] ?? '-'}</span>
            <item.mark class="absolute bottom-0 right-0 text-black/5" />
          </A>
        )}
      </For>
    </div>
  );
};
