import { For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Icon } from '~/components/ui';

export type QuickActionItem = {
  title: string;
  icon: Icon;
  code: string;
  bgColor: string;
};
export const QuickActionPanel = (props: { data: QuickActionItem[]; onActionClick: (code: string) => void; class?: string }) => {
  const { t } = useLocalization();
  return (
    <Panel title={t('Actions')} class={props.class}>
      <div class="flex flex-wrap gap-2 px-2.5 py-4 text-sm text-white">
        <For each={props.data}>
          {(item) => (
            <div class={cn('grow-1 relative flex basis-32 flex-col items-center justify-center')}>
              <div
                onClick={() => props.onActionClick(item.code)}
                class="flex cursor-pointer flex-col items-center justify-center rounded-md p-2 hover-allowed:hover:bg-input/70">
                <div
                  class="flex size-9 items-center justify-center rounded-full"
                  style={{
                    'background-color': item.bgColor,
                  }}>
                  <Dynamic component={item.icon} />
                </div>
                <span class="mt-1 text-nowrap text-sm font-medium text-black">{item.title}</span>
              </div>
            </div>
          )}
        </For>
      </div>
    </Panel>
  );
};
