import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useLocalization } from '~/contexts/global';
import { tenantSections, tenantTableOfContents } from './TenantConstant';
import { TermWithTableOfContents } from './TermWithTableOfContents';

export const TenantTerm = (props: { id?: string; title: string; checked: boolean; onInput: (checked: boolean) => void }) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-4">
      <TermWithTableOfContents
        tableOfContents={tenantTableOfContents}
        sections={tenantSections}
        title={props.title}
        scrollOffset={620}
        header={
          <>
            <h1 class="mb-8 text-3xl font-bold text-gray-900">Property Tenant Terms of Use</h1>
            <div class="mb-8 text-sm text-gray-600">Last Revised: November 13, 2024</div>
          </>
        }
      />
      <Checkbox
        id={props.id}
        checkBoxClass="items-start"
        name="tenant-policy"
        required
        showLabel
        labelClass="inline-block whitespace-normal"
        label={
          <>
            {t('I have read and accept the')}
            <a
              class="m-1 text-blue hover-allowed:hover:underline"
              href="/tenants-terms-of-service.pdf"
              rel="noopener noreferrer"
              target="_blank">
              {t('Terms of service')}
            </a>
          </>
        }
        checked={props.checked}
        onInput={props.onInput}
      />
    </div>
  );
};
