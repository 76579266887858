import { createEffect, createMemo, For, Show } from 'solid-js';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSwitch } from '~/components/common/Inputs/LabeledInputSwitch';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledTimePicker from '~/components/common/Inputs/LabeledTimePicker';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { LanguageSelect } from '~/components/languages';
import { LinkBankAccount } from '~/components/rental-application/rental-application-details/renter-report-tab/applicant-summary/components/LinkBankAccount';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { SaveBtn } from './SaveBtn';
import type { Component, JSX, ValidComponent } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';
import type { AccountType } from '~/pages/chart-of-accounts/list-chart-of-accounts/list-components/ChartOfAccountsTable';

function isComponent(type: string | ValidComponent): type is ValidComponent {
  return typeof type === 'function';
}

interface InputComponentProps {
  value?: string | number | boolean;
  onChange?: (value: string | number | boolean) => void;
  onInput?: (value: string | number) => void;
  options?: any[];
  placeholder?: string;
}

type GenericInputComponent = Component<InputComponentProps> & { [key: string]: any };

export type ItemProps = {
  field: BetterForm.NamePath;
  label: string;
  class?: string;
  type: string | ValidComponent;
  options?: any[];
  onChangeMethodName?: string;
  labelContainerClass?: string;
  visibleMethod?: (data: any) => boolean;
  description?: JSX.Element;
  chartOfAccountType?: AccountType[];
  [key: string]: any;
  placeholder?: string;
};

export const EditFormItem: Component<ItemProps> = (props) => {
  const { t, currentLanguage } = useLocalization();

  const components = createMemo(() => {
    if (isComponent(props.type)) return props.type;
    switch (props.type) {
      case 'boolean':
        return (inputProps: any) => (
          <LabeledInputSwitch
            {...inputProps}
            disabled={typeof props.disabled === 'function' ? props.disabled(inputProps.value) : props.disabled}
          />
        );
      case 'string':
        return (inputProps: any) => <LabeledTextInput {...inputProps} eventTriggerName={props.onChangeMethodName} />;
      case 'number':
        return LabeledNumberInput as GenericInputComponent;
      case 'select':
        return LabeledSelect as GenericInputComponent;
      case 'chartOfAccountSelect':
        return LabeledChartOfAccountSelect as any;
      case 'textarea':
        return LabeledTextArea as GenericInputComponent;
      case 'date':
        return (inputProps: any) => <DueDateInputField {...inputProps} onInput={inputProps.onChange} />;
      case 'checkbox':
        return (inputProps: any) => <Checkbox {...inputProps} onInput={inputProps.onChange} />;
      case 'languageSelect':
        return LanguageSelect as GenericInputComponent;
      case 'timePicker':
        return LabeledTimePicker as GenericInputComponent;
      case 'address':
        return LabeledAddressInput as unknown as GenericInputComponent;
      case 'link':
        return (inputProps: any) => <LinkBankAccount {...inputProps} />;
      default:
        return LabeledTextInput as GenericInputComponent;
    }
  });

  const description = createMemo(() => {
    currentLanguage();
    if (typeof props.description === 'string') {
      return t(props.description);
    }
    return props.description;
  });

  return (
    <FormItem
      {...props}
      labelContainerClass={props.labelContainerClass}
      onChangeMethodName={props.onChangeMethodName}
      options={props.options}
      class={props.class}
      formFieldName={props.field}
      component={components()}
      rules={props.rules || [{ required: true, message: t(`${props.label} is required`) }]}
      label={t(props.label)}
      description={description()}
      placeholder={props.placeholder && t(props.placeholder)}
      labelClass={cn('normal-case text-text-level03', props.labelClass)}
      inputContainerClass={props.inputContainerClass}
      groupClass={`${props.type === 'chartOfAccountSelect' ? props.class : ''}`}
      types={props.chartOfAccountType}
      disabled={props.disabled}
      style={props.style}
    />
  );
};

interface EditFormProps<T> {
  sections: ItemProps[] | ((values: T) => ItemProps[]);
  model: T;
  onClose?: () => void;
  onOk: (model: T) => void;
  defaultForm?: BetterForm.form;
}

export const EditForm = <T extends object>(props: EditFormProps<T>) => {
  const form = props.defaultForm ?? useForm();

  createEffect(() => {
    form.setFormStore(props.model);
  });

  const isDirty = () => {
    if (!props.model) {
      return false;
    }
    const keys = Object.keys(props.model) as (keyof typeof props.model)[];
    const formKeys = Object.keys(form.formStore) as (keyof typeof form.formStore)[];
    return (
      keys.some((key) => JSON.stringify(form.formStore[key as string]) !== JSON.stringify(props.model[key])) ||
      keys.length !== formKeys.length
    );
  };

  const handleSave = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await props.onOk(form.formStore as T);
    props?.onClose?.();
  };

  const onCancel = () => {
    if (props.model) {
      form.setFormStore(props.model);
    }
  };

  const _sections = createMemo(() => {
    if (typeof props.sections === 'function') {
      return props.sections(form.formStore as T);
    }
    return props.sections;
  });

  return (
    <>
      <Form defaultForm={form} class="my-3 grid gap-x-5 gap-y-6 text-left md:grid-cols-2">
        <For each={_sections()}>
          {(section) => (
            <Show when={!section.visibleMethod || section.visibleMethod(form.formStore)}>
              <EditFormItem
                {...section}
                disabled={typeof section.disabled === 'function' ? section.disabled(form.formStore) : section.disabled}
                style={typeof section.style === 'function' ? section.style(form.formStore) : section.style}
              />
            </Show>
          )}
        </For>
      </Form>

      <SaveBtn dirty={isDirty()} onCancel={onCancel} onSave={handleSave} />
    </>
  );
};
