import { Portal } from 'solid-js/web';
import { ModalButton } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
}

// TODO: replace with new confirm modal
const ConfirmationModal: Component<ConfirmationModalProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Portal>
      <div class="fixed inset-0 z-50 flex items-center justify-center">
        <div class="overlay fixed inset-0 animate-fade-in bg-black/70" onClick={() => props.onCancel()} />
        <div class="absolute flex w-4/5 animate-zoom-in flex-col gap-4 rounded-lg bg-white p-4 text-left shadow-md lg:w-96">
          <h3 class="border-b py-2 text-sm font-medium">{props.title || t('Confirmation')}</h3>
          <p class=" text-xs leading-5 text-text-level02">{props.message}</p>
          <div class="flex justify-end gap-2">
            <ModalButton id="confirmation-modal-cancel-btn" type="button" onClick={() => props.onCancel()}>
              {props.cancelText || t('Go back')}
            </ModalButton>
            <ModalButton
              id="confirmation-modal-confirm-btn"
              type="button"
              class="bg-primary px-5 py-2 text-xs text-white hover-allowed:hover:bg-primary/80"
              onClick={() => props.onConfirm()}>
              {props.confirmText || t('Yes')}
            </ModalButton>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ConfirmationModal;
