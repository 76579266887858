import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class ToggleOwnerSelectionUseCase extends MagicUseCase {
  public async runLogic(ownerId: string) {
    this.getState().taxInfo.ownersAndAmounts.forEach((owner) => {
      if (owner.ownerId === ownerId) {
        owner.isSelected = !owner.isSelected;
      }
    });
  }
}
