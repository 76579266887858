import { createMemo, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import ApprovedIcon from '~/assets/images/settings/company/business-info/approved.svg?component-solid';
import DocumentIcon from '~/assets/images/settings/company/business-info/document.svg?component-solid';
import PendingIcon from '~/assets/images/settings/company/business-info/pending.svg?component-solid';
import RejectedIcon from '~/assets/images/settings/company/business-info/rejected.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { CompanyBusinessInfoStatus, CompanyBusinessInfoStatusDto } from '~/swagger/Api';

type StatusConfig = {
  icon: Component;
  textColor: string;
  bgColor: string;
  label: string;
};

const STATUS_CONFIG: Record<CompanyBusinessInfoStatus, StatusConfig> = {
  pending: {
    icon: PendingIcon,
    textColor: 'text-warning',
    bgColor: 'bg-warning/10',
    label: 'Pending',
  },
  approved: {
    icon: ApprovedIcon,
    textColor: 'text-success',
    bgColor: 'bg-success/10',
    label: 'Approved',
  },
  rejected: {
    icon: RejectedIcon,
    textColor: 'text-error',
    bgColor: 'bg-error/10',
    label: 'Rejected',
  },
};

export const BusinessInfoStatusBanner = (props: { businessinfoStatus?: CompanyBusinessInfoStatusDto }) => {
  const { t } = useLocalization();
  const statusConfig = createMemo(() => {
    if (!props.businessinfoStatus?.status) {
      return STATUS_CONFIG.pending;
    }
    return STATUS_CONFIG[props.businessinfoStatus.status];
  });
  return (
    <div class="flex flex-col gap-4 rounded-lg border border-partingline bg-white px-6 py-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
          <DocumentIcon class="size-6 text-primary" />
          <h2 class="text-base font-semibold text-title-gray">{t('Business information status')}</h2>
        </div>
        <div class={`flex items-center gap-2 rounded-full px-3 py-1 ${statusConfig().bgColor}`}>
          <Dynamic component={statusConfig().icon} />
          <span class={`text-sm font-medium ${statusConfig().textColor}`}>{t(statusConfig().label)}</span>
        </div>
      </div>
      <Show when={props.businessinfoStatus?.rejectedReason}>
        <div class="text-center text-sm text-error">{props.businessinfoStatus?.rejectedReason}</div>
      </Show>
    </div>
  );
};
