import { Show, For } from 'solid-js';
import VendorItemIcon from '~/assets/images/bill/vendorItem.svg?component-solid';
import { useLocalization } from '~/contexts/global';
export type OtherTarget = {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
};

export const OtherTargetsList = (props: { targets: OtherTarget[]; title: string }) => {
  const { t } = useLocalization();

  const getTargetLink = (targetType: string, id: string) => {
    switch (targetType.toLowerCase()) {
      case 'vendors':
        return `/maintenance/vendors/${id}`;
      case 'tenants':
        return `/users/tenants/${id}`;
      case 'owners':
        return `/users/owners/${id}`;
      default:
        return `/details/${id}`;
    }
  };

  return (
    <Show when={props.targets && props.targets.length > 0}>
      <div class="mt-4">
        <h4 class="mb-5 border-b py-3 text-base font-semibold uppercase text-text-level02">
          {t('Send to')} - {t(props.title)}
        </h4>
        <For each={props.targets}>
          {(target) => (
            <Show when={target && target.id && (target.name || (target.firstName && target.lastName))}>
              <div class="mb-2 flex h-16 w-full items-center justify-start rounded-md border bg-input px-3 text-sm text-black">
                <VendorItemIcon class="mr-2" />
                <a
                  href={getTargetLink(props.title, target.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="hover-allowed:hover:underline">
                  {target.name || `${target.firstName} ${target.lastName}`}
                </a>
              </div>
            </Show>
          )}
        </For>
      </div>
    </Show>
  );
};
