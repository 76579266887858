import { For, Show } from 'solid-js';
import type { JSX } from 'solid-js';

interface TableColumn {
  title: string | JSX.Element;
  key?: string;
  render?: () => JSX.Element;
  skeletonRender?: () => JSX.Element;
}

interface TableSkeletonProps {
  columns: TableColumn[];
  rowCount: number;
}

const TableSkeleton = (props: TableSkeletonProps) => {
  const createArray = (length: number) => new Array(length).fill(0);

  return (
    <div class="overflow-hidden">
      <div class="flex items-center border-b bg-light-faded text-left text-text-level03">
        <For each={props.columns}>
          {(column) => (
            <div class="flex-1 px-4 py-2 text-xs uppercase">{typeof column.title === 'string' ? column.title : column.title}</div>
          )}
        </For>
      </div>

      <For each={createArray(props.rowCount)}>
        {() => (
          <div class="flex border-b transition-colors hover-allowed:hover:bg-gray-100">
            <For each={props.columns}>
              {(column) => (
                <div class="flex flex-1 items-center px-4 py-3.5">
                  <Show when={!column.skeletonRender} fallback={column.skeletonRender?.()}>
                    <div class="skeleton-shimmer h-5 w-3/4" />
                  </Show>
                </div>
              )}
            </For>
          </div>
        )}
      </For>
    </div>
  );
};

export default TableSkeleton;
