import { Routes, Route, useParams } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization, useProperties, useUnits } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import LeaseCommunicationsTab from '~/pages/leases/communications/LeaseCommunicationsTab';
import { LeaseNavbar } from './components/LeaseNavbar';
import { LeaseDepositRoutes } from './deposits/LeaseDepositRoutes';
import { LeaseEmailsTab } from './emails/LeaseEmailsTab';
import { LeaseFiles } from './files';
import { LeaseNotesTab } from './notes/LeaseNotesTab';
import { Overview } from './overview';
import { LeaseRentCharges } from './rent-charges';
import { Signings } from './signings/Signings';
import { LeaseTransactionRoutes } from './transactions';

export const LeaseDetails = () => {
  const { lease } = useLease();
  const { current, setCurrentId } = useProperties();
  const { unit } = useUnits();
  const params = useParams();
  const { t } = useLocalization();
  const goBack = useGoBack();

  const breadcrumbItems = createMemo(() => [
    { label: t('Properties'), link: '/portfolios' },
    { label: current()?.portfolio?.name || t('Loading'), link: `/portfolios/${current()?.portfolio?.id}` },
    { label: current()?.name || t('Loading'), link: `/portfolios/${current()?.portfolio?.id}/properties/${current()?.id}` },
    { label: t('Units'), link: `/portfolios/${current()?.portfolio?.id}/properties/${current()?.id}/units` },
    { label: unit()?.name || t('Loading') },
  ]);

  createEffect(() => {
    const leaseUnit = lease()?.unit;
    if (leaseUnit) {
      setCurrentId(leaseUnit.propertyId);
    }
  });

  return (
    <div class="flex size-full flex-col gap-y-4">
      <Breadcrumb loading={!current() && !unit()} backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="flex flex-col gap-5 px-3 pb-5 md:px-8">
        <LeaseNavbar />

        <Routes>
          <Route path="/*" component={Overview} />
          <Route path={['/rent-charges/:chargeId?', '/rent-charges/auto-payment/:autoPaymentId']} component={LeaseRentCharges} />
          <Route path="/transactions/*" component={LeaseTransactionRoutes} />
          <Route path="/deposits/*" component={LeaseDepositRoutes} />
          <Route path="/communications" element={<LeaseCommunicationsTab leaseChatId={lease()?.chatId} leaseId={params.leaseId} />} />
          <Route path="/emails/*" component={LeaseEmailsTab} />
          <Route path="/signings" component={Signings} />
          <Route path="/files" component={LeaseFiles} />
          <Route path="/notes/*" component={LeaseNotesTab} />
        </Routes>
      </div>
    </div>
  );
};
