import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type PanelProps = {
  class?: string;
  title?: string | JSX.Element;
  actions?: JSX.Element;
  description?: string | JSX.Element;
  children?: JSX.Element;
  actionClass?: string;
  header?: JSX.Element;
  headerClass?: string;
};

export const Panel: Component<PanelProps> = (props) => {
  return (
    <div class={cn('overflow-hidden rounded-lg border border-partingline bg-white', props.class)}>
      <Show when={props.header}>{props.header}</Show>
      <Show when={props.title && !props.description}>
        <div class={cn('flex items-center justify-between border-b border-partingline px-3 py-[14px] md:px-6 md:py-4', props.headerClass)}>
          <h3 class="text-base font-semibold capitalize md:text-lg">{props.title}</h3>
          <div>{props.actions}</div>
        </div>
      </Show>
      <Show when={props.title && props.description}>
        <div class={cn('flex items-center justify-between border-b border-partingline px-6 py-4', props.headerClass)}>
          <div>
            <h3 class="text-lg font-semibold capitalize">{props.title}</h3>
            <div class="text-xs normal-case text-text-level03">{props.description}</div>
          </div>
          <div class={props.actionClass}>{props.actions}</div>
        </div>
      </Show>
      {props.children}
    </div>
  );
};
