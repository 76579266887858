import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import ListingNone from '~/assets/images/units/listing/listing_none.png';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import { UseAddUnitListing } from './UseAddUnitListing';
import type { Component } from 'solid-js';

export const AddUnitListingMobile: Component = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const params = useParams<{ listingId: `${number}`; portfolioId?: `${number}` }>();
  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: `/listings/${params.portfolioId}` },
    {
      label: t('Select listing units'),
    },
  ]);
  const { handleClose, handleSubmit, setSelected } = UseAddUnitListing();

  return (
    <div class="w-full">
      <div class="w-full">
        <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      </div>
      <div class="relative flex h-section1 flex-col">
        <div class="h-full p-3">
          <div class="h-full rounded-lg bg-white">
            <h1 class="border border-[#EDF2F7] px-3 py-4 text-base font-medium text-title-gray">{t('Select listing units')}</h1>
            <div class="px-3 py-4">
              <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                <img src={ListingNone} alt="none-img" />
                <span class="mt-1 text-xs text-text-level03">{t('Type to search...')}</span>
              </div>
              <LabeledLeveledSearchSelect
                id="listings-add-select"
                label={t('Select items')}
                enabledTypes={[LevelSearch.Portfolio, LevelSearch.Property, LevelSearch.Unit]}
                multiple
                required
                singleTypeOnly={false}
                onChange={(_, variants) => {
                  setSelected(variants);
                }}
              />
            </div>
          </div>
        </div>
        <div class="fixed bottom-0 flex w-full gap-2 bg-white px-3 py-4">
          <Button class="md:ml-auto" variant="outlined" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button class="flex-1 justify-center md:flex-none" onClick={handleSubmit}>
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};
