import { createSignal, Switch, Match } from 'solid-js';
import IconAccountLoginBg from '~/assets/images/settings/bgAccountLoginPW.svg';
import { Button } from '~/components/common/Buttons';
import { useLocalization, useAuth } from '~/contexts/global';
import { UpdatePassword } from './UpdatePassword';
import type { Component } from 'solid-js';

export const LoginAndPassword: Component = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<'login' | 'password'>();
  const { changePassword } = useAuth();

  const handlePasswordSubmit = async (model: { currentPassword: string; newPassword: string }) => {
    await changePassword(model.currentPassword, model.newPassword);
  };

  return (
    <Switch
      fallback={
        <div class="flex justify-between rounded-lg bg-dark-navy text-white">
          <div class="mx-6 my-8 max-w-md flex-1 space-y-6 text-left">
            <Button
              variant="outlined"
              class="bg-essential-colour/10 py-2.5 text-white hover-allowed:hover:bg-essential-colour/30"
              onClick={() => setEditing('password')}>
              {t('Update password')}
            </Button>
          </div>
          <img class="h-20" src={IconAccountLoginBg} alt={t('Account login & password')} />
        </div>
      }>
      <Match when={editing() === 'password'}>
        <UpdatePassword onClose={setEditing} onSubmit={handlePasswordSubmit} errors={changePassword.error} />
      </Match>
    </Switch>
  );
};
