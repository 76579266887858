import { MaintenanceRequestStatus } from '~/swagger/Api';
import { MarkAllOpen, MarkInProcess, MarkPending, MarkClosed, MarkWaitingForWorkOrder, MarkProcessingAi } from './assets';
import type { Component } from 'solid-js';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

export type FilterKey = MaintenanceRequestStatus | 'newRequests';
type Filter = {
  label: string;
  mark: Component<{ class?: string }>;
  key: FilterKey;
  filter: MaintenanceRequestFilter;
};

export const statusFilters = [
  {
    label: 'All open',
    mark: MarkAllOpen,
    key: 'newRequests',
    filter: {
      open: true,
    },
  },
  {
    label: 'Processing AI',
    mark: MarkProcessingAi,
    key: MaintenanceRequestStatus.AiProcessing,
    filter: { status: MaintenanceRequestStatus.AiProcessing },
  },
  { label: 'Pending', mark: MarkPending, key: MaintenanceRequestStatus.Pending, filter: { status: MaintenanceRequestStatus.Pending } },
  {
    label: 'In progress',
    mark: MarkInProcess,
    key: MaintenanceRequestStatus.InProgress,
    filter: {
      status: [MaintenanceRequestStatus.InProgress, MaintenanceRequestStatus.WaitingForWorkOrder, MaintenanceRequestStatus.AiProcessing],
    },
  },
  {
    label: 'Waiting for work order',
    mark: MarkWaitingForWorkOrder,
    key: MaintenanceRequestStatus.WaitingForWorkOrder,
    filter: { status: MaintenanceRequestStatus.WaitingForWorkOrder },
  },
  { label: 'Closed', mark: MarkClosed, key: MaintenanceRequestStatus.Closed, filter: { status: MaintenanceRequestStatus.Closed } },
] as Filter[];
