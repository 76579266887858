import { For, Show, createSignal, createEffect } from 'solid-js';
import { DragAndDrop, IconImage, IconLoader, toast, IconX } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { Promisable } from '~/utils/types';

export type Photo = { id: string; url: string };

export const PhotosEditor: Component<{
  photos?: Photo[];
  onUpload: (files: File[]) => Promisable<void>;
  onDelete: (id: string) => Promisable<void>;
  onChange?: (items: Photo[] | undefined) => void;
  onOrderChange?: (hasChanged: boolean) => void;
  max?: number;
  name?: string;
}> = (props) => {
  const { t } = useLocalization();
  const [uploading, setUploading] = createSignal<boolean>(false);
  const [deleting, setDeleting] = createSignal<string>();
  const [photos, setPhotos] = createSignal<Photo[]>();
  let draggedItem: Photo | null = null;
  let draggedOverItem: Photo | null = null;

  const handleDropFiles = async (files: FileList) => {
    const photoFiles = Array.from(files).filter((file) => {
      if (!file.type.startsWith('image/')) {
        toast.error(t('Only image files are allowed'));
        return false;
      }
      if (file.size > 10 * 1024 * 1024) {
        toast.error(t('Image size should be less than 10MB'));
        return false;
      }
      return true;
    });
    if (props.max && photoFiles.length > props.max) {
      toast.error(t('You can only upload up to {max} photos at a time', { max: props.max }));
      return;
    }

    if (photoFiles.length > 0) {
      setUploading(true);
      try {
        const filesArray = [...photoFiles];
        if (typeof props.onUpload !== 'function') {
          throw new Error('onUpload prop is not a function');
        }
        await props.onUpload(filesArray);
        toast.success(t('{name} has been added successfully', { name: t('Photo') }));
      } catch (error) {
        console.error('Upload failed:', error);
        toast.error(t('Failed to upload photo'));
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDeleteFile = async (fileId: string) => {
    setDeleting(fileId);
    await props.onDelete(fileId);
    toast.success(t('{name} has been deleted successfully', { name: t('Photo') }));
    setDeleting(undefined);
  };

  const handleDragStart = (e: DragEvent, item: Photo) => {
    draggedItem = item;
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '0.5';
    }
  };
  const handleDragOver = (e: DragEvent, item: Photo) => {
    e.preventDefault();
    draggedOverItem = item;
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '1';
    }
    if (!draggedItem || !draggedOverItem) return;

    const currentPhotos = photos();
    const draggedIndex = currentPhotos?.findIndex((item) => item.id === draggedItem?.id);
    const overIndex = currentPhotos?.findIndex((item) => item.id === draggedOverItem?.id);

    if (draggedIndex === overIndex) return;

    const newPhotos = [...(currentPhotos ?? [])];
    if (draggedIndex !== undefined && overIndex !== undefined) {
      [newPhotos[draggedIndex], newPhotos[overIndex]] = [newPhotos[overIndex], newPhotos[draggedIndex]];
    }
    setPhotos(newPhotos);
    props?.onChange && props?.onChange(newPhotos);
    props?.onOrderChange && props?.onOrderChange(true);
    draggedItem = null;
    draggedOverItem = null;
  };

  createEffect(() => {
    if (!photos() || props.photos?.length !== photos()?.length) {
      setPhotos(props?.photos);
    }
  });

  return (
    <Show
      when={props.photos?.length}
      fallback={
        <DragAndDrop
          name={props.name}
          class="m-8 flex cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-20 text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary hover-allowed:hover:bg-light-pink"
          accept="image/*"
          multiple
          onDropFiles={handleDropFiles}
          data-slot="dnd">
          <Show when={uploading()} fallback={<IconImage class="mt-2 size-10" />}>
            <IconLoader class="mt-2 size-10 animate-spin" />
          </Show>
          <p class="mb-4 mt-2 text-xs normal-case">{t('Drag and drop photos here')}</p>
          <span class="rounded-lg border border-primary px-5 py-2 text-sm text-primary">{t('Select from computer')}</span>
        </DragAndDrop>
      }>
      <div class="grid grid-cols-2 gap-3 p-3 md:grid-cols-4 md:gap-4 md:p-8  xl:grid-cols-6">
        <DragAndDrop
          name={props.name}
          class="flex aspect-square cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-4 text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary hover-allowed:hover:bg-light-pink"
          accept="image/*"
          multiple
          onDropFiles={handleDropFiles}
          data-slot="dnd">
          <Show when={uploading()} fallback={<IconImage class="mt-2 size-6" />}>
            <IconLoader class="mt-2 size-6 animate-spin" />
          </Show>
          <p class="mt-1 text-xs">{t('Add photo')}</p>
        </DragAndDrop>
        <For each={photos()}>
          {(item, index) => (
            <div
              id={`${props.name}-item-${index()}`}
              class="relative cursor-move overflow-hidden rounded-lg"
              data-slot="photo"
              draggable="true"
              onDragStart={(e) => handleDragStart(e, item)}
              onDragOver={(e) => handleDragOver(e, item)}
              onDragEnd={handleDragEnd}>
              <img src={item.url} class="aspect-square w-full object-cover" />
              <button
                id={`${props.name}-delete-btn`}
                type="button"
                class="absolute right-1 top-1 rounded bg-black/30 p-1 text-white transition-colors hover-allowed:hover:bg-black/60"
                onClick={() => handleDeleteFile(item.id)}>
                <Show when={deleting() === item.id} fallback={<IconX class="size-4" />}>
                  <IconLoader class="size-4 animate-spin" />
                </Show>
              </button>
            </div>
          )}
        </For>
      </div>
    </Show>
  );
};
