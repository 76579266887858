import IconUploadTrigger from '~/assets/images/common/uploadTrigger.svg?component-solid';
import { FileList, Upload, UploadTrigger, useUpload } from '~/components/common/Upload';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { UploadProps } from '~/components/common/Upload/Interface';
import type { FileDto } from '~/swagger/Api';

export type ImageUploaderProps = { defaultFileList?: FileDto[] } & UploadProps;

export const ImageUploaderTrigger = (props: ImageUploaderProps) => {
  const { t } = useLocalization();
  const { combinedFiles } = useUpload();

  return (
    <UploadTrigger
      class={cn(
        'shrink-0 rounded border-2 border-dashed border-transparent text-gray-500 transition-colors duration-200 data-[active]:border-essential-colour data-[active]:bg-[#A126EC0A] data-[active]:text-primary hover-allowed:hover:border-gray-400',
        combinedFiles().length > 0 ? 'flex-0 border-gray-300' : 'flex-1'
      )}>
      <div class="flex h-24 cursor-pointer flex-col items-center justify-center gap-2 p-4 text-xs normal-case">
        <IconUploadTrigger class="size-5" />
        <span>{t('Drag & drop or click to add files')}</span>
      </div>
    </UploadTrigger>
  );
};

export const ImageUploader = (props: ImageUploaderProps) => {
  return (
    <Upload {...props} accept="*/*" multiple>
      <div class="flex gap-2">
        <ImageUploaderTrigger />
        <FileList<any> defaultFileList={props.defaultFileList} />
      </div>
    </Upload>
  );
};
