import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';
import { ChangeVendorTaxYearUseCase } from './changeVendorTaxYearUseCase';

export class GetAvailableVendorTaxYearsUseCase extends MagicUseCase {
  public async runLogic(vendorId?: string) {
    this.getState().taxInfo.availableYears = await taxDocumentsRepository.getAvailableVendorTaxYears();
    await new ChangeVendorTaxYearUseCase().execute({
      year: this.getState().taxInfo.availableYears[0],
      vendorId,
    });
  }
}
