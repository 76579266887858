import fontkit from 'pdf-fontkit';
import { PageSizes, PDFDocument, rgb } from 'pdf-lib';
import RegularFont from '~/assets/fonts/NotoSansSC-Regular.ttf';
import type { PDFFont, PDFPage } from 'pdf-lib';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';

export interface PdfTaxDocPrintRequest {
  xOffset: number;
  yOffset: number;
  itemsPerPage: number;
  vericalDistanceBetweenItems: number;
  annotations: Annotation[][];
}
export class PdfTaxDocGenerator {
  private yPosition: number = 0;
  private font?: PDFFont;
  private currentPage?: PDFPage = undefined;

  public async createTaxDocument(request: PdfTaxDocPrintRequest): Promise<Blob> {
    const outputPdf = await PDFDocument.create();
    outputPdf.registerFontkit(fontkit);
    const font = await fetch(RegularFont).then((res) => res.arrayBuffer());
    this.font = await outputPdf.embedFont(font, { subset: true });
    request.annotations.forEach((annotationSet: Annotation[], index: number) => {
      if (index % request.itemsPerPage === 0) {
        this.currentPage = outputPdf.addPage(PageSizes.Letter);
        this.yPosition = this.currentPage.getHeight() - request.yOffset;
      }
      this.drawAnnotations(this.currentPage!, request.xOffset, this.yPosition, annotationSet);
      this.yPosition -= request.vericalDistanceBetweenItems;
    });
    const pdfBytes = await outputPdf.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  private drawAnnotations(page: PDFPage, xOffset: number, yOffset: number, annotations: Annotation[]) {
    try {
      const pageWidth = page.getWidth();
      const pageHeight = page.getHeight();
      for (const annotation of annotations) {
        const x = xOffset + annotation.x * pageWidth;
        const y = yOffset - (annotation.y + annotation.height) * pageHeight;
        const width = annotation.width * pageWidth;
        const height = annotation.height * pageHeight;
        const fontSize = annotation.fontSize ?? 11;
        const lines: string[] = this.getWrappedLines(annotation.text || '', fontSize, width);
        let currentY = y + height - fontSize - 1;
        for (const line of lines) {
          page.drawText(line, {
            x: x,
            y: currentY,
            font: this.font,
            size: fontSize,
            color: rgb(0, 0, 0),
            maxWidth: width,
            lineHeight: fontSize + 2,
          });
          currentY -= fontSize + 1;
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  private getWrappedLines(text: string, fontSize: number, maxWidth: number): string[] {
    const lines: string[] = [];
    const words = text.split(' ');
    let currentLine = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.includes('\n')) {
        const parts = word.split('\n');
        for (let j = 0; j < parts.length; j++) {
          const part = parts[j];
          if (this.font && this.font.widthOfTextAtSize(currentLine + part, fontSize) <= maxWidth) {
            currentLine += part;
          } else {
            lines.push(currentLine);
            currentLine = part;
          }
          if (j < parts.length - 1) {
            lines.push(currentLine);
            currentLine = '';
          }
        }
      } else {
        if (this.font && this.font.widthOfTextAtSize(currentLine + ' ' + word, fontSize) <= maxWidth) {
          if (currentLine !== '') {
            currentLine += ' ';
          }
          currentLine += word;
        } else {
          if (this.font && this.font.widthOfTextAtSize(word, fontSize) > maxWidth) {
            for (const char of word) {
              if (this.font.widthOfTextAtSize(currentLine + char, fontSize) <= maxWidth) {
                currentLine += char;
              } else {
                lines.push(currentLine);
                currentLine = char;
              }
            }
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
      }
    }
    if (currentLine) {
      lines.push(currentLine);
    }
    return lines;
  }
}
