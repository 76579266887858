import { AppState } from '~/framework/appState';
import { DataPathManager } from '~/pdfsigner/state/dataPathManager';
import type { Annotation, AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import type { BoundingBox } from '~/pdfsigner/usecases/types/boundingBox';
import type { Document } from '~/pdfsigner/usecases/types/document';
import type { LeaseTemplateCategory } from '~/pdfsigner/usecases/types/leaseTemplateCategory';
import type { OwnerTaxAmount } from '~/pdfsigner/usecases/types/ownerTaxAmount';
import type { PaginatedDocumentDescriptor } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { PaginatedOwnerTaxDocuments, PaginatedVendorTaxDocuments } from '~/pdfsigner/usecases/types/paginatedTaxDocuments';
import type { Position } from '~/pdfsigner/usecases/types/position';
import type { Signer } from '~/pdfsigner/usecases/types/signer';
import type { VendorTaxAmount } from '~/pdfsigner/usecases/types/vendorTaxAmount';
import type { BalanceSheetReportDataDto, CompanyDto, HydratedLeaseDto, PortfolioDto, PropertyDto, RentRollReportDto } from '~/swagger/Api';

export interface PdfState {
  companyInfo?: CompanyDto;
  selectedDocument?: Document;
  leaseTemplates: Document[];
  paginatedLeaseDrafts: PaginatedDocumentDescriptor | undefined;
  selectedAnnotation: Annotation | undefined;
  isEditing: boolean;
  clickPosition: Position | undefined;
  initialBounds: BoundingBox | undefined;
  selectedAnnotationType: AnnotationType | undefined;
  maxNumberOfSigners: number;
  leaseDocumentSigners: Signer[];
  selectedSigner: Signer;
  leaseTemplateCategories: LeaseTemplateCategory[];
  dataPathManager: DataPathManager;
  fontSizes: number[];
  selectedFontSize: number;
  reports: Reports;
  portfolios: PortfolioDto[];
  properties: PropertyDto[];
  taxInfo: TaxInfo;
}

export interface TaxInfo {
  availableYears: number[];
  selectedYear: number;
  paginatedVendorTaxDocuments: PaginatedVendorTaxDocuments;
  paginatedOwnerTaxDocuments: PaginatedOwnerTaxDocuments;
  vendorsAndAmounts: VendorTaxAmount[];
  ownersAndAmounts: OwnerTaxAmount[];
  mergedPdf?: Blob;
}

export interface Reports {
  balanceSheet: BalanceSheetReportDataDto;
  rentRoll: RentRollReportDto;
  leasesWithBalancesDueReport: HydratedLeaseDto[];
  pdf?: Blob;
}

const signers: Signer[] = [
  {
    name: 'Signer1',
    id: '1',
    isPropertyManager: false,
    signatureName: 'Signer1',
    signatureInitials: 'S1',
  },
];

class PdfAppState extends AppState<PdfState> {
  companyInfo: CompanyDto | undefined = undefined;
  selectedDocument: Document | undefined = undefined;
  leaseTemplates: Document[] = [];
  selectedAnnotation: Annotation | undefined = undefined;
  isEditing = false;
  clickPosition: Position | undefined = undefined;
  initialBounds: BoundingBox | undefined = undefined;
  selectedAnnotationType: AnnotationType | undefined = undefined;
  maxNumberOfSigners = 10;
  leaseDocumentSigners: Signer[] = [];
  paginatedLeaseDrafts: PaginatedDocumentDescriptor | undefined = undefined;
  selectedSigner: Signer = signers[0];
  leaseTemplateCategories: LeaseTemplateCategory[] = [];
  dataPathManager = new DataPathManager([]);
  fontSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 30, 36, 48, 60, 72, 96];
  selectedFontSize = 10;
  reports: Reports = {
    balanceSheet: {} as BalanceSheetReportDataDto,
    rentRoll: {} as RentRollReportDto,
    leasesWithBalancesDueReport: [] as HydratedLeaseDto[],
    pdf: undefined,
  };
  portfolios: PortfolioDto[] = [];
  properties: PropertyDto[] = [];
  taxInfo: TaxInfo = {
    availableYears: [new Date().getFullYear()],
    selectedYear: new Date().getFullYear(),
    paginatedVendorTaxDocuments: {
      currentPage: 1,
      totalPages: 1,
      pageSize: 100,
      totalItems: 0,
      items: [],
    },
    paginatedOwnerTaxDocuments: {
      currentPage: 1,
      totalPages: 1,
      pageSize: 100,
      totalItems: 0,
      items: [],
    },
    vendorsAndAmounts: [],
    ownersAndAmounts: [],
  };

  public getState(): PdfState {
    return {
      companyInfo: this.companyInfo,
      selectedDocument: this.selectedDocument,
      leaseTemplates: this.leaseTemplates,
      paginatedLeaseDrafts: this.paginatedLeaseDrafts,
      selectedAnnotation: this.selectedAnnotation,
      isEditing: this.isEditing,
      clickPosition: this.clickPosition,
      initialBounds: this.initialBounds,
      selectedAnnotationType: this.selectedAnnotationType,
      maxNumberOfSigners: this.maxNumberOfSigners,
      leaseDocumentSigners: this.leaseDocumentSigners,
      selectedSigner: this.selectedSigner,
      leaseTemplateCategories: this.leaseTemplateCategories,
      dataPathManager: this.dataPathManager,
      fontSizes: this.fontSizes,
      selectedFontSize: this.selectedFontSize,
      reports: this.reports,
      portfolios: this.portfolios,
      properties: this.properties,
      taxInfo: this.taxInfo,
    };
  }
}

export const pdfAppState = new PdfAppState();
