import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { GetOwnerTaxDocumentsUseCase } from './getOwnerTaxDocumentsUseCase';
import type { NextTaxDocumentBatchRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

export class GetNextOwnerTaxDocumentBatchUseCase extends MagicUseCase {
  public async runLogic(request: NextTaxDocumentBatchRequest) {
    const existingDocuments = this.getState().taxInfo.paginatedOwnerTaxDocuments;
    if (request.currentIndex < existingDocuments.totalItems) {
      const pageSet = new Set<number>();
      if (existingDocuments.items[request.currentIndex] === undefined) {
        pageSet.add(this.findPageByIndex(request.currentIndex, existingDocuments.pageSize, existingDocuments.totalItems));
      }
      const nextIndex = Math.min(request.currentIndex + 20, existingDocuments.totalItems - 1);
      if (existingDocuments.items[nextIndex] === undefined) {
        pageSet.add(this.findPageByIndex(nextIndex, existingDocuments.pageSize, existingDocuments.totalItems));
      }
      const prevIndex = Math.max(request.currentIndex - 20, 0);
      if (existingDocuments.items[prevIndex] === undefined) {
        pageSet.add(this.findPageByIndex(prevIndex, existingDocuments.pageSize, existingDocuments.totalItems));
      }
      const requests: Promise<any>[] = [];
      pageSet.forEach((page) => {
        requests.push(
          new GetOwnerTaxDocumentsUseCase().execute({
            entityId: request.entityId,
            page: page,
            itemsPerPage: existingDocuments.pageSize,
          })
        );
      });
      await Promise.all(requests);
    }
  }

  private findPageByIndex(index: number, pageSize: number, totalItems: number): number {
    if (index < 0) {
      return 1;
    }
    if (index >= totalItems) {
      return Math.floor(totalItems / pageSize) + 1;
    }
    return Math.floor(index / pageSize) + 1;
  }
}
