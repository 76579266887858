import { createSignal, Show } from 'solid-js';
import { DragAndDrop, IconImage, IconLoader, IconX, Image, toast } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface UploadProps extends LabeledGroupProps {
  onInput?: (value: string | undefined, url?: string | undefined) => void;
  labelClass?: string;
  value?: string;
  image?: Photo;
  type: string;
  onUploadingChange?: (uploading: boolean) => void;
}
export type Photo = {
  id?: string;
  url?: string;
};
export const LabeledUploadImage: Component<UploadProps> = (props) => {
  const { t } = useLocalization();
  const [deleting, setDeleting] = createSignal<string>();
  const [uploading, setUploading] = createSignal<boolean>(false);
  const { uploadFiles } = useCompanies();

  const validateUploadImg = (files: FileList) => {
    if (!files[0].type.startsWith('image/')) {
      toast.error(t('Only image files are allowed'));
      return false;
    }
    if (files[0]?.size > 10 * 1024 * 1024) {
      toast.error(t('Image size should be less than 10MB'));
      return false;
    }
    return true;
  };
  const handleDropFiles = async (files: FileList) => {
    setUploading(true);
    props.onUploadingChange?.(true);
    if (!validateUploadImg(files)) return;
    try {
      const result = await uploadFiles(files[0], 'image', true);
      if (result) {
        props?.onInput && props?.onInput(result?.file?.fileId, result?.file?.signedUrl);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUploading(false);
      props.onUploadingChange?.(false);
    }
  };

  const handleDeleteFile = async (fileId: string | undefined) => {
    setDeleting(fileId);
    props?.onInput && props?.onInput(undefined);
    setDeleting(undefined);
  };
  const labelJSX = (
    <div class="flex flex-col gap-1 md:flex-row md:items-center">
      <span>{t('Photo')}</span>
      <span class="text-xs font-normal normal-case text-text-level02">
        ({t(`This image is used only in a list to identify different ${props.type}`)})
      </span>
    </div>
  );

  return (
    <LabeledGroup {...props} labelJSX={labelJSX} labelClass={props.labelClass}>
      <Show
        when={props.image}
        fallback={
          <DragAndDrop
            class="flex cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray px-10 py-4 text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary hover-allowed:hover:bg-light-pink md:px-20"
            accept="image/*"
            multiple={false}
            onDropFiles={handleDropFiles}
            data-slot="dnd">
            <Show when={uploading()} fallback={<IconImage class="mt-2 size-10" />}>
              <IconLoader class="mt-2 size-10 animate-spin" />
            </Show>
            <p class="mb-4 mt-2 text-xs">{t('Drag and drop photos here')}</p>
            <span class="rounded-lg border border-primary px-5 py-2 text-sm text-primary">{t('Select from computer')}</span>
          </DragAndDrop>
        }>
        <div class="flex w-full items-center justify-center rounded-lg border border-dashed border-[#AEBBDB] py-4">
          <div class="relative overflow-hidden rounded-lg" data-slot="photo">
            <Image src={props.image?.url} class="aspect-square w-32 object-cover" />
            <button
              type="button"
              class="absolute right-1 top-1 rounded bg-black/30 p-1 text-white transition-colors hover-allowed:hover:bg-black/60"
              onClick={() => handleDeleteFile(props.image?.id)}>
              <Show when={deleting() === props.image?.id} fallback={<IconX class="size-4" />}>
                <IconLoader class="size-4 animate-spin" />
              </Show>
            </button>
          </div>
        </div>
      </Show>
    </LabeledGroup>
  );
};
