import { useParams } from '@solidjs/router';
import { createSignal, For, onMount, Show } from 'solid-js';
import IconAllProperties from '~/assets/images/owner-funds/allProperties.svg';
import IconSomeProperties from '~/assets/images/owner-funds/someProperties.svg';
import { Section } from '~/components/common/FormSectionTitle';
import { SelectableEntityName } from '~/components/common/SelectableEntityName';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { VendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { ChangeVendorTaxYearUseCase } from '~/pdfsigner/usecases/taxes/vendor/changeVendorTaxYearUseCase';
import { SelectAllVendorsUseCase } from '~/pdfsigner/usecases/taxes/vendor/selectAllVendorsUseCase';
import { SelectVendorUseCase } from '~/pdfsigner/usecases/taxes/vendor/selectVendorUseCase';
import { ToggleVendorSelectionUseCase } from '~/pdfsigner/usecases/taxes/vendor/toggleVendorSelectionUseCase';
import { cn } from '~/utils/classnames';
import type { TaxYearDocumentRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

const VendorCategoryOption = (props: { title: string; icon: string; isSelected: boolean; onClick: () => void }) => (
  <div
    onClick={() => props.onClick()}
    class={cn(
      'inline-flex grow cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover-allowed:hover:border-primary',
      props.isSelected && 'border-primary bg-[rgba(161,38,236,0.06)] text-primary'
    )}>
    <div class="relative mr-4 pr-3">
      <img src={props.icon} class="w-16" />
      <div class="absolute inset-y-[9px] left-full w-px bg-input-border" />
    </div>
    <span>{props.title}</span>
  </div>
);

export const ChooseVendors = () => {
  const { t } = useLocalization();
  const { vendorId } = useParams();
  const [isForAllVendors, setIsForAllVendors] = createSignal(true);
  const { execute: toggleVendorSelection } = useUseCase(ToggleVendorSelectionUseCase);
  const { execute: selectVendor } = useUseCase(SelectVendorUseCase);
  const { execute: selectAllVendors } = useUseCase(SelectAllVendorsUseCase);
  const { execute: changeTaxYear } = useUseCase(ChangeVendorTaxYearUseCase);
  const { model: vendorTaxAmounts } = usePresenter(VendorTaxAmountPresenter);

  onMount(() => {
    if (vendorId) {
      selectVendor(vendorId);
    } else {
      selectAllVendors();
    }
  });

  const onSelectAllVendors = () => {
    setIsForAllVendors(true);
  };

  const onSelectSomeVendors = () => {
    setIsForAllVendors(false);
  };

  const getDescriptionText = () => {
    if (vendorId) {
      return t('This will generate tax documents for the selected vendor.');
    }
    return t('Generate tax documents for vendors.');
  };

  return (
    <div class="flex grow flex-col px-[96px]">
      <div class="mb-4 text-center normal-case text-text-level03">{getDescriptionText()}</div>
      <div class="mb-4 flex flex-row items-center justify-center">
        <label for="year-select" class="mr-2 block font-semibold text-gray-700">
          {t('Year')}:
        </label>
        <select
          id="year-select"
          value={vendorTaxAmounts()?.selectedYear}
          onChange={(e) => changeTaxYear({ year: Number(e.target.value) } as TaxYearDocumentRequest)}
          class="block w-20 rounded-md border-gray-300 text-lg font-semibold shadow-sm focus:border-cyan-500 focus:ring-cyan-500">
          <For each={vendorTaxAmounts()?.years}>{(year) => <option value={year}>{year}</option>}</For>
        </select>
      </div>
      <Show
        when={
          vendorTaxAmounts() &&
          (vendorTaxAmounts()!.vendorTaxAmounts.newOrChanged.length > 0 || vendorTaxAmounts()!.vendorTaxAmounts.existing.length > 0)
        }
        fallback={
          <div class="flex size-full grow items-center justify-center border-partingline bg-white p-6 text-center text-lg normal-case text-gray-600">
            {t('Cannot generate tax documents, as there are no bills for the selected year')}
          </div>
        }>
        <Show when={!vendorId}>
          <Section required title={t('Vendors')} border>
            <div class="mb-6 flex grid-cols-2 gap-4">
              <VendorCategoryOption
                title={t('All vendors')}
                icon={IconAllProperties}
                isSelected={isForAllVendors()}
                onClick={onSelectAllVendors}
              />
              <VendorCategoryOption
                title={t('Some vendors')}
                icon={IconSomeProperties}
                isSelected={!isForAllVendors()}
                onClick={onSelectSomeVendors}
              />
            </div>
            <Show when={!isForAllVendors()}>
              <Show when={(vendorTaxAmounts()?.vendorTaxAmounts.newOrChanged ?? []).length > 0}>
                <div class="font-semibold normal-case">
                  {t('Vendors with new bills or changed compensation amounts in {year}', { year: vendorTaxAmounts()?.selectedYear })}
                </div>
                <div class="col-span-2 mb-4 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
                  <For
                    each={vendorTaxAmounts()?.vendorTaxAmounts.newOrChanged}
                    fallback={<div class={'text-center'}>{t('No vendors found')}</div>}>
                    {(vendor, index) => (
                      <SelectableEntityName
                        id={vendor.vendorId}
                        index={index()}
                        name={vendor.vendorName}
                        isSelected={vendor.isSelected}
                        onClick={toggleVendorSelection}
                        warningMessage={!vendor.hasTaxId ? t('Tax ID is missing!') : undefined}
                      />
                    )}
                  </For>
                </div>
              </Show>
              <Show when={(vendorTaxAmounts()?.vendorTaxAmounts.existing || []).length > 0}>
                <div class="font-semibold normal-case">
                  {t('Vendors with generated tax documents for {year}', { year: vendorTaxAmounts()?.selectedYear })}
                </div>
                <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
                  <For
                    each={vendorTaxAmounts()?.vendorTaxAmounts.existing}
                    fallback={<div class={'text-center'}>{t('No vendors found')}</div>}>
                    {(vendor, index) => (
                      <SelectableEntityName
                        id={vendor.vendorId}
                        index={index()}
                        name={vendor.vendorName}
                        isSelected={vendor.isSelected}
                        onClick={toggleVendorSelection}
                        warningMessage={!vendor.hasTaxId ? t('Tax ID is missing!') : undefined}
                      />
                    )}
                  </For>
                </div>
              </Show>
            </Show>
          </Section>
        </Show>
      </Show>
    </div>
  );
};
