import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, onMount, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import SectionedTableView from '~/components/common/SectionedTableView';
import { Modal } from '~/components/modals/Modal';
import { IconCirclePlus } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { usePdfPrinter } from '~/hooks/usePdfPrinter';
import OwnerTaxDocumentsCell from '~/pages/owner/owner-details/components/OwnerTaxDocumentsCell';
import TaxYearHeader from '~/pages/vendors/components/TaxYearHeader';
import { YearlyOwnerTaxDocPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { CheckIfTaxDocumentsCanBeGenerated } from '~/pdfsigner/usecases/taxes/checkIfTaxDocumentsCanBeGenerated';
import { GetNextOwnerTaxDocumentBatchUseCase } from '~/pdfsigner/usecases/taxes/owner/getNextOwnerTaxDocumentBatchUseCase';
import { GetOwnerTaxAmountsUseCase } from '~/pdfsigner/usecases/taxes/owner/getOwnerTaxAmountsUseCase';
import { GetOwnerTaxDocumentsUseCase } from '~/pdfsigner/usecases/taxes/owner/getOwnerTaxDocumentsUseCase';
import { PrintOwnerTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/owner/printOwnerTaxDocsUseCase';
import { RegenerateOwnerTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/owner/regenerateOwnerTaxDocsUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { OwnerTaxAmountsPage } from './OwnerTaxAmountsPage';
import type { PresentableOwnerTaxDocument } from '~/pdfsigner/ui/types/presentableTaxDocs';
import type {
  GetTaxDocumentsRequest,
  NextTaxDocumentBatchRequest,
  TaxDocRegenerationRequest,
  TaxYearDocumentRequest,
} from '~/pdfsigner/usecases/types/taxDocumentRequests';

export const OwnerTaxDocumentsPage = () => {
  const { t } = useLocalization();
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const { printPdf, isPrinting } = usePdfPrinter();
  const { execute: getTaxDocuments, isLoading } = useUseCase(GetOwnerTaxDocumentsUseCase);
  const { execute: getNextTaxDocBatch } = useUseCase(GetNextOwnerTaxDocumentBatchUseCase);
  const {
    execute: regenerateTaxDoc,
    isLoading: isRegenerating,
    didSucceed: didRegenerateTaxDoc,
  } = useUseCase(RegenerateOwnerTaxDocsUseCase);
  const { execute: printTaxDocs } = useUseCase(PrintOwnerTaxDocsUseCase);
  const { execute: checkIfCanGenerateTaxDocs, didSucceed: canGenerateTaxDocs } = useUseCase(CheckIfTaxDocumentsCanBeGenerated);
  const { execute: getOwnerTaxAmount } = useUseCase(GetOwnerTaxAmountsUseCase);
  const { model: yearlyOwnerTaxDocuments } = usePresenter(YearlyOwnerTaxDocPresenter);
  const [yearDocument, setYearDocument] = createSignal<TaxYearDocumentRequest>();

  onMount(() => getTaxDocuments({ page: 1, entityId: ownerId } as GetTaxDocumentsRequest));

  const onCellScrolledIntoView = async (index: number) => {
    await getNextTaxDocBatch({ entityId: ownerId, currentIndex: index } as NextTaxDocumentBatchRequest);
  };

  const onRedoClicked = async (year: string, document: PresentableOwnerTaxDocument) => {
    await getOwnerTaxAmount({ year: Number(year), entityId: document.entityId } as TaxYearDocumentRequest);
    setYearDocument({ year: Number(year), entityId: document.entityId } as TaxYearDocumentRequest);
  };

  const onConfirmRegenerateClicked = async () => {
    await regenerateTaxDoc({
      year: yearDocument()!.year,
      entityId: yearDocument()!.entityId,
      shouldRefetchAllData: !ownerId,
    } as TaxDocRegenerationRequest);
  };

  createEffect(() => {
    if (didRegenerateTaxDoc()) {
      setYearDocument(undefined);
    }
  });

  const onGenerateButtonClicked = async () => {
    await checkIfCanGenerateTaxDocs();
    if (canGenerateTaxDocs()) {
      const url = ownerId ? `/users/owners/tax-documents/generate/${ownerId}` : `/users/owners/tax-documents/generate`;
      navigate(url);
    }
  };

  return (
    <>
      <div class="flex size-full flex-col">
        <div class="mb-5 flex w-full items-center justify-between gap-4 rounded-lg border border-partingline bg-white px-6 py-3 text-lg font-semibold">
          {t('Statements')}
          <div>
            <Button onClick={onGenerateButtonClicked}>
              <IconCirclePlus class="size-5" />
              {t('Generate')}
            </Button>
          </div>
        </div>
        <Show
          when={!isLoading()}
          fallback={
            <div class="flex size-full flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" size={100} showIcon={true} />
            </div>
          }>
          <Show
            when={yearlyOwnerTaxDocuments() && yearlyOwnerTaxDocuments()!.length > 0}
            fallback={
              <div class="flex size-full grow items-center justify-center rounded-lg border border-partingline bg-white p-6 text-lg text-gray-600">
                {t('No statements available')}
              </div>
            }>
            <SectionedTableView
              class="min-w-min rounded-lg"
              sectionClass="mb-4 min-w-min rounded-lg border border-partingline bg-white p-4"
              numberOfSections={() => yearlyOwnerTaxDocuments()?.length || 0}
              numberOfRowsInSection={(section) => yearlyOwnerTaxDocuments()?.[section].owners.length || 0}
              headerForSection={(section) => (
                <TaxYearHeader
                  class="pl-2 text-2xl font-bold text-gray-800"
                  year={yearlyOwnerTaxDocuments()?.[section].year || ''}
                  shouldShowPrintButton={!ownerId}
                  onPrint={(year) => printPdf(() => printTaxDocs({ year, entityType: TaxEntityTypes.Owner }))}
                />
              )}
              rowForSection={(section, row) => {
                return (
                  <OwnerTaxDocumentsCell
                    year={yearlyOwnerTaxDocuments()?.[section].year || ''}
                    class="m-4 rounded-md border border-gray-300 bg-white p-4"
                    indexOffset={yearlyOwnerTaxDocuments()?.[section].owners[row].indexOffset}
                    ownerName={ownerId ? undefined : yearlyOwnerTaxDocuments()?.[section].owners[row].ownerName || ''}
                    taxDocuments={yearlyOwnerTaxDocuments()?.[section].owners[row].taxDocuments || []}
                    onRowDidScrollIntoView={onCellScrolledIntoView}
                    onRedo={onRedoClicked}
                  />
                );
              }}
            />
          </Show>
        </Show>
      </div>
      <Show when={yearDocument()}>
        <Modal
          visible
          class="lg"
          title={`${t('Redo tax document')}`}
          onCancel={() => setYearDocument(undefined)}
          onDone={onConfirmRegenerateClicked}
          showFooter={!isRegenerating()}>
          <Show
            when={!isRegenerating()}
            fallback={
              <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
                <RingLoader color="#a126ec" size={100} showIcon={true} />
              </div>
            }>
            <OwnerTaxAmountsPage class="px-5" />
          </Show>
        </Modal>
      </Show>
      <Show when={isPrinting()}>
        <Modal visible showFooter={false} class="lg" title={`${t('Printing')}...`}>
          <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" size={100} showIcon={true} />
          </div>
        </Modal>
      </Show>
    </>
  );
};
