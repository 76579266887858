import { For, Show } from 'solid-js';
import IconSelected from '~/assets/images/common/iconSelected.svg?component-solid';
import { unicodeToEscapeSequence } from '~/components/common/FeedbackIcon/utils';
import { useLocalization } from '~/contexts/global';
import { validArr } from '~/utils/tool';
export const Footer = (props: { icons: string[]; onDelete: (icon: string) => void }) => {
  const { t } = useLocalization();
  return (
    <Show when={validArr(props.icons)}>
      <div class="flex h-10 items-center gap-3 border-t border-[#e9eaef] bg-[#f8f8f8] px-2">
        <div class="flex items-center gap-1 text-sm text-text-level03">
          <IconSelected />
          <span>{t('Selected')}</span>
        </div>
        <div class="thinscroll flex max-w-48 items-center gap-2 overflow-x-auto">
          <For each={props.icons}>
            {(icon) => (
              <div
                title={t('Remove')}
                onClick={() => {
                  props.onDelete(icon);
                }}
                class="flex size-7 shrink-0 cursor-pointer items-center justify-center rounded-full border border-essential-colour hover-allowed:hover:bg-essential-colour">
                <emoji-regular class="text-base">{unicodeToEscapeSequence(icon)}</emoji-regular>
              </div>
            )}
          </For>
        </div>
      </div>
    </Show>
  );
};
