import { For, Show } from 'solid-js';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { SelectedVendorTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { UpdateVendorIncomeAmountUseCase } from '~/pdfsigner/usecases/taxes/vendor/updateVendorIncomeAmountUseCase';
import { cn } from '~/utils/classnames';
import { VendorTaxAmountLine } from './VendorTaxAmountLine';

export interface VendorTaxAmountsPageProps {
  class?: string;
}

export const VendorTaxAmountsPage = (props: VendorTaxAmountsPageProps) => {
  const { t } = useLocalization();
  const { execute: updateVendorIncomeAmount } = useUseCase(UpdateVendorIncomeAmountUseCase);
  const { model: vendorTaxAmounts } = usePresenter(SelectedVendorTaxAmountPresenter);

  const onInput = async (vendorId: string, value?: string) => {
    await updateVendorIncomeAmount({ vendorId, amount: value });
  };

  return (
    <Show
      when={vendorTaxAmounts() && (vendorTaxAmounts()!.newOrChanged.length > 0 || vendorTaxAmounts()!.existing.length > 0)}
      fallback={<div class={'text-center normal-case'}>{t('There is no income for the selected year.')}</div>}>
      <Show when={vendorTaxAmounts() && vendorTaxAmounts()!.newOrChanged.length > 0}>
        <Section
          title={t('New or changed compensation amounts')}
          class={cn('mb-4 px-[96px]', props.class)}
          titleClass="mt-2 px-0 normal-case">
          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 py-4">
              <div class="mb-2 flex flex-row justify-between p-2 normal-case">
                <div>{t('Vendor')}</div>
                <div>{t('Non-employee compensation')}</div>
              </div>
              <For each={vendorTaxAmounts()?.newOrChanged}>
                {(vendor) => (
                  <VendorTaxAmountLine
                    id={vendor.vendorId}
                    name={vendor.vendorName}
                    warningMessage={vendor.hasTaxId ? undefined : t('Tax ID is missing!')}
                    amount={vendor.taxAmount}
                    isAmountEditable={true}
                    onInput={onInput}
                  />
                )}
              </For>
            </div>
          </div>
        </Section>
      </Show>
      <Show when={vendorTaxAmounts() && vendorTaxAmounts()!.existing.length > 0}>
        <Section title={t('Existing compensation amounts')} class={cn('px-[96px]', props.class)} titleClass="mt-2 px-0 normal-case">
          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 py-4">
              <div class="mb-2 flex flex-row justify-between p-2 normal-case">
                <div>{t('Vendor')}</div>
                <div>{t('Non-employee compensation')}</div>
              </div>
              <For each={vendorTaxAmounts()?.existing}>
                {(vendor) => (
                  <VendorTaxAmountLine
                    id={vendor.vendorId}
                    name={vendor.vendorName}
                    warningMessage={vendor.hasTaxId ? undefined : t('Tax ID is missing!')}
                    amount={vendor.taxAmount}
                    isAmountEditable={true}
                    onInput={onInput}
                  />
                )}
              </For>
            </div>
          </div>
        </Section>
      </Show>
    </Show>
  );
};
