import { Show, For, createSignal, createMemo } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import { BaseModal } from '~/components/modals/BaseModal';
import { ReportStatusIndicator } from '~/components/rental-application/components/StatusIndicators';
import { REPORT_STATUS_MAP, statusIndicatorsArray } from '~/components/rental-application/components/constants';
import { ReportNotReady } from '~/components/rental-application/rental-application-details/renter-report-tab/applicant-summary/components/ReportNotReady';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { ReportStatus, TransunionReportType } from '~/swagger/Api';
import { REPORT_LOAD_FAILED_MARK } from '~/utils/constant';

export const TransunionReports = () => {
  const { t } = useLocalization();
  const { applicationStore, previewReport, setApplicationStore, requestReport } = useRentalApplication();

  const [parsedReport, setParsedReport] = createSignal<any>();

  const reportStatus = createMemo(() => applicationStore.application?.reportStatus ?? 'notRequested');

  const reports: { title: string; type: MagicDoor.Api.TransunionReportType }[] = [
    {
      title: t('Credit report'),
      type: TransunionReportType.Credit,
    },
    {
      title: t('Eviction relation proceedings'),
      type: TransunionReportType.Eviction,
    },
    {
      title: t('Criminal background report'),
      type: TransunionReportType.Criminal,
    },
  ];

  const handleGetReport = async (type: MagicDoor.Api.TransunionReportType) => {
    const report = await previewReport(type);
    setParsedReport(!report || report === REPORT_LOAD_FAILED_MARK ? REPORT_LOAD_FAILED_MARK : report);
  };

  return (
    <>
      <div class="flex flex-wrap justify-between gap-4">
        <Show
          when={applicationStore.application?.reportStatus !== ReportStatus.NotRequested}
          fallback={
            <div class="flex h-[132px] w-full grow flex-col items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
              <div class="flex flex-col items-start justify-between gap-1.5">
                <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{t('Request report')}</h2>
                <ReportStatusIndicator label={ReportStatus.NotRequested} color="#BEC6DB" />
              </div>
              <Button
                loading={requestReport.loading}
                onClick={() => requestReport()}
                disabled={applicationStore.application?.draft}
                variant="outlined"
                class="flex w-full justify-center text-sm capitalize">
                {t('Request report')}
              </Button>
            </div>
          }>
          <For each={reports}>
            {(section) => (
              <div class="lex h-[132px] w-full flex-1 flex-col  items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
                <div class="flex flex-col items-start justify-between gap-1.5">
                  <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{section.title}</h2>
                  <ReportStatusIndicator
                    {...(statusIndicatorsArray.find(
                      (item) => item.label.toLowerCase() === REPORT_STATUS_MAP[reportStatus()].toLowerCase()
                    ) as (typeof statusIndicatorsArray)[0])}
                  />
                </div>
                <Button
                  onClick={() => handleGetReport(section.type)}
                  disabled={reportStatus() !== 'available'}
                  variant="outlined"
                  class="mt-3 flex w-full justify-center text-sm capitalize">
                  {t('View report')}
                </Button>
              </div>
            )}
          </For>
        </Show>
      </div>

      <BaseModal
        class="h-full !w-[960px] max-w-4xl"
        visible={!!applicationStore.currentPreviewReportType}
        onClose={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        showCloseButton
        title={reports.find((item) => item.type === applicationStore.currentPreviewReportType)?.title}>
        <Show
          when={!previewReport.loading}
          fallback={
            <div class="flex size-full items-center justify-center">
              <RingLoader color="#A126EC" />
            </div>
          }>
          <Show when={parsedReport() !== REPORT_LOAD_FAILED_MARK} fallback={<ReportNotReady />}>
            <div class="p-6">{parsedReport()}</div>
          </Show>
        </Show>
      </BaseModal>
    </>
  );
};
