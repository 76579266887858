import { Show } from 'solid-js';
import IconPending from '~/assets/images/common/pending.svg';
import IconStripe from '~/assets/third-parties/stripeSm.svg';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';
import { currency } from '~/utils/number';
interface StripePanelProps {
  stripeUI: any;
  bankAccount: any;
}

export const StripePanel = (props: StripePanelProps) => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Stripe')}>
      <div class="flex flex-col justify-center gap-2 p-3 ">
        <div class={`${props.stripeUI?.getStripeStatusBgColor()} flex justify-center rounded-md py-4`}>
          <Show when={!props.stripeUI?.isChargesEnabled() && !props.stripeUI?.isDetailSubmitted()}>
            <Tooltip message={t('Redirect to Stripe')} class="cursor-pointer" align="top">
              <Button
                onClick={() => props.stripeUI?.openStripeLink(props.bankAccount?.id)}
                disabled={props.stripeUI?.isStripeLoading()}
                size="xs"
                loading={props.stripeUI?.isStripeLinking()}
                class="rounded-full border-none bg-[#635BFF] hover-allowed:hover:bg-[#635BFF]/90">
                <img src={IconStripe} />
                <span>{t('Link Stripe')}</span>
              </Button>
            </Tooltip>
          </Show>
          <Show when={!props.stripeUI?.isChargesEnabled() && props.stripeUI?.isStripeConnected() && props.stripeUI?.isDetailSubmitted()}>
            <Button variant="text" size="xs" class="flex gap-1 font-semibold text-[#FF8618]">
              <img src={IconPending} />
              <span>{t('Pending')}</span>
            </Button>
          </Show>
          <Show when={props.stripeUI?.isChargesEnabled()}>
            <Button variant="text" size="xs" class="font-semibold text-[#28D9A5]">
              {t('Connected')}
            </Button>
          </Show>
        </div>

        <div class="flex flex-col gap-1 text-sm">
          <div class="flex justify-between text-text-level02">
            {t('Pending')}:
            <Show when={props.stripeUI?.localStripeDetails()?.pending} fallback={<div class="font-semibold">--</div>}>
              <span class="font-semibold"> {currency(props.stripeUI?.localStripeDetails()?.pending)}</span>
            </Show>
          </div>
          <div class="flex justify-between text-text-level02">
            {t('Available')}:
            <Show when={props.stripeUI?.localStripeDetails()?.available} fallback={<div class="font-semibold">--</div>}>
              <span class="font-semibold">{currency(props.stripeUI?.localStripeDetails()?.available)}</span>
            </Show>
          </div>
        </div>
      </div>
    </Panel>
  );
};
