import { useNavigate } from '@solidjs/router';
import { Show, createEffect, onMount } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus, LeaseStatusElement, LeaseTitle } from '~/components/leases';
import { Html, ListView } from '~/components/ui';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { useLeaseFilter, useLocalPagination } from '~/hooks';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import { diffDates } from '~/utils/date';
export const LeasesList = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { page, pageSize, syncLocalPagination } = useLocalPagination('LeasesListView');
  const { leases, getLeases, loading, pagination } = useLeasesList();
  const { filterValue, filterItems, parseFilter, handleFilterReset } = useLeaseFilter({ status: true, date: true });

  const necessaryParams = { ended: false };

  onMount(() => {
    getLeases({ page: page(), pageSize: pageSize(), ...necessaryParams });

    createEffect(() => {
      syncLocalPagination(pagination());
    });
  });

  return (
    <div class="relative w-full">
      <MobileListView
        class="overflow-hidden md:hidden"
        titleClass="w-full"
        searchClass="ml-1 w-72"
        renderItem={(item) => {
          return (
            <div class="flex flex-col gap-2 p-3" onClick={() => navigate(`/leasing/leases/${item.id}`)}>
              <div class="flex justify-between">
                <LeaseTitle lease={item as any} showTenants />
              </div>
              <div class="flex gap-3 divide-x rounded-lg bg-light-gray p-3">
                <LeaseStatus lease={item as any} />
                <LeasePeriod lease={item as any} />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Balance')}:</span>
                <CnMoney money={item.balance} />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-xs font-light"> {t('Posted balance')}:</span>
                <CnMoney money={item.postedBalance} />
              </div>
              <div class="flex items-center gap-1">
                <LinkButton
                  href={`/leasing/leases/${item.id}/transactions/add-charge`}
                  variant="outlined"
                  rounded="full"
                  class="text-md w-full justify-center border-link px-3 py-1.5 text-center text-link hover-allowed:hover:bg-link/10 [&_span]:whitespace-nowrap">
                  {t('Post a charge')}
                </LinkButton>
              </div>
            </div>
          );
        }}
        totalPages={pagination()?.totalPages}
        page={pagination()?.currentPage}
        pageSize={pagination()?.pageSize}
        filterValue={filterValue()}
        filterItems={filterItems()}
        onFilterReset={handleFilterReset}
        onChange={({ page, pageSize, search, filter }) => {
          getLeases({ page, pageSize, search, ...parseFilter(filter), ...necessaryParams });
        }}
        title={t('Leases')}
        searchPlaceholder={t('Search leases')}
        empty={<Empty description={t('No Leases')} />}
        data={leases()}
        loading={loading()}
      />

      <ListView
        class="hidden md:block"
        onChange={({ page, pageSize, search, filter }) => {
          getLeases({ page, pageSize, search, ...parseFilter(filter), ...necessaryParams });
        }}
        title={t('Leases')}
        searchPlaceholder={t('Search leases')}
        totalPages={pagination()?.totalPages}
        page={pagination()?.currentPage}
        pageSize={pagination()?.pageSize}
        filterValue={filterValue()}
        filterItems={filterItems()}
        onFilterReset={handleFilterReset}
        rowLink={(item) => `/leasing/leases/${item.id}`}
        skeletonSize={10}
        loading={loading()}
        data={leases()}
        empty={<Empty description={t('No Leases')} />}
        columns={[
          {
            title: t('Lease'),
            render: (item) => <LeaseTitle lease={item} showTenants />,
          },
          {
            title: t('Lease term'),
            headerClass: 'w-56',
            render: (item) => (
              <>
                <LeaseStatus lease={item} />
                <LeasePeriod lease={item} />
              </>
            ),
          },
          {
            title: t('Status'),
            headerClass: 'w-48',
            render: (item) => <LeaseStatusElement lease={item} />,
          },
          {
            title: t('Balance'),
            headerClass: 'w-32 text-right',
            class: 'text-right',
            render: (item) => <CnMoney money={item.balance} zeroHolder="--" />,
          },
          {
            title: t('Posted balance'),
            headerClass: 'w-32 text-right',
            class: 'text-right',
            render: (item) => <CnMoney money={item.postedBalance} zeroHolder="--" />,
          },
          {
            title: t('Operation'),
            headerClass: 'w-72 text-right',
            render: (item) => (
              <div class="relative z-10 flex justify-end gap-3">
                <Show when={item.balance > 0 && diffDates(item.end, item.earliestUnpaidBillDueDate)}>
                  <QuickLeaseNotifyBalanceDue targets={[item.id]} />
                </Show>
                <LinkButton
                  href={`/leasing/leases/${item.id}/transactions/add-charge`}
                  variant="outlined"
                  rounded="full"
                  class="border-link px-3 py-1 text-xs text-link hover-allowed:hover:bg-link/10 [&_span]:whitespace-nowrap">
                  {t('Post a charge')}
                </LinkButton>
              </div>
            ),
          },
        ]}
        footerSummary={
          <Html as="p" class="flex-1 text-xs text-text-level03 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
            {t('A total of <b>{count}</b> related lease contracts', {
              count: loading() ? '-' : pagination().totalCount.toString(),
            })}
          </Html>
        }
      />
    </div>
  );
};
