import type { Annotation } from './annotation';

export enum TaxDocumentType {
  MISC1099 = 'misc',
  NEC1099 = 'nec',
}

export enum TaxDocumentStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
  NotFound = 'notFound',
}

export interface TaxDocument {
  id: string;
  calendarYear: number;
  entityId: string;
  entityName: string;
  taxDocumentType: TaxDocumentType;
  url: string;
  documentName: string;
  taskId: string;
  status: TaxDocumentStatus;
  taskErrorMessage: string;
  createdAt: string;
  updatedAt: string;
  annotations: Annotation[];
}

export interface VendorTaxDocument extends TaxDocument {
  amount: number;
}

export interface OwnerTaxDocument extends TaxDocument {
  rentAmount: number;
  otherAmount: number;
}

export interface PaginatedVendorTaxDocuments {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalItems: number;
  items: VendorTaxDocument[];
}

export interface PaginatedOwnerTaxDocuments {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalItems: number;
  items: OwnerTaxDocument[];
}
