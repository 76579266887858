import { useParams } from '@solidjs/router';
import { createSignal, For, onMount, Show } from 'solid-js';
import IconAllProperties from '~/assets/images/owner-funds/allProperties.svg';
import IconSomeProperties from '~/assets/images/owner-funds/someProperties.svg';
import { Section } from '~/components/common/FormSectionTitle';
import { SelectableEntityName } from '~/components/common/SelectableEntityName';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { OwnerTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { ChangeOwnerTaxYearUseCase } from '~/pdfsigner/usecases/taxes/owner/changeOwnerTaxYearUseCase';
import { SelectAllOwnersUseCase } from '~/pdfsigner/usecases/taxes/owner/selectAllOwnersUseCase';
import { SelectOwnerUseCase } from '~/pdfsigner/usecases/taxes/owner/selectOwnerUseCase';
import { ToggleOwnerSelectionUseCase } from '~/pdfsigner/usecases/taxes/owner/toggleOwnerSelectionUseCase';
import { cn } from '~/utils/classnames';
import type { TaxYearDocumentRequest } from '~/pdfsigner/usecases/types/taxDocumentRequests';

const VendorCategoryOption = (props: { title: string; icon: string; isSelected: boolean; onClick: () => void }) => (
  <div
    onClick={() => props.onClick()}
    class={cn(
      'inline-flex grow cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-primary',
      props.isSelected && 'border-primary bg-[rgba(161,38,236,0.06)] text-primary'
    )}>
    <div class="relative mr-4 pr-3">
      <img src={props.icon} class="w-16" />
      <div class="absolute inset-y-[9px] left-full w-px bg-input-border" />
    </div>
    <span>{props.title}</span>
  </div>
);

export const ChooseOwners = () => {
  const { t } = useLocalization();
  const { ownerId } = useParams();
  const [isForAllOwners, setIsForAllowners] = createSignal(true);
  const { execute: toggleOwnerSelection } = useUseCase(ToggleOwnerSelectionUseCase);
  const { execute: changeTaxYear } = useUseCase(ChangeOwnerTaxYearUseCase);
  const { execute: selectOwner } = useUseCase(SelectOwnerUseCase);
  const { execute: selectAllOwners } = useUseCase(SelectAllOwnersUseCase);
  const { model: ownerTaxAmounts } = usePresenter(OwnerTaxAmountPresenter);

  onMount(() => {
    if (ownerId) {
      selectOwner(ownerId);
    } else {
      selectAllOwners();
    }
  });

  const onSelectAllVendors = () => {
    setIsForAllowners(true);
  };

  const onSelectSomeVendors = () => {
    setIsForAllowners(false);
  };

  const getDescriptionText = () => {
    if (ownerId) {
      return t('Generate tax documents for the selected owner.');
    }
    return t('Generate tax documents for owners.');
  };

  return (
    <div class="flex grow flex-col px-[96px]">
      <div class="mb-4 text-center normal-case text-text-level03">{getDescriptionText()}</div>
      <div class="mb-4 flex flex-row items-center justify-center">
        <label for="year-select" class="mr-2 block font-semibold text-gray-700">
          {t('Year')}:
        </label>
        <select
          id="year-select"
          value={ownerTaxAmounts()?.selectedYear}
          onChange={(e) => changeTaxYear({ year: Number(e.target.value) } as TaxYearDocumentRequest)}
          class="block w-20 rounded-md border-gray-300 text-lg font-semibold shadow-sm focus:border-cyan-500 focus:ring-cyan-500">
          <For each={ownerTaxAmounts()?.years}>{(year) => <option value={year}>{year}</option>}</For>
        </select>
      </div>
      <Show
        when={
          ownerTaxAmounts() &&
          (ownerTaxAmounts()!.ownerTaxAmounts.newOrChanged.length > 0 || ownerTaxAmounts()!.ownerTaxAmounts.existing.length > 0)
        }
        fallback={
          <div class="flex size-full grow items-center justify-center border-partingline bg-white p-6 text-center text-lg normal-case text-gray-600">
            {t('Cannot generate tax documents, as there is no income for the selected year')}
          </div>
        }>
        <Show when={!ownerId}>
          <Section required title={t('Owners')} border>
            <div class="mb-6 grid grid-cols-2 gap-4">
              <VendorCategoryOption
                title={t('All owners')}
                icon={IconAllProperties}
                isSelected={isForAllOwners()}
                onClick={onSelectAllVendors}
              />
              <VendorCategoryOption
                title={t('Some owners')}
                icon={IconSomeProperties}
                isSelected={!isForAllOwners()}
                onClick={onSelectSomeVendors}
              />
            </div>
            <Show when={!isForAllOwners()}>
              <Show when={(ownerTaxAmounts()?.ownerTaxAmounts.newOrChanged ?? []).length > 0}>
                <div class="font-semibold normal-case">
                  {t('Owners with new or changed income amounts in {year}', { year: ownerTaxAmounts()?.selectedYear })}
                </div>
                <div class="col-span-2 mb-4 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
                  <For
                    each={ownerTaxAmounts()?.ownerTaxAmounts.newOrChanged}
                    fallback={<div class={'text-center'}>{t('No owners found')}</div>}>
                    {(owner, index) => (
                      <SelectableEntityName
                        id={owner.ownerId}
                        index={index()}
                        name={owner.ownerName}
                        isSelected={owner.isSelected}
                        onClick={toggleOwnerSelection}
                        warningMessage={!owner.hasTaxId ? t('Tax ID is missing!') : undefined}
                      />
                    )}
                  </For>
                </div>
              </Show>
              <Show when={(ownerTaxAmounts()?.ownerTaxAmounts.existing || []).length > 0}>
                <div class="font-semibold normal-case">
                  {t('Owners with generated tax documents for {year}', { year: ownerTaxAmounts()?.selectedYear })}
                </div>
                <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 pt-4">
                  <For
                    each={ownerTaxAmounts()?.ownerTaxAmounts.existing}
                    fallback={<div class={'text-center'}>{t('No owners found')}</div>}>
                    {(owner, index) => (
                      <SelectableEntityName
                        id={owner.ownerId}
                        index={index()}
                        name={owner.ownerName}
                        isSelected={owner.isSelected}
                        onClick={toggleOwnerSelection}
                        warningMessage={!owner.hasTaxId ? t('Tax ID is missing!') : undefined}
                      />
                    )}
                  </For>
                </div>
              </Show>
            </Show>
          </Section>
        </Show>
      </Show>
    </div>
  );
};
