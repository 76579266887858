import { MissingCompanyTaxIdError } from '~/errors/missingCompanyTaxIdError';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { taxDocumentsRepository } from '~/repositories/taxDocumentRepository';

export class CheckIfTaxDocumentsCanBeGenerated extends MagicUseCase {
  public async runLogic() {
    const canGenerateTaxDocs = await taxDocumentsRepository.canGenerateTaxDocs();
    if (!canGenerateTaxDocs) {
      throw new MissingCompanyTaxIdError();
    }
  }
}
