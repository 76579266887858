import { createMemo, createSignal } from 'solid-js';
import IconArchive from '~/assets/images/common/archive.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { MultiSectionPanel } from '~/components/common/Panels';
import { useLocalization, useUnits } from '~/contexts/global';
import { ArchiveUnitModal } from './components/ArchiveUnitModal';
import { ContactInformation } from './components/ContactInformation';
import { FactsAndFeatures } from './components/FactsAndFeatures';
import { ListingTerms } from './components/ListingTerms';
import { UnitInformation } from './components/UnitInformation';
import { UnitPhotos } from './components/UnitPhotos';

// FIXME: should update with real data.

export const EditUnit = () => {
  const { t } = useLocalization();
  const { unit, deleteUnit } = useUnits();
  const [archiveModal, showArchiveModal] = createSignal(false);
  const [deleting, setDeleting] = createSignal(false);

  const sections = createMemo(() => [
    {
      id: 'information',
      title: t('Unit information'),
      component: UnitInformation,
    },
    {
      id: 'terms',
      title: t('Terms'),
      component: ListingTerms,
    },
    {
      id: 'photos',
      title: t('Photos'),
      component: UnitPhotos,
    },
    {
      id: 'features',
      title: t('Facts and features'),
      component: FactsAndFeatures,
    },
    {
      id: 'contact',
      title: t('Contact information'),
      component: ContactInformation,
    },
  ]);

  const handleArchive = async () => {
    setDeleting(true);
    await deleteUnit(unit()?.id as string);
    setDeleting(false);
    showArchiveModal(false);
  };

  return (
    <>
      <MultiSectionPanel
        class="h-section2"
        sections={sections()}
        title={t('Edit unit')}
        model={unit() as MagicDoor.Api.HydratedUnitDto}
        actions={
          <Button
            variant="outlined"
            class="border-text-level03 text-text-level03 hover-allowed:hover:border-danger hover-allowed:hover:bg-danger/10 hover-allowed:hover:text-danger"
            onClick={() => showArchiveModal(true)}>
            <IconArchive /> {t('Archive Unit')}
          </Button>
        }>
        {() => <div />}
      </MultiSectionPanel>
      <ArchiveUnitModal
        visible={archiveModal()}
        loading={deleting()}
        onClose={() => showArchiveModal(false)}
        onArchive={() => handleArchive()}
      />
    </>
  );
};
